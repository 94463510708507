<div class="app-body">
    <div class="row">
        <div class="col-xxl-12">
            <div class="card" style="margin-left: 1px">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-ToChucVuSanXuatVungTrong" data-bs-toggle="tab"
                                    href="#ToChucVuSanXuatVungTrong" role="tab" aria-controls="ToChucVuSanXuatVungTrong"
                                    aria-selected="true">
                                    <span class="badge bg-warning" style="font-size: 16px">2. Vùng trồng/Nông hộ</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="ToChucVuSanXuatVungTrong" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">Vụ mùa</label>
                                        <mat-select class="form-control" name="ParentID"
                                            [(ngModel)]="ToChucVuSanXuatVungTrongService.baseParameter.ParentID">
                                            <input placeholder="Tìm..." class="form-control"
                                                (keyup)="ToChucVuSanXuatFilter($event.target.value)">
                                            <mat-option *ngFor="let item of ToChucVuSanXuatService.list001"
                                                [value]="item.ID">
                                                {{item.DanhMucVuMuaName}} - {{item.BatDau | date:'dd/MM/yyyy'}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                    <div class="col-lg-7 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                                        <input name="SearchString"
                                            [(ngModel)]="ToChucVuSanXuatVungTrongService.baseParameter.SearchString"
                                            placeholder="Tìm..." class="form-control"
                                            (keyup.enter)="ToChucVuSanXuatVungTrongSearch()">
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                                        <a class="btn btn-success" title="Tìm..." style="width: 100%;"
                                            (click)="ToChucVuSanXuatVungTrongSearch()"><i class="bi bi-search"></i></a>
                                    </div>
                                </div>
                                <div class="card mb-4 card-warning">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Vùng trồng/Nông hộ
                                            <span
                                                *ngIf="ToChucVuSanXuatVungTrongService.list">({{ToChucVuSanXuatVungTrongService.list.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                                    #ToChucVuSanXuatVungTrongSort="matSort"
                                                    [dataSource]="ToChucVuSanXuatVungTrongService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{ToChucVuSanXuatVungTrongPaginator.pageSize *
                                                            ToChucVuSanXuatVungTrongPaginator.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>ID
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.ID}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Vùng
                                                            trồng/Nông hộ
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text" placeholder="Name"
                                                                name="Name{{element.ID}}" [(ngModel)]="element.Name">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Code">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Điện thoại
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text" placeholder="Code"
                                                                name="Code{{element.ID}}" [(ngModel)]="element.Code">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="MaSoVungTrong">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Mã số vùng
                                                            trồng
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Mã số vùng trồng"
                                                                name="MaSoVungTrong{{element.ID}}"
                                                                [(ngModel)]="element.MaSoVungTrong">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Active">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sản xuất
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline mb-6">
                                                                <input type="checkbox" class="form-check-input"
                                                                    name="Active{{element.ID}}"
                                                                    [(ngModel)]="element.Active" />
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsChungThuc">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chứng thực
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline mb-6">
                                                                <input type="checkbox" class="form-check-input" disabled
                                                                    name="IsChungThuc{{element.ID}}"
                                                                    [(ngModel)]="element.IsChungThuc" />
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <button [disabled]="element.IsChungThuc"
                                                                class="btn btn-info" style="height: 41px;"
                                                                (click)="ToChucVuSanXuatVungTrongSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </button>
                                                            &nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-info"
                                                                title="Sao chép tất cả sang vùng trồng khác cùng vụ mùa"
                                                                (click)="ToChucVuSanXuatNhatKyCopy(element)">Sao chép
                                                                tất cả</a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucVuSanXuatVungTrongService.displayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucVuSanXuatVungTrongService.displayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="!NotificationService.IsMobile"
                                                    #ToChucVuSanXuatVungTrongPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                                    #ToChucVuSanXuatVungTrongSort="matSort"
                                                    [dataSource]="ToChucVuSanXuatVungTrongService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Danh sách
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Vùng
                                                                        trồng/Nông hộ</label>
                                                                    <input class="form-control" type="text" placeholder="Vùng
                                                                        trồng/Nông hộ" name="Name{{element.ID}}"
                                                                        [(ngModel)]="element.Name">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Điện thoại</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Điện thoại" name="Code{{element.ID}}"
                                                                        [(ngModel)]="element.Code">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Mã số vùng trồng</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Mã số vùng trồng"
                                                                        name="MaSoVungTrong{{element.ID}}"
                                                                        [(ngModel)]="element.MaSoVungTrong">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="Active{{element.ID}}"
                                                                            [(ngModel)]="element.Active">
                                                                        <label class="form-check-label" for="Active">Sản
                                                                            xuất</label>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="IsChungThuc{{element.ID}}" disabled
                                                                            [(ngModel)]="element.IsChungThuc">
                                                                        <label class="form-check-label"
                                                                            for="IsChungThuc">Chứng thực</label>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <button [disabled]="element.IsChungThuc"
                                                                        class="btn btn-info"
                                                                        style="width: 100%; height: 41px;"
                                                                        (click)="ToChucVuSanXuatVungTrongSave(element)"><i
                                                                            class="bi bi-sd-card"></i>
                                                                    </button>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <a class="btn btn-info" style="width: 100%;"
                                                                        title="Sao chép tất cả sang vùng trồng khác cùng vụ mùa"
                                                                        (click)="ToChucVuSanXuatNhatKyCopy(element)">Sao
                                                                        chép tất cả</a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucVuSanXuatVungTrongService.DisplayColumnsMobile">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucVuSanXuatVungTrongService.DisplayColumnsMobile">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="NotificationService.IsMobile"
                                                    #ToChucVuSanXuatVungTrongPaginator="matPaginator"
                                                    [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="10000"
                                                    [showFirstLastButtons]></mat-paginator>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>