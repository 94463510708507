import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from 'src/environments/environment';
import { ToChucVuSanXuatNhatKy } from 'src/app/shared/ToChucVuSanXuatNhatKy.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class ToChucVuSanXuatNhatKyService extends BaseService {


    DataSource54: MatTableDataSource<any>;
    DataSource55: MatTableDataSource<any>;
    DataSourceIsMuaVo: MatTableDataSource<any>;

    list54: ToChucVuSanXuatNhatKy[] | undefined;
    list54001: ToChucVuSanXuatNhatKy[] | undefined;
    list55: ToChucVuSanXuatNhatKy[] | undefined;

    list: ToChucVuSanXuatNhatKy[] | undefined;
    list001: ToChucVuSanXuatNhatKy[] | undefined;
    listIsMuaVo: ToChucVuSanXuatNhatKy[] | undefined;
    formData!: ToChucVuSanXuatNhatKy;
    formData54!: ToChucVuSanXuatNhatKy;

    displayColumns0054: string[] = ['BatDau'];
    displayColumns0054001: string[] = ['IsSaoChep', 'BatDau'];
    displayColumns0055: string[] = ['BatDau', 'SanXuatDieuTra', 'NhaCungCap', 'Active'];
    displayColumns0001: string[] = ['STT', 'BatDau', 'DanhMucNguyenVatLieuPhanLoaiID', 'DanhMucNguyenVatLieuName', 'SoLuong', 'DanhMucDonViTinhName', 'FileName', 'Save'];
    displayColumns0002: string[] = ['STT', 'BatDau', 'DanhMucNguyenVatLieuName', 'FileName', 'Save'];
    displayColumns0003: string[] = ['BatDau', 'DanhMucNguyenVatLieuName', 'NhaCungCap', 'Active'];

    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "ToChucVuSanXuatNhatKy";

        this.list54 = [];
        this.list55 = [];
    }
    Filter54(searchString: string) {
        if (searchString.length > 0) {
            searchString = searchString.toLocaleLowerCase();
            this.list54001 = this.list54.filter((item: any) =>
                item.Name.toLocaleLowerCase().indexOf(searchString) !== -1 || item.Code.toLocaleLowerCase().indexOf(searchString) !== -1);
        }
        else {
            this.list54001 = this.list54;
        }
    }
    SaoChepListAsync(list: ToChucVuSanXuatNhatKy[]) {
        let url = this.aPIURL + this.controller + '/SaoChepListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(list));
        return this.httpClient.post(url, formUpload);
    }
    GetByParentID_DanhMucNguyenVatLieuPhanLoaiID_ActiveToListAsync() {
        let url = this.aPIURL + this.controller + '/GetByParentID_DanhMucNguyenVatLieuPhanLoaiID_ActiveToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
    GetByParentID_DanhMucNguyenVatLieuPhanLoaiID_Active_EmptyToListAsync() {
        let url = this.aPIURL + this.controller + '/GetByParentID_DanhMucNguyenVatLieuPhanLoaiID_Active_EmptyToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
    GetByParentID_ActiveToListAsync() {
        let url = this.aPIURL + this.controller + '/GetByParentID_ActiveToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
    GetByParentID_Active001ToListAsync() {
        let url = this.aPIURL + this.controller + '/GetByParentID_Active001ToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
    GetByParentID_Active_Empty001ToListAsync() {
        let url = this.aPIURL + this.controller + '/GetByParentID_Active_Empty001ToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
    GetByToChucVuSanXuatVungTrongID_DanhMucNguyenVatLieuPhanLoaiID_Active_EmptyToListAsync() {
        let url = this.aPIURL + this.controller + '/GetByToChucVuSanXuatVungTrongID_DanhMucNguyenVatLieuPhanLoaiID_Active_EmptyToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
    GetByToChucVuSanXuatVungTrongID_Active_Empty001ToListAsync() {
        let url = this.aPIURL + this.controller + '/GetByToChucVuSanXuatVungTrongID_Active_Empty001ToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
    GetByToChucVuSanXuatVungTrongID_Active_IsMuaVo_Empty001ToListAsync() {
        let url = this.aPIURL + this.controller + '/GetByToChucVuSanXuatVungTrongID_Active_IsMuaVo_Empty001ToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
    GetByToChucVuSanXuatVungTrongID_IsMuaVo_Empty001ToListAsync() {
        let url = this.aPIURL + this.controller + '/GetByToChucVuSanXuatVungTrongID_IsMuaVo_Empty001ToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
    GetSQLByToChucVuSanXuatVungTrongID_Active_Empty001ToListAsync() {
        let url = this.aPIURL + this.controller + '/GetSQLByToChucVuSanXuatVungTrongID_Active_Empty001ToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
    GetSQLByToChucVuSanXuatVungTrongID_Active001ToListAsync() {
        let url = this.aPIURL + this.controller + '/GetSQLByToChucVuSanXuatVungTrongID_Active001ToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
    GetSQLByToChucVuSanXuatVungTrongID_DanhMucNguyenVatLieuPhanLoaiID_ActiveToListAsync() {
        let url = this.aPIURL + this.controller + '/GetSQLByToChucVuSanXuatVungTrongID_DanhMucNguyenVatLieuPhanLoaiID_ActiveToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
    GetSQLByToChucVuSanXuatVungTrongID_DanhMucNguyenVatLieuPhanLoaiID_Active_EmptyToListAsync() {
        let url = this.aPIURL + this.controller + '/GetSQLByToChucVuSanXuatVungTrongID_DanhMucNguyenVatLieuPhanLoaiID_Active_EmptyToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
    CopySQLByToChucVuSanXuatVungTrongIDAsync() {
        let url = this.aPIURL + this.controller + '/CopySQLByToChucVuSanXuatVungTrongIDAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
    CopySQLByToChucVuSanXuatVungTrongID_DanhMucNguyenVatLieuPhanLoaiIDAsync() {
        let url = this.aPIURL + this.controller + '/CopySQLByToChucVuSanXuatVungTrongID_DanhMucNguyenVatLieuPhanLoaiIDAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
    CopyFullSQLByToChucVuSanXuatVungTrongIDAsync() {
        let url = this.aPIURL + this.controller + '/CopyFullSQLByToChucVuSanXuatVungTrongIDAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
    CopySQLByIDAsync() {
        let url = this.aPIURL + this.controller + '/CopySQLByIDAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
}

