import { Component, OnInit, Inject, ElementRef, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { DanhMucTinhThanh } from 'src/app/shared/DanhMucTinhThanh.model';
import { DanhMucTinhThanhService } from 'src/app/shared/DanhMucTinhThanh.service';
import { DanhMucQuanHuyen } from 'src/app/shared/DanhMucQuanHuyen.model';
import { DanhMucQuanHuyenService } from 'src/app/shared/DanhMucQuanHuyen.service';
import { DanhMucXaPhuong } from 'src/app/shared/DanhMucXaPhuong.model';
import { DanhMucXaPhuongService } from 'src/app/shared/DanhMucXaPhuong.service';

import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';

import { ToChuc } from 'src/app/shared/ToChuc.model';
import { ToChucService } from 'src/app/shared/ToChuc.service';
import { ToChucHopTac } from 'src/app/shared/ToChucHopTac.model';
import { ToChucHopTacService } from 'src/app/shared/ToChucHopTac.service';
import { ToChucKhoHang } from 'src/app/shared/ToChucKhoHang.model';
import { ToChucKhoHangService } from 'src/app/shared/ToChucKhoHang.service';
import { ToChucPhuongTien } from 'src/app/shared/ToChucPhuongTien.model';
import { ToChucPhuongTienService } from 'src/app/shared/ToChucPhuongTien.service';
import { ToChucTapTinDinhKem } from 'src/app/shared/ToChucTapTinDinhKem.model';
import { ToChucTapTinDinhKemService } from 'src/app/shared/ToChucTapTinDinhKem.service';

import { ToChucToaDo } from 'src/app/shared/ToChucToaDo.model';
import { ToChucToaDoService } from 'src/app/shared/ToChucToaDo.service';
import { ToChucVungTrong } from 'src/app/shared/ToChucVungTrong.model';
import { ToChucVungTrongService } from 'src/app/shared/ToChucVungTrong.service';
import { ToChucToaDoDetailComponent } from '../to-chuc-toa-do-detail/to-chuc-toa-do-detail.component';

import * as maplibregl from 'maplibre-gl';
import { ToChucToaDoDetailMobileComponent } from '../to-chuc-toa-do-detail-mobile/to-chuc-toa-do-detail-mobile.component';

@Component({
  selector: 'app-thanh-vien-to-chuc-nong-ho',
  templateUrl: './thanh-vien-to-chuc-nong-ho.component.html',
  styleUrls: ['./thanh-vien-to-chuc-nong-ho.component.css']
})
export class ThanhVienToChucNongHoComponent implements OnInit {

  @ViewChild('ToChucKhoHangSort') ToChucKhoHangSort: MatSort;
  @ViewChild('ToChucKhoHangPaginator') ToChucKhoHangPaginator: MatPaginator;

  @ViewChild('ToChucHopTacSort') ToChucHopTacSort: MatSort;
  @ViewChild('ToChucHopTacPaginator') ToChucHopTacPaginator: MatPaginator;

  @ViewChild('ToChucPhuongTienSort') ToChucPhuongTienSort: MatSort;
  @ViewChild('ToChucPhuongTienPaginator') ToChucPhuongTienPaginator: MatPaginator;

  @ViewChild('ToChucTapTinDinhKemSort') ToChucTapTinDinhKemSort: MatSort;
  @ViewChild('ToChucTapTinDinhKemPaginator') ToChucTapTinDinhKemPaginator: MatPaginator;

  @ViewChild('ToChucToaDoSort') ToChucToaDoSort: MatSort;
  @ViewChild('ToChucToaDoPaginator') ToChucToaDoPaginator: MatPaginator;

  @ViewChild('ToChucVungTrongSort') ToChucVungTrongSort: MatSort;
  @ViewChild('ToChucVungTrongPaginator') ToChucVungTrongPaginator: MatPaginator;

  isShowLoading: boolean = false;
  domainURL: string = environment.DomainURL;
  constructor(
    private dialog: MatDialog,

    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public DanhMucTinhThanhService: DanhMucTinhThanhService,
    public DanhMucQuanHuyenService: DanhMucQuanHuyenService,
    public DanhMucXaPhuongService: DanhMucXaPhuongService,

    public ThanhVienService: ThanhVienService,

    public ToChucService: ToChucService,
    public ToChucHopTacService: ToChucHopTacService,
    public ToChucKhoHangService: ToChucKhoHangService,
    public ToChucPhuongTienService: ToChucPhuongTienService,
    public ToChucTapTinDinhKemService: ToChucTapTinDinhKemService,
    public ToChucToaDoService: ToChucToaDoService,
    public ToChucVungTrongService: ToChucVungTrongService,


  ) { }

  ngOnInit(): void {
    this.ThanhVienSearch();
    this.ToChucSearch();
    this.DanhMucTinhThanhGetToList();
    this.ToChucGetAllToList();
  }
  ToChucGetAllToList() {
    this.ToChucService.baseParameter.ParentID = environment.HopTacXaID;
    this.ToChucService.ComponentGetByParentIDToListAsync();
  }
  ToChucFilter(searchString: string) {
    searchString = searchString.trim();
    this.ToChucService.Filter(searchString);
  }
  DateNgayHoatDong(value) {
    this.ToChucService.formData.NgayHoatDong = new Date(value);
  }
  DateNgayGhiNhan(element: ToChucHopTac, value) {
    element.NgayGhiNhan = new Date(value);
  }
  ThanhVienSearch() {
    this.ThanhVienService.GetLogin();
  }
  ThanhVienSave() {
    this.isShowLoading = true;
    this.ThanhVienService.baseParameter.ID = this.ThanhVienService.formDataLogin.ID;
    this.ThanhVienService.GetByIDAsync().subscribe(
      res => {
        this.ThanhVienService.formData = res as ThanhVien;
        this.ThanhVienService.formData.ToChucID = this.ToChucService.formData.ID;
        this.ThanhVienService.SaveAsync().subscribe(
          res => {
            this.ThanhVienService.formData = res as ThanhVien;
          },
          err => {
          }
        );
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  ToChucSearch() {
    this.isShowLoading = true;
    this.ToChucService.baseParameter.ID = this.ThanhVienService.formDataLogin.ToChucID;
    this.ToChucService.GetByIDAsync().subscribe(
      res => {
        this.ToChucService.formData = res as ToChuc;
        this.ToChucVungTrongSearch();
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  ToChucSave() {
    this.isShowLoading = true;
    this.ToChucService.formData.ParentID = environment.DanhMucToChucIDNongHo;
    this.ToChucService.SaveAsync().subscribe(
      res => {
        this.ToChucService.formData = res as ToChuc;
        this.ThanhVienSave();
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }

  ToChucKhoHangSearch() {
    if (this.ToChucKhoHangService.baseParameter.SearchString.length > 0) {
      this.ToChucKhoHangService.DataSource.filter = this.ToChucKhoHangService.baseParameter.SearchString.toLowerCase();
    }
    else {
      this.isShowLoading = true;
      this.ToChucKhoHangService.baseParameter.ParentID = this.ToChucService.formData.ID;
      this.ToChucKhoHangService.GetByParentIDAndEmptyToListAsync().subscribe(
        res => {
          this.ToChucKhoHangService.list = (res as ToChucKhoHang[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
          this.ToChucKhoHangService.DataSource = new MatTableDataSource(this.ToChucKhoHangService.list);
          this.ToChucKhoHangService.DataSource.sort = this.ToChucKhoHangSort;
          this.ToChucKhoHangService.DataSource.paginator = this.ToChucKhoHangPaginator;
          this.isShowLoading = false;
        },
        err => {
          this.isShowLoading = false;
        }
      );
    }
  }
  ToChucKhoHangSave(element: ToChucKhoHang) {
    element.ParentID = this.ToChucService.formData.ID;
    this.ToChucKhoHangService.formData = element;
    this.NotificationService.warn(this.ToChucKhoHangService.ComponentSaveByParentID(this.ToChucKhoHangSort, this.ToChucKhoHangPaginator));
  }
  ToChucKhoHangDelete(element: ToChucKhoHang) {
    this.ToChucKhoHangService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.ToChucKhoHangService.ComponentDeleteByParentID(this.ToChucKhoHangSort, this.ToChucKhoHangPaginator));
  }

  ToChucPhuongTienSearch() {
    if (this.ToChucPhuongTienService.baseParameter.SearchString.length > 0) {
      this.ToChucPhuongTienService.DataSource.filter = this.ToChucPhuongTienService.baseParameter.SearchString.toLowerCase();
    }
    else {
      this.isShowLoading = true;
      this.ToChucPhuongTienService.baseParameter.ParentID = this.ToChucService.formData.ID;
      this.ToChucPhuongTienService.GetByParentIDAndEmptyToListAsync().subscribe(
        res => {
          this.ToChucPhuongTienService.list = (res as ToChucPhuongTien[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
          this.ToChucPhuongTienService.DataSource = new MatTableDataSource(this.ToChucPhuongTienService.list);
          this.ToChucPhuongTienService.DataSource.sort = this.ToChucPhuongTienSort;
          this.ToChucPhuongTienService.DataSource.paginator = this.ToChucPhuongTienPaginator;
          this.isShowLoading = false;
        },
        err => {
          this.isShowLoading = false;
        }
      );
    }
  }
  ToChucPhuongTienSave(element: ToChucPhuongTien) {
    element.ParentID = this.ToChucService.formData.ID;
    this.ToChucPhuongTienService.formData = element;
    this.NotificationService.warn(this.ToChucPhuongTienService.ComponentSaveByParentID(this.ToChucPhuongTienSort, this.ToChucPhuongTienPaginator));
  }
  ToChucPhuongTienDelete(element: ToChucPhuongTien) {
    this.ToChucPhuongTienService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.ToChucPhuongTienService.ComponentDeleteByParentID(this.ToChucPhuongTienSort, this.ToChucPhuongTienPaginator));
  }

  ToChucHopTacSearch() {
    if (this.ToChucHopTacService.baseParameter.SearchString.length > 0) {
      this.ToChucHopTacService.DataSource.filter = this.ToChucHopTacService.baseParameter.SearchString.toLowerCase();
    }
    else {
      this.isShowLoading = true;
      this.ToChucHopTacService.baseParameter.ParentID = this.ToChucService.formData.ID;
      this.ToChucHopTacService.GetByParentIDAndEmptyToListAsync().subscribe(
        res => {
          this.ToChucHopTacService.list = (res as ToChucHopTac[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
          this.ToChucHopTacService.DataSource = new MatTableDataSource(this.ToChucHopTacService.list);
          this.ToChucHopTacService.DataSource.sort = this.ToChucHopTacSort;
          this.ToChucHopTacService.DataSource.paginator = this.ToChucHopTacPaginator;
          this.isShowLoading = false;
        },
        err => {
          this.isShowLoading = false;
        }
      );
    }
  }
  ToChucHopTacSave(element: ToChucHopTac) {
    element.ParentID = this.ToChucService.formData.ID;
    this.ToChucHopTacService.formData = element;
    this.NotificationService.warn(this.ToChucHopTacService.ComponentSaveByParentID(this.ToChucHopTacSort, this.ToChucHopTacPaginator));
  }
  ToChucHopTacDelete(element: ToChucHopTac) {
    this.ToChucHopTacService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.ToChucHopTacService.ComponentDeleteByParentID(this.ToChucHopTacSort, this.ToChucHopTacPaginator));
  }

  ToChucTapTinDinhKemSearch() {
    if (this.ToChucTapTinDinhKemService.baseParameter.SearchString.length > 0) {
      this.ToChucTapTinDinhKemService.DataSource.filter = this.ToChucTapTinDinhKemService.baseParameter.SearchString.toLowerCase();
    }
    else {
      this.isShowLoading = true;
      this.ToChucTapTinDinhKemService.baseParameter.ParentID = this.ToChucService.formData.ID;
      this.ToChucTapTinDinhKemService.GetByParentIDAndEmptyToListAsync().subscribe(
        res => {
          this.ToChucTapTinDinhKemService.list = (res as ToChucTapTinDinhKem[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
          this.ToChucTapTinDinhKemService.DataSource = new MatTableDataSource(this.ToChucTapTinDinhKemService.list);
          this.ToChucTapTinDinhKemService.DataSource.sort = this.ToChucTapTinDinhKemSort;
          this.ToChucTapTinDinhKemService.DataSource.paginator = this.ToChucTapTinDinhKemPaginator;
          this.isShowLoading = false;
        },
        err => {
          this.isShowLoading = false;
        }
      );
    }
  }
  ToChucTapTinDinhKemSave(element: ToChucTapTinDinhKem) {
    element.ParentID = this.ToChucService.formData.ID;
    this.ToChucTapTinDinhKemService.formData = element;
    this.ToChucTapTinDinhKemService.SaveAndUploadFilesAsync().subscribe(
      res => {
        this.ToChucTapTinDinhKemSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }
  ToChucTapTinDinhKemDelete(element: ToChucTapTinDinhKem) {
    this.ToChucTapTinDinhKemService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.ToChucTapTinDinhKemService.ComponentDeleteByParentID(this.ToChucTapTinDinhKemSort, this.ToChucTapTinDinhKemPaginator));
  }

  DanhMucTinhThanhGetToList() {
    this.isShowLoading = true;
    this.DanhMucTinhThanhService.GetAllToListAsync().subscribe(
      res => {
        this.DanhMucTinhThanhService.list = (res as DanhMucTinhThanh[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucTinhThanhService.list001 = this.DanhMucTinhThanhService.list;
        this.DanhMucQuanHuyenGetToList();
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucQuanHuyenGetToList() {
    this.isShowLoading = true;
    this.DanhMucQuanHuyenService.baseParameter.ParentID = this.ToChucService.formData.DanhMucTinhThanhID;
    this.DanhMucQuanHuyenService.GetByParentIDToListAsync().subscribe(
      res => {
        this.DanhMucQuanHuyenService.list = (res as DanhMucQuanHuyen[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucQuanHuyenService.list001 = this.DanhMucQuanHuyenService.list;
        this.DanhMucXaPhuongGetToList();
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucXaPhuongGetToList() {
    this.isShowLoading = true;
    this.DanhMucXaPhuongService.baseParameter.ParentID = this.ToChucService.formData.DanhMucQuanHuyenID;
    this.DanhMucXaPhuongService.GetByParentIDToListAsync().subscribe(
      res => {
        this.DanhMucXaPhuongService.list = (res as DanhMucXaPhuong[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucXaPhuongService.list001 = this.DanhMucXaPhuongService.list;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucTinhThanhFilter(searchString: string) {
    this.DanhMucTinhThanhService.Filter(searchString);
  }
  DanhMucQuanHuyenFilter(searchString: string) {
    this.DanhMucQuanHuyenService.Filter(searchString);
  }
  DanhMucXaPhuongFilter(searchString: string) {
    this.DanhMucXaPhuongService.Filter(searchString);
  }

  ToChucVungTrongSearch() {
    if (this.ToChucVungTrongService.baseParameter.SearchString.length > 0) {
      this.ToChucVungTrongService.DataSource.filter = this.ToChucVungTrongService.baseParameter.SearchString.toLowerCase();
    }
    else {
      this.isShowLoading = true;
      this.ToChucVungTrongService.baseParameter.ParentID = this.ToChucService.formData.ID;
      this.ToChucVungTrongService.GetByParentIDAndEmptyToListAsync().subscribe(
        res => {
          this.ToChucVungTrongService.list = (res as ToChucVungTrong[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
          this.ToChucVungTrongService.list001 = this.ToChucVungTrongService.list.filter((item) => item.ID > 0 && item.Active != true);
          this.ToChucVungTrongService.DataSource = new MatTableDataSource(this.ToChucVungTrongService.list);
          this.ToChucVungTrongService.DataSource.sort = this.ToChucVungTrongSort;
          this.ToChucVungTrongService.DataSource.paginator = this.ToChucVungTrongPaginator;
          this.isShowLoading = false;
        },
        err => {
          this.isShowLoading = false;
        }
      );
    }
  }
  ToChucVungTrongSave(element: ToChucVungTrong) {
    element.ParentID = this.ToChucService.formData.ID;
    this.ToChucVungTrongService.formData = element;
    this.NotificationService.warn(this.ToChucVungTrongService.ComponentSaveByParentID(this.ToChucVungTrongSort, this.ToChucVungTrongPaginator));
  }
  ToChucVungTrongDelete(element: ToChucVungTrong) {
    this.ToChucVungTrongService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.ToChucVungTrongService.ComponentDeleteByParentID(this.ToChucVungTrongSort, this.ToChucVungTrongPaginator));
  }
  ToChucVungTrongAdd(ID: any) {
    this.ToChucVungTrongService.baseParameter.ID = ID;
    this.ToChucVungTrongService.GetByIDAsync().subscribe(
      res => {
        this.ToChucVungTrongService.formData = res as ToChucVungTrong;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(ToChucToaDoDetailMobileComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
        });
      },
      err => {
      }
    );
  }


  OpenWindowVeVungTrong() {
    let width = screen.width;
    let height = screen.height;
    let width01 = width - 100;
    let height01 = height - 200;
    let left = (width - width01) / 2;
    let top = (height - height01) / 3;
    let url = "/html/MapHopTacXa.html?ID=" + this.ToChucService.formData.ID;
    window.open(url, '_blank', 'location=yes, height=' + height01 + ',width=' + width01 + ', scrollbars=yes, status=yes, toolbar=yes, top=' + top + ', left=' + left + '');
  }

  map: maplibregl.Map | undefined;

  @ViewChild('map')
  private mapContainer!: ElementRef<HTMLElement>;

  ngAfterViewInit() {

  }

  ngOnDestroy() {
    this.map?.remove();
  }

  MapInitialization(longitude, latitude) {
    let IDDate = new Date().toISOString();
    let zoom = 12;

    if ((latitude > 90) || (latitude == 0)) {
      latitude = environment.Latitude;
      longitude = environment.Longitude;
    }
    this.map = new maplibregl.Map({
      container: this.mapContainer.nativeElement,
      style: 'https://api.maptiler.com/maps/hybrid/style.json?key=' + environment.MaptilerAPIKey,
      center: [longitude, latitude],
      zoom: zoom,
    });

    this.map.addControl(
      new maplibregl.NavigationControl({
        visualizePitch: true,
        showZoom: true,
        showCompass: true
      })
    );
    this.map.addControl(
      new maplibregl.FullscreenControl({
      })
    );
    this.map.on('load', () => {

      this.map.addSource("HoangSa" + IDDate, {
        "type": "image",
        "url": environment.DomainURL + "assets/image/HoangSa01.png",
        "coordinates": [
          [111.09665858054495, 17.432475898867523],
          [113.11720985517763, 17.38420482529338],
          [112.79285037220984, 15.643938718432054],
          [110.88537855035554, 15.672592116966598],
        ]
      });
      this.map.addLayer({
        "id": "HoangSa" + IDDate,
        "source": "HoangSa" + IDDate,
        "type": "raster",
        "paint": {
          "raster-opacity": 1
        }
      });

      this.map.addSource("TruongSa" + IDDate, {
        "type": "image",
        "url": environment.DomainURL + "assets/image/TruongSa01.png",
        "coordinates": [
          [112.32373278444106, 12.236103169381323],
          [117.4620551483049, 11.606334626304161],
          [115.59654957671216, 7.357025445897818],
          [110.62186805246108, 7.811210355974268],


        ]
      });
      this.map.addLayer({
        "id": "TruongSa" + IDDate,
        "source": "TruongSa" + IDDate,
        "type": "raster",
        "paint": {
          "raster-opacity": 1
        }
      });

    });
  }



  MapLoad(ToChucVungTrongID: number) {
    if (this.ToChucService.formData) {
      this.ToChucToaDoService.baseParameter.ParentID = this.ToChucService.formData.ID;
      this.ToChucToaDoService.GetSQLByParentIDToListAsync().subscribe(
        res => {
          this.ToChucToaDoService.listMap = res as ToChucToaDo[];
          if (this.ToChucToaDoService.listMap) {
            if (this.ToChucToaDoService.listMap.length > 0) {
              let listToChucToaDoActive = [];
              let listToChucToaDoActiveNot = [];
              for (let i = 0; i < this.ToChucToaDoService.listMap.length; i++) {
                let ToChucToaDo = this.ToChucToaDoService.listMap[i];
                if (ToChucToaDo.Active == true) {
                  listToChucToaDoActive.push(ToChucToaDo);
                }
                if (ToChucToaDo.Active == false) {
                  listToChucToaDoActiveNot.push(ToChucToaDo);
                }
              }
              let ToChucToaDo = this.ToChucToaDoService.listMap[0];
              let latitude = environment.Latitude;
              let longitude = environment.Longitude;
              if (listToChucToaDoActive) {
                if (listToChucToaDoActive.length > 0) {
                  ToChucToaDo = listToChucToaDoActive[0];
                }
              }
              latitude = Number(ToChucToaDo.ViDo);
              longitude = Number(ToChucToaDo.KinhDo);
              this.MapInitialization(longitude, latitude);
              if (latitude <= 90) {

                let popupContent = "<div style='opacity: 0.8; text-align: center; background-color: transparent;'>";
                popupContent = popupContent + "<a class='link-primary form-label' href='" + environment.DomainDestination + "HopTacXaInfo/" + ToChucToaDo.ID + "'><h1>" + ToChucToaDo.TypeName + "</h1></a>";
                popupContent = popupContent + '<div><b> Trụ sở: ' + ToChucToaDo.Description + '</b></div>';
                popupContent = popupContent + "</div>";

                let popup = new maplibregl.Popup({ offset: 25 }).setHTML(popupContent)
                  .setMaxWidth("600px");

                var el = document.createElement('div');
                el.style.backgroundImage = "url(" + environment.DomainURL + "assets/image/HTX4_30.png)";
                el.style.width = '30px';
                el.style.height = '30px';

                let marker = new maplibregl.Marker({ element: el })
                  .setLngLat([longitude, latitude])
                  .setPopup(popup)
                  .addTo(this.map);
              }
              if (ToChucVungTrongID == -1) {
                for (let k = 0; k < this.ToChucVungTrongService.list001.length; k++) {
                  let ToChucVungTrong = this.ToChucVungTrongService.list001[k];
                  if (ToChucVungTrong.ID > 0) {
                    if (ToChucVungTrong.Active != true) {

                      let listToChucToaDo = listToChucToaDoActiveNot.filter((item) => item.ToChucVungTrongID == ToChucVungTrong.ID);
                      let listToChucToaDoPolygon = [];
                      let listPolygon = [];
                      for (let j = 0; j < listToChucToaDo.length; j++) {
                        let latitudeSub1 = Number(listToChucToaDo[j].ViDo);
                        let longitudeSub1 = Number(listToChucToaDo[j].KinhDo);
                        listToChucToaDoPolygon.push([longitudeSub1, latitudeSub1]);
                      }
                      if (listToChucToaDoPolygon.length > 0) {
                        let IDDate = new Date().toISOString();
                        listPolygon.push(listToChucToaDoPolygon);
                        let layerID = 'Layer_' + ToChucVungTrong.ID + IDDate;
                        let sourceID = 'Source_' + ToChucVungTrong.ID + IDDate;
                        this.map.on('load', () => {
                          this.map.addSource(sourceID, {
                            'type': 'geojson',
                            'data': {
                              'type': 'Feature',
                              'properties': {
                                "name": "",
                                "address": "",
                              },
                              'geometry': {
                                'type': 'Polygon',
                                'coordinates': listPolygon,
                              }
                            }
                          });
                          let color = this.DownloadService.GetRandomColor(listToChucToaDoPolygon.length);
                          this.map.addLayer({
                            'id': layerID,
                            'type': 'fill',
                            'source': sourceID,
                            'paint': {
                              'fill-color': color,
                              'fill-opacity': 0.5,
                              'fill-outline-color': color,
                            }
                          });
                        });
                        let ToChucToaDo = listToChucToaDo[0];
                        let popupContent = "<div style='opacity: 0.8; background-color: transparent;'>";
                        popupContent = popupContent + "<a class='link-primary form-label' style='cursor: pointer; text-align: center;')'><h3>" + ToChucToaDo.TypeName + ": " + ToChucToaDo.Name + "</h3></a>";
                        popupContent = popupContent + "</div>";

                        let mapSub = this.map;
                        this.map.on('click', layerID, function (e) {
                          this.map = mapSub;
                          let popup = new maplibregl.Popup({ offset: 25 })
                            .setLngLat(e.lngLat)
                            .setHTML(popupContent)
                            .setMaxWidth("600px")
                            .addTo(this.map);
                        });
                      }
                    }
                  }
                }
              }
              if (ToChucVungTrongID > 0) {
                let listToChucToaDo = listToChucToaDoActiveNot.filter((item) => item.ToChucVungTrongID == ToChucVungTrongID);
                let listToChucToaDoPolygon = [];
                let listPolygon = [];
                for (let j = 0; j < listToChucToaDo.length; j++) {
                  let latitudeSub1 = Number(listToChucToaDo[j].ViDo);
                  let longitudeSub1 = Number(listToChucToaDo[j].KinhDo);
                  listToChucToaDoPolygon.push([longitudeSub1, latitudeSub1]);
                }
                if (listToChucToaDoPolygon.length > 0) {
                  let IDDate = new Date().toISOString();
                  listPolygon.push(listToChucToaDoPolygon);
                  let layerID = 'Layer_' + ToChucVungTrongID + IDDate;
                  let sourceID = 'Source_' + ToChucVungTrongID + IDDate;
                  this.map.on('load', () => {
                    this.map.addSource(sourceID, {
                      'type': 'geojson',
                      'data': {
                        'type': 'Feature',
                        'properties': {
                          "name": "",
                          "address": "",
                        },
                        'geometry': {
                          'type': 'Polygon',
                          'coordinates': listPolygon,
                        }
                      }
                    });
                    let color = this.DownloadService.GetRandomColor(listToChucToaDoPolygon.length);
                    this.map.addLayer({
                      'id': layerID,
                      'type': 'fill',
                      'source': sourceID,
                      'paint': {
                        'fill-color': color,
                        'fill-opacity': 0.5,
                        'fill-outline-color': color,
                      }
                    });
                  });
                  let ToChucToaDo = listToChucToaDo[0];
                  let popupContent = "<div style='opacity: 0.8; background-color: transparent;'>";
                  popupContent = popupContent + "<a class='link-primary form-label' style='cursor: pointer; text-align: center;')'><h3>" + ToChucToaDo.TypeName + ": " + ToChucToaDo.Name + "</h3></a>";
                  popupContent = popupContent + "</div>";

                  let mapSub = this.map;
                  this.map.on('click', layerID, function (e) {
                    this.map = mapSub;
                    let popup = new maplibregl.Popup({ offset: 25 })
                      .setLngLat(e.lngLat)
                      .setHTML(popupContent)
                      .setMaxWidth("600px")
                      .addTo(this.map);
                  });
                }
              }
            }
          }
        },
        err => {
          this.isShowLoading = false;
        }
      );
    }
  }
 
}