import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ToChucVuSanXuat } from 'src/app/shared/ToChucVuSanXuat.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class ToChucVuSanXuatService extends BaseService{

    list: ToChucVuSanXuat[] | undefined;
    list001: ToChucVuSanXuat[] | undefined;
    formData!: ToChucVuSanXuat;    

    displayColumns001: string[] = ['STT', 'ID', 'Name', 'Code', 'DanhMucVuMuaName', 'DanhMucNguyenVatLieuName', 'BatDau', 'Active'];   

    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "ToChucVuSanXuat";
    }
    Filter(searchString: string) {
        this.list001 = this.list;
        if (searchString) {
            if (searchString.length > 0) {
                searchString = searchString.trim();
                searchString = searchString.toLocaleLowerCase();
                this.list001 = this.list.filter((item: any) =>
                    item.Name.toLocaleLowerCase().indexOf(searchString) !== -1 || item.Code.toLocaleLowerCase().indexOf(searchString) !== -1 || item.DanhMucVuMuaName.toLocaleLowerCase().indexOf(searchString) !== -1);
            }
        }
    }
    GetBySearchString_YearToListAsync() {
        let url = this.aPIURL + this.controller + '/GetBySearchString_YearToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
    GetByParentID_SearchString_YearToListAsync() {
        let url = this.aPIURL + this.controller + '/GetByParentID_SearchString_YearToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
}

