<div class="app-hero-header d-flex align-items-center">
    <div class="d-flex align-items-center">
        <div class="me-3 icon-box md bg-white rounded-4">
            <i class="bi bi-hypnotize fs-3 text-primary"></i>
        </div>
        <div>
            <h2 class="mb-1">Hạ tầng</h2>
            <h6 class="m-0 text-dark fw-light">
                Mô hình thí điểm
            </h6>
        </div>
    </div>
    <div class="ms-auto d-lg-flex d-none flex-row">
        <div class="d-flex flex-row gap-2">
        </div>
    </div>
</div>
<div class="app-body">
    <div class="row gx-4">
        <div class="col-xxl-12">
            <div class="card mb-4">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-ToChuc" data-bs-toggle="tab" href="#ToChuc"
                                    role="tab" aria-controls="ToChuc" aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">Hạ tầng</span>
                                </a>
                            </li>                           
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="ToChuc" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <label class="form-label">Tỉnh thành</label>
                                        <mat-select class="form-control" name="DanhMucTinhThanhID"
                                            (selectionChange)="DanhMucQuanHuyenGetToList()"
                                            [(ngModel)]="DanhMucTinhThanhID">
                                            <input placeholder="Tìm..." class="form-control"
                                                (keyup)="DanhMucTinhThanhFilter($event.target.value)">
                                            <mat-option *ngFor="let item of DanhMucTinhThanhService.list001"
                                                [value]="item.ID">
                                                {{item.Name}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <label class="form-label">Quận huyện</label>
                                        <mat-select class="form-control" name="DanhMucQuanHuyenID"
                                            (selectionChange)="DanhMucXaPhuongGetToList()"
                                            [(ngModel)]="DanhMucQuanHuyenID">
                                            <input placeholder="Tìm..." class="form-control"
                                                (keyup)="DanhMucQuanHuyenFilter($event.target.value)">
                                            <mat-option [value]="0">
                                                Tất cả
                                            </mat-option>
                                            <mat-option *ngFor="let item of DanhMucQuanHuyenService.list001"
                                                [value]="item.ID">
                                                {{item.Name}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <label class="form-label">Xã phường</label>
                                        <mat-select class="form-control" name="DanhMucXaPhuongID"
                                            [(ngModel)]="DanhMucXaPhuongID" (selectionChange)="ToChucSearch()">
                                            <input placeholder="Tìm..." class="form-control"
                                                (keyup)="DanhMucXaPhuongFilter($event.target.value)">
                                            <mat-option [value]="0">
                                                Tất cả
                                            </mat-option>
                                            <mat-option *ngFor="let item of DanhMucXaPhuongService.list001"
                                                [value]="item.ID">
                                                {{item.Name}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <label class="form-label">.</label>
                                        <input [(ngModel)]="ToChucService.SearchString" placeholder="Tìm ..."
                                            class="form-control" (keyup.enter)="ToChucSearch()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <label class="form-label">.</label>
                                        <div>
                                            <a class="btn btn-success" title="Tìm" (click)="ToChucSearch()"><i
                                                    class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                            <a class="btn btn-success" title="Thêm mới" (click)="ToChucAdd(0)"><i
                                                    class="bi bi-plus"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Hạ tầng
                                            <span *ngIf="ToChucService.list000">({{ToChucService.list000.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #ToChucSort="matSort"
                                                    [dataSource]="ToChucDataSource" class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{ToChucPaginator.pageSize *
                                                            ToChucPaginator.pageIndex + i + 1}} - [{{element.ID}}]
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu đề
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a style="cursor: pointer;" title="{{element.Name}}"
                                                                class="link-primary"
                                                                (click)="ToChucAdd(element.ID)"><b>{{element.Name}}</b></a>
                                                        </td>
                                                    </ng-container>                                                   
                                                    <ng-container matColumnDef="Description">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Cơ quan quản lý
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.Description}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucTinhThanhName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tỉnh thành
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.DanhMucTinhThanhName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucQuanHuyenName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Quận huyện
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.DanhMucQuanHuyenName}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucXaPhuongName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Xã phường
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.DanhMucXaPhuongName}}
                                                        </td>
                                                    </ng-container>                                                   
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucService.displayColumns007">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucService.displayColumns007;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #ToChucPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>                      
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>