<div class="app-body">
    <div class="row">
        <div class="col-xxl-12">
            <div class="card" style="margin-left: 1px">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-ToChucVuSanXuatTieuChi" data-bs-toggle="tab"
                                    href="#ToChucVuSanXuatTieuChi" role="tab" aria-controls="ToChucVuSanXuatTieuChi"
                                    aria-selected="true">
                                    <span class="badge bg-danger" style="font-size: 16px">7. Tiêu chí</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="ToChucVuSanXuatTieuChi" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">Vụ mùa</label>
                                        <mat-select class="form-control" name="ParentID"                                            
                                            [(ngModel)]="ToChucVuSanXuatTieuChiService.baseParameter.ParentID">
                                            <input placeholder="Tìm..." class="form-control"
                                                (keyup)="ToChucVuSanXuatFilter($event.target.value)">
                                            <mat-option *ngFor="let item of ToChucVuSanXuatService.list001"
                                                [value]="item.ID">
                                                {{item.DanhMucVuMuaName}} - {{item.BatDau | date:'dd/MM/yyyy'}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">Năm</label>
                                        <input [(ngModel)]="ToChucVuSanXuatTieuChiService.baseParameter.Year"
                                            placeholder="0" style="text-align: right;" class="form-control"
                                            type="number" (keyup.enter)="ToChucVuSanXuatTieuChiSearch()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">Tháng</label>
                                        <input [(ngModel)]="ToChucVuSanXuatTieuChiService.baseParameter.Month"
                                            placeholder="0" style="text-align: right;" class="form-control"
                                            type="number" (keyup.enter)="ToChucVuSanXuatTieuChiSearch()">
                                    </div>
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                                        <input name="SearchString"
                                            [(ngModel)]="ToChucVuSanXuatTieuChiService.baseParameter.SearchString"
                                            placeholder="Tìm..." class="form-control"
                                            (keyup.enter)="ToChucVuSanXuatTieuChiSearch()">
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                                        <a class="btn btn-success" title="Tìm..." style="width: 100%;"
                                            (click)="ToChucVuSanXuatTieuChiSearch()"><i class="bi bi-search"></i></a>
                                    </div>
                                </div>
                                <div class="card mb-4 card-danger">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Tiêu chí
                                            <span
                                                *ngIf="ToChucVuSanXuatTieuChiService.list">({{ToChucVuSanXuatTieuChiService.list.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                                    #ToChucVuSanXuatTieuChiSort="matSort"
                                                    [dataSource]="ToChucVuSanXuatTieuChiService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{ToChucVuSanXuatTieuChiPaginator.pageSize *
                                                            ToChucVuSanXuatTieuChiPaginator.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                            class="sticky-col" style="background-color: black;">
                                                            <span style="color: #ffffff;">Tiêu chí</span>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element" class="sticky-col">
                                                            <b>{{element.Name}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Year">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Năm
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <b>{{element.Year}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Month">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Tháng
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <b>{{element.Month}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="KetQuaThucTeThang">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Thực tế
                                                            tháng
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="number" placeholder="0"
                                                                name="KetQuaThucTeThang{{element.ID}}"
                                                                [(ngModel)]="element.KetQuaThucTeThang"
                                                                style="text-align: right;">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="KetQuaThucTeNam">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Lũy kế năm
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="number" placeholder="0"
                                                                name="KetQuaThucTeNam{{element.ID}}"
                                                                [(ngModel)]="element.KetQuaThucTeNam"
                                                                style="text-align: right;">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="KetQuaNhoNhat2025">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Mục tiêu
                                                            đến 2025
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.KetQuaNhoNhat2025 |
                                                            number:'1.0-0'}} - {{element.KetQuaLonNhat2025 |
                                                            number:'1.0-0'}} ({{element.DonViTinh2025}})
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="TyLeHoanThanh2025">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tỷ lệ hoàn
                                                            thành
                                                            đến 2025
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.TyLeHoanThanh2025 |
                                                            number:'1.0-0'}} %
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="KetQuaNhoNhat2030">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Mục tiêu
                                                            đến 2030
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.KetQuaNhoNhat2030 |
                                                            number:'1.0-0'}} - {{element.KetQuaLonNhat2030 |
                                                            number:'1.0-0'}} ({{element.DonViTinh2030}})
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="TyLeHoanThanh2030">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tỷ lệ hoàn
                                                            thành
                                                            đến 2030
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.TyLeHoanThanh2030 |
                                                            number:'1.0-0'}} %
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsChungThuc">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chứng thực
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox" disabled
                                                                    name="IsChungThuc{{element.ID}}"
                                                                    [(ngModel)]="element.IsChungThuc">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <button [disabled]="element.IsChungThuc"
                                                                class="btn btn-info" style="width: 100%; height: 41px;"
                                                                (click)="ToChucVuSanXuatTieuChiSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </button>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucVuSanXuatTieuChiService.displayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucVuSanXuatTieuChiService.displayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="!NotificationService.IsMobile"
                                                    #ToChucVuSanXuatTieuChiPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                                    #ToChucVuSanXuatTieuChiSort="matSort"
                                                    [dataSource]="ToChucVuSanXuatTieuChiService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Danh sách
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12" style="text-wrap: wrap;">                                                                    
                                                                    <b>{{element.Name}}</b>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label
                                                                        class="form-label">Năm:&nbsp;&nbsp;&nbsp;</label>
                                                                    <label class="form-label">{{element.Year}}</label>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label
                                                                        class="form-label">Tháng:&nbsp;&nbsp;&nbsp;</label>
                                                                    <label class="form-label">{{element.Month}}</label>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Thực tế tháng:</label>
                                                                    <input class="form-control" type="number"
                                                                        placeholder="0"
                                                                        name="KetQuaThucTeThang{{element.ID}}"
                                                                        [(ngModel)]="element.KetQuaThucTeThang"
                                                                        style="text-align: right;">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Lũy kế năm:</label>
                                                                    <input class="form-control" type="number"
                                                                        placeholder="0"
                                                                        name="KetQuaThucTeNam{{element.ID}}"
                                                                        [(ngModel)]="element.KetQuaThucTeNam"
                                                                        style="text-align: right;">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Mục tiêu đến
                                                                        2025:&nbsp;&nbsp;&nbsp;</label>
                                                                    <br/>
                                                                    <label
                                                                        class="form-label">{{element.KetQuaNhoNhat2025 |
                                                                        number:'1.0-0'}} - {{element.KetQuaLonNhat2025 |
                                                                        number:'1.0-0'}}
                                                                        ({{element.DonViTinh2025}})</label>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Tỷ lệ hoàn thành đến
                                                                        2025:&nbsp;&nbsp;&nbsp;</label>
                                                                    <label
                                                                        class="form-label">{{element.TyLeHoanThanh2025 |
                                                                        number:'1.0-0'}} %</label>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Mục tiêu đến
                                                                        2030:&nbsp;&nbsp;&nbsp;</label>
                                                                        <br/>
                                                                    <label
                                                                        class="form-label">{{element.KetQuaNhoNhat2030 |
                                                                        number:'1.0-0'}} - {{element.KetQuaLonNhat2030 |
                                                                        number:'1.0-0'}}
                                                                        ({{element.DonViTinh2030}})</label>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Tỷ lệ hoàn thành đến
                                                                        2030:&nbsp;&nbsp;&nbsp;</label>
                                                                    <label
                                                                        class="form-label">{{element.TyLeHoanThanh2030 |
                                                                        number:'1.0-0'}} %</label>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="IsChungThuc{{element.ID}}" disabled
                                                                            [(ngModel)]="element.IsChungThuc">
                                                                        <label class="form-check-label"
                                                                            for="IsChungThuc">Chứng thực</label>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <a class="btn btn-info" style="width: 100%;"
                                                                        (click)="ToChucVuSanXuatTieuChiSave(element)"><i
                                                                            class="bi bi-sd-card"></i>
                                                                    </a>
                                                                </div>                                                                
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucVuSanXuatTieuChiService.DisplayColumnsMobile">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucVuSanXuatTieuChiService.DisplayColumnsMobile">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="NotificationService.IsMobile"
                                                    #ToChucVuSanXuatTieuChiPaginator="matPaginator"
                                                    [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="10000"
                                                    [showFirstLastButtons]></mat-paginator>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>