<div class="custom-tabs-container">
    <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link active" id="tab-ToChucVuSanXuatNhatKyDetail" data-bs-toggle="tab"
                href="#ToChucVuSanXuatNhatKyDetail" role="tab" aria-controls="ToChucVuSanXuatNhatKyDetail"
                aria-selected="true">
                <span class="badge bg-warning" style="font-size: 16px;">5. Mua vô</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a style="cursor: pointer;" class="nav-link" id="tab-Close" data-bs-toggle="tab" (click)="Close()"
                role="tab" aria-controls="Close" aria-selected="false">
                <span class="badge bg-danger" style="font-size: 16px;"><i class="bi bi-x-lg"></i></span>
            </a>
        </li>
    </ul>
    <div class="tab-content" id="customTabContent">
        <div class="tab-pane fade show active" id="ToChucVuSanXuatNhatKyDetail" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-4 col-sm-12 col-12">
                    <a style="width: 100%;" title="Lưu thay đổi" (click)="ToChucVuSanXuatNhatKySave()"
                        class="btn btn-info"><i class="bi bi-sd-card"></i> Lưu thay đổi</a>
                </div>
                <div class="col-lg-2 col-sm-12 col-12">
                    <div class="form-check form-check-inline mb-6">
                        <input type="checkbox" class="form-check-input" name="Active"
                            [(ngModel)]="ToChucVuSanXuatNhatKyService.formData.Active" />
                        <label class="form-check-label" for="Active">Đã hoàn thành</label>
                    </div>
                </div>                
            </div>
            <div class="row gx-4">
                <div class="col-lg-4 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Bắt đầu</label>
                        <input name="BatDau"
                            [ngModel]="ToChucVuSanXuatNhatKyService.formData.BatDau | date:'yyyy-MM-dd HH:mm:ss'"
                            (ngModelChange)="DateBatDau($event)" [ngModelOptions]="{ updateOn: 'blur' }"
                            type="datetime-local" style="text-align: right;" class="form-control" autocomplete="off">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Kết thúc</label>
                        <input name="KetThuc"
                            [ngModel]="ToChucVuSanXuatNhatKyService.formData.KetThuc | date:'yyyy-MM-dd HH:mm:ss'"
                            (ngModelChange)="DateKetThuc($event)" [ngModelOptions]="{ updateOn: 'blur' }"
                            type="datetime-local" style="text-align: right;" class="form-control" autocomplete="off">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Hạng mục công cụ/Thiết
                            bị</label>
                        <br />
                        <mat-select class="form-control" name="DanhMucVuSanXuatThietBiID"
                            [(ngModel)]="ToChucVuSanXuatNhatKyService.formData.DanhMucVuSanXuatThietBiID">
                            <input placeholder="Tìm..." class="form-control"
                                (keyup)="DanhMucVuSanXuatThietBiFilter($event.target.value)">
                            <mat-option *ngFor="let item of DanhMucVuSanXuatThietBiService.list001" [value]="item.ID">
                                {{item.Name}}
                            </mat-option>
                        </mat-select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Hạ tầng</label>
                        <br />
                        <mat-select class="form-control" name="ToChucID"
                            [(ngModel)]="ToChucVuSanXuatNhatKyService.formData.ToChucID">
                            <input placeholder="Tìm..." class="form-control"
                                (keyup)="ToChucFilter($event.target.value)">
                            <mat-option *ngFor="let item of ToChucService.listHaTang001" [value]="item.ID">
                                {{item.Name}}
                            </mat-option>
                        </mat-select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ghi chú</label>
                        <input class="form-control" type="text" placeholder="Ghi chú" name="Note"
                            [(ngModel)]="ToChucVuSanXuatNhatKyService.formData.Note">
                    </div>
                </div>
                <div class="col-lg-4 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Số lượng</label>
                        <input class="form-control" type="number" style="text-align: right;" placeholder="0"
                            name="SoLuong" [(ngModel)]="ToChucVuSanXuatNhatKyService.formData.SoLuong">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Đơn vị tính</label>
                        <input class="form-control" type="text" placeholder="Đơn vị tính" name="DanhMucDonViTinhName"
                            [(ngModel)]="ToChucVuSanXuatNhatKyService.formData.DanhMucDonViTinhName">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Loại nhiên liệu</label>
                        <input class="form-control" type="text" placeholder="Loại nhiên liệu" name="Description"
                            [(ngModel)]="ToChucVuSanXuatNhatKyService.formData.Description">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Tiêu thụ nhiên
                            liệu</label>
                        <input class="form-control" type="text" placeholder="Tiêu thụ nhiên liệu" name="SanXuatGayHai"
                            [(ngModel)]="ToChucVuSanXuatNhatKyService.formData.SanXuatGayHai">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Đơn vị tính (lít/h,
                            kW/h)</label>
                        <input class="form-control" type="text" placeholder="Đơn vị tính (lít/h, kW/h)"
                            name="SanXuatDieuTra" [(ngModel)]="ToChucVuSanXuatNhatKyService.formData.SanXuatDieuTra">
                    </div>
                </div>
                <div class="col-lg-4 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Hình thức</label>
                        <input class="form-control" type="text" placeholder="Thuê/Mua mới/Đã có" name="SanXuatGiaiDoan"
                            [(ngModel)]="ToChucVuSanXuatNhatKyService.formData.SanXuatGiaiDoan">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Công suất</label>
                        <input class="form-control" type="text" placeholder="Công suất" name="SanXuatThucHien"
                            [(ngModel)]="ToChucVuSanXuatNhatKyService.formData.SanXuatThucHien">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Nhà cung cấp (nếu
                            có)</label>
                        <input class="form-control" type="text" placeholder="Nhà cung cấp (nếu có)" name="NhaCungCap"
                            [(ngModel)]="ToChucVuSanXuatNhatKyService.formData.NhaCungCap">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Điện thoại</label>
                        <input class="form-control" type="text" placeholder="Điện thoại" name="NhaCungCapDienThoai"
                            [(ngModel)]="ToChucVuSanXuatNhatKyService.formData.NhaCungCapDienThoai">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Địa chỉ</label>
                        <input class="form-control" type="text" placeholder="Địa chỉ" name="NhaCungCapDiaChi"
                            [(ngModel)]="ToChucVuSanXuatNhatKyService.formData.NhaCungCapDiaChi">
                    </div>
                    <div class="col-lg-4 col-sm-12 col-12">
                        <label class="form-label">Tải lên</label>
                        <br />
                        <input type="file" multiple (change)="ToChucVuSanXuatNhatKyChangeFileName($event.target.files)">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>
<br />
<br />
<br />