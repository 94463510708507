import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';
import { ToChuc } from 'src/app/shared/ToChuc.model';
import { ToChucService } from 'src/app/shared/ToChuc.service';

import { ToChucVuSanXuat } from 'src/app/shared/ToChucVuSanXuat.model';
import { ToChucVuSanXuatService } from 'src/app/shared/ToChucVuSanXuat.service';

import { ToChucDetailComponent } from '../to-chuc-detail/to-chuc-detail.component';
import { ThanhVienToChucVuSanXuatDetailMobileComponent } from '../thanh-vien-to-chuc-vu-san-xuat-detail-mobile/thanh-vien-to-chuc-vu-san-xuat-detail-mobile.component';

@Component({
  selector: 'app-thanh-vien-to-chuc-vu-san-xuat-mobile',
  templateUrl: './thanh-vien-to-chuc-vu-san-xuat-mobile.component.html',
  styleUrls: ['./thanh-vien-to-chuc-vu-san-xuat-mobile.component.css']
})
export class ThanhVienToChucVuSanXuatMobileComponent implements OnInit {

  @ViewChild('ToChucVuSanXuatSort') ToChucVuSanXuatSort: MatSort;
  @ViewChild('ToChucVuSanXuatPaginator') ToChucVuSanXuatPaginator: MatPaginator;


  isShowLoading: boolean = false;

  constructor(
    private dialog: MatDialog,

    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public ThanhVienService: ThanhVienService,

    public ToChucService: ToChucService,

    public ToChucVuSanXuatService: ToChucVuSanXuatService,
  ) { }

  ngOnInit(): void {
    this.ThanhVienSearch();
  }

  ThanhVienSearch() {
    this.ThanhVienService.GetLogin();
  }

  ToChucVuSanXuatGetToList() {
    this.isShowLoading = true;
    this.ToChucVuSanXuatService.baseParameter.ParentID = this.ThanhVienService.formDataLogin.ToChucID;
    this.ToChucVuSanXuatService.GetByParentID_SearchString_YearToListAsync().subscribe(
      res => {
        this.ToChucVuSanXuatService.list = (res as ToChucVuSanXuat[]).sort((a, b) => (a.BatDau < b.BatDau ? 1 : -1));
        this.ToChucVuSanXuatService.DataSource = new MatTableDataSource(this.ToChucVuSanXuatService.list);
        this.ToChucVuSanXuatService.DataSource.sort = this.ToChucVuSanXuatSort;
        this.ToChucVuSanXuatService.DataSource.paginator = this.ToChucVuSanXuatPaginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  ToChucVuSanXuatSearch() {
    this.ToChucVuSanXuatGetToList();
  }

  ToChucVuSanXuatAdd(ID: any) {
    this.ToChucVuSanXuatService.baseParameter.ID = ID;
    this.ToChucVuSanXuatService.GetByIDAsync().subscribe(
      res => {
        this.ToChucVuSanXuatService.formData = res as ToChucVuSanXuat;
        this.ToChucVuSanXuatService.formData.ParentID = this.ThanhVienService.formDataLogin.ToChucID;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(ThanhVienToChucVuSanXuatDetailMobileComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
        });
      },
      err => {
      }
    );
  } 

}