import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { DanhMucVuSanXuatThietBi } from 'src/app/shared/DanhMucVuSanXuatThietBi.model';
import { DanhMucVuSanXuatThietBiService } from 'src/app/shared/DanhMucVuSanXuatThietBi.service';
import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';
import { ToChuc } from 'src/app/shared/ToChuc.model';
import { ToChucService } from 'src/app/shared/ToChuc.service';

import { ToChucVuSanXuat } from 'src/app/shared/ToChucVuSanXuat.model';
import { ToChucVuSanXuatService } from 'src/app/shared/ToChucVuSanXuat.service';
import { ToChucVuSanXuatVungTrong } from 'src/app/shared/ToChucVuSanXuatVungTrong.model';
import { ToChucVuSanXuatVungTrongService } from 'src/app/shared/ToChucVuSanXuatVungTrong.service';
import { ToChucVuSanXuatNhatKy } from 'src/app/shared/ToChucVuSanXuatNhatKy.model';
import { ToChucVuSanXuatNhatKyService } from 'src/app/shared/ToChucVuSanXuatNhatKy.service';

@Component({
  selector: 'app-thanh-vien-to-chuc-vu-san-xuat-mobile-to-chuc-vu-san-xuat-nhat-ky',
  templateUrl: './thanh-vien-to-chuc-vu-san-xuat-mobile-to-chuc-vu-san-xuat-nhat-ky.component.html',
  styleUrls: ['./thanh-vien-to-chuc-vu-san-xuat-mobile-to-chuc-vu-san-xuat-nhat-ky.component.css']
})
export class ThanhVienToChucVuSanXuatMobileToChucVuSanXuatNhatKyComponent implements OnInit {

  @ViewChild('ToChucVuSanXuatNhatKySort') ToChucVuSanXuatNhatKySort: MatSort;
  @ViewChild('ToChucVuSanXuatNhatKyPaginator') ToChucVuSanXuatNhatKyPaginator: MatPaginator;

  isShowLoading: boolean = false;
  constructor(
    private dialog: MatDialog,

    public NotificationService: NotificationService,
    public DownloadService: DownloadService,
    public DanhMucVuSanXuatThietBiService: DanhMucVuSanXuatThietBiService,

    public ThanhVienService: ThanhVienService,
    public ToChucService: ToChucService,

    public ToChucVuSanXuatService: ToChucVuSanXuatService,
    public ToChucVuSanXuatVungTrongService: ToChucVuSanXuatVungTrongService,
    public ToChucVuSanXuatNhatKyService: ToChucVuSanXuatNhatKyService,
  ) { }

  ngOnInit(): void {
    this.ThanhVienSearch();
    this.ToChucVuSanXuatSearch();
  }
  DanhMucVuSanXuatThietBiFilter(searchString: string) {
    searchString = searchString.trim();
    this.DanhMucVuSanXuatThietBiService.Filter(searchString);
  }
  ToChucVuSanXuatNhatKyFilter(searchString: string) {
    searchString = searchString.trim();
    this.ToChucVuSanXuatNhatKyService.Filter54(searchString);
  }
  ToChucFilter(searchString: string) {
    searchString = searchString.trim();
    this.ToChucService.FilterHaTang(searchString);
  }
  DateToChucVuSanXuatNhatKyBatDau(element: ToChucVuSanXuatNhatKy, value) {
    element.BatDau = new Date(value);
  }
  DateToChucVuSanXuatNhatKyKetThuc(element: ToChucVuSanXuatNhatKy, value) {
    element.KetThuc = new Date(value);
  }  
  ToChucVuSanXuatNhatKyChangeFileName(files: FileList) {
    if (files) {
      this.ToChucVuSanXuatNhatKyService.fileToUpload = files;
    }
  }
  ThanhVienSearch() {
    this.ThanhVienService.GetLogin();
  }
  ToChucVuSanXuatSearch() {
    this.isShowLoading = true;
    this.ToChucVuSanXuatService.baseParameter.ParentID = this.ThanhVienService.formDataLogin.ToChucID;
    this.ToChucVuSanXuatService.GetByParentID_SearchString_YearToListAsync().subscribe(
      res => {
        this.ToChucVuSanXuatService.list = (res as ToChucVuSanXuat[]).sort((a, b) => (a.BatDau < b.BatDau ? 1 : -1));
        this.ToChucVuSanXuatService.list001 = this.ToChucVuSanXuatService.list;
        if (this.ToChucVuSanXuatService.list001) {
          if (this.ToChucVuSanXuatService.list001.length > 0) {
            this.ToChucVuSanXuatNhatKyService.baseParameter.ParentID = this.ToChucVuSanXuatService.list001[0].ID;
          }
        }
        this.ToChucVuSanXuatVungTrongSearch();
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  ToChucVuSanXuatFilter(searchString: string) {
    this.ToChucVuSanXuatService.Filter(searchString);
  }
  ToChucVuSanXuatVungTrongSearch() {
    this.isShowLoading = true;
    this.ToChucVuSanXuatVungTrongService.baseParameter.ParentID = this.ToChucVuSanXuatNhatKyService.baseParameter.ParentID;
    this.ToChucVuSanXuatVungTrongService.baseParameter.Active = true;
    this.ToChucVuSanXuatVungTrongService.GetByParentIDAndActiveToListAsync().subscribe(
      res => {
        this.ToChucVuSanXuatVungTrongService.list = (res as ToChucVuSanXuatVungTrong[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.ToChucVuSanXuatVungTrongService.list001 = this.ToChucVuSanXuatVungTrongService.list;
        if (this.ToChucVuSanXuatVungTrongService.list001) {
          if (this.ToChucVuSanXuatVungTrongService.list001.length > 0) {
            this.ToChucVuSanXuatNhatKyService.baseParameter.ToChucVuSanXuatVungTrongID = this.ToChucVuSanXuatVungTrongService.list001[0].ID;
            this.ToChucVuSanXuatVungTrongService.formData = this.ToChucVuSanXuatVungTrongService.list001[0];
          }
        }
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  ToChucVuSanXuatNhatKySearch54() {
    if (this.ToChucVuSanXuatNhatKyService.baseParameter.SearchString.length > 0) {
      this.ToChucVuSanXuatNhatKyService.DataSource54.filter = this.ToChucVuSanXuatNhatKyService.baseParameter.SearchString.toLowerCase();
    }
    else {
      this.isShowLoading = true;
      this.ToChucVuSanXuatNhatKyService.baseParameter.DanhMucNguyenVatLieuPhanLoaiID = environment.DanhMucNguyenVatLieuPhanLoaiID54;
      this.ToChucVuSanXuatNhatKyService.baseParameter.Active = true;
      this.ToChucVuSanXuatNhatKyService.GetSQLByToChucVuSanXuatVungTrongID_DanhMucNguyenVatLieuPhanLoaiID_ActiveToListAsync().subscribe(
        res => {
          this.ToChucVuSanXuatNhatKyService.list54 = (res as ToChucVuSanXuatNhatKy[])
          for (let index = 0; index < this.ToChucVuSanXuatNhatKyService.list54.length; index++) {
            this.ToChucVuSanXuatNhatKyService.list54[index].IsShow = false;
          }        
          this.isShowLoading = false;
        },
        err => {
          this.isShowLoading = false;
        }
      );
    }    
  }
  ToChucVuSanXuatNhatKyCopy00() {
    if (confirm(environment.Copy00)) {
      this.isShowLoading = true;
      this.ToChucVuSanXuatNhatKyService.CopySQLByToChucVuSanXuatVungTrongIDAsync().subscribe(
        res => {
          this.NotificationService.warn(environment.SaveSuccess);
          this.isShowLoading = false;
        },
        err => {
          this.NotificationService.warn(environment.SaveNotSuccess);
          this.isShowLoading = false;
        }
      );
    }
  }
  ToChucVuSanXuatNhatKySearch() {
    if (this.ToChucVuSanXuatNhatKyService.baseParameter.SearchString.length > 0) {
      this.ToChucVuSanXuatNhatKyService.DataSource.filter = this.ToChucVuSanXuatNhatKyService.baseParameter.SearchString.toLowerCase();
    }
    else {
      this.isShowLoading = true;
      this.ToChucVuSanXuatNhatKyService.baseParameter.IsMuaVo = true;
      this.ToChucVuSanXuatNhatKyService.GetByToChucVuSanXuatVungTrongID_IsMuaVo_Empty001ToListAsync().subscribe(
        res => {
          this.ToChucVuSanXuatNhatKyService.list = (res as ToChucVuSanXuatNhatKy[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
          this.ToChucVuSanXuatNhatKyService.DataSource = new MatTableDataSource(this.ToChucVuSanXuatNhatKyService.list);
          this.ToChucVuSanXuatNhatKyService.DataSource.sort = this.ToChucVuSanXuatNhatKySort;
          this.ToChucVuSanXuatNhatKyService.DataSource.paginator = this.ToChucVuSanXuatNhatKyPaginator;
          this.isShowLoading = false;
        },
        err => {
          this.isShowLoading = false;
        }
      );
    }
    this.ToChucVuSanXuatNhatKySearch54();
  }
  ToChucVuSanXuatNhatKySave(element: ToChucVuSanXuatNhatKy) {
    this.isShowLoading = true;
    element.ParentID = this.ToChucVuSanXuatNhatKyService.baseParameter.ParentID;
    element.ToChucVuSanXuatVungTrongID = this.ToChucVuSanXuatNhatKyService.baseParameter.ToChucVuSanXuatVungTrongID;
    this.ToChucVuSanXuatNhatKyService.formData = element;
    this.ToChucVuSanXuatNhatKyService.SaveAndUploadFilesAsync().subscribe(
      res => {
        this.ToChucVuSanXuatNhatKySearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }
  ToChucVuSanXuatNhatKyDelete(element: ToChucVuSanXuatNhatKy) {
    this.isShowLoading = true;
    this.ToChucVuSanXuatNhatKyService.baseParameter.ID = element.ID;
    this.ToChucVuSanXuatNhatKyService.RemoveAsync().subscribe(
      res => {
        this.ToChucVuSanXuatNhatKySearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }
  ToChucVuSanXuatNhatKyCopyByID(element: ToChucVuSanXuatNhatKy) {
    if (confirm(environment.CopyByID)) {
      this.isShowLoading = true;
      this.ToChucVuSanXuatNhatKyService.formData = element;
      this.ToChucVuSanXuatNhatKyService.formData.ID = environment.InitializationNumber;
      this.ToChucVuSanXuatNhatKyService.SaveAsync().subscribe(
        res => {          
          this.ToChucVuSanXuatNhatKySearch();          
          this.NotificationService.warn(environment.SaveSuccess);
          this.isShowLoading = false;
        },
        err => {
          this.NotificationService.warn(environment.SaveNotSuccess);
          this.isShowLoading = false;
        }
      );
    }
  }
}