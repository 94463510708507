<div class="app-hero-header d-flex align-items-center">
    <div class="d-flex align-items-center">
        <div class="me-3 icon-box md bg-white rounded-4">
            <i class="bi bi-hypnotize fs-3 text-primary"></i>
        </div>
        <div>
            <h2 class="mb-1">{{ToChucVuSanXuatService.formData.DanhMucVuMuaName}} -
                {{ToChucVuSanXuatService.formData.Code}} [{{ToChucVuSanXuatService.formData.ID}}]</h2>
            <h6 class="m-0 text-dark fw-light">
                Nhật ký sản xuất
            </h6>
        </div>
    </div>
    <div class="ms-auto d-lg-flex d-none flex-row">
        <div class="d-flex flex-row gap-2">
            <a class="btn btn-danger" title="Đóng" (click)="Close()">
                <i class="bi bi-x-lg"></i>
            </a>
        </div>
    </div>
</div>
<div class="app-body">
    <div class="row gx-4">
        <div class="col-xxl-12">
            <div class="card mb-4">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-ToChucVuSanXuatDetail" data-bs-toggle="tab"
                                    href="#ToChucVuSanXuatDetail" role="tab" aria-controls="ToChucVuSanXuatDetail"
                                    aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">01: Vụ mùa</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-ToChucVuSanXuatVungTrongDetail" data-bs-toggle="tab"
                                    href="#ToChucVuSanXuatVungTrongDetail" role="tab"
                                    aria-controls="ToChucVuSanXuatVungTrongDetail" aria-selected="false">
                                    <span class="badge bg-warning" style="font-size: 16px;">02: Vùng trồng</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-ToChucVuSanXuatNhatKyDetail54" data-bs-toggle="tab"
                                    href="#ToChucVuSanXuatNhatKyDetail54" role="tab"
                                    aria-controls="ToChucVuSanXuatNhatKyDetail54" aria-selected="false">
                                    <span class="badge bg-success" style="font-size: 16px;">03: Nhật ký</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-ToChucVuSanXuatNhatKyDetail55" data-bs-toggle="tab"
                                    href="#ToChucVuSanXuatNhatKyDetail55" role="tab"
                                    aria-controls="ToChucVuSanXuatNhatKyDetail55" aria-selected="false">
                                    <span class="badge bg-danger" style="font-size: 16px;">04: Gây hại</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-ToChucVuSanXuatNhatKyDetail" data-bs-toggle="tab"
                                    href="#ToChucVuSanXuatNhatKyDetail" role="tab"
                                    aria-controls="ToChucVuSanXuatNhatKyDetail" aria-selected="false">
                                    <span class="badge bg-warning" style="font-size: 16px;">05: Mua vô</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-ToChucVuSanXuatNhatKyDetailIsMuaVo" data-bs-toggle="tab"
                                    href="#ToChucVuSanXuatNhatKyDetailIsMuaVo" role="tab"
                                    aria-controls="ToChucVuSanXuatNhatKyDetailIsMuaVo" aria-selected="false">
                                    <span class="badge bg-success" style="font-size: 16px;">06: Bán ra</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-ToChucVuSanXuatTieuChiDetail" data-bs-toggle="tab"
                                    href="#ToChucVuSanXuatTieuChiDetail" role="tab"
                                    aria-controls="ToChucVuSanXuatTieuChiDetail" aria-selected="false">
                                    <span class="badge bg-danger" style="font-size: 16px;">07: Tiêu chí</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-ToChucVuSanXuatTapTinDinhKemDetail" data-bs-toggle="tab"
                                    href="#ToChucVuSanXuatTapTinDinhKemDetail" role="tab"
                                    aria-controls="ToChucVuSanXuatTapTinDinhKemDetail" aria-selected="false">
                                    <span class="badge bg-primary" style="font-size: 16px;">08: Tài liệu</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="ToChucVuSanXuatDetail" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <a style="width: 100%;" title="Lưu thay đổi" (click)="ToChucVuSanXuatSave()"
                                            class="btn btn-info"><i class="bi bi-sd-card"></i> Lưu thay đổi</a>
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <div class="form-check form-check-inline mb-6">
                                            <input type="checkbox" class="form-check-input" name="Active"
                                                [(ngModel)]="ToChucVuSanXuatService.formData.Active" />
                                            <label class="form-check-label" for="Active">Kích hoạt</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <div class="form-check form-check-inline mb-6">
                                            <input type="checkbox" class="form-check-input" name="IsChungThuc"
                                                [(ngModel)]="ToChucVuSanXuatService.formData.IsChungThuc" />
                                            <label class="form-check-label" for="IsChungThuc">Đã chứng thực</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row gx-4">
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Hợp tác xã</label>
                                            <mat-select class="form-control" name="ParentID"
                                                [(ngModel)]="ToChucVuSanXuatService.formData.ParentID">
                                                <mat-option *ngFor="let item of ToChucService.list" [value]="item.ID">
                                                    {{item.Name}} - {{item.DanhMucTinhThanhName}}
                                                </mat-option>
                                            </mat-select>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Vụ mùa</label>
                                            <mat-select class="form-control" name="DanhMucVuMuaID"
                                                [(ngModel)]="ToChucVuSanXuatService.formData.DanhMucVuMuaID">
                                                <mat-option *ngFor="let item of DanhMucVuMuaService.list001"
                                                    [value]="item.ID">
                                                    {{item.Name}}
                                                </mat-option>
                                            </mat-select>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Mã vụ</label>
                                            <input name="Code" [(ngModel)]="ToChucVuSanXuatService.formData.Code"
                                                placeholder="Mã vụ" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Quy trình</label>
                                            <input name="TieuChuan"
                                                [(ngModel)]="ToChucVuSanXuatService.formData.TieuChuan"
                                                placeholder="Quy trình" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Mã số vùng trồng</label>
                                            <input name="MaSoVungTrong"
                                                [(ngModel)]="ToChucVuSanXuatService.formData.MaSoVungTrong"
                                                placeholder="Mã số vùng trồng" type="text" class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Giống</label>
                                            <input name="DanhMucNguyenVatLieuName"
                                                [(ngModel)]="ToChucVuSanXuatService.formData.DanhMucNguyenVatLieuName"
                                                placeholder="Giống" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Số lượng giống (kg)</label>
                                            <input name="SoLuong" [(ngModel)]="ToChucVuSanXuatService.formData.SoLuong"
                                                placeholder="0" type="number" style="text-align: right;"
                                                class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Mật độ (giống/m2)</label>
                                            <input name="MatDo" [(ngModel)]="ToChucVuSanXuatService.formData.MatDo"
                                                placeholder="0" type="number" style="text-align: right;"
                                                class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Chiều sâu (cm)</label>
                                            <input name="ChieuSau"
                                                [(ngModel)]="ToChucVuSanXuatService.formData.ChieuSau" placeholder="0"
                                                type="number" style="text-align: right;" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Sản lượng thu hoạch (tấn)</label>
                                            <input name="SanLuong"
                                                [(ngModel)]="ToChucVuSanXuatService.formData.SanLuong" placeholder="0"
                                                type="number" style="text-align: right;" class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Bắt đầu</label>
                                            <input
                                                [ngModel]="ToChucVuSanXuatService.formData.BatDau | date:'yyyy-MM-dd'"
                                                (ngModelChange)="DateBatDau($event)"
                                                [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control"
                                                style="text-align: right;" autocomplete="off">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Sản xuất (Gieo sạ)</label>
                                            <input
                                                [ngModel]="ToChucVuSanXuatService.formData.NgaySanXuat | date:'yyyy-MM-dd'"
                                                (ngModelChange)="DateNgaySanXuat($event)"
                                                [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control"
                                                style="text-align: right;" autocomplete="off">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Thu hoạch</label>
                                            <input
                                                [ngModel]="ToChucVuSanXuatService.formData.NgayThuHoach | date:'yyyy-MM-dd'"
                                                (ngModelChange)="DateNgayThuHoach($event)"
                                                [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control"
                                                style="text-align: right;" autocomplete="off">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Kết thúc</label>
                                            <input
                                                [ngModel]="ToChucVuSanXuatService.formData.KetThuc | date:'yyyy-MM-dd'"
                                                (ngModelChange)="DateKetThuc($event)"
                                                [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control"
                                                style="text-align: right;" autocomplete="off">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Ghi chú</label>
                                            <input name="Note" [(ngModel)]="ToChucVuSanXuatService.formData.Note"
                                                placeholder="Ghi chú" type="text" class="form-control">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="ToChucVuSanXuatService.formData.ID>0" class="tab-pane fade"
                                id="ToChucVuSanXuatVungTrongDetail" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-10 col-sm-12 col-12">
                                        <input [(ngModel)]="ToChucVuSanXuatVungTrongService.baseParameter.SearchString"
                                            placeholder="Tìm..." class="form-control"
                                            (keyup.enter)="ToChucVuSanXuatVungTrongSearch()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <div>
                                            <a class="btn btn-success" title="Tìm"
                                                (click)="ToChucVuSanXuatVungTrongSearch()"><i
                                                    class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                        </div>
                                    </div>
                                </div>
                                <div class="card mb-4 card-warning">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Vùng trồng/Nông hộ
                                            <span
                                                *ngIf="ToChucVuSanXuatVungTrongService.list">({{ToChucVuSanXuatVungTrongService.list.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #ToChucVuSanXuatVungTrongSort="matSort"
                                                    [dataSource]="ToChucVuSanXuatVungTrongService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{ToChucVuSanXuatVungTrongPaginator.pageSize *
                                                            ToChucVuSanXuatVungTrongPaginator.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>ID
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.ID}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Vùng
                                                            trồng/Nông hộ
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text" placeholder="Name"
                                                                name="Name{{element.ID}}" [(ngModel)]="element.Name">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Code">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Điện thoại
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text" placeholder="Code"
                                                                name="Code{{element.ID}}" [(ngModel)]="element.Code">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="MaSoVungTrong">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Mã số vùng
                                                            trồng
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Mã số vùng trồng"
                                                                name="MaSoVungTrong{{element.ID}}"
                                                                [(ngModel)]="element.MaSoVungTrong">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Active">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sản xuất
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline mb-6">
                                                                <input type="checkbox" class="form-check-input"
                                                                    name="Active{{element.ID}}"
                                                                    [(ngModel)]="element.Active" />
                                                            </div>
                                                        </td>
                                                    </ng-container>                                                   
                                                    <ng-container matColumnDef="IsChungThuc">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chứng thực
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline mb-6">
                                                                <input type="checkbox" class="form-check-input"
                                                                    name="IsChungThuc{{element.ID}}"
                                                                    [(ngModel)]="element.IsChungThuc" />
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info"
                                                                (click)="ToChucVuSanXuatVungTrongSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-info"
                                                                title="Sao chép tất cả sang vùng trồng khác cùng vụ mùa"
                                                                (click)="ToChucVuSanXuatNhatKyCopy(element)">Sao chép
                                                                tất cả</a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucVuSanXuatVungTrongService.displayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucVuSanXuatVungTrongService.displayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #ToChucVuSanXuatVungTrongPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="ToChucVuSanXuatService.formData.ID>0" class="tab-pane fade"
                                id="ToChucVuSanXuatNhatKyDetail54" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <select class="form-select"
                                            [(ngModel)]="ToChucVuSanXuatNhatKyService.baseParameter.ToChucVuSanXuatVungTrongID">
                                            <option *ngFor="let item of ToChucVuSanXuatVungTrongService.list001;"
                                                [value]="item.ID">
                                                {{item.Name}} - {{item.Code}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <input [(ngModel)]="ToChucVuSanXuatNhatKyService.baseParameter.SearchString"
                                            placeholder="Tìm..." class="form-control"
                                            (keyup.enter)="ToChucVuSanXuatNhatKySearch54()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <div class="form-check form-check-inline mb-6">
                                            <input type="checkbox" class="form-check-input" name="IsChungThuc54"
                                                [(ngModel)]="IsChungThuc54" (change)="ToChucVuSanXuatNhatKyChungThuc54Change()" />
                                            <label class="form-check-label" for="IsChungThuc54">Chứng thực</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <a class="btn btn-success" title="Tìm" style="width: 100%;"
                                            (click)="ToChucVuSanXuatNhatKySearch54()"><i class="bi bi-search"></i></a>
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <a class="btn btn-success"
                                            title="Sao chép nhật ký sang vùng trồng khác cùng vụ mùa"
                                            style="width: 100%;" (click)="ToChucVuSanXuatNhatKyAddSaoChep()">Sao chép</a>
                                    </div>
                                </div>
                                <div class="card mb-4 card-success">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Sổ nhật ký canh tác lúa phát thải thấp của
                                            {{ToChucVuSanXuatVungTrongService.formData.Name}}
                                            <span
                                                *ngIf="ToChucVuSanXuatNhatKyService.list54">({{ToChucVuSanXuatNhatKyService.list54.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #ToChucVuSanXuatNhatKySort54="matSort"
                                                    [dataSource]="ToChucVuSanXuatNhatKyService.DataSource54"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="BatDau">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Sổ nhật ký canh tác lúa phát thải thấp
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <a *ngIf="element.Active && !element.IsChungThuc"
                                                                        style="cursor: pointer; color: green;"
                                                                        title="{{element.Name}}"
                                                                        (click)="ToChucVuSanXuatNhatKyIsShow(element)"><b>{{element.Code}}
                                                                            - {{element.Name}} (Đã hoàn thành)</b></a>
                                                                    <a *ngIf="element.Active && element.IsChungThuc"
                                                                        style="cursor: pointer; color: blue;"
                                                                        title="{{element.Name}}"
                                                                        (click)="ToChucVuSanXuatNhatKyIsShow(element)"><b>{{element.Code}}
                                                                            - {{element.Name}} (Đã chứng thực)</b></a>
                                                                    <a *ngIf="!element.Active"
                                                                        style="cursor: pointer; color: red;"
                                                                        title="{{element.Name}}"
                                                                        (click)="ToChucVuSanXuatNhatKyIsShow(element)"><b>{{element.Code}}
                                                                            - {{element.Name}}</b></a>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12"
                                                                    style="text-wrap: wrap;">
                                                                    {{element.Description}}
                                                                </div>
                                                            </div>
                                                            <div *ngIf="element.IsShow">
                                                                <hr />
                                                                <div class="row gx-4">
                                                                    <div class="col-lg-6 col-sm-12 col-12">
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label class="form-label">Bắt đầu</label>
                                                                            <input name="BatDau{{element.ID}}"
                                                                                [ngModel]="element.BatDau | date:'yyyy-MM-dd HH:mm:ss'"
                                                                                (ngModelChange)="DateToChucVuSanXuatNhatKyBatDau(element, $event)"
                                                                                [ngModelOptions]="{ updateOn: 'blur' }"
                                                                                type="datetime-local"
                                                                                style="text-align: right;"
                                                                                class="form-control" autocomplete="off">
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label class="form-label">Kết thúc</label>
                                                                            <input name="KetThuc{{element.ID}}"
                                                                                [ngModel]="element.KetThuc | date:'yyyy-MM-dd HH:mm:ss'"
                                                                                (ngModelChange)="DateToChucVuSanXuatNhatKyKetThuc(element, $event)"
                                                                                [ngModelOptions]="{ updateOn: 'blur' }"
                                                                                type="datetime-local"
                                                                                style="text-align: right;"
                                                                                class="form-control" autocomplete="off">
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label class="form-label">Dữ liệu thực
                                                                                tế</label>
                                                                            <input class="form-control" type="text"
                                                                                placeholder="Dữ liệu thực tế"
                                                                                name="SanXuatThucHien{{element.ID}}"
                                                                                [(ngModel)]="element.SanXuatThucHien">
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-6 col-sm-12 col-12">
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label class="form-label">Tải lên
                                                                                [{{element.ID}}]</label>
                                                                            <br />
                                                                            <input type="file" multiple
                                                                                (change)="ToChucVuSanXuatNhatKyChangeFileName($event.target.files)">
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <div
                                                                                class="form-check form-check-inline mb-6">
                                                                                <input type="checkbox"
                                                                                    class="form-check-input"
                                                                                    name="Active{{element.ID}}"
                                                                                    [(ngModel)]="element.Active" />
                                                                                <label class="form-check-label"
                                                                                    for="Active">Đã hoàn thành công
                                                                                    việc</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <div
                                                                                class="form-check form-check-inline mb-6">
                                                                                <input type="checkbox"
                                                                                    class="form-check-input"
                                                                                    name="IsChungThuc{{element.ID}}"
                                                                                    [(ngModel)]="element.IsChungThuc" />
                                                                                <label class="form-check-label"
                                                                                    for="IsChungThuc">Đã chứng thực và
                                                                                    khóa dữ liệu</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row gx-4">
                                                                    <div class="col-lg-3 col-sm-12 col-12">
                                                                        <button class="btn btn-info"
                                                                            style="width: 100%; height: 41px;"
                                                                            (click)="ToChucVuSanXuatNhatKySave54(element)"><i
                                                                                class="bi bi-sd-card"></i>
                                                                        </button>
                                                                    </div>
                                                                    <div class="col-lg-3 col-sm-12 col-12">
                                                                        <a *ngIf="element.ID>0" class="btn btn-info"
                                                                            style="width: 100%;"
                                                                            (click)="ToChucVuSanXuatNhatKyCopy54ByID(element)">Copy
                                                                        </a>
                                                                    </div>
                                                                    <div class="col-lg-3 col-sm-12 col-12">
                                                                        <a *ngIf="element.ID>0" class="btn btn-warning"
                                                                            style="width: 100%;"
                                                                            (click)="ToChucVuSanXuatNhatKyAddIn(element)">Mua
                                                                            vô
                                                                        </a>
                                                                    </div>
                                                                    <div *ngIf="element.ID>0"
                                                                        class="col-lg-3 col-sm-12 col-12">
                                                                        <a class="btn btn-success" style="width: 100%;"
                                                                            (click)="ToChucVuSanXuatNhatKyAddOut(element)">Bán
                                                                            ra
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucVuSanXuatNhatKyService.displayColumns0054">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucVuSanXuatNhatKyService.displayColumns0054;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #ToChucVuSanXuatNhatKyPaginator54="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100,200,500]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="ToChucVuSanXuatService.formData.ID>0" class="tab-pane fade"
                                id="ToChucVuSanXuatNhatKyDetail55" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <select class="form-select"
                                            [(ngModel)]="ToChucVuSanXuatNhatKyService.baseParameter.ToChucVuSanXuatVungTrongID">
                                            <option *ngFor="let item of ToChucVuSanXuatVungTrongService.list001;"
                                                [value]="item.ID">
                                                {{item.Name}} - {{item.Code}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <input [(ngModel)]="ToChucVuSanXuatNhatKyService.baseParameter.SearchString"
                                            placeholder="Tìm..." class="form-control"
                                            (keyup.enter)="ToChucVuSanXuatNhatKySearch55()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <div class="form-check form-check-inline mb-6">
                                            <input type="checkbox" class="form-check-input" name="IsChungThuc55"
                                                [(ngModel)]="IsChungThuc55" (change)="ToChucVuSanXuatNhatKyChungThuc55Change()" />
                                            <label class="form-check-label" for="IsChungThuc55">Chứng thực</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <a class="btn btn-success" title="Tìm" style="width: 100%;"
                                            (click)="ToChucVuSanXuatNhatKySearch55()"><i class="bi bi-search"></i></a>
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <a class="btn btn-info"
                                            title="Sao chép gây hại sang vùng trồng khác cùng vụ mùa"
                                            style="width: 100%;" (click)="ToChucVuSanXuatNhatKyCopy55()">Sao chép</a>
                                    </div>
                                </div>
                                <div class="card mb-4 card-danger">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Gây hại
                                            <span
                                                *ngIf="ToChucVuSanXuatNhatKyService.list55">({{ToChucVuSanXuatNhatKyService.list55.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #ToChucVuSanXuatNhatKySort55="matSort"
                                                    [dataSource]="ToChucVuSanXuatNhatKyService.DataSource55"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="BatDau">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Ghi nhận
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Ghi nhận</label>
                                                                    <input
                                                                        [ngModel]="element.BatDau | date:'yyyy-MM-dd'"
                                                                        (ngModelChange)="DateToChucVuSanXuatNhatKyBatDau(element, $event)"
                                                                        [ngModelOptions]="{ updateOn: 'blur' }"
                                                                        type="date" style="text-align: right;"
                                                                        class="form-control" style="text-align: right;"
                                                                        autocomplete="off">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Tác nhân</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Tác nhân"
                                                                        name="SanXuatGayHai{{element.ID}}"
                                                                        [(ngModel)]="element.SanXuatGayHai">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Tên khoa học</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Tên khoa học"
                                                                        name="Code{{element.ID}}"
                                                                        [(ngModel)]="element.Code">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Ghi chú</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Ghi chú" name="Note{{element.ID}}"
                                                                        [(ngModel)]="element.Note">
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SanXuatDieuTra">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Điều tra
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Phương pháp</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Phương pháp"
                                                                        name="SanXuatDieuTra{{element.ID}}"
                                                                        [(ngModel)]="element.SanXuatDieuTra">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Xử lý</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Xử lý"
                                                                        name="SanXuatXuLy{{element.ID}}"
                                                                        [(ngModel)]="element.SanXuatXuLy">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Số lượng</label>
                                                                    <input class="form-control" type="number"
                                                                        style="text-align: right;" placeholder="0"
                                                                        name="SoLuong{{element.ID}}"
                                                                        [(ngModel)]="element.SoLuong">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Mật độ</label>
                                                                    <input class="form-control" type="number"
                                                                        style="text-align: right;" placeholder="0"
                                                                        name="DonGia{{element.ID}}"
                                                                        [(ngModel)]="element.DonGia">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Tỷ lệ thiệt hại</label>
                                                                    <input class="form-control" type="number"
                                                                        style="text-align: right;" placeholder="0"
                                                                        name="ThanhTien{{element.ID}}"
                                                                        [(ngModel)]="element.ThanhTien">
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NhaCungCap">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Giám sát +
                                                            Giám định
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Người giám sát</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Người giám sát"
                                                                        name="NhaCungCap{{element.ID}}"
                                                                        [(ngModel)]="element.NhaCungCap">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Phương pháp giám
                                                                        sát</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Phương pháp"
                                                                        name="NhaCungCapDienThoai{{element.ID}}"
                                                                        [(ngModel)]="element.NhaCungCapDienThoai">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Vị trí giám sát</label>
                                                                    <input class="form-control" placeholder="Vị trí"
                                                                        name="NhaCungCapDiaChi{{element.ID}}"
                                                                        [(ngModel)]="element.NhaCungCapDiaChi">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Người giám định</label>
                                                                    <input class="form-control"
                                                                        placeholder="Người giám định"
                                                                        name="Name{{element.ID}}"
                                                                        [(ngModel)]="element.Name">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Phương pháp giám
                                                                        định</label>
                                                                    <input class="form-control"
                                                                        placeholder="Phương pháp"
                                                                        name="Description{{element.ID}}"
                                                                        [(ngModel)]="element.Description">
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Active">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tình trạng
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Tải lên
                                                                        [{{element.ID}}]</label>
                                                                    <br />
                                                                    <input type="file" multiple
                                                                        (change)="ToChucVuSanXuatNhatKyChangeFileName($event.target.files)">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <div class="form-check form-check-inline mb-6">
                                                                        <input type="checkbox" class="form-check-input"
                                                                            name="Active{{element.ID}}"
                                                                            [(ngModel)]="element.Active" />
                                                                        <label class="form-check-label" for="Active">Đã
                                                                            hoàn thành công việc</label>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <div class="form-check form-check-inline mb-6">
                                                                        <input type="checkbox" class="form-check-input"
                                                                            name="IsChungThuc{{element.ID}}"
                                                                            [(ngModel)]="element.IsChungThuc" />
                                                                        <label class="form-check-label"
                                                                            for="IsChungThuc">Đã chứng thực và khóa dữ
                                                                            liệu</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12 col-sm-12 col-12">
                                                                <a class="btn btn-info" style="width: 100%;"
                                                                    (click)="ToChucVuSanXuatNhatKySave55(element)"><i
                                                                        class="bi bi-sd-card"></i></a>
                                                            </div>
                                                            <div class="col-lg-12 col-sm-12 col-12">
                                                                <a *ngIf="element.ID>0" class="btn btn-info"
                                                                    style="width: 100%;"
                                                                    (click)="ToChucVuSanXuatNhatKyCopyByID(element)">Copy</a>
                                                            </div>
                                                            <div class="col-lg-12 col-sm-12 col-12">
                                                                <a class="btn btn-danger" style="width: 100%;"
                                                                    (click)="ToChucVuSanXuatNhatKyDelete55(element)"
                                                                    *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                                </a>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucVuSanXuatNhatKyService.displayColumns0055">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucVuSanXuatNhatKyService.displayColumns0055;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #ToChucVuSanXuatNhatKyPaginator55="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="ToChucVuSanXuatService.formData.ID>0" class="tab-pane fade"
                                id="ToChucVuSanXuatNhatKyDetail" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <select class="form-select"
                                            [(ngModel)]="ToChucVuSanXuatNhatKyService.baseParameter.ToChucVuSanXuatVungTrongID">
                                            <option *ngFor="let item of ToChucVuSanXuatVungTrongService.list001;"
                                                [value]="item.ID">
                                                {{item.Name}} - {{item.Code}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <input [(ngModel)]="ToChucVuSanXuatNhatKyService.baseParameter.SearchString"
                                            placeholder="Tìm..." class="form-control"
                                            (keyup.enter)="ToChucVuSanXuatNhatKySearch()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <div class="form-check form-check-inline mb-6">
                                            <input type="checkbox" class="form-check-input" name="IsChungThuc00"
                                                [(ngModel)]="IsChungThuc00" (change)="ToChucVuSanXuatNhatKyChungThuc00Change()" />
                                            <label class="form-check-label" for="IsChungThuc00">Chứng thực</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <a class="btn btn-success" title="Tìm" style="width: 100%;"
                                            (click)="ToChucVuSanXuatNhatKySearch()"><i class="bi bi-search"></i></a>
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <a class="btn btn-info" title="Sao chép Mua vô sang vùng trồng khác cùng vụ mùa"
                                            style="width: 100%;" (click)="ToChucVuSanXuatNhatKyCopy00()">Sao chép</a>
                                    </div>
                                </div>
                                <div class="card mb-4 card-warning">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Mua vô
                                            <span
                                                *ngIf="ToChucVuSanXuatNhatKyService.list">({{ToChucVuSanXuatNhatKyService.list.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #ToChucVuSanXuatNhatKySort="matSort"
                                                    [dataSource]="ToChucVuSanXuatNhatKyService.DataSource"
                                                    class="table table-striped m-0">                                                   
                                                    <ng-container matColumnDef="BatDau">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi nhận
                                                        </th>
                                                        <td mat-cell *matCellDef="let element" style="width: 50%;">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Bắt đầu</label>
                                                                    <input name="BatDau{{element.ID}}"
                                                                        [ngModel]="element.BatDau | date:'yyyy-MM-dd HH:mm:ss'"
                                                                        (ngModelChange)="DateToChucVuSanXuatNhatKyBatDau(element, $event)"
                                                                        [ngModelOptions]="{ updateOn: 'blur' }"
                                                                        type="datetime-local" style="text-align: right;"
                                                                        class="form-control" autocomplete="off">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Kết thúc</label>
                                                                    <input name="KetThuc{{element.ID}}"
                                                                        [ngModel]="element.KetThuc | date:'yyyy-MM-dd HH:mm:ss'"
                                                                        (ngModelChange)="DateToChucVuSanXuatNhatKyKetThuc(element, $event)"
                                                                        [ngModelOptions]="{ updateOn: 'blur' }"
                                                                        type="datetime-local" style="text-align: right;"
                                                                        class="form-control" autocomplete="off">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Nhật ký</label>
                                                                    <br />
                                                                    <mat-select class="form-control"
                                                                        name="ToChucVuSanXuatNhatKyID{{element.ID}}"
                                                                        [(ngModel)]="element.ToChucVuSanXuatNhatKyID">
                                                                        <input placeholder="Tìm..." class="form-control"
                                                                            (keyup)="ToChucVuSanXuatNhatKyFilter($event.target.value)">
                                                                        <mat-option
                                                                            *ngFor="let item of ToChucVuSanXuatNhatKyService.list54"
                                                                            [value]="item.ID">
                                                                            {{item.Code}} - {{item.Name}}
                                                                        </mat-option>
                                                                    </mat-select>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Hạng mục công cụ/Thiết
                                                                        bị</label>
                                                                    <br />
                                                                    <mat-select class="form-control"
                                                                        name="DanhMucVuSanXuatThietBiID{{element.ID}}"
                                                                        [(ngModel)]="element.DanhMucVuSanXuatThietBiID">
                                                                        <input placeholder="Tìm..." class="form-control"
                                                                            (keyup)="DanhMucVuSanXuatThietBiFilter($event.target.value)">
                                                                        <mat-option
                                                                            *ngFor="let item of DanhMucVuSanXuatThietBiService.list001"
                                                                            [value]="item.ID">
                                                                            {{item.Name}}
                                                                        </mat-option>
                                                                    </mat-select>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Hạ tầng</label>
                                                                    <br />
                                                                    <mat-select class="form-control"
                                                                        name="ToChucID{{element.ID}}"
                                                                        [(ngModel)]="element.ToChucID">
                                                                        <input placeholder="Tìm..." class="form-control"
                                                                            (keyup)="ToChucFilter($event.target.value)">
                                                                        <mat-option
                                                                            *ngFor="let item of ToChucService.listHaTang001"
                                                                            [value]="item.ID">
                                                                            {{item.Name}}
                                                                        </mat-option>
                                                                    </mat-select>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucNguyenVatLieuName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Mua vô
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Số lượng</label>
                                                                    <input class="form-control" type="number"
                                                                        style="text-align: right;" placeholder="0"
                                                                        name="SoLuong{{element.ID}}"
                                                                        [(ngModel)]="element.SoLuong">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Đơn vị tính</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Đơn vị tính"
                                                                        name="DanhMucDonViTinhName{{element.ID}}"
                                                                        [(ngModel)]="element.DanhMucDonViTinhName">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Loại nhiên liệu</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Loại nhiên liệu"
                                                                        name="Description{{element.ID}}"
                                                                        [(ngModel)]="element.Description">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Tiêu thụ nhiên
                                                                        liệu</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Tiêu thụ nhiên liệu"
                                                                        name="SanXuatGayHai{{element.ID}}"
                                                                        [(ngModel)]="element.SanXuatGayHai">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Đơn vị tính (lít/h,
                                                                        kW/h)</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Đơn vị tính (lít/h, kW/h)"
                                                                        name="SanXuatDieuTra{{element.ID}}"
                                                                        [(ngModel)]="element.SanXuatDieuTra">
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NhaCungCap">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Cung cấp
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Hình thức</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Thuê/Mua mới/Đã có"
                                                                        name="SanXuatGiaiDoan{{element.ID}}"
                                                                        [(ngModel)]="element.SanXuatGiaiDoan">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Công suất</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Công suất"
                                                                        name="SanXuatThucHien{{element.ID}}"
                                                                        [(ngModel)]="element.SanXuatThucHien">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Nhà cung cấp (nếu
                                                                        có)</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Nhà cung cấp (nếu có)"
                                                                        name="NhaCungCap{{element.ID}}"
                                                                        [(ngModel)]="element.NhaCungCap">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Điện thoại</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Điện thoại"
                                                                        name="NhaCungCapDienThoai{{element.ID}}"
                                                                        [(ngModel)]="element.NhaCungCapDienThoai">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Địa chỉ</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Địa chỉ"
                                                                        name="NhaCungCapDiaChi{{element.ID}}"
                                                                        [(ngModel)]="element.NhaCungCapDiaChi">
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Active">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tình trạng
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Ghi chú</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Ghi chú" name="Note{{element.ID}}"
                                                                        [(ngModel)]="element.Note">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Tải lên
                                                                        [{{element.ID}}]</label>
                                                                    <br />
                                                                    <input type="file" multiple
                                                                        (change)="ToChucVuSanXuatNhatKyChangeFileName($event.target.files)">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <div class="form-check form-check-inline mb-6">
                                                                        <input type="checkbox" class="form-check-input"
                                                                            name="Active{{element.ID}}"
                                                                            [(ngModel)]="element.Active" />
                                                                        <label class="form-check-label" for="Active">Đã
                                                                            hoàn thành công việc</label>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <div class="form-check form-check-inline mb-6">
                                                                        <input type="checkbox" class="form-check-input"
                                                                            name="IsChungThuc{{element.ID}}"
                                                                            [(ngModel)]="element.IsChungThuc" />
                                                                        <label class="form-check-label"
                                                                            for="IsChungThuc">Đã chứng thực và khóa dữ
                                                                            liệu</label>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <a class="btn btn-info" style="width: 100%;"
                                                                        (click)="ToChucVuSanXuatNhatKySave(element)"><i
                                                                            class="bi bi-sd-card"></i></a>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <a *ngIf="element.ID>0" class="btn btn-info"
                                                                        style="width: 100%;"
                                                                        (click)="ToChucVuSanXuatNhatKyCopyByID(element)">Copy</a>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <a class="btn btn-danger" style="width: 100%;"
                                                                        (click)="ToChucVuSanXuatNhatKyDelete(element)"
                                                                        *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucVuSanXuatNhatKyService.displayColumns0003">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucVuSanXuatNhatKyService.displayColumns0003;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #ToChucVuSanXuatNhatKyPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="ToChucVuSanXuatService.formData.ID>0" class="tab-pane fade"
                                id="ToChucVuSanXuatNhatKyDetailIsMuaVo" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <select class="form-select"
                                            [(ngModel)]="ToChucVuSanXuatNhatKyService.baseParameter.ToChucVuSanXuatVungTrongID">
                                            <option *ngFor="let item of ToChucVuSanXuatVungTrongService.list001;"
                                                [value]="item.ID">
                                                {{item.Name}} - {{item.Code}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <input [(ngModel)]="ToChucVuSanXuatNhatKyService.baseParameter.SearchString"
                                            placeholder="Tìm..." class="form-control"
                                            (keyup.enter)="ToChucVuSanXuatNhatKySearchIsMuaVo()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <div class="form-check form-check-inline mb-6">
                                            <input type="checkbox" class="form-check-input" name="IsChungThucIsMuaVo"
                                                [(ngModel)]="IsChungThucIsMuaVo" (change)="ToChucVuSanXuatNhatKyChungThucIsMuaVoChange()" />
                                            <label class="form-check-label" for="IsChungThucIsMuaVo">Chứng thực</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <a class="btn btn-success" title="Tìm" style="width: 100%;"
                                            (click)="ToChucVuSanXuatNhatKySearchIsMuaVo()"><i class="bi bi-search"></i></a>
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <a class="btn btn-info" title="Sao chép Bán ra sang vùng trồng khác cùng vụ mùa"
                                            style="width: 100%;" (click)="ToChucVuSanXuatNhatKyCopy00()">Sao chép</a>
                                    </div>
                                </div>
                                <div class="card mb-4 card-warning">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Bán ra
                                            <span
                                                *ngIf="ToChucVuSanXuatNhatKyService.listIsMuaVo">({{ToChucVuSanXuatNhatKyService.listIsMuaVo.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #ToChucVuSanXuatNhatKyIsMuaVoSort="matSort"
                                                    [dataSource]="ToChucVuSanXuatNhatKyService.DataSourceIsMuaVo"
                                                    class="table table-striped m-0">                                                    
                                                    <ng-container matColumnDef="BatDau">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi nhận
                                                        </th>
                                                        <td mat-cell *matCellDef="let element" style="width: 30%;">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Ghi nhận</label>
                                                                    <input name="BatDau{{element.ID}}"
                                                                        [ngModel]="element.BatDau | date:'yyyy-MM-dd'"
                                                                        (ngModelChange)="DateToChucVuSanXuatNhatKyBatDau(element, $event)"
                                                                        [ngModelOptions]="{ updateOn: 'blur' }"
                                                                        type="date" style="text-align: right;"
                                                                        class="form-control" autocomplete="off">
                                                                </div>                                                               
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Nhật ký</label>
                                                                    <br />
                                                                    <mat-select class="form-control"
                                                                        name="ToChucVuSanXuatNhatKyID{{element.ID}}"
                                                                        [(ngModel)]="element.ToChucVuSanXuatNhatKyID">
                                                                        <input placeholder="Tìm..." class="form-control"
                                                                            (keyup)="ToChucVuSanXuatNhatKyFilter($event.target.value)">
                                                                        <mat-option
                                                                            *ngFor="let item of ToChucVuSanXuatNhatKyService.list54"
                                                                            [value]="item.ID">
                                                                            {{item.Code}} - {{item.Name}}
                                                                        </mat-option>
                                                                    </mat-select>
                                                                </div>   
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Ghi chú</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Ghi chú" name="Note{{element.ID}}"
                                                                        [(ngModel)]="element.Note">
                                                                </div>                                                             
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucNguyenVatLieuName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Bán ra
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Bán ra</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Bán ra"
                                                                        name="DanhMucNguyenVatLieuName{{element.ID}}"
                                                                        [(ngModel)]="element.DanhMucNguyenVatLieuName">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Số lượng</label>
                                                                    <input class="form-control" type="number"
                                                                        style="text-align: right;" placeholder="0"
                                                                        name="SoLuong{{element.ID}}"
                                                                        [(ngModel)]="element.SoLuong">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Đơn vị tính</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Đơn vị tính"
                                                                        name="DanhMucDonViTinhName{{element.ID}}"
                                                                        [(ngModel)]="element.DanhMucDonViTinhName">
                                                                </div>                                                                
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NhaCungCap">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Người mua
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">                                                   
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Người mua (nếu
                                                                        có)</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Người mua (nếu có)"
                                                                        name="NhaCungCap{{element.ID}}"
                                                                        [(ngModel)]="element.NhaCungCap">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Điện thoại</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Điện thoại"
                                                                        name="NhaCungCapDienThoai{{element.ID}}"
                                                                        [(ngModel)]="element.NhaCungCapDienThoai">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Địa chỉ</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Địa chỉ"
                                                                        name="NhaCungCapDiaChi{{element.ID}}"
                                                                        [(ngModel)]="element.NhaCungCapDiaChi">
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Active">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tình trạng
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">                                                               
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Tải lên
                                                                        [{{element.ID}}]</label>
                                                                    <br />
                                                                    <input type="file" multiple
                                                                        (change)="ToChucVuSanXuatNhatKyChangeFileName($event.target.files)">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <div class="form-check form-check-inline mb-6">
                                                                        <input type="checkbox" class="form-check-input"
                                                                            name="Active{{element.ID}}"
                                                                            [(ngModel)]="element.Active" />
                                                                        <label class="form-check-label" for="Active">Đã
                                                                            hoàn thành công việc</label>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <div class="form-check form-check-inline mb-6">
                                                                        <input type="checkbox" class="form-check-input"
                                                                            name="IsChungThuc{{element.ID}}"
                                                                            [(ngModel)]="element.IsChungThuc" />
                                                                        <label class="form-check-label"
                                                                            for="IsChungThuc">Đã chứng thực và khóa dữ
                                                                            liệu</label>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <a class="btn btn-info" style="width: 100%;"
                                                                        (click)="ToChucVuSanXuatNhatKySaveIsMuaVo(element)"><i
                                                                            class="bi bi-sd-card"></i></a>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <a *ngIf="element.ID>0" class="btn btn-info"
                                                                        style="width: 100%;"
                                                                        (click)="ToChucVuSanXuatNhatKyCopyByID(element)">Copy</a>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <a class="btn btn-danger" style="width: 100%;"
                                                                        (click)="ToChucVuSanXuatNhatKyDeleteIsMuaVo(element)"
                                                                        *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucVuSanXuatNhatKyService.displayColumns0003">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucVuSanXuatNhatKyService.displayColumns0003;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #ToChucVuSanXuatNhatKyIsMuaVoPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="ToChucVuSanXuatService.formData.ID>0" class="tab-pane fade"
                                id="ToChucVuSanXuatTieuChiDetail" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <label class="form-label">Năm</label>
                                        <input [(ngModel)]="ToChucVuSanXuatTieuChiService.baseParameter.Year"
                                            placeholder="0" style="text-align: right;" class="form-control"
                                            type="number" (keyup.enter)="ToChucVuSanXuatTieuChiSearch()">
                                    </div>
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <label class="form-label">Tháng</label>
                                        <input [(ngModel)]="ToChucVuSanXuatTieuChiService.baseParameter.Month"
                                            placeholder="0" style="text-align: right;" class="form-control"
                                            type="number" (keyup.enter)="ToChucVuSanXuatTieuChiSearch()">
                                    </div>
                                    <div class="col-lg-5 col-sm-12 col-12">
                                        <label class="form-label">.</label>
                                        <input [(ngModel)]="ToChucVuSanXuatTieuChiService.baseParameter.SearchString"
                                            placeholder="Tìm..." class="form-control"
                                            (keyup.enter)="ToChucVuSanXuatTieuChiSearch()">
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <label class="form-label">.</label>
                                        <div>
                                            <a class="btn btn-success" title="Tìm"
                                                (click)="ToChucVuSanXuatTieuChiSearch()"><i
                                                    class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                        </div>
                                    </div>
                                </div>
                                <div class="card mb-4 card-success">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Tiêu chí
                                            <span
                                                *ngIf="ToChucVuSanXuatTieuChiService.list">({{ToChucVuSanXuatTieuChiService.list.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #ToChucVuSanXuatTieuChiSort="matSort"
                                                    [dataSource]="ToChucVuSanXuatTieuChiService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{ToChucVuSanXuatTieuChiPaginator.pageSize *
                                                            ToChucVuSanXuatTieuChiPaginator.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                            class="sticky-col" style="background-color: black;">
                                                            <span style="color: #ffffff;">Tiêu chí</span>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element" class="sticky-col">
                                                            <b>{{element.Name}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Year">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Năm
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <b>{{element.Year}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Month">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Tháng
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <b>{{element.Month}}</b>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="KetQuaThucTeThang">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Thực tế
                                                            tháng
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="number" placeholder="0"
                                                                name="KetQuaThucTeThang{{element.ID}}"
                                                                [(ngModel)]="element.KetQuaThucTeThang"
                                                                style="text-align: right;">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="KetQuaThucTeNam">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Lũy kế năm
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="number" placeholder="0"
                                                                name="KetQuaThucTeNam{{element.ID}}"
                                                                [(ngModel)]="element.KetQuaThucTeNam"
                                                                style="text-align: right;">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="KetQuaNhoNhat2025">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Mục tiêu
                                                            đến 2025
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.KetQuaNhoNhat2025 |
                                                            number:'1.0-0'}} - {{element.KetQuaLonNhat2025 |
                                                            number:'1.0-0'}} ({{element.DonViTinh2025}})
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="TyLeHoanThanh2025">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tỷ lệ hoàn
                                                            thành
                                                            đến 2025
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.TyLeHoanThanh2025 |
                                                            number:'1.0-0'}} %
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="KetQuaNhoNhat2030">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Mục tiêu
                                                            đến 2030
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.KetQuaNhoNhat2030 |
                                                            number:'1.0-0'}} - {{element.KetQuaLonNhat2030 |
                                                            number:'1.0-0'}} ({{element.DonViTinh2030}})
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="TyLeHoanThanh2030">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tỷ lệ hoàn
                                                            thành
                                                            đến 2030
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.TyLeHoanThanh2030 |
                                                            number:'1.0-0'}} %
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsChungThuc">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chứng thực
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="IsChungThuc{{element.ID}}"
                                                                    [(ngModel)]="element.IsChungThuc">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info"
                                                                (click)="ToChucVuSanXuatTieuChiSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucVuSanXuatTieuChiService.displayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucVuSanXuatTieuChiService.displayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #ToChucVuSanXuatTieuChiPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="100"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="ToChucVuSanXuatService.formData.ID>0" class="tab-pane fade"
                                id="ToChucVuSanXuatTapTinDinhKemDetail" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-5 col-sm-12 col-12">
                                        <select class="form-select"
                                            [(ngModel)]="ToChucVuSanXuatTapTinDinhKemService.baseParameter.ToChucVuSanXuatVungTrongID">
                                            <option *ngFor="let item of ToChucVuSanXuatVungTrongService.list001;"
                                                [value]="item.ID">
                                                {{item.Name}} - {{item.Code}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-5 col-sm-12 col-12">
                                        <input
                                            [(ngModel)]="ToChucVuSanXuatTapTinDinhKemService.baseParameter.SearchString"
                                            placeholder="Tìm..." class="form-control"
                                            (keyup.enter)="ToChucVuSanXuatTapTinDinhKemSearch()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <div>
                                            <a class="btn btn-success" title="Tìm"
                                                (click)="ToChucVuSanXuatTapTinDinhKemSearch()"><i
                                                    class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                        </div>
                                    </div>
                                </div>
                                <div class="card mb-4 card-primary">
                                    <div class="card-header">
                                        <h5 class="card-title text-white bg-primary">Tài liệu
                                            <span
                                                *ngIf="ToChucVuSanXuatTapTinDinhKemService.list">({{ToChucVuSanXuatTapTinDinhKemService.list.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body bg-primary">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #ToChucVuSanXuatTapTinDinhKemSort="matSort"
                                                    [dataSource]="ToChucVuSanXuatTapTinDinhKemService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{ToChucVuSanXuatTapTinDinhKemPaginator.pageSize *
                                                            ToChucVuSanXuatTapTinDinhKemPaginator.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ToChucVuSanXuatVungTrongID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Vùng trồng
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <select class="form-select"
                                                                name="ToChucVuSanXuatVungTrongID{{element.ID}}"
                                                                [(ngModel)]="element.ToChucVuSanXuatVungTrongID">
                                                                <option
                                                                    *ngFor="let item of ToChucVuSanXuatVungTrongService.list001;"
                                                                    [value]="item.ID">
                                                                    {{item.Name}} - {{item.Code}}</option>
                                                            </select>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tài liệu
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Tài liệu" name="Name{{element.ID}}"
                                                                [(ngModel)]="element.Name">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Code">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tải lên
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input *ngIf="element.ID == 0" type="file" multiple
                                                                (change)="ChangeFileName($event.target.files)">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info"
                                                                (click)="ToChucVuSanXuatTapTinDinhKemSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger"
                                                                (click)="ToChucVuSanXuatTapTinDinhKemDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                            &nbsp;&nbsp;&nbsp;
                                                            <a *ngIf="element.ID > 0 && element.FileName"
                                                                class="btn btn-success" title="Tải về"
                                                                href="{{element.FileName}}" target="_blank"><i
                                                                    class="bi bi-cloud-download"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucVuSanXuatTapTinDinhKemService.displayColumns002">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucVuSanXuatTapTinDinhKemService.displayColumns002;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #ToChucVuSanXuatTapTinDinhKemPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>