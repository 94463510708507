import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-to-chuc-vu-san-xuat-tap-tin-dinh-kem-detail',
  templateUrl: './to-chuc-vu-san-xuat-tap-tin-dinh-kem-detail.component.html',
  styleUrls: ['./to-chuc-vu-san-xuat-tap-tin-dinh-kem-detail.component.css']
})
export class ToChucVuSanXuatTapTinDinhKemDetailComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
