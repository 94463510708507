<div class="app-body">
    <div class="row">
        <div class="col-xxl-12">
            <div class="card" style="margin-left: 1px">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-ToChucVuSanXuatNhatKy" data-bs-toggle="tab"
                                    href="#ToChucVuSanXuatNhatKy" role="tab" aria-controls="ToChucVuSanXuatNhatKy"
                                    aria-selected="true">
                                    <span class="badge bg-success" style="font-size: 16px">3. Nhật ký</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="ToChucVuSanXuatNhatKy" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">Vụ mùa</label>
                                        <mat-select class="form-control" name="ParentID"
                                            (selectionChange)="ToChucVuSanXuatVungTrongSearch()"
                                            [(ngModel)]="ToChucVuSanXuatNhatKyService.baseParameter.ParentID">
                                            <input placeholder="Tìm..." class="form-control"
                                                (keyup)="ToChucVuSanXuatFilter($event.target.value)">
                                            <mat-option *ngFor="let item of ToChucVuSanXuatService.list001"
                                                [value]="item.ID">
                                                {{item.DanhMucVuMuaName}} - {{item.BatDau | date:'dd/MM/yyyy'}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">Vùng trồng</label>
                                        <mat-select class="form-control" name="ToChucVuSanXuatVungTrongID"
                                            [(ngModel)]="ToChucVuSanXuatNhatKyService.baseParameter.ToChucVuSanXuatVungTrongID">
                                            <mat-option *ngFor="let item of ToChucVuSanXuatVungTrongService.list001"
                                                [value]="item.ID">
                                                {{item.Name}} - {{item.Code}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                                        <input name="SearchString"
                                            [(ngModel)]="ToChucVuSanXuatNhatKyService.baseParameter.SearchString"
                                            placeholder="Tìm..." class="form-control"
                                            (keyup.enter)="ToChucVuSanXuatNhatKySearch54()">
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                                        <a class="btn btn-success" title="Tìm..." style="width: 100%;"
                                            (click)="ToChucVuSanXuatNhatKySearch54()"><i class="bi bi-search"></i></a>
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                                        <a class="btn btn-success"
                                            title="Sao chép nhật ký sang vùng trồng khác cùng vụ mùa"
                                            style="width: 100%;" (click)="ToChucVuSanXuatNhatKyAddSaoChep()">Sao chép</a>
                                    </div>
                                </div>
                                <div class="card mb-4 card-success">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Sổ nhật ký canh tác lúa phát thải thấp của
                                            {{ToChucVuSanXuatVungTrongService.formData.Name}}
                                            <span
                                                *ngIf="ToChucVuSanXuatNhatKyService.list54">({{ToChucVuSanXuatNhatKyService.list54.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                                    #ToChucVuSanXuatNhatKySort54="matSort"
                                                    [dataSource]="ToChucVuSanXuatNhatKyService.DataSource54"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="BatDau">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Sổ nhật ký canh tác lúa phát thải thấp
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <a *ngIf="element.Active && !element.IsChungThuc"
                                                                        style="cursor: pointer; color: green;"
                                                                        title="{{element.Name}}"
                                                                        (click)="ToChucVuSanXuatNhatKyIsShow(element)"><b>{{element.Code}}
                                                                            - {{element.Name}} (Đã hoàn thành)</b></a>
                                                                    <a *ngIf="element.Active && element.IsChungThuc"
                                                                        style="cursor: pointer; color: blue;"
                                                                        title="{{element.Name}}"
                                                                        (click)="ToChucVuSanXuatNhatKyIsShow(element)"><b>{{element.Code}}
                                                                            - {{element.Name}} (Đã chứng thực)</b></a>
                                                                    <a *ngIf="!element.Active"
                                                                        style="cursor: pointer; color: red;"
                                                                        title="{{element.Name}}"
                                                                        (click)="ToChucVuSanXuatNhatKyIsShow(element)"><b>{{element.Code}}
                                                                            - {{element.Name}}</b></a>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12"
                                                                    style="text-wrap: wrap;">
                                                                    {{element.Description}}
                                                                </div>
                                                            </div>
                                                            <div *ngIf="element.IsShow">
                                                                <hr />
                                                                <div class="row gx-4">
                                                                    <div class="col-lg-6 col-sm-12 col-12">
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label class="form-label">Bắt đầu</label>
                                                                            <input name="BatDau{{element.ID}}"
                                                                                [ngModel]="element.BatDau | date:'yyyy-MM-dd HH:mm:ss'"
                                                                                (ngModelChange)="DateToChucVuSanXuatNhatKyBatDau(element, $event)"
                                                                                [ngModelOptions]="{ updateOn: 'blur' }"
                                                                                type="datetime-local"
                                                                                style="text-align: right;"
                                                                                class="form-control" autocomplete="off">
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label class="form-label">Kết thúc</label>
                                                                            <input name="KetThuc{{element.ID}}"
                                                                                [ngModel]="element.KetThuc | date:'yyyy-MM-dd HH:mm:ss'"
                                                                                (ngModelChange)="DateToChucVuSanXuatNhatKyKetThuc(element, $event)"
                                                                                [ngModelOptions]="{ updateOn: 'blur' }"
                                                                                type="datetime-local"
                                                                                style="text-align: right;"
                                                                                class="form-control" autocomplete="off">
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label class="form-label">Dữ liệu thực
                                                                                tế</label>
                                                                            <input class="form-control" type="text"
                                                                                placeholder="Dữ liệu thực tế"
                                                                                name="SanXuatThucHien{{element.ID}}"
                                                                                [(ngModel)]="element.SanXuatThucHien">
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-6 col-sm-12 col-12">
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <label class="form-label">Tải lên
                                                                                [{{element.ID}}]</label>
                                                                            <br />
                                                                            <input type="file" multiple
                                                                                (change)="ToChucVuSanXuatNhatKyChangeFileName($event.target.files)">
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <div
                                                                                class="form-check form-check-inline mb-6">
                                                                                <input type="checkbox"
                                                                                    class="form-check-input"
                                                                                    name="Active{{element.ID}}"
                                                                                    [(ngModel)]="element.Active" />
                                                                                <label class="form-check-label"
                                                                                    for="Active">Đã hoàn thành công
                                                                                    việc</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12 col-sm-12 col-12">
                                                                            <div
                                                                                class="form-check form-check-inline mb-6">
                                                                                <input type="checkbox" disabled
                                                                                    class="form-check-input"
                                                                                    name="IsChungThuc{{element.ID}}"
                                                                                    [(ngModel)]="element.IsChungThuc" />
                                                                                <label class="form-check-label"
                                                                                    for="IsChungThuc">Đã chứng thực và
                                                                                    khóa dữ liệu</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row gx-4">
                                                                    <div class="col-lg-3 col-sm-12 col-12">
                                                                        <button [disabled]="element.IsChungThuc"
                                                                            class="btn btn-info"
                                                                            style="width: 100%; height: 41px;"
                                                                            (click)="ToChucVuSanXuatNhatKySave54(element)"><i
                                                                                class="bi bi-sd-card"></i>
                                                                        </button>
                                                                    </div>
                                                                    <div class="col-lg-3 col-sm-12 col-12">
                                                                        <a *ngIf="element.ID>0" class="btn btn-info"
                                                                            style="width: 100%;"
                                                                            (click)="ToChucVuSanXuatNhatKyCopy54ByID(element)">Copy
                                                                        </a>
                                                                    </div>
                                                                    <div class="col-lg-3 col-sm-12 col-12">
                                                                        <a *ngIf="element.ID>0" class="btn btn-warning"
                                                                            style="width: 100%;"
                                                                            (click)="ToChucVuSanXuatNhatKyAddIn(element)">Mua
                                                                            vô
                                                                        </a>
                                                                    </div>
                                                                    <div *ngIf="element.ID>0"
                                                                        class="col-lg-3 col-sm-12 col-12">
                                                                        <a class="btn btn-success" style="width: 100%;"
                                                                            (click)="ToChucVuSanXuatNhatKyAddOut(element)">Bán
                                                                            ra
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucVuSanXuatNhatKyService.displayColumns0054">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucVuSanXuatNhatKyService.displayColumns0054;">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="!NotificationService.IsMobile"
                                                    #ToChucVuSanXuatNhatKyPaginator54="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                                    #ToChucVuSanXuatNhatKySort54="matSort"
                                                    [dataSource]="ToChucVuSanXuatNhatKyService.DataSource54"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Danh sách
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12"
                                                                    style="text-wrap: wrap;">
                                                                    <a *ngIf="element.Active && !element.IsChungThuc"
                                                                        style="cursor: pointer; color: green; text-wrap: wrap;"
                                                                        title="{{element.Name}}"
                                                                        (click)="ToChucVuSanXuatNhatKyIsShow(element)"><b>{{element.Code}}
                                                                            - {{element.Name}} (Đã hoàn thành)</b></a>
                                                                    <a *ngIf="element.Active && element.IsChungThuc"
                                                                        style="cursor: pointer; color: blue; text-wrap: wrap;"
                                                                        title="{{element.Name}}"
                                                                        (click)="ToChucVuSanXuatNhatKyIsShow(element)"><b>{{element.Code}}
                                                                            - {{element.Name}} (Đã chứng thực)</b></a>
                                                                    <a *ngIf="!element.Active"
                                                                        style="cursor: pointer; color: red; text-wrap: wrap;"
                                                                        title="{{element.Name}}"
                                                                        (click)="ToChucVuSanXuatNhatKyIsShow(element)"><b>{{element.Code}}
                                                                            - {{element.Name}}</b></a>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12"
                                                                    style="text-wrap: wrap;">
                                                                    {{element.Description}}
                                                                </div>
                                                            </div>
                                                            <div *ngIf="element.IsShow">
                                                                <hr />
                                                                <div class="row gx-4">
                                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                                        <label class="form-label">Bắt đầu</label>
                                                                        <input name="BatDau{{element.ID}}"
                                                                            [ngModel]="element.BatDau | date:'yyyy-MM-dd HH:mm:ss'"
                                                                            (ngModelChange)="DateToChucVuSanXuatNhatKyBatDau(element, $event)"
                                                                            [ngModelOptions]="{ updateOn: 'blur' }"
                                                                            type="datetime-local"
                                                                            style="text-align: right;"
                                                                            class="form-control" autocomplete="off">
                                                                    </div>
                                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                                        <label class="form-label">Kết thúc</label>
                                                                        <input name="KetThuc{{element.ID}}"
                                                                            [ngModel]="element.KetThuc | date:'yyyy-MM-dd HH:mm:ss'"
                                                                            (ngModelChange)="DateToChucVuSanXuatNhatKyKetThuc(element, $event)"
                                                                            [ngModelOptions]="{ updateOn: 'blur' }"
                                                                            type="datetime-local"
                                                                            style="text-align: right;"
                                                                            class="form-control" autocomplete="off">
                                                                    </div>
                                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                                        <label class="form-label">Dữ liệu thực
                                                                            tế</label>
                                                                        <input class="form-control" type="text"
                                                                            placeholder="Dữ liệu thực tế"
                                                                            name="SanXuatThucHien{{element.ID}}"
                                                                            [(ngModel)]="element.SanXuatThucHien">
                                                                    </div>
                                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                                        <label class="form-label">Tải lên
                                                                            [{{element.ID}}]</label>
                                                                        <br />
                                                                        <input type="file" multiple style="width: 200px;"
                                                                            (change)="ToChucVuSanXuatNhatKyChangeFileName($event.target.files)">
                                                                    </div>
                                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                                        <div class="form-check form-check-inline mb-6">
                                                                            <input type="checkbox"
                                                                                class="form-check-input"
                                                                                name="Active{{element.ID}}"
                                                                                [(ngModel)]="element.Active" />
                                                                            <label class="form-check-label"
                                                                                for="Active">Đã hoàn thành công
                                                                                việc</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                                        <div class="form-check form-check-inline mb-6">
                                                                            <input type="checkbox" disabled
                                                                                class="form-check-input"
                                                                                name="IsChungThuc{{element.ID}}"
                                                                                [(ngModel)]="element.IsChungThuc" />
                                                                            <label class="form-check-label"
                                                                                for="IsChungThuc">Đã chứng thực</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row gx-4">
                                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                                        <button [disabled]="element.IsChungThuc"
                                                                            class="btn btn-info"
                                                                            style="width: 100%; height: 41px;"
                                                                            (click)="ToChucVuSanXuatNhatKySave54(element)"><i
                                                                                class="bi bi-sd-card"></i>
                                                                        </button>
                                                                    </div>
                                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                                        <a *ngIf="element.ID>0" class="btn btn-info"
                                                                            style="width: 100%;"
                                                                            (click)="ToChucVuSanXuatNhatKyCopy54ByID(element)">Copy
                                                                        </a>
                                                                    </div>
                                                                    <div class="col-lg-12 col-sm-12 col-12">
                                                                        <a *ngIf="element.ID>0" class="btn btn-warning"
                                                                            style="width: 100%;"
                                                                            (click)="ToChucVuSanXuatNhatKyAddIn(element)">Mua
                                                                            vô
                                                                        </a>
                                                                    </div>
                                                                    <div *ngIf="element.ID>0"
                                                                        class="col-lg-312 col-sm-12 col-12">
                                                                        <a class="btn btn-success" style="width: 100%;"
                                                                            (click)="ToChucVuSanXuatNhatKyAddOut(element)">Bán
                                                                            ra
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucVuSanXuatNhatKyService.DisplayColumnsMobile">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucVuSanXuatNhatKyService.DisplayColumnsMobile">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="NotificationService.IsMobile"
                                                    #ToChucVuSanXuatNhatKyPaginator54="matPaginator"
                                                    [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="10000"
                                                    [showFirstLastButtons]></mat-paginator>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>