<div class="app-hero-header d-flex align-items-center">
    <div class="d-flex align-items-center">
        <div class="me-3 icon-box md bg-white rounded-4">
            <i class="bi bi-hypnotize fs-3 text-primary"></i>
        </div>
        <div>
            <h2 class="mb-1">{{ToChucService.formData.Name}} [{{ToChucService.formData.ID}}]</h2>
            <h6 class="m-0 text-dark fw-light">
                Hạ tầng
            </h6>
        </div>
    </div>
    <div class="ms-auto d-lg-flex d-none flex-row">
        <div class="d-flex flex-row gap-2">
            <a class="btn btn-danger" title="Đóng" (click)="Close()">
                <i class="bi bi-x-lg"></i>
            </a>
        </div>
    </div>
</div>
<div class="app-body">
    <div class="row gx-4">
        <div class="col-xxl-12">
            <div class="card mb-4">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-ToChucDetail" data-bs-toggle="tab"
                                    href="#ToChucDetail" role="tab" aria-controls="ToChucDetail" aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">01: Pháp lý</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-ToChucVungTrongDetail" data-bs-toggle="tab"
                                    href="#ToChucVungTrongDetail" role="tab" aria-controls="ToChucVungTrongDetail"
                                    aria-selected="false">
                                    <span class="badge bg-danger" style="font-size: 16px;">02: Vị trí</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-MapDetail" data-bs-toggle="tab" href="#MapDetail" role="tab"
                                    aria-controls="MapDetail" aria-selected="false">
                                    <span class="badge bg-success" style="font-size: 16px;">03: Bản đồ</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-ToChucHopTacDetail" data-bs-toggle="tab"
                                    href="#ToChucHopTacDetail" role="tab" aria-controls="ToChucHopTacDetail"
                                    aria-selected="false">
                                    <span class="badge bg-warning" style="font-size: 16px;">04: HTX hoặc Nông hộ</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-ToChucTapTinDinhKemDetail" data-bs-toggle="tab"
                                    href="#ToChucTapTinDinhKemDetail" role="tab"
                                    aria-controls="ToChucTapTinDinhKemDetail" aria-selected="false">
                                    <span class="badge bg-primary" style="font-size: 16px;">04: Tài liệu</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="ToChucDetail" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <a style="width: 100%;" title="Lưu thay đổi" (click)="ToChucSave()"
                                            class="btn btn-info"><i class="bi bi-sd-card"></i> Lưu thay đổi</a>
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <div class="form-check form-check-inline mb-6">
                                            <input type="checkbox" class="form-check-input" name="Active"
                                                [(ngModel)]="ToChucService.formData.Active" />
                                            <label class="form-check-label" for="Active">Kích hoạt</label>
                                        </div>
                                    </div>                                    
                                </div>
                                <div class="row gx-4">
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Tiêu đề</label>
                                            <input name="Name" [(ngModel)]="ToChucService.formData.Name"
                                                placeholder="Tiêu đề" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Mã quản lý</label>
                                            <input name="Code" [(ngModel)]="ToChucService.formData.Code"
                                                placeholder="Mã quản lý" type="text" class="form-control">
                                        </div>     
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Cơ quan quản lý</label>
                                            <input name="Description" [(ngModel)]="ToChucService.formData.Description"
                                                placeholder="Cơ quan quản lý" type="text" class="form-control">
                                        </div>                                                                           
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Loại Năng Lượng</label>
                                            <input name="Display" [(ngModel)]="ToChucService.formData.Display"
                                                placeholder="Loại Năng Lượng" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Hiệu Quả Mang Lại</label>
                                            <input name="ChungNhan" [(ngModel)]="ToChucService.formData.ChungNhan"
                                                placeholder="Hiệu Quả Mang Lại" type="text" class="form-control">
                                        </div>       
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Hình Thức Sử Dụng</label>
                                            <input name="DienThoai" [(ngModel)]="ToChucService.formData.DienThoai"
                                                placeholder="Hình Thức Sử Dụng" type="text" class="form-control">
                                        </div>  
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Hình Thức Vận Hành</label>
                                            <input name="Email" [(ngModel)]="ToChucService.formData.Email"
                                                placeholder="Hình Thức Vận Hành" type="text" class="form-control">
                                        </div>                                 
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">                                       
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Công suất (KW/h)</label>
                                            <input name="NangLucThuMua"
                                                [(ngModel)]="ToChucService.formData.NangLucThuMua" placeholder="0"
                                                type="number" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Lượng nguyên liệu tiêu hao theo giờ</label>
                                            <input name="NangLucTaiChinh"
                                                [(ngModel)]="ToChucService.formData.NangLucTaiChinh" placeholder="0"
                                                type="number" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Số giờ sử dụng thiết bị mỗi lần</label>
                                            <input name="DoanhThu" [(ngModel)]="ToChucService.formData.DoanhThu"
                                                placeholder="0" type="number" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Người liên hệ</label>
                                            <input name="LienHeHoTen" [(ngModel)]="ToChucService.formData.LienHeHoTen"
                                                placeholder="Người liên hệ" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Điện thoại</label>
                                            <input name="LienHeDienThoai"
                                                [(ngModel)]="ToChucService.formData.LienHeDienThoai"
                                                placeholder="Điện thoại" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Email</label>
                                            <input name="LienHeEmail" [(ngModel)]="ToChucService.formData.LienHeEmail"
                                                placeholder="Email" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">CCCD</label>
                                            <input name="LienHeCCCD" [(ngModel)]="ToChucService.formData.LienHeCCCD"
                                                placeholder="CCCD" type="text" class="form-control">
                                        </div>                                       
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Tỉnh thành</label>
                                            <mat-select class="form-control" name="DanhMucTinhThanhID"
                                                (selectionChange)="DanhMucQuanHuyenGetToList()"
                                                [(ngModel)]="ToChucService.formData.DanhMucTinhThanhID">
                                                <input class="form-control"
                                                    (keyup)="DanhMucTinhThanhFilter($event.target.value)">
                                                <mat-option *ngFor="let item of DanhMucTinhThanhService.list001"
                                                    [value]="item.ID">
                                                    {{item.Name}}
                                                </mat-option>
                                            </mat-select>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Quận huyện</label>
                                            <mat-select class="form-control" name="DanhMucQuanHuyenID"
                                                (selectionChange)="DanhMucXaPhuongGetToList()"
                                                [(ngModel)]="ToChucService.formData.DanhMucQuanHuyenID">
                                                <input class="form-control"
                                                    (keyup)="DanhMucQuanHuyenFilter($event.target.value)">
                                                <mat-option *ngFor="let item of DanhMucQuanHuyenService.list001"
                                                    [value]="item.ID">
                                                    {{item.Name}}
                                                </mat-option>
                                            </mat-select>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Xã phường</label>
                                            <mat-select class="form-control" name="DanhMucXaPhuongID"
                                                [(ngModel)]="ToChucService.formData.DanhMucXaPhuongID">
                                                <input class="form-control"
                                                    (keyup)="DanhMucXaPhuongFilter($event.target.value)">
                                                <mat-option *ngFor="let item of DanhMucXaPhuongService.list001"
                                                    [value]="item.ID">
                                                    {{item.Name}}
                                                </mat-option>
                                            </mat-select>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Ấp thôn</label>
                                            <input name="ApThon" [(ngModel)]="ToChucService.formData.ApThon"
                                                placeholder="Ấp thôn" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Địa chỉ</label>
                                            <input name="DiaChi" [(ngModel)]="ToChucService.formData.DiaChi"
                                                placeholder="Địa chỉ" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Kinh độ</label>
                                            <input name="KinhDo" [(ngModel)]="ToChucService.formData.KinhDo"
                                                placeholder="Kinh độ" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Vĩ độ</label>
                                            <input name="ViDo" [(ngModel)]="ToChucService.formData.ViDo"
                                                placeholder="Vĩ độ" type="text" class="form-control">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="ToChucService.formData.ID>0" class="tab-pane fade" id="ToChucKhoHangDetail"
                                role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-10 col-sm-12 col-12">
                                        <input [(ngModel)]="ToChucKhoHangService.SearchString" placeholder="Tìm..."
                                            class="form-control" (keyup.enter)="ToChucKhoHangSearch()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <div>
                                            <a class="btn btn-success" title="Tìm" (click)="ToChucKhoHangSearch()"><i
                                                    class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                        </div>
                                    </div>
                                </div>
                                <div class="card mb-4 card-danger">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Kho hàng
                                            <span
                                                *ngIf="ToChucKhoHangService.list">({{ToChucKhoHangService.list.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #ToChucKhoHangSort="matSort"
                                                    [dataSource]="ToChucKhoHangService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{ToChucKhoHangPaginator.pageSize *
                                                            ToChucKhoHangPaginator.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Kho hàng
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Kho hàng" name="Name{{element.ID}}"
                                                                [(ngModel)]="element.Name">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Code">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Mã kho
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text" placeholder="Mã kho"
                                                                name="Code{{element.ID}}" [(ngModel)]="element.Code">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DienTich">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Diện tích
                                                            (m2)
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="number"
                                                                style="text-align: right;" placeholder="0"
                                                                name="DienTich{{element.ID}}"
                                                                [(ngModel)]="element.DienTich">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="CongNgheBaoQuan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Công nghệ
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Công nghệ bảo quản"
                                                                name="CongNgheBaoQuan{{element.ID}}"
                                                                [(ngModel)]="element.CongNgheBaoQuan">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="TieuChuan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu chuẩn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Tiêu chuẩn" name="TieuChuan{{element.ID}}"
                                                                [(ngModel)]="element.TieuChuan">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DiaChi">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Địa chỉ
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Địa chỉ" name="TieuChuan{{element.ID}}"
                                                                [(ngModel)]="element.DiaChi">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="KinhDo">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Kinh độ
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Kinh độ" name="KinhDo{{element.ID}}"
                                                                [(ngModel)]="element.KinhDo">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ViDo">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Vĩ độ
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text" placeholder="Vĩ độ"
                                                                name="ViDo{{element.ID}}" [(ngModel)]="element.ViDo">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info"
                                                                (click)="ToChucKhoHangSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger"
                                                                (click)="ToChucKhoHangDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucKhoHangService.displayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucKhoHangService.displayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #ToChucKhoHangPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="ToChucService.formData.ID>0" class="tab-pane fade" id="ToChucPhuongTienDetail"
                                role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-10 col-sm-12 col-12">
                                        <input [(ngModel)]="ToChucPhuongTienService.SearchString" placeholder="Tìm..."
                                            class="form-control" (keyup.enter)="ToChucPhuongTienSearch()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <div>
                                            <a class="btn btn-success" title="Tìm" (click)="ToChucPhuongTienSearch()"><i
                                                    class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                        </div>
                                    </div>
                                </div>
                                <div class="card mb-4 card-success">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Phương tiện
                                            <span
                                                *ngIf="ToChucPhuongTienService.list">({{ToChucPhuongTienService.list.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #ToChucPhuongTienSort="matSort"
                                                    [dataSource]="ToChucPhuongTienService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{ToChucPhuongTienPaginator.pageSize *
                                                            ToChucPhuongTienPaginator.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Phương
                                                            tiện
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Phương tiện" name="Name{{element.ID}}"
                                                                [(ngModel)]="element.Name">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Code">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Giấy phép
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Giấy phép" name="Code{{element.ID}}"
                                                                [(ngModel)]="element.Code">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SoHieuPhuongTien">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Số hiệu
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Số hiệu"
                                                                name="CongNgheBaoQuan{{element.ID}}"
                                                                [(ngModel)]="element.CongNgheBaoQuan">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="CongSuat">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Công suất
                                                            (tấn)</th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="number"
                                                                style="text-align: right;" placeholder="0"
                                                                name="CongSuat{{element.ID}}"
                                                                [(ngModel)]="element.CongSuat">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info"
                                                                (click)="ToChucPhuongTienSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger"
                                                                (click)="ToChucPhuongTienDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucPhuongTienService.displayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucPhuongTienService.displayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #ToChucPhuongTienPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="ToChucService.formData.ID>0" class="tab-pane fade" id="ToChucHopTacDetail" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-10 col-sm-12 col-12">
                                        <input [(ngModel)]="ToChucHopTacService.SearchString" placeholder="Tìm..."
                                            class="form-control" (keyup.enter)="ToChucHopTacSearch()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <div>
                                            <a class="btn btn-success" title="Tìm" (click)="ToChucHopTacSearch()"><i
                                                    class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                        </div>
                                    </div>
                                </div>
                                <div class="card mb-4 card-warning">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">HTX hoặc Nông hộ
                                            <span *ngIf="ToChucHopTacService.list">({{ToChucHopTacService.list.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #ToChucHopTacSort="matSort"
                                                    [dataSource]="ToChucHopTacService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{ToChucHopTacPaginator.pageSize *
                                                            ToChucHopTacPaginator.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Display">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hình thức
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Hình thức" name="Display{{element.ID}}"
                                                                [(ngModel)]="element.Display">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>HTX hoặc Nông hộ
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="HTX hoặc Nông hộ" name="Name{{element.ID}}"
                                                                [(ngModel)]="element.Name">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Code">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Điện thoại
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Điện thoại" name="Code{{element.ID}}"
                                                                [(ngModel)]="element.Code">
                                                        </td>
                                                    </ng-container>     
                                                    <ng-container matColumnDef="NgayGhiNhan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi nhận
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input [ngModel]="element.NgayGhiNhan | date:'yyyy-MM-dd'"
                                                                (ngModelChange)="DateNgayGhiNhan(element, $event)"
                                                                [ngModelOptions]="{ updateOn: 'blur' }" type="date"
                                                                class="form-control" style="text-align: right;"
                                                                autocomplete="off">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info"
                                                                (click)="ToChucHopTacSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger"
                                                                (click)="ToChucHopTacDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucHopTacService.displayColumns003">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucHopTacService.displayColumns003;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #ToChucHopTacPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="ToChucService.formData.ID>0" class="tab-pane fade"
                                id="ToChucTapTinDinhKemDetail" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-10 col-sm-12 col-12">
                                        <input [(ngModel)]="ToChucTapTinDinhKemService.SearchString"
                                            placeholder="Tìm..." class="form-control"
                                            (keyup.enter)="ToChucTapTinDinhKemSearch()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <div>
                                            <a class="btn btn-success" title="Tìm"
                                                (click)="ToChucTapTinDinhKemSearch()"><i
                                                    class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                        </div>
                                    </div>
                                </div>
                                <div class="card mb-4 card-primary">
                                    <div class="card-header">
                                        <h5 class="card-title text-white bg-primary">Tài liệu
                                            <span
                                                *ngIf="ToChucTapTinDinhKemService.list">({{ToChucTapTinDinhKemService.list.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body bg-primary">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #ToChucTapTinDinhKemSort="matSort"
                                                    [dataSource]="ToChucTapTinDinhKemService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{ToChucTapTinDinhKemPaginator.pageSize *
                                                            ToChucTapTinDinhKemPaginator.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tài liệu
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Tài liệu" name="Name{{element.ID}}"
                                                                [(ngModel)]="element.Name">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Code">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tải lên
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input *ngIf="element.ID == 0" type="file" multiple
                                                                (change)="ChangeFileName($event.target.files)">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info"
                                                                (click)="ToChucTapTinDinhKemSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger"
                                                                (click)="ToChucTapTinDinhKemDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                            &nbsp;&nbsp;&nbsp;
                                                            <a *ngIf="element.ID > 0 && element.FileName"
                                                                class="btn btn-success" title="Tải về"
                                                                href="{{element.FileName}}" target="_blank"><i
                                                                    class="bi bi-cloud-download"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucTapTinDinhKemService.displayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucTapTinDinhKemService.displayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #ToChucTapTinDinhKemPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="ToChucVungTrongDetail" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-10 col-sm-12 col-12">
                                        <input [(ngModel)]="ToChucVungTrongService.SearchString" placeholder="Tìm..."
                                            class="form-control" (keyup.enter)="ToChucVungTrongSearch()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <div>
                                            <a class="btn btn-success" title="Tìm" (click)="ToChucVungTrongSearch()"><i
                                                    class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                        </div>
                                    </div>
                                </div>
                                <div class="card mb-4 card-danger">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Vị trí
                                            <span
                                                *ngIf="ToChucVungTrongService.list">({{ToChucVungTrongService.list.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #ToChucVungTrongSort="matSort"
                                                    [dataSource]="ToChucVungTrongService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{ToChucVungTrongPaginator.pageSize *
                                                            ToChucVungTrongPaginator.pageIndex + i + 1}} |
                                                            {{element.ID}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu đề
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Tiêu đề" name="Name{{element.ID}}"
                                                                [(ngModel)]="element.Name">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Code">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Điện thoại
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Điện thoại" name="Code{{element.ID}}"
                                                                [(ngModel)]="element.Code">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Note">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Địa chỉ
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Địa chỉ" name="Note{{element.ID}}"
                                                                [(ngModel)]="element.Note">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SortOrder">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sắp xếp
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="number"
                                                                style="text-align: right;" placeholder="0"
                                                                name="SortOrder{{element.ID}}"
                                                                [(ngModel)]="element.SortOrder">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Active">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Trụ sở
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="Active{{element.ID}}"
                                                                    [(ngModel)]="element.Active">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ToaDo">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Tọa độ
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-success" *ngIf="element.ID>0"
                                                                (click)="ToChucVungTrongAdd(element.ID)"><i
                                                                    class="bi bi-arrows-fullscreen"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info"
                                                                (click)="ToChucVungTrongSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger"
                                                                (click)="ToChucVungTrongDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucVungTrongService.displayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucVungTrongService.displayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #ToChucVungTrongPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="6"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="MapDetail" role="tabpanel">
                                <div class="row">
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <a style="width: 100%;" title="Tải lại bản đồ" (click)="MapLoad(0)"
                                            class="btn btn-success"><i class="bi bi-arrow-repeat"></i> Tải lại bản
                                            đồ</a>
                                    </div>
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <a style="width: 100%;" title="Vẽ vùng trồng" (click)="OpenWindowVeVungTrong()"
                                            class="btn btn-success"><i class="bi bi-brush"></i> Vẽ vùng trồng</a>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <ul>
                                            <li>
                                                <a style="cursor: pointer;" (click)="MapLoad(-1)">
                                                    <h4><b>Tất cả</b></h4>
                                                </a>
                                            </li>
                                            <li *ngFor="let ToChucVungTrong of ToChucVungTrongService.list001;">
                                                <a style="cursor: pointer;" (click)="MapLoad(ToChucVungTrong.ID)">
                                                    <h4>{{ToChucVungTrong.Name}} [{{ToChucVungTrong.ID}}]</h4>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-lg-9 col-sm-12 col-12">
                                        <div class="map" #map style="height: 500px; width: 100%;">
                                            <div style="z-index: 1000; position: absolute; top: 0;">
                                                <img src="{{domainURL}}assets/image/vungtrong.png" />
                                            </div>
                                            <div
                                                style="z-index: 1000; position: absolute; bottom: 0; width: 100%; height: 20px; background-color: #ffffff;">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>