<div class="app-hero-header d-flex align-items-center">
    <div class="d-flex align-items-center">
        <div class="me-3 icon-box md bg-white rounded-4">
            <i class="bi bi-shield-check fs-3 text-primary"></i>
        </div>
        <div>
            <h2 class="mb-1">{{ThanhVienService.formData.Name}}</h2>
            <h6 class="m-0 text-dark fw-light">
                Phân quyền
            </h6>
        </div>
    </div>
    <div class="ms-auto d-lg-flex d-none flex-row">
        <div class="d-flex flex-row gap-2">
            <a class="btn btn-danger" title="Đóng" (click)="Close()">
                <i class="bi bi-x-lg"></i>
            </a>
        </div>
    </div>
</div>
<div class="app-body">
    <div class="row gx-4">
        <div class="col-xxl-12">
            <div class="card mb-4">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-ThanhVienDetail" data-bs-toggle="tab"
                                    href="#ThanhVienDetail" role="tab" aria-controls="ThanhVienDetail"
                                    aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">Thông tin</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-ThanhVienPhanQuyenChucNang" data-bs-toggle="tab"
                                    href="#ThanhVienPhanQuyenChucNang" role="tab"
                                    aria-controls="ThanhVienPhanQuyenChucNang" aria-selected="false">
                                    <span class="badge bg-danger" style="font-size: 16px;">Chức năng</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-ThanhVienPhanQuyenKhuVuc" data-bs-toggle="tab"
                                    href="#ThanhVienPhanQuyenKhuVuc" role="tab" aria-controls="ThanhVienPhanQuyenKhuVuc"
                                    aria-selected="false">
                                    <span class="badge bg-success" style="font-size: 16px;">Khu vực</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="ThanhVienDetail" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <a style="width: 100%;" title="Lưu thay đổi" (click)="ThanhVienSave()"
                                            class="btn btn-info"><i class="bi bi-sd-card"></i></a>
                                    </div>
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <div class="form-check form-check-inline mb-6">
                                            <input type="checkbox" class="form-check-input" name="Active"
                                                [(ngModel)]="ThanhVienService.formData.Active" />
                                            <label class="form-check-label" for="Active">Kích hoạt</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row gx-4">
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Họ tên</label>
                                            <input name="Name" [(ngModel)]="ThanhVienService.formData.Name"
                                                placeholder="Họ tên" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Tài khoản</label>
                                            <input name="TaiKhoan" [(ngModel)]="ThanhVienService.formData.TaiKhoan"
                                                disabled placeholder="Tài khoản" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Mật khẩu</label>
                                            <input name="MatKhau" [(ngModel)]="ThanhVienService.formData.MatKhau"
                                                placeholder="Mật khẩu" type="password" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Điện thoại</label>
                                            <input name="DienThoai" [(ngModel)]="ThanhVienService.formData.DienThoai"
                                                placeholder="Điện thoại" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Email</label>
                                            <input name="Email" [(ngModel)]="ThanhVienService.formData.Email"
                                                placeholder="Email" type="text" class="form-control">
                                        </div>

                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">URL</label>
                                            <input name="TypeName" [(ngModel)]="ThanhVienService.formData.TypeName"
                                                placeholder="URL" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Chức danh</label>
                                            <input name="Note" [(ngModel)]="ThanhVienService.formData.Note"
                                                placeholder="Chức danh" type="text" class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Phân quyền</label>
                                            <mat-select class="form-control" name="ParentID"
                                                [(ngModel)]="ThanhVienService.formData.ParentID">
                                                <input class="form-control"
                                                    (keyup)="DanhMucTinhThanhFilter($event.target.value)">
                                                <mat-option *ngFor="let item of DanhMucThanhVienService.list"
                                                    [value]="item.ID">
                                                    {{item.Name}}
                                                </mat-option>
                                            </mat-select>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Hợp tác xã</label>
                                            <mat-select class="form-control" name="ToChucID"
                                                [(ngModel)]="ThanhVienService.formData.ToChucID">
                                                <mat-option *ngFor="let item of ToChucService.list" [value]="item.ID">
                                                    {{item.Name}} - {{item.DanhMucTinhThanhName}}
                                                </mat-option>
                                            </mat-select>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Tỉnh thành</label>
                                            <mat-select class="form-control" name="DanhMucTinhThanhID"
                                                (selectionChange)="DanhMucQuanHuyenGetToList()"
                                                [(ngModel)]="ThanhVienService.formData.DanhMucTinhThanhID">
                                                <input class="form-control"
                                                    (keyup)="DanhMucTinhThanhFilter($event.target.value)">
                                                <mat-option *ngFor="let item of DanhMucTinhThanhService.list001"
                                                    [value]="item.ID">
                                                    {{item.Name}}
                                                </mat-option>
                                            </mat-select>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Quận huyện</label>
                                            <mat-select class="form-control" name="DanhMucQuanHuyenID"
                                                (selectionChange)="DanhMucXaPhuongGetToList()"
                                                [(ngModel)]="ThanhVienService.formData.DanhMucQuanHuyenID">
                                                <input class="form-control"
                                                    (keyup)="DanhMucQuanHuyenFilter($event.target.value)">
                                                <mat-option *ngFor="let item of DanhMucQuanHuyenService.list001"
                                                    [value]="item.ID">
                                                    {{item.Name}}
                                                </mat-option>
                                            </mat-select>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Xã phường</label>
                                            <mat-select class="form-control" name="DanhMucXaPhuongID"
                                                [(ngModel)]="ThanhVienService.formData.DanhMucXaPhuongID">
                                                <input class="form-control"
                                                    (keyup)="DanhMucXaPhuongFilter($event.target.value)">
                                                <mat-option *ngFor="let item of DanhMucXaPhuongService.list001"
                                                    [value]="item.ID">
                                                    {{item.Name}}
                                                </mat-option>
                                            </mat-select>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Địa chỉ</label>
                                            <input name="DiaChi" [(ngModel)]="ThanhVienService.formData.DiaChi"
                                                placeholder="Địa chỉ" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Đơn vị công tác</label>
                                            <input name="Description"
                                                [(ngModel)]="ThanhVienService.formData.Description"
                                                placeholder="Đơn vị công tác" type="text" class="form-control">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="ThanhVienPhanQuyenChucNang" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <div class="form-check form-check-inline">
                                            <input type="checkbox" class="form-check-input" name="ActiveAll"
                                                (change)="ThanhVienPhanQuyenChucNangActiveAllChange()"
                                                [(ngModel)]="activeAll" />
                                            <label class="form-check-label" for="ActiveAll">Tất cả</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="card mb-4 card-danger">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Phân quyền chức năng
                                            <span
                                                *ngIf="ThanhVienPhanQuyenChucNangService.list">({{ThanhVienPhanQuyenChucNangService.list.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #ThanhVienPhanQuyenChucNangSort="matSort"
                                                    [dataSource]="ThanhVienPhanQuyenChucNangService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{ThanhVienPhanQuyenChucNangPaginator.pageSize *
                                                            ThanhVienPhanQuyenChucNangPaginator.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chức năng
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.Name}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Active">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Kích hoạt
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    (change)="ThanhVienPhanQuyenChucNangActiveChange(element)"
                                                                    name="Active{{element.ID}}"
                                                                    [(ngModel)]="element.Active">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ThanhVienPhanQuyenChucNangService.displayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ThanhVienPhanQuyenChucNangService.displayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #ThanhVienPhanQuyenChucNangPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="ThanhVienPhanQuyenKhuVuc" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <label class="form-label">Tỉnh thành</label>
                                        <mat-select class="form-control"
                                            name="ThanhVienService.formData.DanhMucTinhThanhID"
                                            [(ngModel)]="ThanhVienService.formData.DanhMucTinhThanhID">
                                            <input placeholder="Tìm..." class="form-control"
                                                (keyup)="DanhMucTinhThanhFilter($event.target.value)">
                                            <mat-option [value]="0">
                                                Đồng bằng sông Cửu Long
                                            </mat-option>
                                            <mat-option *ngFor="let item of DanhMucTinhThanhService.list001"
                                                [value]="item.ID">
                                                {{item.Name}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <label class="form-label">.</label>
                                        <input [(ngModel)]="ThanhVienPhanQuyenKhuVucService.SearchString"
                                            placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="ThanhVienPhanQuyenKhuVucSearch()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <label class="form-label">.</label>
                                        <div>
                                            <a class="btn btn-success" title="Tìm"
                                                (click)="ThanhVienPhanQuyenKhuVucSearch()"><i
                                                    class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <br />
                                        <div class="form-check form-check-inline">
                                            <input type="checkbox" class="form-check-input" name="activeAllKhuVuc"
                                                (change)="ThanhVienPhanQuyenKhuVucActiveAllChange()"
                                                [(ngModel)]="activeAllKhuVuc" />
                                            <label class="form-check-label" for="activeAllKhuVuc">Tất cả</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="card mb-4 card-success">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Phân quyền chức năng
                                            <span
                                                *ngIf="ThanhVienPhanQuyenKhuVucService.list">({{ThanhVienPhanQuyenKhuVucService.list.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #ThanhVienPhanQuyenKhuVucSort="matSort"
                                                    [dataSource]="ThanhVienPhanQuyenKhuVucService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{ThanhVienPhanQuyenKhuVucPaginator.pageSize *
                                                            ThanhVienPhanQuyenKhuVucPaginator.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Display">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tỉnh thành
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.Display}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Code">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Quận huyện
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.Code}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Xã phường
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.Name}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Active">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Kích hoạt
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    (change)="ThanhVienPhanQuyenKhuVucActiveChange(element)"
                                                                    name="Active{{element.ID}}"
                                                                    [(ngModel)]="element.Active">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ThanhVienPhanQuyenKhuVucService.displayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ThanhVienPhanQuyenKhuVucService.displayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #ThanhVienPhanQuyenKhuVucPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>