import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';
import { ToChuc } from 'src/app/shared/ToChuc.model';
import { ToChucService } from 'src/app/shared/ToChuc.service';

import { ToChucVuSanXuat } from 'src/app/shared/ToChucVuSanXuat.model';
import { ToChucVuSanXuatService } from 'src/app/shared/ToChucVuSanXuat.service';
import { ToChucVuSanXuatVungTrong } from 'src/app/shared/ToChucVuSanXuatVungTrong.model';
import { ToChucVuSanXuatVungTrongService } from 'src/app/shared/ToChucVuSanXuatVungTrong.service';
import { ToChucVuSanXuatTieuChi } from 'src/app/shared/ToChucVuSanXuatTieuChi.model';
import { ToChucVuSanXuatTieuChiService } from 'src/app/shared/ToChucVuSanXuatTieuChi.service';

@Component({
  selector: 'app-thanh-vien-to-chuc-vu-san-xuat-mobile-to-chuc-vu-san-xuat-tieu-chi',
  templateUrl: './thanh-vien-to-chuc-vu-san-xuat-mobile-to-chuc-vu-san-xuat-tieu-chi.component.html',
  styleUrls: ['./thanh-vien-to-chuc-vu-san-xuat-mobile-to-chuc-vu-san-xuat-tieu-chi.component.css']
})
export class ThanhVienToChucVuSanXuatMobileToChucVuSanXuatTieuChiComponent implements OnInit {

  @ViewChild('ToChucVuSanXuatTieuChiSort') ToChucVuSanXuatTieuChiSort: MatSort;
  @ViewChild('ToChucVuSanXuatTieuChiPaginator') ToChucVuSanXuatTieuChiPaginator: MatPaginator;

  isShowLoading: boolean = false;
  constructor(
    private dialog: MatDialog,

    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public ThanhVienService: ThanhVienService,

    public ToChucService: ToChucService,

    public ToChucVuSanXuatService: ToChucVuSanXuatService,
    public ToChucVuSanXuatVungTrongService: ToChucVuSanXuatVungTrongService,
    public ToChucVuSanXuatTieuChiService: ToChucVuSanXuatTieuChiService,
  ) { }

  ngOnInit(): void {
    this.ThanhVienSearch();
    this.ToChucVuSanXuatSearch();
  }
  ThanhVienSearch() {
    this.ThanhVienService.GetLogin();
  }
  ToChucVuSanXuatSearch() {
    this.isShowLoading = true;
    this.ToChucVuSanXuatService.baseParameter.ParentID = this.ThanhVienService.formDataLogin.ToChucID;
    this.ToChucVuSanXuatService.GetByParentID_SearchString_YearToListAsync().subscribe(
      res => {
        this.ToChucVuSanXuatService.list = (res as ToChucVuSanXuat[]).sort((a, b) => (a.BatDau < b.BatDau ? 1 : -1));
        this.ToChucVuSanXuatService.list001 = this.ToChucVuSanXuatService.list;
        if (this.ToChucVuSanXuatService.list001) {
          if (this.ToChucVuSanXuatService.list001.length > 0) {
            this.ToChucVuSanXuatTieuChiService.baseParameter.ParentID = this.ToChucVuSanXuatService.list001[0].ID;
          }
        }        
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  ToChucVuSanXuatFilter(searchString: string) {
    this.ToChucVuSanXuatService.Filter(searchString);
  }  
  ToChucVuSanXuatTieuChiSearch() {
    if (this.ToChucVuSanXuatTieuChiService.baseParameter.SearchString.length > 0) {
      this.ToChucVuSanXuatTieuChiService.DataSource.filter = this.ToChucVuSanXuatTieuChiService.baseParameter.SearchString.toLowerCase();
    }
    else {
      this.isShowLoading = true;
      this.ToChucVuSanXuatTieuChiService.GetSQLByParentID_Year_MonthToListAsync().subscribe(
        res => {
          this.ToChucVuSanXuatTieuChiService.list = (res as ToChucVuSanXuatTieuChi[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
          this.ToChucVuSanXuatTieuChiService.DataSource = new MatTableDataSource(this.ToChucVuSanXuatTieuChiService.list);
          this.ToChucVuSanXuatTieuChiService.DataSource.sort = this.ToChucVuSanXuatTieuChiSort;
          this.ToChucVuSanXuatTieuChiService.DataSource.paginator = this.ToChucVuSanXuatTieuChiPaginator;
          this.isShowLoading = false;
        },
        err => {
          this.isShowLoading = false;
        }
      );
    }
  }
  ToChucVuSanXuatTieuChiSave(element: ToChucVuSanXuatTieuChi) {
    this.isShowLoading = true;
    element.ParentID = this.ToChucVuSanXuatService.formData.ID;    
    this.ToChucVuSanXuatTieuChiService.formData = element;
    this.ToChucVuSanXuatTieuChiService.SaveAndUploadFilesAsync().subscribe(
      res => {
        this.ToChucVuSanXuatTieuChiSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }
  ToChucVuSanXuatTieuChiDelete(element: ToChucVuSanXuatTieuChi) {
    this.isShowLoading = true;
    this.ToChucVuSanXuatTieuChiService.baseParameter.ID = element.ID;
    this.ToChucVuSanXuatTieuChiService.RemoveAsync().subscribe(
      res => {
        this.ToChucVuSanXuatTieuChiSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }
}