import { Component, OnInit, Inject, ElementRef, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';

import { DanhMucVuMua } from 'src/app/shared/DanhMucVuMua.model';
import { DanhMucVuMuaService } from 'src/app/shared/DanhMucVuMua.service';


import { ToChuc } from 'src/app/shared/ToChuc.model';
import { ToChucService } from 'src/app/shared/ToChuc.service';

import { ToChucVuSanXuat } from 'src/app/shared/ToChucVuSanXuat.model';
import { ToChucVuSanXuatService } from 'src/app/shared/ToChucVuSanXuat.service';



@Component({
  selector: 'app-thanh-vien-to-chuc-vu-san-xuat-detail-mobile',
  templateUrl: './thanh-vien-to-chuc-vu-san-xuat-detail-mobile.component.html',
  styleUrls: ['./thanh-vien-to-chuc-vu-san-xuat-detail-mobile.component.css']
})
export class ThanhVienToChucVuSanXuatDetailMobileComponent implements OnInit {

  isShowLoading: boolean = false;

  IsChungThuc54: boolean = false;
  IsChungThuc55: boolean = false;
  IsChungThuc00: boolean = false;
  IsChungThucIsMuaVo: boolean = false;

  constructor(
    private dialog: MatDialog,
    public router: Router,
    public dialogRef: MatDialogRef<ThanhVienToChucVuSanXuatDetailMobileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public DanhMucVuMuaService: DanhMucVuMuaService,    

    public ToChucService: ToChucService,

    public ToChucVuSanXuatService: ToChucVuSanXuatService,   
  ) {

  }

  ngOnInit(): void {   
    this.DanhMucVuMuaSearch();
    this.ToChucSearch();    
  } 

  ToChucVuSanXuatSave() {
    this.isShowLoading = true;
    this.ToChucVuSanXuatService.SaveAsync().subscribe(
      res => {
        this.ToChucVuSanXuatService.formData = res as ToChucVuSanXuat;        
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }

  DateBatDau(value) {
    this.ToChucVuSanXuatService.formData.BatDau = new Date(value);
  }
  DateNgaySanXuat(value) {
    this.ToChucVuSanXuatService.formData.NgaySanXuat = new Date(value);
  }
  DateNgayThuHoach(value) {
    this.ToChucVuSanXuatService.formData.NgayThuHoach = new Date(value);
  }
  DateKetThuc(value) {
    this.ToChucVuSanXuatService.formData.KetThuc = new Date(value);
  }
 
  ToChucFilter(searchString: string) {
    searchString = searchString.trim();
    this.ToChucService.FilterHaTang(searchString);
  } 
  DanhMucVuMuaSearch() {
    this.isShowLoading = true;
    this.DanhMucVuMuaService.baseParameter.Active = true;
    this.DanhMucVuMuaService.GetByActiveToListAsync().subscribe(
      res => {
        this.DanhMucVuMuaService.list = (res as DanhMucVuMua[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucVuMuaService.list001 = this.DanhMucVuMuaService.list;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  ToChucSearch() {
    this.isShowLoading = true;
    this.ToChucService.baseParameter.IsZeroCarbonRice = true;
    this.ToChucService.GetByIsZeroCarbonRiceToListAsync().subscribe(
      res => {
        this.ToChucService.list = (res as ToChuc[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );    
  } 
  Close() {
    this.dialogRef.close();
  }
}