<div class="app-hero-header d-flex align-items-center">
    <div class="d-flex align-items-center">
        <div class="me-3 icon-box md bg-white rounded-4">
            <i class="bi bi-hypnotize fs-3 text-primary"></i>
        </div>
        <div>
            <h2 class="mb-1">{{ToChucService.formData.Name}} [{{ToChucService.formData.ID}}]</h2>
            <h6 class="m-0 text-dark fw-light">
                Đơn vị cung cấp vật tư nông nghiệp
            </h6>
        </div>
    </div>
    <div class="ms-auto d-lg-flex d-none flex-row">
        <div class="d-flex flex-row gap-2">
            <a class="btn btn-danger" title="Đóng" (click)="Close()">
                <i class="bi bi-x-lg"></i>
            </a>
        </div>
    </div>
</div>
<div class="app-body">
    <div class="row gx-4">
        <div class="col-xxl-12">
            <div class="card mb-4">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-ToChucDetail" data-bs-toggle="tab"
                                    href="#ToChucDetail" role="tab" aria-controls="ToChucDetail" aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">01: Pháp lý</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-ToChucKhoHangDetail" data-bs-toggle="tab"
                                    href="#ToChucKhoHangDetail" role="tab" aria-controls="ToChucKhoHangDetail"
                                    aria-selected="false">
                                    <span class="badge bg-danger" style="font-size: 16px;">02: Kho hàng</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-ToChucSanPhamDetail" data-bs-toggle="tab"
                                    href="#ToChucSanPhamDetail" role="tab" aria-controls="ToChucSanPhamDetail"
                                    aria-selected="false">
                                    <span class="badge bg-success" style="font-size: 16px;">03: Vật tư</span>
                                </a>
                            </li>                           
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-ToChucHopTacDetail" data-bs-toggle="tab"
                                    href="#ToChucHopTacDetail" role="tab" aria-controls="ToChucHopTacDetail"
                                    aria-selected="false">
                                    <span class="badge bg-warning" style="font-size: 16px;">04: Đối tác</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-ToChucTapTinDinhKemDetail" data-bs-toggle="tab"
                                    href="#ToChucTapTinDinhKemDetail" role="tab"
                                    aria-controls="ToChucTapTinDinhKemDetail" aria-selected="false">
                                    <span class="badge bg-primary" style="font-size: 16px;">05: Tài liệu</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="ToChucDetail" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <a style="width: 100%;" title="Lưu thay đổi" (click)="ToChucSave()"
                                            class="btn btn-info"><i class="bi bi-sd-card"></i> Lưu thay đổi</a>
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <div class="form-check form-check-inline mb-6">
                                            <input type="checkbox" class="form-check-input" name="Active"
                                                [(ngModel)]="ToChucService.formData.Active" />
                                            <label class="form-check-label" for="Active">Kích hoạt</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row gx-4">
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Tiêu đề</label>
                                            <input name="Name" [(ngModel)]="ToChucService.formData.Name"
                                                placeholder="Tiêu đề" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Mã số thuế/Mã Số Doanh Nghiệp</label>
                                            <input name="Code" [(ngModel)]="ToChucService.formData.Code"
                                                placeholder="Mã số thuế/Mã Số Doanh Nghiệp" type="text"
                                                class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Điện thoại</label>
                                            <input name="DienThoai" [(ngModel)]="ToChucService.formData.DienThoai"
                                                placeholder="Điện thoại" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Email</label>
                                            <input name="Email" [(ngModel)]="ToChucService.formData.Email"
                                                placeholder="Email" type="text" class="form-control">
                                        </div>                                        
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Loại hình</label>
                                            <input name="Display" [(ngModel)]="ToChucService.formData.Display"
                                                placeholder="Loại hình" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Ngày hoạt động</label>
                                            <input [ngModel]="ToChucService.formData.NgayHoatDong | date:'yyyy-MM-dd'"
                                                (ngModelChange)="DateNgayHoatDong($event)"
                                                [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control"
                                                style="text-align: right;" autocomplete="off">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Khu vực hoạt động</label>
                                            <input name="Description" [(ngModel)]="ToChucService.formData.Description"
                                                placeholder="Khu vực hoạt động" type="text" class="form-control">
                                        </div>      
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Hình thức kinh doanh</label>
                                            <input name="HTMLContent" [(ngModel)]="ToChucService.formData.HTMLContent"
                                                placeholder="Hình thức kinh doanh" type="text" class="form-control">
                                        </div>                                    
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Năng Lực Cung Cấp (Số lượng/năm)</label>
                                            <input name="NangLucThuMua"
                                                [(ngModel)]="ToChucService.formData.NangLucThuMua" placeholder="0"
                                                type="number" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Năng lực Tài chính (đồng)</label>
                                            <input name="NangLucTaiChinh"
                                                [(ngModel)]="ToChucService.formData.NangLucTaiChinh" placeholder="0"
                                                type="number" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Doanh thu (đồng)</label>
                                            <input name="DoanhThu" [(ngModel)]="ToChucService.formData.DoanhThu"
                                                placeholder="0" type="number" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Người liên hệ</label>
                                            <input name="LienHeHoTen" [(ngModel)]="ToChucService.formData.LienHeHoTen"
                                                placeholder="Người liên hệ" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Điện thoại</label>
                                            <input name="LienHeDienThoai"
                                                [(ngModel)]="ToChucService.formData.LienHeDienThoai"
                                                placeholder="Điện thoại" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Email</label>
                                            <input name="LienHeEmail" [(ngModel)]="ToChucService.formData.LienHeEmail"
                                                placeholder="Email" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">CCCD</label>
                                            <input name="LienHeCCCD" [(ngModel)]="ToChucService.formData.LienHeCCCD"
                                                placeholder="CCCD" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Ghi chú</label>
                                            <input name="Note" [(ngModel)]="ToChucService.formData.Note"
                                                placeholder="Ghi chú" type="text" class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Tỉnh thành</label>
                                            <mat-select class="form-control" name="DanhMucTinhThanhID"
                                                (selectionChange)="DanhMucQuanHuyenGetToList()"
                                                [(ngModel)]="ToChucService.formData.DanhMucTinhThanhID">
                                                <input class="form-control"
                                                    (keyup)="DanhMucTinhThanhFilter($event.target.value)">
                                                <mat-option *ngFor="let item of DanhMucTinhThanhService.list001"
                                                    [value]="item.ID">
                                                    {{item.Name}}
                                                </mat-option>
                                            </mat-select>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Quận huyện</label>
                                            <mat-select class="form-control" name="DanhMucQuanHuyenID"
                                                (selectionChange)="DanhMucXaPhuongGetToList()"
                                                [(ngModel)]="ToChucService.formData.DanhMucQuanHuyenID">
                                                <input class="form-control"
                                                    (keyup)="DanhMucQuanHuyenFilter($event.target.value)">
                                                <mat-option *ngFor="let item of DanhMucQuanHuyenService.list001"
                                                    [value]="item.ID">
                                                    {{item.Name}}
                                                </mat-option>
                                            </mat-select>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Xã phường</label>
                                            <mat-select class="form-control" name="DanhMucXaPhuongID"
                                                [(ngModel)]="ToChucService.formData.DanhMucXaPhuongID">
                                                <input class="form-control"
                                                    (keyup)="DanhMucXaPhuongFilter($event.target.value)">
                                                <mat-option *ngFor="let item of DanhMucXaPhuongService.list001"
                                                    [value]="item.ID">
                                                    {{item.Name}}
                                                </mat-option>
                                            </mat-select>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Ấp thôn</label>
                                            <input name="ApThon" [(ngModel)]="ToChucService.formData.ApThon"
                                                placeholder="Ấp thôn" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Địa chỉ</label>
                                            <input name="DiaChi" [(ngModel)]="ToChucService.formData.DiaChi"
                                                placeholder="Địa chỉ" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Kinh độ</label>
                                            <input name="KinhDo" [(ngModel)]="ToChucService.formData.KinhDo"
                                                placeholder="Kinh độ" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Vĩ độ</label>
                                            <input name="ViDo" [(ngModel)]="ToChucService.formData.ViDo"
                                                placeholder="Vĩ độ" type="text" class="form-control">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="ToChucService.formData.ID>0" class="tab-pane fade" id="ToChucKhoHangDetail" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-10 col-sm-12 col-12">
                                        <input [(ngModel)]="ToChucKhoHangService.SearchString" placeholder="Tìm..."
                                            class="form-control" (keyup.enter)="ToChucKhoHangSearch()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <div>
                                            <a class="btn btn-success" title="Tìm" (click)="ToChucKhoHangSearch()"><i
                                                    class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                        </div>
                                    </div>
                                </div>
                                <div class="card mb-4 card-danger">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Kho hàng
                                            <span
                                                *ngIf="ToChucKhoHangService.list">({{ToChucKhoHangService.list.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #ToChucKhoHangSort="matSort"
                                                    [dataSource]="ToChucKhoHangService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{ToChucKhoHangPaginator.pageSize *
                                                            ToChucKhoHangPaginator.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Kho hàng
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Kho hàng" name="Name{{element.ID}}"
                                                                [(ngModel)]="element.Name">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Code">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Mã kho
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text" placeholder="Mã kho"
                                                                name="Code{{element.ID}}" [(ngModel)]="element.Code">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DienTich">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Diện tích
                                                            (m2)
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="number"
                                                                style="text-align: right;" placeholder="0"
                                                                name="DienTich{{element.ID}}"
                                                                [(ngModel)]="element.DienTich">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="CongNgheBaoQuan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Công nghệ
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Công nghệ bảo quản"
                                                                name="CongNgheBaoQuan{{element.ID}}"
                                                                [(ngModel)]="element.CongNgheBaoQuan">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="TieuChuan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu chuẩn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Tiêu chuẩn" name="TieuChuan{{element.ID}}"
                                                                [(ngModel)]="element.TieuChuan">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DiaChi">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Địa chỉ
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Địa chỉ" name="TieuChuan{{element.ID}}"
                                                                [(ngModel)]="element.DiaChi">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="KinhDo">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Kinh độ
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Kinh độ" name="KinhDo{{element.ID}}"
                                                                [(ngModel)]="element.KinhDo">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ViDo">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Vĩ độ
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text" placeholder="Vĩ độ"
                                                                name="ViDo{{element.ID}}" [(ngModel)]="element.ViDo">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info"
                                                                (click)="ToChucKhoHangSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger"
                                                                (click)="ToChucKhoHangDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucKhoHangService.displayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucKhoHangService.displayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #ToChucKhoHangPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="ToChucService.formData.ID>0" class="tab-pane fade" id="ToChucSanPhamDetail" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-10 col-sm-12 col-12">
                                        <input [(ngModel)]="ToChucSanPhamService.SearchString" placeholder="Tìm..."
                                            class="form-control" (keyup.enter)="ToChucSanPhamSearch()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <div>
                                            <a class="btn btn-success" title="Tìm" (click)="ToChucSanPhamSearch()"><i
                                                    class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                        </div>
                                    </div>
                                </div>
                                <div class="card mb-4 card-success">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Vật tư
                                            <span
                                                *ngIf="ToChucSanPhamService.list">({{ToChucSanPhamService.list.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #ToChucSanPhamSort="matSort"
                                                    [dataSource]="ToChucSanPhamService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{ToChucSanPhamPaginator.pageSize *
                                                                ToChucSanPhamPaginator.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Vật tư
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Vật tư" name="Name{{element.ID}}"
                                                                [(ngModel)]="element.Name">
                                                        </td>
                                                    </ng-container>                                                    
                                                    <ng-container matColumnDef="LoaiVatTu">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Loại vật tư
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Loại vật tư"
                                                                name="LoaiVatTu{{element.ID}}"
                                                                [(ngModel)]="element.LoaiVatTu">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ThanhPhan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Thành phần
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Thành phần"
                                                                name="ThanhPhan{{element.ID}}"
                                                                [(ngModel)]="element.ThanhPhan">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="CongDung">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Công dụng
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Công dụng"
                                                                name="CongDung{{element.ID}}"
                                                                [(ngModel)]="element.CongDung">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="QuyCachDongGoi">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Quy cách
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Quy cách"
                                                                name="QuyCachDongGoi{{element.ID}}"
                                                                [(ngModel)]="element.QuyCachDongGoi">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="TieuChuan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu chuẩn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Tiêu chuẩn"
                                                                name="TieuChuan{{element.ID}}"
                                                                [(ngModel)]="element.TieuChuan">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="GiaBan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Giá bán</th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="number"
                                                                style="text-align: right;" placeholder="0"
                                                                name="GiaBan{{element.ID}}"
                                                                [(ngModel)]="element.GiaBan">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="HanSuDung">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hạn sử dụng
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input [ngModel]="element.HanSuDung | date:'yyyy-MM-dd'"
                                                                (ngModelChange)="DateHanSuDung(element, $event)"
                                                                [ngModelOptions]="{ updateOn: 'blur' }" type="date"
                                                                class="form-control" style="text-align: right;"
                                                                autocomplete="off">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsCamKetHoTro">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hỗ trợ 24/7
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="IsCamKetHoTro{{element.ID}}"
                                                                    [(ngModel)]="element.IsCamKetHoTro">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="IsSanPhamChinhHang">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Chính hãng
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="IsSanPhamChinhHang{{element.ID}}"
                                                                    [(ngModel)]="element.IsSanPhamChinhHang">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info"
                                                                (click)="ToChucSanPhamSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger"
                                                                (click)="ToChucSanPhamDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucSanPhamService.displayColumns002">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucSanPhamService.displayColumns002;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #ToChucSanPhamPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="ToChucService.formData.ID>0" class="tab-pane fade" id="ToChucHopTacDetail" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-10 col-sm-12 col-12">
                                        <input [(ngModel)]="ToChucHopTacService.SearchString" placeholder="Tìm..."
                                            class="form-control" (keyup.enter)="ToChucHopTacSearch()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <div>
                                            <a class="btn btn-success" title="Tìm" (click)="ToChucHopTacSearch()"><i
                                                    class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                        </div>
                                    </div>
                                </div>
                                <div class="card mb-4 card-warning">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Thu mua
                                            <span *ngIf="ToChucHopTacService.list">({{ToChucHopTacService.list.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #ToChucHopTacSort="matSort"
                                                    [dataSource]="ToChucHopTacService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{ToChucHopTacPaginator.pageSize *
                                                            ToChucHopTacPaginator.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Display">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hình thức
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Hình thức" name="Display{{element.ID}}"
                                                                [(ngModel)]="element.Display">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Đối tác
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Đối tác" name="Name{{element.ID}}"
                                                                [(ngModel)]="element.Name">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Code">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Điện thoại
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Điện thoại" name="Code{{element.ID}}"
                                                                [(ngModel)]="element.Code">
                                                        </td>
                                                    </ng-container>                                                                                     
                                                    <ng-container matColumnDef="NgayGhiNhan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi nhận
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input [ngModel]="element.NgayGhiNhan | date:'yyyy-MM-dd'"
                                                                (ngModelChange)="DateNgayGhiNhan(element, $event)"
                                                                [ngModelOptions]="{ updateOn: 'blur' }" type="date"
                                                                class="form-control" style="text-align: right;"
                                                                autocomplete="off">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info"
                                                                (click)="ToChucHopTacSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger"
                                                                (click)="ToChucHopTacDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucHopTacService.displayColumns003">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucHopTacService.displayColumns003;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #ToChucHopTacPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="ToChucService.formData.ID>0" class="tab-pane fade" id="ToChucTapTinDinhKemDetail" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-10 col-sm-12 col-12">
                                        <input [(ngModel)]="ToChucTapTinDinhKemService.SearchString"
                                            placeholder="Tìm..." class="form-control"
                                            (keyup.enter)="ToChucTapTinDinhKemSearch()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <div>
                                            <a class="btn btn-success" title="Tìm"
                                                (click)="ToChucTapTinDinhKemSearch()"><i
                                                    class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                        </div>
                                    </div>
                                </div>
                                <div class="card mb-4 card-primary">
                                    <div class="card-header">
                                        <h5 class="card-title text-white bg-primary">Tài liệu
                                            <span
                                                *ngIf="ToChucTapTinDinhKemService.list">({{ToChucTapTinDinhKemService.list.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body bg-primary">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #ToChucTapTinDinhKemSort="matSort"
                                                    [dataSource]="ToChucTapTinDinhKemService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{ToChucTapTinDinhKemPaginator.pageSize *
                                                            ToChucTapTinDinhKemPaginator.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tài liệu
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Tài liệu" name="Name{{element.ID}}"
                                                                [(ngModel)]="element.Name">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Code">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tải lên
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input *ngIf="element.ID == 0" type="file" multiple
                                                                (change)="ChangeFileName($event.target.files)">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info"
                                                                (click)="ToChucTapTinDinhKemSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger"
                                                                (click)="ToChucTapTinDinhKemDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                            &nbsp;&nbsp;&nbsp;
                                                            <a *ngIf="element.ID > 0 && element.FileName"
                                                                class="btn btn-success" title="Tải về"
                                                                href="{{element.FileName}}" target="_blank"><i
                                                                    class="bi bi-cloud-download"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucTapTinDinhKemService.displayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucTapTinDinhKemService.displayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #ToChucTapTinDinhKemPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>