import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DanhMucVuSanXuatTieuChi } from 'src/app/shared/DanhMucVuSanXuatTieuChi.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class DanhMucVuSanXuatTieuChiService extends BaseService{

    list: DanhMucVuSanXuatTieuChi[] | undefined;
    list001: DanhMucVuSanXuatTieuChi[] | undefined;
    formData!: DanhMucVuSanXuatTieuChi;    

    displayColumns001: string[] = ['STT', 'Name', 'KetQuaNhoNhat2025', 'KetQuaNhoNhat2030', 'Save'];

    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "DanhMucVuSanXuatTieuChi";
    }
}

