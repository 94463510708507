import { Component, OnInit, Inject, ElementRef, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';


import { ToChucVuSanXuat } from 'src/app/shared/ToChucVuSanXuat.model';
import { ToChucVuSanXuatService } from 'src/app/shared/ToChucVuSanXuat.service';

import { ToChucVuSanXuatVungTrong } from 'src/app/shared/ToChucVuSanXuatVungTrong.model';
import { ToChucVuSanXuatVungTrongService } from 'src/app/shared/ToChucVuSanXuatVungTrong.service';

import { ToChucVuSanXuatNhatKy } from 'src/app/shared/ToChucVuSanXuatNhatKy.model';
import { ToChucVuSanXuatNhatKyService } from 'src/app/shared/ToChucVuSanXuatNhatKy.service';

@Component({
  selector: 'app-to-chuc-vu-san-xuat-tap-tin-dinh-kem',
  templateUrl: './to-chuc-vu-san-xuat-tap-tin-dinh-kem.component.html',
  styleUrls: ['./to-chuc-vu-san-xuat-tap-tin-dinh-kem.component.css']
})
export class ToChucVuSanXuatTapTinDinhKemComponent implements OnInit {

  @ViewChild('ToChucVuSanXuatNhatKySort54') ToChucVuSanXuatNhatKySort54: MatSort;
  @ViewChild('ToChucVuSanXuatNhatKyPaginator54') ToChucVuSanXuatNhatKyPaginator54: MatPaginator;


  isShowLoading: boolean = false;

  IsChungThuc54: boolean = false;

  constructor(
    private dialog: MatDialog,
    public router: Router,
    public dialogRef: MatDialogRef<ToChucVuSanXuatTapTinDinhKemComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public ToChucVuSanXuatService: ToChucVuSanXuatService,
    public ToChucVuSanXuatVungTrongService: ToChucVuSanXuatVungTrongService,
    public ToChucVuSanXuatNhatKyService: ToChucVuSanXuatNhatKyService,

  ) {

  }

  ngOnInit(): void {
    this.ToChucVuSanXuatVungTrongSearch();
  }

  ToChucVuSanXuatNhatKyChungThuc54Change() {
    if (confirm(environment.ChungThucConfirm)) {
      this.isShowLoading = true;
      for (let index = 0; index < this.ToChucVuSanXuatNhatKyService.list54.length; index++) {
        if (this.ToChucVuSanXuatNhatKyService.list54[index].Active == true) {
          this.ToChucVuSanXuatNhatKyService.list54[index].IsChungThuc = this.IsChungThuc54;
        }
      }
      this.isShowLoading = true;
      this.ToChucVuSanXuatNhatKyService.SaveListAsync(this.ToChucVuSanXuatNhatKyService.list54).subscribe(
        res => {
          this.NotificationService.warn(environment.SaveSuccess);
          this.isShowLoading = false;
        },
        err => {
          this.NotificationService.warn(environment.SaveNotSuccess);
          this.isShowLoading = false;
        }
      );
    }
  }

  ToChucVuSanXuatNhatKySearch54() {
    if (this.ToChucVuSanXuatNhatKyService.baseParameter.SearchString.length > 0) {
      this.ToChucVuSanXuatNhatKyService.DataSource54.filter = this.ToChucVuSanXuatNhatKyService.baseParameter.SearchString.toLowerCase();
    }
    else {
      this.isShowLoading = true;
      this.ToChucVuSanXuatNhatKyService.baseParameter.DanhMucNguyenVatLieuPhanLoaiID = environment.DanhMucNguyenVatLieuPhanLoaiID54;
      this.ToChucVuSanXuatNhatKyService.baseParameter.Active = true;
      this.ToChucVuSanXuatNhatKyService.GetSQLByToChucVuSanXuatVungTrongID_DanhMucNguyenVatLieuPhanLoaiID_ActiveToListAsync().subscribe(
        res => {
          this.ToChucVuSanXuatNhatKyService.list54 = (res as ToChucVuSanXuatNhatKy[])
          for (let index = 0; index < this.ToChucVuSanXuatNhatKyService.list54.length; index++) {
            this.ToChucVuSanXuatNhatKyService.list54[index].IsShow = false;
          }
          this.ToChucVuSanXuatNhatKyService.DataSource54 = new MatTableDataSource(this.ToChucVuSanXuatNhatKyService.list54);
          this.ToChucVuSanXuatNhatKyService.DataSource54.sort = this.ToChucVuSanXuatNhatKySort54;
          this.ToChucVuSanXuatNhatKyService.DataSource54.paginator = this.ToChucVuSanXuatNhatKyPaginator54;
          this.isShowLoading = false;
        },
        err => {
          this.isShowLoading = false;
        }
      );
    }
    this.ToChucVuSanXuatVungTrongChange();
  }
  ToChucVuSanXuatVungTrongChange() {
    for (let i = 0; i < this.ToChucVuSanXuatVungTrongService.list001.length; i++) {
      let ToChucVuSanXuatVungTrong = this.ToChucVuSanXuatVungTrongService.list001[i];
      if (ToChucVuSanXuatVungTrong.ID == this.ToChucVuSanXuatNhatKyService.baseParameter.ToChucVuSanXuatVungTrongID) {
        this.ToChucVuSanXuatVungTrongService.formData = ToChucVuSanXuatVungTrong;
      }
    }
  }
  ToChucVuSanXuatNhatKySave54(element: ToChucVuSanXuatNhatKy) {
    element.ParentID = this.ToChucVuSanXuatService.formData.ID;
    element.ToChucVuSanXuatVungTrongID = this.ToChucVuSanXuatNhatKyService.baseParameter.ToChucVuSanXuatVungTrongID;
    element.DanhMucNguyenVatLieuPhanLoaiID = environment.DanhMucNguyenVatLieuPhanLoaiID54;
    this.ToChucVuSanXuatNhatKyService.formData = element;
    this.ToChucVuSanXuatNhatKyService.SaveAndUploadFilesAsync().subscribe(
      res => {
        this.ToChucVuSanXuatNhatKySearch54();
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }
  ToChucVuSanXuatNhatKyDelete54(element: ToChucVuSanXuatNhatKy) {
    this.isShowLoading = true;
    this.ToChucVuSanXuatNhatKyService.baseParameter.ID = element.ID;
    this.ToChucVuSanXuatNhatKyService.RemoveAsync().subscribe(
      res => {
        this.ToChucVuSanXuatNhatKySearch54();
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }
  ToChucVuSanXuatNhatKyCopy54() {
    if (confirm(environment.Copy54)) {
      this.isShowLoading = true;
      this.ToChucVuSanXuatNhatKyService.SaoChepListAsync(this.ToChucVuSanXuatNhatKyService.list54).subscribe(
        res => {
          this.NotificationService.warn(environment.SaveSuccess);
          this.isShowLoading = false;
        },
        err => {
          this.NotificationService.warn(environment.SaveNotSuccess);
          this.isShowLoading = false;
        }
      );
    }
  }


  ToChucVuSanXuatVungTrongSearch() {
    this.isShowLoading = true;
    this.ToChucVuSanXuatVungTrongService.baseParameter.ParentID = this.ToChucVuSanXuatService.formData.ID;
    this.ToChucVuSanXuatVungTrongService.baseParameter.Active = true;
    this.ToChucVuSanXuatVungTrongService.GetByParentIDAndActiveToListAsync().subscribe(
      res => {
        this.ToChucVuSanXuatVungTrongService.list001 = (res as ToChucVuSanXuatVungTrong[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        if (this.ToChucVuSanXuatVungTrongService.list001) {
          if (this.ToChucVuSanXuatVungTrongService.list001.length > 0) {
            this.ToChucVuSanXuatNhatKyService.baseParameter.ToChucVuSanXuatVungTrongID = this.ToChucVuSanXuatVungTrongService.list001[0].ID;
            this.ToChucVuSanXuatVungTrongService.formData = this.ToChucVuSanXuatVungTrongService.list001[0];
          }
        }
        this.ToChucVuSanXuatNhatKySearch54();
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  Close() {
    this.dialogRef.close();
  }
}