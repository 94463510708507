import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

import { ToChucVuSanXuatTieuChi } from 'src/app/shared/ToChucVuSanXuatTieuChi.model';
import { ToChucVuSanXuatTieuChiService } from 'src/app/shared/ToChucVuSanXuatTieuChi.service';


@Component({
  selector: 'app-to-chuc-vu-san-xuat-tieu-chi',
  templateUrl: './to-chuc-vu-san-xuat-tieu-chi.component.html',
  styleUrls: ['./to-chuc-vu-san-xuat-tieu-chi.component.css']
})
export class ToChucVuSanXuatTieuChiComponent implements OnInit {

  @ViewChild('ToChucVuSanXuatTieuChiSort') ToChucVuSanXuatTieuChiSort: MatSort;
  @ViewChild('ToChucVuSanXuatTieuChiPaginator') ToChucVuSanXuatTieuChiPaginator: MatPaginator;

  
  isShowLoading: boolean = false; 

  constructor(
    private dialog: MatDialog,

    public NotificationService: NotificationService,
    public DownloadService: DownloadService,    
  
    public ToChucVuSanXuatTieuChiService: ToChucVuSanXuatTieuChiService,
  ) { }

  ngOnInit(): void {    
  }  

  ToChucVuSanXuatTieuChiSearch() {
    if (this.ToChucVuSanXuatTieuChiService.baseParameter.SearchString.length > 0) {
      this.ToChucVuSanXuatTieuChiService.DataSource.filter = this.ToChucVuSanXuatTieuChiService.baseParameter.SearchString.toLowerCase();
    }
    else {
      this.isShowLoading = true;
      this.ToChucVuSanXuatTieuChiService.baseParameter.ParentID = environment.InitializationNumber;
      this.ToChucVuSanXuatTieuChiService.GetSQLByParentID_Year_MonthToListAsync().subscribe(
        res => {
          this.ToChucVuSanXuatTieuChiService.list = (res as ToChucVuSanXuatTieuChi[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
          this.ToChucVuSanXuatTieuChiService.DataSource = new MatTableDataSource(this.ToChucVuSanXuatTieuChiService.list);
          this.ToChucVuSanXuatTieuChiService.DataSource.sort = this.ToChucVuSanXuatTieuChiSort;
          this.ToChucVuSanXuatTieuChiService.DataSource.paginator = this.ToChucVuSanXuatTieuChiPaginator;
          this.isShowLoading = false;
        },
        err => {
          this.isShowLoading = false;
        }
      );
    }    
  }
  ToChucVuSanXuatTieuChiSave(element: ToChucVuSanXuatTieuChi) {
    element.ParentID = environment.InitializationNumber;
    this.ToChucVuSanXuatTieuChiService.formData = element;
    this.ToChucVuSanXuatTieuChiService.SaveAsync().subscribe(
      res => {
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }
 
  
 
}