import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ToChucTapTinDinhKem } from 'src/app/shared/ToChucTapTinDinhKem.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class ToChucTapTinDinhKemService extends BaseService {
    list: ToChucTapTinDinhKem[] | undefined;
    list001: ToChucTapTinDinhKem[] | undefined;
    formData!: ToChucTapTinDinhKem;

    displayColumns001: string[] = ['STT', 'Name', 'Code', 'Save'];
    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "ToChucTapTinDinhKem";
    }
}

