<div class="custom-tabs-container">
    <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link active" id="tab-ToChucToaDoDetail" data-bs-toggle="tab" href="#ToChucToaDoDetail"
                role="tab" aria-controls="ToChucToaDoDetail" aria-selected="true">
                <span class="badge bg-info" style="font-size: 16px;">{{ToChucVungTrongService.formData.Name}}</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a style="cursor: pointer;" class="nav-link" id="tab-Close" data-bs-toggle="tab" (click)="Close()"
                role="tab" aria-controls="Close" aria-selected="false">
                <span class="badge bg-danger" style="font-size: 16px;"><i class="bi bi-x-lg"></i></span>
            </a>
        </li>
    </ul>
    <div class="tab-content" id="customTabContent">
        <div class="tab-pane fade show active" id="ToChucToaDoDetail" role="tabpanel">
            <div class="card mb-4 card-danger">
                <div class="card-header">
                    <h5 class="card-title text-white">Tọa độ
                        <span *ngIf="ToChucToaDoService.list">({{ToChucToaDoService.list.length}}
                            items)</span>
                    </h5>
                </div>
                <div class="card-body">
                    <div class="table-outer">
                        <div class="table-responsive">
                            <table *ngIf="!NotificationService.IsMobile" mat-table matSort #ToChucToaDoSort="matSort"
                                [dataSource]="ToChucToaDoService.DataSource" class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        STT
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i;" style="text-align: center;">
                                        {{ToChucToaDoPaginator.pageSize *
                                        ToChucToaDoPaginator.pageIndex + i + 1}} | {{element.ID}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="LastUpdatedDate">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Ngày sửa cuối
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        {{element.LastUpdatedDate | date:'dd/MM/yyyy'}}
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="KinhDo">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Kinh độ
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <input class="form-control" type="text" placeholder="Kinh độ"
                                            name="KinhDo{{element.ID}}" [(ngModel)]="element.KinhDo">
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="ViDo">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Vĩ độ
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <input class="form-control" type="text" placeholder="Vĩ độ"
                                            name="ViDo{{element.ID}}" [(ngModel)]="element.ViDo">
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Name">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Nông hộ
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <input class="form-control" type="text" placeholder="Nông hộ"
                                            name="Name{{element.ID}}" [(ngModel)]="element.Name">
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Code">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Điện thoại
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <input class="form-control" type="text" placeholder="Điện thoại"
                                            name="Code{{element.ID}}" [(ngModel)]="element.Code">
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Note">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Sản phẩm
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <input class="form-control" type="text" placeholder="Sản phẩm"
                                            name="Note{{element.ID}}" [(ngModel)]="element.Note">
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="SortOrder">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Sắp xếp
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <input class="form-control" type="number" style="text-align: right;"
                                            placeholder="0" name="SortOrder{{element.ID}}"
                                            [(ngModel)]="element.SortOrder">
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Active">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Trụ sở
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" name="Active{{element.ID}}"
                                                [(ngModel)]="element.Active">
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="Save">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <a class="btn btn-info" (click)="ToChucToaDoSave(element)"><i
                                                class="bi bi-sd-card"></i>
                                        </a>&nbsp;&nbsp;&nbsp;
                                        <a class="btn btn-danger" (click)="ToChucToaDoDelete(element)"
                                            *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                        </a>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="ToChucToaDoService.displayColumns004">
                                </tr>
                                <tr mat-row *matRowDef="let row; columns: ToChucToaDoService.displayColumns004;">
                                </tr>
                            </table>
                            <mat-paginator *ngIf="!NotificationService.IsMobile" #ToChucToaDoPaginator="matPaginator"
                                [pageSizeOptions]="[10,20,50,100]" [pageSize]="6"
                                [showFirstLastButtons]></mat-paginator>
                            <table *ngIf="NotificationService.IsMobile" mat-table matSort #ToChucToaDoSort="matSort"
                                [dataSource]="ToChucToaDoService.DataSource" class="table table-striped m-0">
                                <ng-container matColumnDef="STT">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Danh sách
                                    </th>
                                    <td mat-cell *matCellDef="let element; index as i">
                                        <div class="row gx-4">
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">Kinh độ</label>
                                                <input class="form-control" type="text" placeholder="Kinh độ"
                                                    name="KinhDo{{element.ID}}" [(ngModel)]="element.KinhDo">
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">Vĩ độ</label>
                                                <input class="form-control" type="text" placeholder="Vĩ độ"
                                                    name="ViDo{{element.ID}}" [(ngModel)]="element.ViDo">
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">Nông hộ</label>
                                                <input class="form-control" type="text" placeholder="Nông hộ"
                                                    name="Name{{element.ID}}" [(ngModel)]="element.Name">
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">Điện thoại</label>
                                                <input class="form-control" type="text" placeholder="Điện thoại"
                                                    name="Code{{element.ID}}" [(ngModel)]="element.Code">
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">Sản phẩm</label>
                                                <input class="form-control" type="text" placeholder="Sản phẩm"
                                                    name="Note{{element.ID}}" [(ngModel)]="element.Note">
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <label class="form-label">Sắp xếp</label>
                                                <input class="form-control" type="number" style="text-align: right;"
                                                    placeholder="0" name="SortOrder{{element.ID}}"
                                                    [(ngModel)]="element.SortOrder">
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <div class="form-check form-check-inline">
                                                    <input class="form-check-input" type="checkbox"
                                                        name="Active{{element.ID}}" [(ngModel)]="element.Active">
                                                    <label class="form-check-label" for="Active">Trụ sở</label>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <a class="btn btn-info" style="width: 100%;"
                                                    (click)="ToChucToaDoSave(element)"><i class="bi bi-sd-card"></i>
                                                </a>
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <a class="btn btn-danger" style="width: 100%;"
                                                    (click)="ToChucToaDoDelete(element)" *ngIf="element.ID>0"><i
                                                        class="bi bi-trash"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="ToChucToaDoService.DisplayColumnsMobile">
                                </tr>
                                <tr mat-row *matRowDef="let row; columns: ToChucToaDoService.DisplayColumnsMobile">
                                </tr>
                            </table>
                            <mat-paginator *ngIf="NotificationService.IsMobile" #ToChucToaDoPaginator="matPaginator"
                                [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="10000"
                                [showFirstLastButtons]></mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>
<br />
<br />
<br />