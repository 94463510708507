<div class="app-body">
    <div class="row">
        <div class="col-xxl-12">
            <div class="card" style="margin-left: 1px">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-ToChuc" data-bs-toggle="tab" href="#ToChuc"
                                    role="tab" aria-controls="ToChuc" aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px">5. Nông hộ
                                        (lúa)</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="ToChuc" role="tabpanel">
                                <h2 class="text-center">01: Pháp lý</h2>
                                <div class="row gx-4">
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <a style="width: 100%;" title="Lưu thay đổi" (click)="ToChucSave()"
                                            class="btn btn-info"><i class="bi bi-sd-card"></i> Lưu thay đổi</a>
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <div class="form-check form-check-inline mb-6">
                                            <input type="checkbox" class="form-check-input" name="IsGioiTinh"
                                                [(ngModel)]="ToChucService.formData.IsGioiTinh" />
                                            <label class="form-check-label" for="IsGioiTinh">Nam/Nữ</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <div class="form-check form-check-inline mb-6">
                                            <input type="checkbox" class="form-check-input" name="Active" disabled
                                                [(ngModel)]="ToChucService.formData.Active" />
                                            <label class="form-check-label" for="Active">Kích hoạt</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row gx-4">
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Họ tên</label>
                                            <input name="Name" [(ngModel)]="ToChucService.formData.Name"
                                                placeholder="Họ tên" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">CCCD</label>
                                            <input name="Code" [(ngModel)]="ToChucService.formData.Code"
                                                placeholder="CCCD" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Điện thoại</label>
                                            <input name="DienThoai" [(ngModel)]="ToChucService.formData.DienThoai"
                                                placeholder="Điện thoại" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Email</label>
                                            <input name="Email" [(ngModel)]="ToChucService.formData.Email"
                                                placeholder="Email" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Ngày sinh</label>
                                            <input [ngModel]="ToChucService.formData.NgayHoatDong | date:'yyyy-MM-dd'"
                                                (ngModelChange)="DateNgayHoatDong($event)"
                                                [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control"
                                                style="text-align: right;" autocomplete="off">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Hợp tác xã</label>
                                            <mat-select class="form-control" name="ToChucID"
                                                [(ngModel)]="ToChucService.formData.ToChucID">
                                                <input placeholder="Tìm..." class="form-control"
                                                    (keyup)="ToChucFilter($event.target.value)">
                                                <mat-option *ngFor="let item of ToChucService.list001"
                                                    [value]="item.ID">
                                                    {{item.Name}} - {{item.DanhMucTinhThanhName}}
                                                </mat-option>
                                            </mat-select>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Giống</label>
                                            <input name="Giong" [(ngModel)]="ToChucService.formData.Giong"
                                                placeholder="Người liên hệ" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Năm trồng</label>
                                            <input name="NamTrong" [(ngModel)]="ToChucService.formData.NamTrong"
                                                placeholder="0" type="number" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Tuổi cây</label>
                                            <input name="TuoiCay" [(ngModel)]="ToChucService.formData.TuoiCay"
                                                placeholder="0" type="number" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Chứng nhận</label>
                                            <input name="ChungNhan" [(ngModel)]="ToChucService.formData.ChungNhan"
                                                placeholder="Điện thoại" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Ghi chú</label>
                                            <input name="Note" [(ngModel)]="ToChucService.formData.Note"
                                                placeholder="Ghi chú" type="text" class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Tỉnh thành</label>
                                            <mat-select class="form-control" name="DanhMucTinhThanhID"
                                                (selectionChange)="DanhMucQuanHuyenGetToList()"
                                                [(ngModel)]="ToChucService.formData.DanhMucTinhThanhID">
                                                <input class="form-control"
                                                    (keyup)="DanhMucTinhThanhFilter($event.target.value)">
                                                <mat-option *ngFor="let item of DanhMucTinhThanhService.list001"
                                                    [value]="item.ID">
                                                    {{item.Name}}
                                                </mat-option>
                                            </mat-select>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Quận huyện</label>
                                            <mat-select class="form-control" name="DanhMucQuanHuyenID"
                                                (selectionChange)="DanhMucXaPhuongGetToList()"
                                                [(ngModel)]="ToChucService.formData.DanhMucQuanHuyenID">
                                                <input class="form-control"
                                                    (keyup)="DanhMucQuanHuyenFilter($event.target.value)">
                                                <mat-option *ngFor="let item of DanhMucQuanHuyenService.list001"
                                                    [value]="item.ID">
                                                    {{item.Name}}
                                                </mat-option>
                                            </mat-select>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Xã phường</label>
                                            <mat-select class="form-control" name="DanhMucXaPhuongID"
                                                [(ngModel)]="ToChucService.formData.DanhMucXaPhuongID">
                                                <input class="form-control"
                                                    (keyup)="DanhMucXaPhuongFilter($event.target.value)">
                                                <mat-option *ngFor="let item of DanhMucXaPhuongService.list001"
                                                    [value]="item.ID">
                                                    {{item.Name}}
                                                </mat-option>
                                            </mat-select>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Ấp thôn</label>
                                            <input name="ApThon" [(ngModel)]="ToChucService.formData.ApThon"
                                                placeholder="Ấp thôn" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Địa chỉ</label>
                                            <input name="DiaChi" [(ngModel)]="ToChucService.formData.DiaChi"
                                                placeholder="Địa chỉ" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Kinh độ</label>
                                            <input name="KinhDo" [(ngModel)]="ToChucService.formData.KinhDo"
                                                placeholder="Kinh độ" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Vĩ độ</label>
                                            <input name="ViDo" [(ngModel)]="ToChucService.formData.ViDo"
                                                placeholder="Vĩ độ" type="text" class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <h2 class="text-center">02: Vùng trồng</h2>
                                <div class="row gx-4">
                                    <div class="col-lg-11 col-sm-12 col-12">
                                        <input [(ngModel)]="ToChucVungTrongService.baseParameter.SearchString"
                                            placeholder="Tìm..." class="form-control"
                                            (keyup.enter)="ToChucVungTrongSearch()">
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <a style="width: 100%;" class="btn btn-success" title="Tìm"
                                            (click)="ToChucVungTrongSearch()"><i
                                                class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;

                                    </div>
                                </div>
                                <div class="card mb-4 card-danger">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Vùng trồng
                                            <span
                                                *ngIf="ToChucVungTrongService.list">({{ToChucVungTrongService.list.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                                    #ToChucVungTrongSort="matSort"
                                                    [dataSource]="ToChucVungTrongService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{ToChucVungTrongPaginator.pageSize *
                                                            ToChucVungTrongPaginator.pageIndex + i + 1}} |
                                                            {{element.ID}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Vùng trồng
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Vùng trồng" name="Name{{element.ID}}"
                                                                [(ngModel)]="element.Name">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Code">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Điện thoại
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Điện thoại" name="Code{{element.ID}}"
                                                                [(ngModel)]="element.Code">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Note">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sản phẩm
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Sản phẩm" name="Note{{element.ID}}"
                                                                [(ngModel)]="element.Note">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SortOrder">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sắp xếp
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="number"
                                                                style="text-align: right;" placeholder="0"
                                                                name="SortOrder{{element.ID}}"
                                                                [(ngModel)]="element.SortOrder">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Active">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Trụ sở
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="Active{{element.ID}}"
                                                                    [(ngModel)]="element.Active">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ToaDo">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Tọa độ
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-success" *ngIf="element.ID>0"
                                                                (click)="ToChucVungTrongAdd(element.ID)"><i
                                                                    class="bi bi-arrows-fullscreen"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info"
                                                                (click)="ToChucVungTrongSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger"
                                                                (click)="ToChucVungTrongDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucVungTrongService.displayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucVungTrongService.displayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="!NotificationService.IsMobile"
                                                    #ToChucVungTrongPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                                    #ToChucVungTrongSort="matSort"
                                                    [dataSource]="ToChucVungTrongService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Danh sách
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Vùng trồng</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Vùng trồng"
                                                                        name="Name{{element.ID}}"
                                                                        [(ngModel)]="element.Name">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Điện thoại</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Điện thoại"
                                                                        name="Code{{element.ID}}"
                                                                        [(ngModel)]="element.Code">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Sản phẩm</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Sản phẩm" name="Note{{element.ID}}"
                                                                        [(ngModel)]="element.Note">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Sắp xếp</label>
                                                                    <input class="form-control" type="number"
                                                                        style="text-align: right;" placeholder="0"
                                                                        name="SortOrder{{element.ID}}"
                                                                        [(ngModel)]="element.SortOrder">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <div class="form-check form-check-inline">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            name="Active{{element.ID}}"
                                                                            [(ngModel)]="element.Active">
                                                                        <label class="form-check-label"
                                                                            for="Active">Trụ sở</label>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <a class="btn btn-success" *ngIf="element.ID>0"
                                                                        style="width: 100%;"
                                                                        (click)="ToChucVungTrongAdd(element.ID)"><i
                                                                            class="bi bi-arrows-fullscreen"></i> Tọa độ
                                                                    </a>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <a class="btn btn-info" style="width: 100%;"
                                                                        (click)="ToChucVungTrongSave(element)"><i
                                                                            class="bi bi-sd-card"></i>
                                                                    </a>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <a class="btn btn-danger" style="width: 100%;"
                                                                        (click)="ToChucVungTrongDelete(element)"
                                                                        *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucVungTrongService.DisplayColumnsMobile">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucVungTrongService.DisplayColumnsMobile">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="NotificationService.IsMobile"
                                                    #ToChucVungTrongPaginator="matPaginator"
                                                    [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="10000"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h2 class="text-center">03: Bản đồ</h2>
                                <div class="row">
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <a style="width: 100%;" title="Tải lại bản đồ" (click)="MapLoad(0)"
                                            class="btn btn-success"><i class="bi bi-arrow-repeat"></i> Tải lại bản
                                            đồ</a>
                                    </div>
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <a style="width: 100%;" title="Vẽ vùng trồng" (click)="OpenWindowVeVungTrong()"
                                            class="btn btn-success"><i class="bi bi-brush"></i> Vẽ vùng trồng</a>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <ul>
                                            <li>
                                                <a style="cursor: pointer;" (click)="MapLoad(-1)">
                                                    <h4><b>Tất cả</b></h4>
                                                </a>
                                            </li>
                                            <li *ngFor="let ToChucVungTrong of ToChucVungTrongService.list001;">
                                                <a style="cursor: pointer;" (click)="MapLoad(ToChucVungTrong.ID)">
                                                    <h4>{{ToChucVungTrong.Name}} [{{ToChucVungTrong.ID}}]</h4>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-lg-9 col-sm-12 col-12">
                                        <div class="map" #map style="height: 500px; width: 100%;">
                                            <div style="z-index: 1000; position: absolute; top: 0;">
                                                <img src="{{domainURL}}assets/image/vungtrong.png" />
                                            </div>
                                            <div
                                                style="z-index: 1000; position: absolute; bottom: 0; width: 100%; height: 20px; background-color: #ffffff;">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h2 class="text-center">04: Tài liệu</h2>
                                <div class="row gx-4">
                                    <div class="col-lg-11 col-sm-12 col-12">
                                        <input [(ngModel)]="ToChucTapTinDinhKemService.baseParameter.SearchString"
                                            placeholder="Tìm..." class="form-control"
                                            (keyup.enter)="ToChucTapTinDinhKemSearch()">
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <a style="width: 100%;" class="btn btn-success" title="Tìm"
                                            (click)="ToChucTapTinDinhKemSearch()"><i
                                                class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;

                                    </div>
                                </div>
                                <div class="card mb-4 card-primary">
                                    <div class="card-header">
                                        <h5 class="card-title text-white bg-primary">Tài liệu
                                            <span
                                                *ngIf="ToChucTapTinDinhKemService.list">({{ToChucTapTinDinhKemService.list.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body bg-primary">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                                    #ToChucTapTinDinhKemSort="matSort"
                                                    [dataSource]="ToChucTapTinDinhKemService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{ToChucTapTinDinhKemPaginator.pageSize *
                                                            ToChucTapTinDinhKemPaginator.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tài liệu
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Tài liệu" name="Name{{element.ID}}"
                                                                [(ngModel)]="element.Name">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Code">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tải lên
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input *ngIf="element.ID == 0" type="file" multiple
                                                                (change)="ChangeFileName($event.target.files)">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info"
                                                                (click)="ToChucTapTinDinhKemSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger"
                                                                (click)="ToChucTapTinDinhKemDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                            &nbsp;&nbsp;&nbsp;
                                                            <a *ngIf="element.ID > 0 && element.FileName"
                                                                class="btn btn-success" title="Tải về"
                                                                href="{{element.FileName}}" target="_blank"><i
                                                                    class="bi bi-cloud-download"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucTapTinDinhKemService.displayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucTapTinDinhKemService.displayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="!NotificationService.IsMobile"
                                                    #ToChucTapTinDinhKemPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                                    #ToChucTapTinDinhKemSort="matSort"
                                                    [dataSource]="ToChucTapTinDinhKemService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Danh sách
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Tài liệu</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Tài liệu" name="Name{{element.ID}}"
                                                                        [(ngModel)]="element.Name">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Tải lên</label>
                                                                    <br />
                                                                    <input *ngIf="element.ID == 0" type="file" multiple
                                                                        style="width: 200px;"
                                                                        (change)="ChangeFileName($event.target.files)">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <a class="btn btn-info" style="width: 100%;"
                                                                        (click)="ToChucHopTacSave(element)"><i
                                                                            class="bi bi-sd-card"></i>
                                                                    </a>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <a class="btn btn-danger" style="width: 100%;"
                                                                        (click)="ToChucHopTacDelete(element)"
                                                                        *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                                    </a>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <a *ngIf="element.ID > 0 && element.FileName"
                                                                        class="btn btn-success" title="Tải về"
                                                                        style="width: 100%;" href="{{element.FileName}}"
                                                                        target="_blank"><i
                                                                            class="bi bi-cloud-download"></i>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucTapTinDinhKemService.DisplayColumnsMobile">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucTapTinDinhKemService.DisplayColumnsMobile">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="NotificationService.IsMobile"
                                                    #ToChucTapTinDinhKemPaginator="matPaginator"
                                                    [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="10000"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>