<div class="app-hero-header d-flex align-items-center">
    <div class="d-flex align-items-center">
        <div class="me-3 icon-box md bg-white rounded-4">
            <i class="bi bi-hypnotize fs-3 text-primary"></i>
        </div>
        <div>
            <h2 class="mb-1">Upload excel</h2>
            <h6 class="m-0 text-dark fw-light">
                Mô hình thí điểm
            </h6>
        </div>
    </div>
    <div class="ms-auto d-lg-flex d-none flex-row">
        <div class="d-flex flex-row gap-2">
        </div>
    </div>
</div>
<div class="app-body">
    <div class="row gx-4">
        <div class="col-xxl-12">
            <div class="card mb-4">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-QuyTrinhSanXuat" data-bs-toggle="tab"
                                    href="#QuyTrinhSanXuat" role="tab" aria-controls="QuyTrinhSanXuat"
                                    aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">Quy trình sản xuất</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="QuyTrinhSanXuat" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-12 col-sm-12 col-12">
                                        <h3>Quy trình kỹ thuật sản xuất lúa chất lượng cao và phát thải thấp vùng đồng
                                            bằng sông Cửu Long</h3>
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Bước 01:</label> <a class="link-primary"
                                                target="_blank" href="{{excelQuyTrinhSanXuatURL}}" title="Tải về">Tải
                                                về</a> file
                                            excel mẫu
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Bước 02:</label> Chọn file
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <input class="form-control" type="file" #uploadQuyTrinhSanXuat
                                                (change)="changeQuyTrinhSanXuat($event.target.files)">
                                        </div>
                                        <br />
                                        <button [disabled]="!isQuyTrinhSanXuat" class="btn btn-info"
                                            (click)="onSubmitQuyTrinhSanXuat()" style="width: 100%;">Upload</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>