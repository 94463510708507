import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomepageComponent } from './homepage/homepage.component';
import { DanhMucComponent } from './danh-muc/danh-muc.component';
import { UploadComponent } from './upload/upload.component';
import { HopTacXaComponent } from './hop-tac-xa/hop-tac-xa.component';
import { DanhMucNguyenVatLieuComponent } from './danh-muc-nguyen-vat-lieu/danh-muc-nguyen-vat-lieu.component';
import { DoanhNghiepComponent } from './doanh-nghiep/doanh-nghiep.component';
import { ToChucQuyTrinhSanXuatComponent } from './to-chuc-quy-trinh-san-xuat/to-chuc-quy-trinh-san-xuat.component';
import { ReportComponent } from './report/report.component';
import { ThanhVienComponent } from './thanh-vien/thanh-vien.component';
import { NongDanComponent } from './nong-dan/nong-dan.component';
import { ThoiTietComponent } from './thoi-tiet/thoi-tiet.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MapComponent } from './map/map.component';
import { ToChucDetailComponent } from './to-chuc-detail/to-chuc-detail.component';
import { ToChucInfoComponent } from './to-chuc-info/to-chuc-info.component';
import { MapVungTrongComponent } from './map-vung-trong/map-vung-trong.component';
import { MapVungTrongInfoComponent } from './map-vung-trong-info/map-vung-trong-info.component';
import { MapToChucToaDoComponent } from './map-to-chuc-toa-do/map-to-chuc-toa-do.component';
import { ThongBaoComponent } from './thong-bao/thong-bao.component';
import { GioiThieuComponent } from './gioi-thieu/gioi-thieu.component';
import { ThongKeComponent } from './thong-ke/thong-ke.component';
import { MapHopTacXaComponent } from './map-hop-tac-xa/map-hop-tac-xa.component';
import { TuVanComponent } from './tu-van/tu-van.component';
import { HuongDanSuDungComponent } from './huong-dan-su-dung/huong-dan-su-dung.component';
import { PhanQuyenComponent } from './phan-quyen/phan-quyen.component';
import { ThanhVienPhanQuyenChucNangComponent } from './thanh-vien-phan-quyen-chuc-nang/thanh-vien-phan-quyen-chuc-nang.component';
import { ThanhVienPhanQuyenKhuVucComponent } from './thanh-vien-phan-quyen-khu-vuc/thanh-vien-phan-quyen-khu-vuc.component';
import { QuanLyComponent } from './quan-ly/quan-ly.component';
import { ThongKe001Component } from './thong-ke001/thong-ke001.component';
import { ChuyenGiaComponent } from './chuyen-gia/chuyen-gia.component';
import { TaiLieuComponent } from './tai-lieu/tai-lieu.component';
import { ThanhVienThongTinComponent } from './thanh-vien-thong-tin/thanh-vien-thong-tin.component';
import { ChinhSachBaoMatComponent } from './chinh-sach-bao-mat/chinh-sach-bao-mat.component';
import { ToChucDonViThuMuaNongSanComponent } from './to-chuc-don-vi-thu-mua-nong-san/to-chuc-don-vi-thu-mua-nong-san.component';
import { ToChucThuongLaiComponent } from './to-chuc-thuong-lai/to-chuc-thuong-lai.component';
import { ToChucDonViCungCapVatTuNongNghiepComponent } from './to-chuc-don-vi-cung-cap-vat-tu-nong-nghiep/to-chuc-don-vi-cung-cap-vat-tu-nong-nghiep.component';
import { ToChucDonViCungCapThietBiCoGioiComponent } from './to-chuc-don-vi-cung-cap-thiet-bi-co-gioi/to-chuc-don-vi-cung-cap-thiet-bi-co-gioi.component';
import { ToChucNongHoComponent } from './to-chuc-nong-ho/to-chuc-nong-ho.component';
import { ToChucZeroCarbonRiceComponent } from './to-chuc-zero-carbon-rice/to-chuc-zero-carbon-rice.component';
import { ToChucHaTangComponent } from './to-chuc-ha-tang/to-chuc-ha-tang.component';
import { ToChucVuSanXuatComponent } from './to-chuc-vu-san-xuat/to-chuc-vu-san-xuat.component';
import { DanhMucVuSanXuatPhanDoanComponent } from './danh-muc-vu-san-xuat-phan-doan/danh-muc-vu-san-xuat-phan-doan.component';
import { ToChucVuSanXuatTieuChiComponent } from './to-chuc-vu-san-xuat-tieu-chi/to-chuc-vu-san-xuat-tieu-chi.component';
import { UploadQuyTrinhSanXuatComponent } from './upload-quy-trinh-san-xuat/upload-quy-trinh-san-xuat.component';
import { ThanhVienToChucVuSanXuatComponent } from './thanh-vien-to-chuc-vu-san-xuat/thanh-vien-to-chuc-vu-san-xuat.component';
import { ThanhVienToChucVuSanXuatMobileComponent } from './thanh-vien-to-chuc-vu-san-xuat-mobile/thanh-vien-to-chuc-vu-san-xuat-mobile.component';
import { ThanhVienToChucVuSanXuatMobileToChucVuSanXuatVungTrongComponent } from './thanh-vien-to-chuc-vu-san-xuat-mobile-to-chuc-vu-san-xuat-vung-trong/thanh-vien-to-chuc-vu-san-xuat-mobile-to-chuc-vu-san-xuat-vung-trong.component';
import { ThanhVienToChucVuSanXuatMobileToChucVuSanXuatTieuChiComponent } from './thanh-vien-to-chuc-vu-san-xuat-mobile-to-chuc-vu-san-xuat-tieu-chi/thanh-vien-to-chuc-vu-san-xuat-mobile-to-chuc-vu-san-xuat-tieu-chi.component';
import { ThanhVienToChucVuSanXuatMobileToChucVuSanXuatTapTinDinhKemComponent } from './thanh-vien-to-chuc-vu-san-xuat-mobile-to-chuc-vu-san-xuat-tap-tin-dinh-kem/thanh-vien-to-chuc-vu-san-xuat-mobile-to-chuc-vu-san-xuat-tap-tin-dinh-kem.component';
import { ThanhVienToChucVuSanXuatMobileToChucVuSanXuatNhatKy55Component } from './thanh-vien-to-chuc-vu-san-xuat-mobile-to-chuc-vu-san-xuat-nhat-ky55/thanh-vien-to-chuc-vu-san-xuat-mobile-to-chuc-vu-san-xuat-nhat-ky55.component';
import { ThanhVienToChucVuSanXuatMobileToChucVuSanXuatNhatKy54Component } from './thanh-vien-to-chuc-vu-san-xuat-mobile-to-chuc-vu-san-xuat-nhat-ky54/thanh-vien-to-chuc-vu-san-xuat-mobile-to-chuc-vu-san-xuat-nhat-ky54.component';
import { ThanhVienToChucVuSanXuatMobileToChucVuSanXuatNhatKyIsMuaVoComponent } from './thanh-vien-to-chuc-vu-san-xuat-mobile-to-chuc-vu-san-xuat-nhat-ky-is-mua-vo/thanh-vien-to-chuc-vu-san-xuat-mobile-to-chuc-vu-san-xuat-nhat-ky-is-mua-vo.component';
import { ThanhVienToChucVuSanXuatMobileToChucVuSanXuatNhatKyComponent } from './thanh-vien-to-chuc-vu-san-xuat-mobile-to-chuc-vu-san-xuat-nhat-ky/thanh-vien-to-chuc-vu-san-xuat-mobile-to-chuc-vu-san-xuat-nhat-ky.component';
import { ThanhVienToChucDonViThuMuaComponent } from './thanh-vien-to-chuc-don-vi-thu-mua/thanh-vien-to-chuc-don-vi-thu-mua.component';
import { ThanhVienToChucDonViCungCapThietBiComponent } from './thanh-vien-to-chuc-don-vi-cung-cap-thiet-bi/thanh-vien-to-chuc-don-vi-cung-cap-thiet-bi.component';
import { ThanhVienToChucDonViCungCapVatTuComponent } from './thanh-vien-to-chuc-don-vi-cung-cap-vat-tu/thanh-vien-to-chuc-don-vi-cung-cap-vat-tu.component';
import { ThanhVienToChucNongHoComponent } from './thanh-vien-to-chuc-nong-ho/thanh-vien-to-chuc-nong-ho.component';
import { ThanhVienToChucThuongLaiComponent } from './thanh-vien-to-chuc-thuong-lai/thanh-vien-to-chuc-thuong-lai.component';
import { LoginComponent } from './login/login.component';



const routes: Routes = [
  { path: '', redirectTo: '/Login', pathMatch: 'full' },
  {
    path: 'Login', component: LoginComponent,
  },
  {
    path: 'Homepage', component: ChinhSachBaoMatComponent,
  },
  {
    path: 'Map', component: MapComponent,
  },
  {
    path: 'MapHopTacXa', component: MapHopTacXaComponent,
  },
  {
    path: 'MapVungTrong', component: MapVungTrongComponent,
  },
  {
    path: 'MapToChucToaDo', component: MapToChucToaDoComponent,
  },
  {
    path: 'DanhMuc', component: DanhMucComponent,
  },
  {
    path: 'DanhMucNguyenVatLieu', component: DanhMucNguyenVatLieuComponent,
  },
  {
    path: 'Upload', component: UploadComponent,
  },
  {
    path: 'HopTacXa', component: HopTacXaComponent,
  },
  {
    path: 'NongDan', component: NongDanComponent,
  },
  {
    path: 'ChuyenGia', component: ChuyenGiaComponent,
  },
  {
    path: 'DoanhNghiep', component: DoanhNghiepComponent,
  },
  {
    path: 'TuVan', component: TuVanComponent,
  },
  {
    path: 'TaiLieu', component: TaiLieuComponent,
  },
  {
    path: 'HuongDanSuDung', component: HuongDanSuDungComponent,
  },
  {
    path: 'ToChucQuyTrinhSanXuat', component: ToChucQuyTrinhSanXuatComponent,
  },
  {
    path: 'ThanhVien', component: ThanhVienComponent,
  },
  {
    path: 'QuanLy', component: QuanLyComponent,
  },
  {
    path: 'PhanQuyen', component: PhanQuyenComponent,
  },
  {
    path: 'ThanhVienPhanQuyenChucNang', component: ThanhVienPhanQuyenChucNangComponent,
  },
  {
    path: 'ThanhVienPhanQuyenKhuVuc', component: ThanhVienPhanQuyenKhuVucComponent,
  },
  {
    path: 'ThongBao', component: ThongBaoComponent,
  },
  {
    path: 'GioiThieu', component: GioiThieuComponent,
  },
  {
    path: 'Report', component: ReportComponent,
  },
  {
    path: 'Dashboard', component: DashboardComponent,
  },
  {
    path: 'ThongKe', component: ThongKeComponent,
  },
  {
    path: 'ThongKe001', component: ThongKe001Component,
  },
  {
    path: 'ToChucInfo/:ID', component: ToChucInfoComponent,
  },
  {
    path: 'VungTrongInfo/:ID', component: MapVungTrongInfoComponent,
  },
  {
    path: 'ThanhVienThongTin', component: ThanhVienThongTinComponent,
  },
  {
    path: 'DonViThuMuaNongSan', component: ToChucDonViThuMuaNongSanComponent,
  },
  {
    path: 'ThuongLai', component: ToChucThuongLaiComponent,
  },
  {
    path: 'DonViCungCapVatTuNongNghiep', component: ToChucDonViCungCapVatTuNongNghiepComponent,
  },
  {
    path: 'DonViCungCapThietBiCoGioi', component: ToChucDonViCungCapThietBiCoGioiComponent,
  },
  {
    path: 'NongHo', component: ToChucNongHoComponent,
  },
  {
    path: 'ToChucZeroCarbonRice', component: ToChucZeroCarbonRiceComponent,
  },
  {
    path: 'ToChucHaTang', component: ToChucHaTangComponent,
  },
  {
    path: 'ToChucVuSanXuat', component: ToChucVuSanXuatComponent,
  },
  {
    path: 'ToChucVuSanXuatTieuChi', component: ToChucVuSanXuatTieuChiComponent,
  },
  {
    path: 'ThanhVienToChucVuSanXuat', component: ThanhVienToChucVuSanXuatComponent,
  },
  {
    path: 'ThanhVienToChucVuSanXuatMobile', component: ThanhVienToChucVuSanXuatMobileComponent,
  },
  {
    path: 'ThanhVienToChucVuSanXuatMobileToChucVuSanXuatVungTrong', component: ThanhVienToChucVuSanXuatMobileToChucVuSanXuatVungTrongComponent,
  },
  {
    path: 'ThanhVienToChucVuSanXuatMobileToChucVuSanXuatTieuChi', component: ThanhVienToChucVuSanXuatMobileToChucVuSanXuatTieuChiComponent,
  },
  {
    path: 'ThanhVienToChucVuSanXuatMobileToChucVuSanXuatTapTinDinhKem', component: ThanhVienToChucVuSanXuatMobileToChucVuSanXuatTapTinDinhKemComponent,
  },
  {
    path: 'ThanhVienToChucVuSanXuatMobileToChucVuSanXuatNhatKy55', component: ThanhVienToChucVuSanXuatMobileToChucVuSanXuatNhatKy55Component,
  },
  {
    path: 'ThanhVienToChucVuSanXuatMobileToChucVuSanXuatNhatKy54', component: ThanhVienToChucVuSanXuatMobileToChucVuSanXuatNhatKy54Component,
  },
  {
    path: 'ThanhVienToChucVuSanXuatMobileToChucVuSanXuatNhatKyIsMuaVo', component: ThanhVienToChucVuSanXuatMobileToChucVuSanXuatNhatKyIsMuaVoComponent,
  },
  {
    path: 'ThanhVienToChucVuSanXuatMobileToChucVuSanXuatNhatKy', component: ThanhVienToChucVuSanXuatMobileToChucVuSanXuatNhatKyComponent,
  },
  {
    path: 'ThanhVienToChucDonViThuMua', component: ThanhVienToChucDonViThuMuaComponent,
  },
  {
    path: 'ThanhVienToChucDonViCungCapThietBi', component: ThanhVienToChucDonViCungCapThietBiComponent,
  },
  {
    path: 'ThanhVienToChucDonViCungCapVatTu', component: ThanhVienToChucDonViCungCapVatTuComponent,
  },
  {
    path: 'ThanhVienToChucNongHo', component: ThanhVienToChucNongHoComponent,
  },
  {
    path: 'ThanhVienToChucThuongLai', component: ThanhVienToChucThuongLaiComponent,
  },


  {
    path: 'UploadQuyTrinhSanXuat', component: UploadQuyTrinhSanXuatComponent,
  },
  {
    path: 'DanhMucVuSanXuatPhanDoan', component: DanhMucVuSanXuatPhanDoanComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, initialNavigation: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
