import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ToChucVuSanXuatTapTinDinhKem } from 'src/app/shared/ToChucVuSanXuatTapTinDinhKem.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class ToChucVuSanXuatTapTinDinhKemService extends BaseService{

    list: ToChucVuSanXuatTapTinDinhKem[] | undefined;
    list001: ToChucVuSanXuatTapTinDinhKem[] | undefined;
    formData!: ToChucVuSanXuatTapTinDinhKem;    

    displayColumns001: string[] = ['STT', 'Name', 'Code', 'Save'];
    displayColumns002: string[] = ['STT', 'ToChucVuSanXuatVungTrongID', 'Name', 'Code', 'Save'];

    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "ToChucVuSanXuatTapTinDinhKem";
    }
    GetByToChucVuSanXuatNhatKyIDToListAsync() {
        let url = this.aPIURL + this.controller + '/GetByToChucVuSanXuatNhatKyIDToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
    GetByToChucVuSanXuatNhatKyID_EmptyToListAsync() {
        let url = this.aPIURL + this.controller + '/GetByToChucVuSanXuatNhatKyID_EmptyToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
    GetByToChucVuSanXuatVungTrongID_EmptyToListAsync() {
        let url = this.aPIURL + this.controller + '/GetByToChucVuSanXuatVungTrongID_EmptyToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
}

