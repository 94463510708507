import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from 'src/app/shared/Notification.service';

import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(
    public router: Router,
    public NotificationService: NotificationService,
    public ThanhVienService: ThanhVienService,
  ) { }

  ngOnInit(): void {
    this.GetByQueryString(); document.getElementById("sidebar").style.display = "none";
    document.getElementById("main-container").style.padding = "0";
  }

  ngOnDestroy(): void {
    document.getElementById("sidebar").style.display = "block";
    document.getElementById("main-container").style.padding = "0 0 0 300px";
  }

  GetByQueryString() {
    this.ThanhVienService.IsShowLoading = true;
    this.ThanhVienService.baseParameter.ID = environment.InitializationNumber;
    this.ThanhVienService.GetByIDAsync().subscribe(
      res => {
        this.ThanhVienService.formDataLogin = res as ThanhVien;
        this.ThanhVienService.IsShowLoading = false;
      },
      err => {
        this.ThanhVienService.IsShowLoading = false;
      }
    );
  }
  Submit() {
    this.ThanhVienService.IsShowLoading = true;
    let tokenFCM = localStorage.getItem(environment.TokenFCM);
    if (tokenFCM != null) {
      this.ThanhVienService.formData.Code = tokenFCM;
    }
    this.ThanhVienService.AuthenticationAsync().subscribe(
      res => {
        this.ThanhVienService.IsShowLoading = false;
        this.ThanhVienService.formDataLogin = res as ThanhVien;
        if (this.ThanhVienService.formDataLogin) {
          if (this.ThanhVienService.formDataLogin.HTMLContent) {
            localStorage.setItem(environment.Token, this.ThanhVienService.formDataLogin.HTMLContent);
            if (this.ThanhVienService.formDataLogin.ParentID == null) {
              this.ThanhVienService.formDataLogin.ParentID = environment.InitializationNumber;
            }
            localStorage.setItem(environment.ThanhVienID, this.ThanhVienService.formDataLogin.ID.toString());
            localStorage.setItem(environment.ThanhVienParentID, environment.InitializationString);
            localStorage.setItem(environment.ThanhVienToChucID, environment.InitializationString);
            if (this.ThanhVienService.formDataLogin.ParentID) {
              localStorage.setItem(environment.ThanhVienParentID, this.ThanhVienService.formDataLogin.ParentID.toString());
            }
            if (this.ThanhVienService.formDataLogin.ToChucID) {
              localStorage.setItem(environment.ThanhVienToChucID, this.ThanhVienService.formDataLogin.ToChucID.toString());
            }
            localStorage.setItem(environment.ThanhVienTaiKhoan, this.ThanhVienService.formDataLogin.TaiKhoan);
            localStorage.setItem(environment.ThanhVienHoTen, this.ThanhVienService.formDataLogin.Name);
            localStorage.setItem(environment.ThanhVienFileName, this.ThanhVienService.formDataLogin.FileName);
            this.router.navigate(['/' + environment.Homepage]);
          }
          else {
            this.NotificationService.success(environment.LoginNotSuccess);
          }
        }
        else {
          this.NotificationService.success(environment.LoginNotSuccess);
        }
      },
      err => {
        this.NotificationService.warn(environment.LoginNotSuccess);
        this.ThanhVienService.IsShowLoading = false;
      }
    );
  }
}
