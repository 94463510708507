import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ToChucPhuongTien } from 'src/app/shared/ToChucPhuongTien.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class ToChucPhuongTienService extends BaseService{
    list: ToChucPhuongTien[] | undefined;
    list001: ToChucPhuongTien[] | undefined;
    formData!: ToChucPhuongTien;    

    displayColumns001: string[] = ['STT', 'Name', 'Code', 'SoHieuPhuongTien', 'CongSuat', 'Save'];
    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "ToChucPhuongTien";
    }
}

