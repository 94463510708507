<div class="app-body">
    <div class="row">
        <div class="col-xxl-12">
            <div class="card" style="margin-left: 1px">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-ToChucVuSanXuatNhatKy" data-bs-toggle="tab"
                                    href="#ToChucVuSanXuatNhatKy" role="tab" aria-controls="ToChucVuSanXuatNhatKy"
                                    aria-selected="true">
                                    <span class="badge bg-success" style="font-size: 16px">6. Bán ra</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="ToChucVuSanXuatNhatKy" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">Vụ mùa</label>
                                        <mat-select class="form-control" name="ParentID"
                                            (selectionChange)="ToChucVuSanXuatVungTrongSearch()"
                                            [(ngModel)]="ToChucVuSanXuatNhatKyService.baseParameter.ParentID">
                                            <input placeholder="Tìm..." class="form-control"
                                                (keyup)="ToChucVuSanXuatFilter($event.target.value)">
                                            <mat-option *ngFor="let item of ToChucVuSanXuatService.list001"
                                                [value]="item.ID">
                                                {{item.DanhMucVuMuaName}} - {{item.BatDau | date:'dd/MM/yyyy'}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">Vùng trồng</label>
                                        <mat-select class="form-control" name="ToChucVuSanXuatVungTrongID"
                                            [(ngModel)]="ToChucVuSanXuatNhatKyService.baseParameter.ToChucVuSanXuatVungTrongID">
                                            <mat-option *ngFor="let item of ToChucVuSanXuatVungTrongService.list001"
                                                [value]="item.ID">
                                                {{item.Name}} - {{item.Code}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                                        <input name="SearchString"
                                            [(ngModel)]="ToChucVuSanXuatNhatKyService.baseParameter.SearchString"
                                            placeholder="Tìm..." class="form-control"
                                            (keyup.enter)="ToChucVuSanXuatNhatKySearchIsMuaVo()">
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                                        <a class="btn btn-success" title="Tìm..." style="width: 100%;"
                                            (click)="ToChucVuSanXuatNhatKySearchIsMuaVo()"><i class="bi bi-search"></i></a>
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                                        <a class="btn btn-info" title="Sao chép Bán ra sang vùng trồng khác cùng vụ mùa"
                                            style="width: 100%;" (click)="ToChucVuSanXuatNhatKyCopy00()">Sao chép</a>
                                    </div>
                                </div>
                                <div class="card mb-4 card-success">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Bán ra
                                            <span
                                                *ngIf="ToChucVuSanXuatNhatKyService.listIsMuaVo">({{ToChucVuSanXuatNhatKyService.listIsMuaVo.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                                    #ToChucVuSanXuatNhatKyIsMuaVoSort="matSort"
                                                    [dataSource]="ToChucVuSanXuatNhatKyService.DataSourceIsMuaVo"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="BatDau">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi nhận
                                                        </th>
                                                        <td mat-cell *matCellDef="let element" style="width: 30%;">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Ghi nhận</label>
                                                                    <input name="BatDau{{element.ID}}"
                                                                        [ngModel]="element.BatDau | date:'yyyy-MM-dd'"
                                                                        (ngModelChange)="DateToChucVuSanXuatNhatKyBatDau(element, $event)"
                                                                        [ngModelOptions]="{ updateOn: 'blur' }"
                                                                        type="date" style="text-align: right;"
                                                                        class="form-control" autocomplete="off">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Nhật ký</label>
                                                                    <br />
                                                                    <mat-select class="form-control"
                                                                        name="ToChucVuSanXuatNhatKyID{{element.ID}}"
                                                                        [(ngModel)]="element.ToChucVuSanXuatNhatKyID">
                                                                        <input placeholder="Tìm..." class="form-control"
                                                                            (keyup)="ToChucVuSanXuatNhatKyFilter($event.target.value)">
                                                                        <mat-option
                                                                            *ngFor="let item of ToChucVuSanXuatNhatKyService.list54"
                                                                            [value]="item.ID">
                                                                            {{item.Code}} - {{item.Name}}
                                                                        </mat-option>
                                                                    </mat-select>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Ghi chú</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Ghi chú" name="Note{{element.ID}}"
                                                                        [(ngModel)]="element.Note">
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DanhMucNguyenVatLieuName">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Bán ra
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Bán ra</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Bán ra"
                                                                        name="DanhMucNguyenVatLieuName{{element.ID}}"
                                                                        [(ngModel)]="element.DanhMucNguyenVatLieuName">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Số lượng</label>
                                                                    <input class="form-control" type="number"
                                                                        style="text-align: right;" placeholder="0"
                                                                        name="SoLuong{{element.ID}}"
                                                                        [(ngModel)]="element.SoLuong">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Đơn vị tính</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Đơn vị tính"
                                                                        name="DanhMucDonViTinhName{{element.ID}}"
                                                                        [(ngModel)]="element.DanhMucDonViTinhName">
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NhaCungCap">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Người mua
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Người mua (nếu
                                                                        có)</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Người mua (nếu có)"
                                                                        name="NhaCungCap{{element.ID}}"
                                                                        [(ngModel)]="element.NhaCungCap">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Điện thoại</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Điện thoại"
                                                                        name="NhaCungCapDienThoai{{element.ID}}"
                                                                        [(ngModel)]="element.NhaCungCapDienThoai">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Địa chỉ</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Địa chỉ"
                                                                        name="NhaCungCapDiaChi{{element.ID}}"
                                                                        [(ngModel)]="element.NhaCungCapDiaChi">
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Active">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tình trạng
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Tải lên
                                                                        [{{element.ID}}]</label>
                                                                    <br />
                                                                    <input type="file" multiple
                                                                        (change)="ToChucVuSanXuatNhatKyChangeFileName($event.target.files)">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <div class="form-check form-check-inline mb-6">
                                                                        <input type="checkbox" class="form-check-input"
                                                                            name="Active{{element.ID}}"
                                                                            [(ngModel)]="element.Active" />
                                                                        <label class="form-check-label" for="Active">Đã
                                                                            hoàn thành công việc</label>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <div class="form-check form-check-inline mb-6">
                                                                        <input type="checkbox" class="form-check-input"
                                                                            name="IsChungThuc{{element.ID}}" disabled
                                                                            [(ngModel)]="element.IsChungThuc" />
                                                                        <label class="form-check-label"
                                                                            for="IsChungThuc">Đã chứng thực và khóa dữ
                                                                            liệu</label>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <a class="btn btn-info" style="width: 100%;"
                                                                        (click)="ToChucVuSanXuatNhatKySaveIsMuaVo(element)"><i
                                                                            class="bi bi-sd-card"></i></a>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <a *ngIf="element.ID>0" class="btn btn-info"
                                                                        style="width: 100%;"
                                                                        (click)="ToChucVuSanXuatNhatKyCopyByID(element)">Copy</a>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <a class="btn btn-danger" style="width: 100%;"
                                                                        (click)="ToChucVuSanXuatNhatKyDeleteIsMuaVo(element)"
                                                                        *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucVuSanXuatNhatKyService.displayColumns0003">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucVuSanXuatNhatKyService.displayColumns0003;">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="!NotificationService.IsMobile"
                                                    #ToChucVuSanXuatNhatKyIsMuaVoPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                                    #ToChucVuSanXuatNhatKyIsMuaVoSort="matSort"
                                                    [dataSource]="ToChucVuSanXuatNhatKyService.DataSourceIsMuaVo"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Danh sách
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Ghi nhận</label>
                                                                    <input name="BatDau{{element.ID}}"
                                                                        [ngModel]="element.BatDau | date:'yyyy-MM-dd'"
                                                                        (ngModelChange)="DateToChucVuSanXuatNhatKyBatDau(element, $event)"
                                                                        [ngModelOptions]="{ updateOn: 'blur' }"
                                                                        type="date" style="text-align: right;"
                                                                        class="form-control" autocomplete="off">
                                                                </div>                                                               
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Nhật ký</label>
                                                                    <br />
                                                                    <mat-select class="form-control"
                                                                        name="ToChucVuSanXuatNhatKyID{{element.ID}}"
                                                                        [(ngModel)]="element.ToChucVuSanXuatNhatKyID">
                                                                        <input placeholder="Tìm..." class="form-control"
                                                                            (keyup)="ToChucVuSanXuatNhatKyFilter($event.target.value)">
                                                                        <mat-option
                                                                            *ngFor="let item of ToChucVuSanXuatNhatKyService.list54"
                                                                            [value]="item.ID">
                                                                            {{item.Code}} - {{item.Name}}
                                                                        </mat-option>
                                                                    </mat-select>
                                                                </div>   
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Ghi chú</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Ghi chú" name="Note{{element.ID}}"
                                                                        [(ngModel)]="element.Note">
                                                                </div>      
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Bán ra</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Bán ra"
                                                                        name="DanhMucNguyenVatLieuName{{element.ID}}"
                                                                        [(ngModel)]="element.DanhMucNguyenVatLieuName">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Số lượng</label>
                                                                    <input class="form-control" type="number"
                                                                        style="text-align: right;" placeholder="0"
                                                                        name="SoLuong{{element.ID}}"
                                                                        [(ngModel)]="element.SoLuong">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Đơn vị tính</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Đơn vị tính"
                                                                        name="DanhMucDonViTinhName{{element.ID}}"
                                                                        [(ngModel)]="element.DanhMucDonViTinhName">
                                                                </div>   
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Người mua (nếu
                                                                        có)</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Người mua (nếu có)"
                                                                        name="NhaCungCap{{element.ID}}"
                                                                        [(ngModel)]="element.NhaCungCap">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Điện thoại</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Điện thoại"
                                                                        name="NhaCungCapDienThoai{{element.ID}}"
                                                                        [(ngModel)]="element.NhaCungCapDienThoai">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Địa chỉ</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Địa chỉ"
                                                                        name="NhaCungCapDiaChi{{element.ID}}"
                                                                        [(ngModel)]="element.NhaCungCapDiaChi">
                                                                </div>                                                                     
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <div class="form-check form-check-inline mb-6">
                                                                        <input type="checkbox" class="form-check-input"
                                                                            name="Active{{element.ID}}"
                                                                            [(ngModel)]="element.Active" />
                                                                        <label class="form-check-label" for="Active">Đã
                                                                            hoàn thành công việc</label>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <div class="form-check form-check-inline mb-6">
                                                                        <input type="checkbox" class="form-check-input"
                                                                            name="IsChungThuc{{element.ID}}" disabled
                                                                            [(ngModel)]="element.IsChungThuc" />
                                                                        <label class="form-check-label"
                                                                            for="IsChungThuc">Đã chứng thực</label>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Tải lên
                                                                        [{{element.ID}}]</label>
                                                                    <br />
                                                                    <input type="file" multiple style="width: 200px;"
                                                                        (change)="ToChucVuSanXuatNhatKyChangeFileName($event.target.files)">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <a class="btn btn-info" style="width: 100%;"
                                                                        (click)="ToChucVuSanXuatNhatKySaveIsMuaVo(element)"><i
                                                                            class="bi bi-sd-card"></i></a>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <a *ngIf="element.ID>0" class="btn btn-info"
                                                                        style="width: 100%;"
                                                                        (click)="ToChucVuSanXuatNhatKyCopyByID(element)">Copy</a>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <a class="btn btn-danger" style="width: 100%;"
                                                                        (click)="ToChucVuSanXuatNhatKyDeleteIsMuaVo(element)"
                                                                        *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                                    </a>
                                                                </div>          
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucVuSanXuatNhatKyService.DisplayColumnsMobile">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucVuSanXuatNhatKyService.DisplayColumnsMobile">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="NotificationService.IsMobile"
                                                    #ToChucVuSanXuatNhatKyIsMuaVoPaginator="matPaginator"
                                                    [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="10000"
                                                    [showFirstLastButtons]></mat-paginator>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>