<div class="app-body">
    <div class="row">
        <div class="col-xxl-12">
            <div class="card" style="margin:auto;max-width: 500px;">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <div class="custom-tabs-container">
                            <div class="tab-content" id="customTabContent">
                                <div class="tab-pane fade show active" id="ThanhVienDetail" role="tabpanel">
                                    <div class="row gx-4">
                                        <div class="col-lg-12 col-sm-12 col-12" style="text-align: center;">
                                            <img width="100" height="100" src="assets/image/logo.png"
                                                title="Cổng xác thực để truy cập hệ sinh thái số"
                                                alt="Cổng xác thực để truy cập hệ sinh thái số">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12" style="text-align: center;">
                                            <label class="form-label">Cổng xác thực truy cập</label>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <input name="TaiKhoan" [(ngModel)]="ThanhVienService.formData.TaiKhoan"
                                                    placeholder="Tài khoản" type="text" class="form-control">
                                            </div>
                                            <div class="col-lg-12 col-sm-12 col-12">
                                                <input name="MatKhau" [(ngModel)]="ThanhVienService.formData.MatKhau"
                                                    placeholder="Mật khẩu" type="password" class="form-control" (keyup.enter)="Submit()">
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <button type="submit" class="btn btn-info" style="width: 100%;"
                                                (click)="Submit()"
                                                [disabled]="!ThanhVienService.formData.TaiKhoan || !ThanhVienService.formData.MatKhau">Đăng
                                                nhập</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="ThanhVienService.IsShowLoading"></app-loading>