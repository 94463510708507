<div class="custom-tabs-container">
    <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link active" id="tab-ToChucVuSanXuatDetail" data-bs-toggle="tab" href="#ToChucVuSanXuatDetail"
                role="tab" aria-controls="ToChucVuSanXuatDetail" aria-selected="true">
                <span class="badge bg-info" style="font-size: 16px;">1. Vụ mùa</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a style="cursor: pointer;" class="nav-link" id="tab-Close" data-bs-toggle="tab" (click)="Close()"
                role="tab" aria-controls="Close" aria-selected="false">
                <span class="badge bg-danger" style="font-size: 16px;"><i class="bi bi-x-lg"></i></span>
            </a>
        </li>
    </ul>
    <div class="tab-content" id="customTabContent">
        <div class="tab-pane fade show active" id="ToChucVuSanXuatDetail" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-4 col-sm-12 col-12">                                       
                    <button [disabled]="ToChucVuSanXuatService.formData.IsChungThuc" class="btn btn-info"
                        style="width: 100%; height: 41px;"
                        (click)="ToChucVuSanXuatSave()"><i class="bi bi-sd-card"></i> Lưu thay đổi
                    </button>
                </div>
                <div class="col-lg-2 col-sm-12 col-12">
                    <div class="form-check form-check-inline mb-6">
                        <input type="checkbox" class="form-check-input" name="Active"
                            [(ngModel)]="ToChucVuSanXuatService.formData.Active" />
                        <label class="form-check-label" for="Active">Kích hoạt</label>
                    </div>
                </div>
                <div class="col-lg-2 col-sm-12 col-12">
                    <div class="form-check form-check-inline mb-6">
                        <input type="checkbox" class="form-check-input" name="IsChungThuc" disabled
                            [(ngModel)]="ToChucVuSanXuatService.formData.IsChungThuc" />
                        <label class="form-check-label" for="IsChungThuc">Đã chứng thực</label>
                    </div>
                </div>
            </div>
            <div class="row gx-4">
                <div class="col-lg-4 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Hợp tác xã</label>
                        <mat-select class="form-control" name="ParentID" disabled
                            [(ngModel)]="ToChucVuSanXuatService.formData.ParentID">
                            <mat-option *ngFor="let item of ToChucService.list" [value]="item.ID">
                                {{item.Name}} - {{item.DanhMucTinhThanhName}}
                            </mat-option>
                        </mat-select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Vụ mùa</label>
                        <mat-select class="form-control" name="DanhMucVuMuaID"
                            [(ngModel)]="ToChucVuSanXuatService.formData.DanhMucVuMuaID">
                            <mat-option *ngFor="let item of DanhMucVuMuaService.list001"
                                [value]="item.ID">
                                {{item.Name}}
                            </mat-option>
                        </mat-select>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Mã vụ</label>
                        <input name="Code" [(ngModel)]="ToChucVuSanXuatService.formData.Code"
                            placeholder="Mã vụ" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Quy trình</label>
                        <input name="TieuChuan"
                            [(ngModel)]="ToChucVuSanXuatService.formData.TieuChuan"
                            placeholder="Quy trình" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Mã số vùng trồng</label>
                        <input name="MaSoVungTrong"
                            [(ngModel)]="ToChucVuSanXuatService.formData.MaSoVungTrong"
                            placeholder="Mã số vùng trồng" type="text" class="form-control">
                    </div>
                </div>
                <div class="col-lg-4 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Giống</label>
                        <input name="DanhMucNguyenVatLieuName"
                            [(ngModel)]="ToChucVuSanXuatService.formData.DanhMucNguyenVatLieuName"
                            placeholder="Giống" type="text" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Số lượng giống (kg)</label>
                        <input name="SoLuong" [(ngModel)]="ToChucVuSanXuatService.formData.SoLuong"
                            placeholder="0" type="number" style="text-align: right;"
                            class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Mật độ (giống/m2)</label>
                        <input name="MatDo" [(ngModel)]="ToChucVuSanXuatService.formData.MatDo"
                            placeholder="0" type="number" style="text-align: right;"
                            class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Chiều sâu (cm)</label>
                        <input name="ChieuSau"
                            [(ngModel)]="ToChucVuSanXuatService.formData.ChieuSau" placeholder="0"
                            type="number" style="text-align: right;" class="form-control">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Sản lượng thu hoạch (tấn)</label>
                        <input name="SanLuong"
                            [(ngModel)]="ToChucVuSanXuatService.formData.SanLuong" placeholder="0"
                            type="number" style="text-align: right;" class="form-control">
                    </div>
                </div>
                <div class="col-lg-4 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Bắt đầu</label>
                        <input
                            [ngModel]="ToChucVuSanXuatService.formData.BatDau | date:'yyyy-MM-dd'"
                            (ngModelChange)="DateBatDau($event)"
                            [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control"
                            style="text-align: right;" autocomplete="off">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Sản xuất (Gieo sạ)</label>
                        <input
                            [ngModel]="ToChucVuSanXuatService.formData.NgaySanXuat | date:'yyyy-MM-dd'"
                            (ngModelChange)="DateNgaySanXuat($event)"
                            [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control"
                            style="text-align: right;" autocomplete="off">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Thu hoạch</label>
                        <input
                            [ngModel]="ToChucVuSanXuatService.formData.NgayThuHoach | date:'yyyy-MM-dd'"
                            (ngModelChange)="DateNgayThuHoach($event)"
                            [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control"
                            style="text-align: right;" autocomplete="off">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Kết thúc</label>
                        <input
                            [ngModel]="ToChucVuSanXuatService.formData.KetThuc | date:'yyyy-MM-dd'"
                            (ngModelChange)="DateKetThuc($event)"
                            [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control"
                            style="text-align: right;" autocomplete="off">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ghi chú</label>
                        <input name="Note" [(ngModel)]="ToChucVuSanXuatService.formData.Note"
                            placeholder="Ghi chú" type="text" class="form-control">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>
<br/>
<br/>
<br/>