<div class="app-body">
    <div class="row">
        <div class="col-xxl-12">
            <div class="card" style="margin-left: 1px">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-ToChuc" data-bs-toggle="tab" href="#ToChuc"
                                    role="tab" aria-controls="ToChuc" aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px">2. Thương lái</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="ToChuc" role="tabpanel">
                                <h2 class="text-center">01: Pháp lý</h2>
                                <div class="row gx-4">
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <a style="width: 100%;" title="Lưu thay đổi" (click)="ToChucSave()"
                                            class="btn btn-info"><i class="bi bi-sd-card"></i> Lưu thay đổi</a>
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <div class="form-check form-check-inline mb-6">
                                            <input type="checkbox" class="form-check-input" name="Active" disabled
                                                [(ngModel)]="ToChucService.formData.Active" />
                                            <label class="form-check-label" for="Active">Kích hoạt</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row gx-4">
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Tiêu đề</label>
                                            <input name="Name" [(ngModel)]="ToChucService.formData.Name"
                                                placeholder="Tiêu đề" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Mã số thuế/Mã Số Doanh Nghiệp</label>
                                            <input name="Code" [(ngModel)]="ToChucService.formData.Code"
                                                placeholder="Mã số thuế/Mã Số Doanh Nghiệp" type="text"
                                                class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Điện thoại</label>
                                            <input name="DienThoai" [(ngModel)]="ToChucService.formData.DienThoai"
                                                placeholder="Điện thoại" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Email</label>
                                            <input name="Email" [(ngModel)]="ToChucService.formData.Email"
                                                placeholder="Email" type="text" class="form-control">
                                        </div>                                        
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Loại hình</label>
                                            <input name="Display" [(ngModel)]="ToChucService.formData.Display"
                                                placeholder="Loại hình" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Ngày hoạt động</label>
                                            <input [ngModel]="ToChucService.formData.NgayHoatDong | date:'yyyy-MM-dd'"
                                                (ngModelChange)="DateNgayHoatDong($event)"
                                                [ngModelOptions]="{ updateOn: 'blur' }" type="date" class="form-control"
                                                style="text-align: right;" autocomplete="off">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Khu vực hoạt động</label>
                                            <input name="Description" [(ngModel)]="ToChucService.formData.Description"
                                                placeholder="Khu vực hoạt động" type="text" class="form-control">
                                        </div>                                    
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Năng lực Thu mua (tấn/năm)</label>
                                            <input name="NangLucThuMua"
                                                [(ngModel)]="ToChucService.formData.NangLucThuMua" placeholder="0"
                                                type="number" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Năng lực Tài chính (đồng)</label>
                                            <input name="NangLucTaiChinh"
                                                [(ngModel)]="ToChucService.formData.NangLucTaiChinh" placeholder="0"
                                                type="number" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Doanh thu (đồng)</label>
                                            <input name="DoanhThu" [(ngModel)]="ToChucService.formData.DoanhThu"
                                                placeholder="0" type="number" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Người liên hệ</label>
                                            <input name="LienHeHoTen" [(ngModel)]="ToChucService.formData.LienHeHoTen"
                                                placeholder="Người liên hệ" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Điện thoại</label>
                                            <input name="LienHeDienThoai"
                                                [(ngModel)]="ToChucService.formData.LienHeDienThoai"
                                                placeholder="Điện thoại" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Email</label>
                                            <input name="LienHeEmail" [(ngModel)]="ToChucService.formData.LienHeEmail"
                                                placeholder="Email" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">CCCD</label>
                                            <input name="LienHeCCCD" [(ngModel)]="ToChucService.formData.LienHeCCCD"
                                                placeholder="CCCD" type="text" class="form-control">
                                        </div>                                       
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Tỉnh thành</label>
                                            <mat-select class="form-control" name="DanhMucTinhThanhID"
                                                (selectionChange)="DanhMucQuanHuyenGetToList()"
                                                [(ngModel)]="ToChucService.formData.DanhMucTinhThanhID">
                                                <input class="form-control"
                                                    (keyup)="DanhMucTinhThanhFilter($event.target.value)">
                                                <mat-option *ngFor="let item of DanhMucTinhThanhService.list001"
                                                    [value]="item.ID">
                                                    {{item.Name}}
                                                </mat-option>
                                            </mat-select>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Quận huyện</label>
                                            <mat-select class="form-control" name="DanhMucQuanHuyenID"
                                                (selectionChange)="DanhMucXaPhuongGetToList()"
                                                [(ngModel)]="ToChucService.formData.DanhMucQuanHuyenID">
                                                <input class="form-control"
                                                    (keyup)="DanhMucQuanHuyenFilter($event.target.value)">
                                                <mat-option *ngFor="let item of DanhMucQuanHuyenService.list001"
                                                    [value]="item.ID">
                                                    {{item.Name}}
                                                </mat-option>
                                            </mat-select>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Xã phường</label>
                                            <mat-select class="form-control" name="DanhMucXaPhuongID"
                                                [(ngModel)]="ToChucService.formData.DanhMucXaPhuongID">
                                                <input class="form-control"
                                                    (keyup)="DanhMucXaPhuongFilter($event.target.value)">
                                                <mat-option *ngFor="let item of DanhMucXaPhuongService.list001"
                                                    [value]="item.ID">
                                                    {{item.Name}}
                                                </mat-option>
                                            </mat-select>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Ấp thôn</label>
                                            <input name="ApThon" [(ngModel)]="ToChucService.formData.ApThon"
                                                placeholder="Ấp thôn" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Địa chỉ</label>
                                            <input name="DiaChi" [(ngModel)]="ToChucService.formData.DiaChi"
                                                placeholder="Địa chỉ" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Kinh độ</label>
                                            <input name="KinhDo" [(ngModel)]="ToChucService.formData.KinhDo"
                                                placeholder="Kinh độ" type="text" class="form-control">
                                        </div>
                                        <div class="col-lg-12 col-sm-12 col-12">
                                            <label class="form-label">Vĩ độ</label>
                                            <input name="ViDo" [(ngModel)]="ToChucService.formData.ViDo"
                                                placeholder="Vĩ độ" type="text" class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <h2 class="text-center">02: Kho hàng</h2>
                                <div class="row gx-4">
                                    <div class="col-lg-11 col-sm-12 col-12">
                                        <input [(ngModel)]="ToChucKhoHangService.baseParameter.SearchString"
                                            placeholder="Tìm..." class="form-control"
                                            (keyup.enter)="ToChucKhoHangSearch()">
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <a style="width: 100%;" class="btn btn-success" title="Tìm"
                                            (click)="ToChucKhoHangSearch()"><i
                                                class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;

                                    </div>
                                </div>
                                <div class="card mb-4 card-danger">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Kho hàng
                                            <span
                                                *ngIf="ToChucKhoHangService.list">({{ToChucKhoHangService.list.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                                    #ToChucKhoHangSort="matSort"
                                                    [dataSource]="ToChucKhoHangService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{ToChucKhoHangPaginator.pageSize *
                                                            ToChucKhoHangPaginator.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Kho hàng
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Kho hàng" name="Name{{element.ID}}"
                                                                [(ngModel)]="element.Name">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Code">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Mã kho
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text" placeholder="Mã kho"
                                                                name="Code{{element.ID}}" [(ngModel)]="element.Code">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DienTich">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Diện tích
                                                            (m2)
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="number"
                                                                style="text-align: right;" placeholder="0"
                                                                name="DienTich{{element.ID}}"
                                                                [(ngModel)]="element.DienTich">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="CongNgheBaoQuan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Công nghệ
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Công nghệ bảo quản"
                                                                name="CongNgheBaoQuan{{element.ID}}"
                                                                [(ngModel)]="element.CongNgheBaoQuan">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="TieuChuan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu chuẩn
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Tiêu chuẩn" name="TieuChuan{{element.ID}}"
                                                                [(ngModel)]="element.TieuChuan">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="DiaChi">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Địa chỉ
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Địa chỉ" name="TieuChuan{{element.ID}}"
                                                                [(ngModel)]="element.DiaChi">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="KinhDo">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Kinh độ
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Kinh độ" name="KinhDo{{element.ID}}"
                                                                [(ngModel)]="element.KinhDo">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ViDo">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Vĩ độ
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text" placeholder="Vĩ độ"
                                                                name="ViDo{{element.ID}}" [(ngModel)]="element.ViDo">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info"
                                                                (click)="ToChucKhoHangSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger"
                                                                (click)="ToChucKhoHangDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucKhoHangService.displayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucKhoHangService.displayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="!NotificationService.IsMobile"
                                                    #ToChucKhoHangPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                                    #ToChucKhoHangSort="matSort"
                                                    [dataSource]="ToChucKhoHangService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Danh sách
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Kho hàng</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Kho hàng" name="Name{{element.ID}}"
                                                                        [(ngModel)]="element.Name">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Mã kho</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Mã kho" name="Code{{element.ID}}"
                                                                        [(ngModel)]="element.Code">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Diện tích (m2)</label>
                                                                    <input class="form-control" type="number"
                                                                        style="text-align: right;" placeholder="0"
                                                                        name="DienTich{{element.ID}}"
                                                                        [(ngModel)]="element.DienTich">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Công nghệ</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Công nghệ bảo quản"
                                                                        name="CongNgheBaoQuan{{element.ID}}"
                                                                        [(ngModel)]="element.CongNgheBaoQuan">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Tiêu chuẩn</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Tiêu chuẩn"
                                                                        name="TieuChuan{{element.ID}}"
                                                                        [(ngModel)]="element.TieuChuan">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Địa chỉ</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Địa chỉ"
                                                                        name="TieuChuan{{element.ID}}"
                                                                        [(ngModel)]="element.DiaChi">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Kinh độ</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Kinh độ"
                                                                        name="KinhDo{{element.ID}}"
                                                                        [(ngModel)]="element.KinhDo">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Vĩ độ</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Vĩ độ" name="ViDo{{element.ID}}"
                                                                        [(ngModel)]="element.ViDo">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <a class="btn btn-info" style="width: 100%;"
                                                                        (click)="ToChucKhoHangSave(element)"><i
                                                                            class="bi bi-sd-card"></i>
                                                                    </a>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <a class="btn btn-danger" style="width: 100%;"
                                                                        (click)="ToChucKhoHangDelete(element)"
                                                                        *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucKhoHangService.DisplayColumnsMobile">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucKhoHangService.DisplayColumnsMobile">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="NotificationService.IsMobile"
                                                    #ToChucKhoHangPaginator="matPaginator"
                                                    [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="10000"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h2 class="text-center">03: Phương tiện</h2>
                                <div class="row gx-4">
                                    <div class="col-lg-11 col-sm-12 col-12">
                                        <input [(ngModel)]="ToChucPhuongTienService.baseParameter.SearchString"
                                            placeholder="Tìm..." class="form-control"
                                            (keyup.enter)="ToChucPhuongTienSearch()">
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <a style="width: 100%;" class="btn btn-success" title="Tìm"
                                            (click)="ToChucPhuongTienSearch()"><i
                                                class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;

                                    </div>
                                </div>
                                <div class="card mb-4 card-success">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Phương tiện
                                            <span
                                                *ngIf="ToChucPhuongTienService.list">({{ToChucPhuongTienService.list.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                                    #ToChucPhuongTienSort="matSort"
                                                    [dataSource]="ToChucPhuongTienService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{ToChucPhuongTienPaginator.pageSize *
                                                            ToChucPhuongTienPaginator.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Phương
                                                            tiện
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Phương tiện" name="Name{{element.ID}}"
                                                                [(ngModel)]="element.Name">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Code">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Giấy phép
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Giấy phép" name="Code{{element.ID}}"
                                                                [(ngModel)]="element.Code">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SoHieuPhuongTien">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Số hiệu
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Số hiệu"
                                                                name="SoHieuPhuongTien{{element.ID}}"
                                                                [(ngModel)]="element.SoHieuPhuongTien">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="CongSuat">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Công suất
                                                            (tấn)</th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="number"
                                                                style="text-align: right;" placeholder="0"
                                                                name="CongSuat{{element.ID}}"
                                                                [(ngModel)]="element.CongSuat">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info"
                                                                (click)="ToChucPhuongTienSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger"
                                                                (click)="ToChucPhuongTienDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucPhuongTienService.displayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucPhuongTienService.displayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="!NotificationService.IsMobile"
                                                    #ToChucPhuongTienPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                                    #ToChucPhuongTienSort="matSort"
                                                    [dataSource]="ToChucPhuongTienService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Danh sách
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Phương tiện</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Phương tiện"
                                                                        name="Name{{element.ID}}"
                                                                        [(ngModel)]="element.Name">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Giấy phép</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Giấy phép"
                                                                        name="Code{{element.ID}}"
                                                                        [(ngModel)]="element.Code">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Số hiệu</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Số hiệu"
                                                                        name="SoHieuPhuongTien{{element.ID}}"
                                                                        [(ngModel)]="element.SoHieuPhuongTien">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Công suất</label>
                                                                    <input class="form-control" type="number"
                                                                        style="text-align: right;" placeholder="0"
                                                                        name="CongSuat{{element.ID}}"
                                                                        [(ngModel)]="element.CongSuat">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <a class="btn btn-info" style="width: 100%;"
                                                                        (click)="ToChucPhuongTienSave(element)"><i
                                                                            class="bi bi-sd-card"></i>
                                                                    </a>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <a class="btn btn-danger" style="width: 100%;"
                                                                        (click)="ToChucPhuongTienDelete(element)"
                                                                        *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucPhuongTienService.DisplayColumnsMobile">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucPhuongTienService.DisplayColumnsMobile">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="NotificationService.IsMobile"
                                                    #ToChucPhuongTienPaginator="matPaginator"
                                                    [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="10000"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h2 class="text-center">04: Thu mua</h2>
                                <div class="row gx-4">
                                    <div class="col-lg-11 col-sm-12 col-12">
                                        <input [(ngModel)]="ToChucHopTacService.baseParameter.SearchString"
                                            placeholder="Tìm..." class="form-control"
                                            (keyup.enter)="ToChucHopTacSearch()">
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <a style="width: 100%;" class="btn btn-success" title="Tìm"
                                            (click)="ToChucHopTacSearch()"><i
                                                class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;

                                    </div>
                                </div>
                                <div class="card mb-4 card-warning">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Thu mua
                                            <span *ngIf="ToChucHopTacService.list">({{ToChucHopTacService.list.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                                    #ToChucHopTacSort="matSort"
                                                    [dataSource]="ToChucHopTacService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{ToChucHopTacPaginator.pageSize *
                                                            ToChucHopTacPaginator.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Display">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hình thức
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Hình thức" name="Display{{element.ID}}"
                                                                [(ngModel)]="element.Display">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Đối tác
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Đối tác" name="Name{{element.ID}}"
                                                                [(ngModel)]="element.Name">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Code">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Điện thoại
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Điện thoại" name="Code{{element.ID}}"
                                                                [(ngModel)]="element.Code">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NgayGhiNhan">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi nhận
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input [ngModel]="element.NgayGhiNhan | date:'yyyy-MM-dd'"
                                                                (ngModelChange)="DateNgayGhiNhan(element, $event)"
                                                                [ngModelOptions]="{ updateOn: 'blur' }" type="date"
                                                                class="form-control" style="text-align: right;"
                                                                autocomplete="off">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info"
                                                                (click)="ToChucHopTacSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger"
                                                                (click)="ToChucHopTacDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucHopTacService.displayColumns003">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucHopTacService.displayColumns003;">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="!NotificationService.IsMobile"
                                                    #ToChucHopTacPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                                    #ToChucHopTacSort="matSort"
                                                    [dataSource]="ToChucHopTacService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Danh sách
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Hình thức</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Hình thức"
                                                                        name="Display{{element.ID}}"
                                                                        [(ngModel)]="element.Display">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Đối tác</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Đối tác" name="Name{{element.ID}}"
                                                                        [(ngModel)]="element.Name">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Điện thoại</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Điện thoại"
                                                                        name="Code{{element.ID}}"
                                                                        [(ngModel)]="element.Code">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Ghi nhận</label>
                                                                    <input
                                                                        [ngModel]="element.NgayGhiNhan | date:'yyyy-MM-dd'"
                                                                        (ngModelChange)="DateNgayGhiNhan(element, $event)"
                                                                        [ngModelOptions]="{ updateOn: 'blur' }"
                                                                        type="date" class="form-control"
                                                                        style="text-align: right;" autocomplete="off">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <a class="btn btn-info" style="width: 100%;"
                                                                        (click)="ToChucHopTacSave(element)"><i
                                                                            class="bi bi-sd-card"></i>
                                                                    </a>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <a class="btn btn-danger" style="width: 100%;"
                                                                        (click)="ToChucHopTacDelete(element)"
                                                                        *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucHopTacService.DisplayColumnsMobile">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucHopTacService.DisplayColumnsMobile">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="NotificationService.IsMobile"
                                                    #ToChucHopTacPaginator="matPaginator"
                                                    [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="10000"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h2 class="text-center">05: Tài liệu</h2>
                                <div class="row gx-4">
                                    <div class="col-lg-11 col-sm-12 col-12">
                                        <input [(ngModel)]="ToChucTapTinDinhKemService.baseParameter.SearchString"
                                            placeholder="Tìm..." class="form-control"
                                            (keyup.enter)="ToChucTapTinDinhKemSearch()">
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <a style="width: 100%;" class="btn btn-success" title="Tìm"
                                            (click)="ToChucTapTinDinhKemSearch()"><i
                                                class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;

                                    </div>
                                </div>
                                <div class="card mb-4 card-primary">
                                    <div class="card-header">
                                        <h5 class="card-title text-white bg-primary">Tài liệu
                                            <span
                                                *ngIf="ToChucTapTinDinhKemService.list">({{ToChucTapTinDinhKemService.list.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body bg-primary">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                                    #ToChucTapTinDinhKemSort="matSort"
                                                    [dataSource]="ToChucTapTinDinhKemService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{ToChucTapTinDinhKemPaginator.pageSize *
                                                            ToChucTapTinDinhKemPaginator.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tài liệu
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Tài liệu" name="Name{{element.ID}}"
                                                                [(ngModel)]="element.Name">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Code">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tải lên
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input *ngIf="element.ID == 0" type="file" multiple
                                                                (change)="ChangeFileName($event.target.files)">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info"
                                                                (click)="ToChucTapTinDinhKemSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger"
                                                                (click)="ToChucTapTinDinhKemDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                            &nbsp;&nbsp;&nbsp;
                                                            <a *ngIf="element.ID > 0 && element.FileName"
                                                                class="btn btn-success" title="Tải về"
                                                                href="{{element.FileName}}" target="_blank"><i
                                                                    class="bi bi-cloud-download"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucTapTinDinhKemService.displayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucTapTinDinhKemService.displayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="!NotificationService.IsMobile" #ToChucTapTinDinhKemPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                                    #ToChucTapTinDinhKemSort="matSort"
                                                    [dataSource]="ToChucTapTinDinhKemService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Danh sách
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Tài liệu</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Tài liệu" name="Name{{element.ID}}"
                                                                        [(ngModel)]="element.Name">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Tải lên</label>
                                                                    <br />
                                                                    <input *ngIf="element.ID == 0" type="file" multiple
                                                                        style="width: 200px;"
                                                                        (change)="ChangeFileName($event.target.files)">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <a class="btn btn-info" style="width: 100%;"
                                                                        (click)="ToChucHopTacSave(element)"><i
                                                                            class="bi bi-sd-card"></i>
                                                                    </a>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <a class="btn btn-danger" style="width: 100%;"
                                                                        (click)="ToChucHopTacDelete(element)"
                                                                        *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                                    </a>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <a *ngIf="element.ID > 0 && element.FileName"
                                                                        class="btn btn-success" title="Tải về"
                                                                        style="width: 100%;" href="{{element.FileName}}"
                                                                        target="_blank"><i
                                                                            class="bi bi-cloud-download"></i>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucTapTinDinhKemService.DisplayColumnsMobile">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucTapTinDinhKemService.DisplayColumnsMobile">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="NotificationService.IsMobile"
                                                    #ToChucTapTinDinhKemPaginator="matPaginator"
                                                    [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="10000"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>