import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { DanhMucTinhThanh } from 'src/app/shared/DanhMucTinhThanh.model';
import { DanhMucTinhThanhService } from 'src/app/shared/DanhMucTinhThanh.service';
import { DanhMucQuanHuyen } from 'src/app/shared/DanhMucQuanHuyen.model';
import { DanhMucQuanHuyenService } from 'src/app/shared/DanhMucQuanHuyen.service';
import { DanhMucXaPhuong } from 'src/app/shared/DanhMucXaPhuong.model';
import { DanhMucXaPhuongService } from 'src/app/shared/DanhMucXaPhuong.service';

import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';

import { ToChuc } from 'src/app/shared/ToChuc.model';
import { ToChucService } from 'src/app/shared/ToChuc.service';
import { ToChucHopTac } from 'src/app/shared/ToChucHopTac.model';
import { ToChucHopTacService } from 'src/app/shared/ToChucHopTac.service';
import { ToChucKhoHang } from 'src/app/shared/ToChucKhoHang.model';
import { ToChucKhoHangService } from 'src/app/shared/ToChucKhoHang.service';
import { ToChucPhuongTien } from 'src/app/shared/ToChucPhuongTien.model';
import { ToChucPhuongTienService } from 'src/app/shared/ToChucPhuongTien.service';
import { ToChucTapTinDinhKem } from 'src/app/shared/ToChucTapTinDinhKem.model';
import { ToChucTapTinDinhKemService } from 'src/app/shared/ToChucTapTinDinhKem.service';
import { ToChucSanPham } from 'src/app/shared/ToChucSanPham.model';
import { ToChucSanPhamService } from 'src/app/shared/ToChucSanPham.service';

@Component({
  selector: 'app-thanh-vien-to-chuc-don-vi-cung-cap-vat-tu',
  templateUrl: './thanh-vien-to-chuc-don-vi-cung-cap-vat-tu.component.html',
  styleUrls: ['./thanh-vien-to-chuc-don-vi-cung-cap-vat-tu.component.css']
})
export class ThanhVienToChucDonViCungCapVatTuComponent implements OnInit {

  @ViewChild('ToChucKhoHangSort') ToChucKhoHangSort: MatSort;
  @ViewChild('ToChucKhoHangPaginator') ToChucKhoHangPaginator: MatPaginator;

  @ViewChild('ToChucHopTacSort') ToChucHopTacSort: MatSort;
  @ViewChild('ToChucHopTacPaginator') ToChucHopTacPaginator: MatPaginator;

  @ViewChild('ToChucPhuongTienSort') ToChucPhuongTienSort: MatSort;
  @ViewChild('ToChucPhuongTienPaginator') ToChucPhuongTienPaginator: MatPaginator;

  @ViewChild('ToChucTapTinDinhKemSort') ToChucTapTinDinhKemSort: MatSort;
  @ViewChild('ToChucTapTinDinhKemPaginator') ToChucTapTinDinhKemPaginator: MatPaginator;

  @ViewChild('ToChucSanPhamSort') ToChucSanPhamSort: MatSort;
  @ViewChild('ToChucSanPhamPaginator') ToChucSanPhamPaginator: MatPaginator;

  isShowLoading: boolean = false;

  constructor(
    private dialog: MatDialog,

    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public DanhMucTinhThanhService: DanhMucTinhThanhService,
    public DanhMucQuanHuyenService: DanhMucQuanHuyenService,
    public DanhMucXaPhuongService: DanhMucXaPhuongService,

    public ThanhVienService: ThanhVienService,

    public ToChucService: ToChucService,
    public ToChucHopTacService: ToChucHopTacService,
    public ToChucKhoHangService: ToChucKhoHangService,
    public ToChucPhuongTienService: ToChucPhuongTienService,
    public ToChucTapTinDinhKemService: ToChucTapTinDinhKemService,
    public ToChucSanPhamService: ToChucSanPhamService,

  ) { }

  ngOnInit(): void {
   this.ThanhVienSearch();
   this.ToChucSearch();
   this.DanhMucTinhThanhGetToList();
  }
  DateNgayHoatDong(value) {
    this.ToChucService.formData.NgayHoatDong = new Date(value);
  }
  DateNgayGhiNhan(element: ToChucHopTac, value) {
    element.NgayGhiNhan = new Date(value);
  }
  ThanhVienSearch() {
    this.ThanhVienService.GetLogin();
  }
  ThanhVienSave() {
    this.isShowLoading = true;
    this.ThanhVienService.baseParameter.ID = this.ThanhVienService.formDataLogin.ID;
    this.ThanhVienService.GetByIDAsync().subscribe(
      res => {
        this.ThanhVienService.formData = res as ThanhVien;
        this.ThanhVienService.formData.ToChucID = this.ToChucService.formData.ID;
        this.ThanhVienService.SaveAsync().subscribe(
          res => {
            this.ThanhVienService.formData = res as ThanhVien;
          },
          err => {
          }
        );
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  ToChucSearch() {
    this.isShowLoading = true;
    this.ToChucService.baseParameter.ID = this.ThanhVienService.formDataLogin.ToChucID;
    this.ToChucService.GetByIDAsync().subscribe(
      res => {
        this.ToChucService.formData = res as ToChuc;        
        this.isShowLoading = false;
      },
      err => {        
        this.isShowLoading = false;
      }
    );
  }
  ToChucSave() {
    this.isShowLoading = true;
    this.ToChucService.formData.ParentID = environment.DanhMucToChucIDDonViCungCapVatTuNongNghiep;
    this.ToChucService.SaveAsync().subscribe(
      res => {
        this.ToChucService.formData = res as ToChuc;
        this.ThanhVienSave();
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }

  ToChucKhoHangSearch() {
    if (this.ToChucKhoHangService.baseParameter.SearchString.length > 0) {
      this.ToChucKhoHangService.DataSource.filter = this.ToChucKhoHangService.baseParameter.SearchString.toLowerCase();
    }
    else {
      this.isShowLoading = true;
      this.ToChucKhoHangService.baseParameter.ParentID = this.ToChucService.formData.ID;
      this.ToChucKhoHangService.GetByParentIDAndEmptyToListAsync().subscribe(
        res => {
          this.ToChucKhoHangService.list = (res as ToChucKhoHang[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
          this.ToChucKhoHangService.DataSource = new MatTableDataSource(this.ToChucKhoHangService.list);
          this.ToChucKhoHangService.DataSource.sort = this.ToChucKhoHangSort;
          this.ToChucKhoHangService.DataSource.paginator = this.ToChucKhoHangPaginator;
          this.isShowLoading = false;
        },
        err => {
          this.isShowLoading = false;
        }
      );
    }
  }
  ToChucKhoHangSave(element: ToChucKhoHang) {
    element.ParentID = this.ToChucService.formData.ID;
    this.ToChucKhoHangService.formData = element;
    this.NotificationService.warn(this.ToChucKhoHangService.ComponentSaveByParentID(this.ToChucKhoHangSort, this.ToChucKhoHangPaginator));
  }
  ToChucKhoHangDelete(element: ToChucKhoHang) {
    this.ToChucKhoHangService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.ToChucKhoHangService.ComponentDeleteByParentID(this.ToChucKhoHangSort, this.ToChucKhoHangPaginator));
  }

  ToChucPhuongTienSearch() {
    if (this.ToChucPhuongTienService.baseParameter.SearchString.length > 0) {
      this.ToChucPhuongTienService.DataSource.filter = this.ToChucPhuongTienService.baseParameter.SearchString.toLowerCase();
    }
    else {
      this.isShowLoading = true;
      this.ToChucPhuongTienService.baseParameter.ParentID = this.ToChucService.formData.ID;
      this.ToChucPhuongTienService.GetByParentIDAndEmptyToListAsync().subscribe(
        res => {
          this.ToChucPhuongTienService.list = (res as ToChucPhuongTien[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
          this.ToChucPhuongTienService.DataSource = new MatTableDataSource(this.ToChucPhuongTienService.list);
          this.ToChucPhuongTienService.DataSource.sort = this.ToChucPhuongTienSort;
          this.ToChucPhuongTienService.DataSource.paginator = this.ToChucPhuongTienPaginator;
          this.isShowLoading = false;
        },
        err => {
          this.isShowLoading = false;
        }
      );
    }
  }
  ToChucPhuongTienSave(element: ToChucPhuongTien) {
    element.ParentID = this.ToChucService.formData.ID;
    this.ToChucPhuongTienService.formData = element;
    this.NotificationService.warn(this.ToChucPhuongTienService.ComponentSaveByParentID(this.ToChucPhuongTienSort, this.ToChucPhuongTienPaginator));
  }
  ToChucPhuongTienDelete(element: ToChucPhuongTien) {
    this.ToChucPhuongTienService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.ToChucPhuongTienService.ComponentDeleteByParentID(this.ToChucPhuongTienSort, this.ToChucPhuongTienPaginator));
  }

  ToChucHopTacSearch() {
    if (this.ToChucHopTacService.baseParameter.SearchString.length > 0) {
      this.ToChucHopTacService.DataSource.filter = this.ToChucHopTacService.baseParameter.SearchString.toLowerCase();
    }
    else {
      this.isShowLoading = true;
      this.ToChucHopTacService.baseParameter.ParentID = this.ToChucService.formData.ID;
      this.ToChucHopTacService.GetByParentIDAndEmptyToListAsync().subscribe(
        res => {
          this.ToChucHopTacService.list = (res as ToChucHopTac[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
          this.ToChucHopTacService.DataSource = new MatTableDataSource(this.ToChucHopTacService.list);
          this.ToChucHopTacService.DataSource.sort = this.ToChucHopTacSort;
          this.ToChucHopTacService.DataSource.paginator = this.ToChucHopTacPaginator;
          this.isShowLoading = false;
        },
        err => {
          this.isShowLoading = false;
        }
      );
    }
  }
  ToChucHopTacSave(element: ToChucHopTac) {
    element.ParentID = this.ToChucService.formData.ID;
    this.ToChucHopTacService.formData = element;
    this.NotificationService.warn(this.ToChucHopTacService.ComponentSaveByParentID(this.ToChucHopTacSort, this.ToChucHopTacPaginator));
  }
  ToChucHopTacDelete(element: ToChucHopTac) {
    this.ToChucHopTacService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.ToChucHopTacService.ComponentDeleteByParentID(this.ToChucHopTacSort, this.ToChucHopTacPaginator));
  }

  ToChucSanPhamSearch() {
    if (this.ToChucSanPhamService.baseParameter.SearchString.length > 0) {
      this.ToChucSanPhamService.DataSource.filter = this.ToChucSanPhamService.baseParameter.SearchString.toLowerCase();
    }
    else {
      this.isShowLoading = true;
      this.ToChucSanPhamService.baseParameter.ParentID = this.ToChucService.formData.ID;
      this.ToChucSanPhamService.GetByParentIDAndEmptyToListAsync().subscribe(
        res => {
          this.ToChucSanPhamService.list = (res as ToChucSanPham[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
          this.ToChucSanPhamService.DataSource = new MatTableDataSource(this.ToChucSanPhamService.list);
          this.ToChucSanPhamService.DataSource.sort = this.ToChucSanPhamSort;
          this.ToChucSanPhamService.DataSource.paginator = this.ToChucSanPhamPaginator;
          this.isShowLoading = false;
        },
        err => {
          this.isShowLoading = false;
        }
      );
    }
  }
  ToChucSanPhamSave(element: ToChucSanPham) {
    element.ParentID = this.ToChucService.formData.ID;
    this.ToChucSanPhamService.formData = element;
    this.NotificationService.warn(this.ToChucSanPhamService.ComponentSaveByParentID(this.ToChucSanPhamSort, this.ToChucSanPhamPaginator));
  }
  ToChucSanPhamDelete(element: ToChucSanPham) {
    this.ToChucSanPhamService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.ToChucSanPhamService.ComponentDeleteByParentID(this.ToChucSanPhamSort, this.ToChucSanPhamPaginator));
  }

  ToChucTapTinDinhKemSearch() {
    if (this.ToChucTapTinDinhKemService.baseParameter.SearchString.length > 0) {
      this.ToChucTapTinDinhKemService.DataSource.filter = this.ToChucTapTinDinhKemService.baseParameter.SearchString.toLowerCase();
    }
    else {
      this.isShowLoading = true;
      this.ToChucTapTinDinhKemService.baseParameter.ParentID = this.ToChucService.formData.ID;
      this.ToChucTapTinDinhKemService.GetByParentIDAndEmptyToListAsync().subscribe(
        res => {
          this.ToChucTapTinDinhKemService.list = (res as ToChucTapTinDinhKem[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
          this.ToChucTapTinDinhKemService.DataSource = new MatTableDataSource(this.ToChucTapTinDinhKemService.list);
          this.ToChucTapTinDinhKemService.DataSource.sort = this.ToChucTapTinDinhKemSort;
          this.ToChucTapTinDinhKemService.DataSource.paginator = this.ToChucTapTinDinhKemPaginator;
          this.isShowLoading = false;
        },
        err => {
          this.isShowLoading = false;
        }
      );
    }
  }
  ToChucTapTinDinhKemSave(element: ToChucTapTinDinhKem) {
    element.ParentID = this.ToChucService.formData.ID;
    this.ToChucTapTinDinhKemService.formData = element;
    this.ToChucTapTinDinhKemService.SaveAndUploadFilesAsync().subscribe(
      res => {
        this.ToChucTapTinDinhKemSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }
  ToChucTapTinDinhKemDelete(element: ToChucTapTinDinhKem) {
    this.ToChucTapTinDinhKemService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.ToChucTapTinDinhKemService.ComponentDeleteByParentID(this.ToChucTapTinDinhKemSort, this.ToChucTapTinDinhKemPaginator));
  }

  DanhMucTinhThanhGetToList() {
    this.isShowLoading = true;
    this.DanhMucTinhThanhService.GetAllToListAsync().subscribe(
      res => {
        this.DanhMucTinhThanhService.list = (res as DanhMucTinhThanh[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucTinhThanhService.list001 = this.DanhMucTinhThanhService.list;
        this.DanhMucQuanHuyenGetToList();
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucQuanHuyenGetToList() {
    this.isShowLoading = true;
    this.DanhMucQuanHuyenService.baseParameter.ParentID = this.ToChucService.formData.DanhMucTinhThanhID;
    this.DanhMucQuanHuyenService.GetByParentIDToListAsync().subscribe(
      res => {
        this.DanhMucQuanHuyenService.list = (res as DanhMucQuanHuyen[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucQuanHuyenService.list001 = this.DanhMucQuanHuyenService.list;
        this.DanhMucXaPhuongGetToList();
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucXaPhuongGetToList() {
    this.isShowLoading = true;
    this.DanhMucXaPhuongService.baseParameter.ParentID = this.ToChucService.formData.DanhMucQuanHuyenID;
    this.DanhMucXaPhuongService.GetByParentIDToListAsync().subscribe(
      res => {
        this.DanhMucXaPhuongService.list = (res as DanhMucXaPhuong[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucXaPhuongService.list001 = this.DanhMucXaPhuongService.list;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucTinhThanhFilter(searchString: string) {
    this.DanhMucTinhThanhService.Filter(searchString);
  }
  DanhMucQuanHuyenFilter(searchString: string) {
    this.DanhMucQuanHuyenService.Filter(searchString);
  }
  DanhMucXaPhuongFilter(searchString: string) {
    this.DanhMucXaPhuongService.Filter(searchString);
  }
}