import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';
import { ToChuc } from 'src/app/shared/ToChuc.model';
import { ToChucService } from 'src/app/shared/ToChuc.service';

import { ToChucVuSanXuat } from 'src/app/shared/ToChucVuSanXuat.model';
import { ToChucVuSanXuatService } from 'src/app/shared/ToChucVuSanXuat.service';
import { ToChucVuSanXuatVungTrong } from 'src/app/shared/ToChucVuSanXuatVungTrong.model';
import { ToChucVuSanXuatVungTrongService } from 'src/app/shared/ToChucVuSanXuatVungTrong.service';
import { ToChucVuSanXuatNhatKy } from 'src/app/shared/ToChucVuSanXuatNhatKy.model';
import { ToChucVuSanXuatNhatKyService } from 'src/app/shared/ToChucVuSanXuatNhatKy.service';
import { ToChucVuSanXuatNhatKyDetailComponent } from '../to-chuc-vu-san-xuat-nhat-ky-detail/to-chuc-vu-san-xuat-nhat-ky-detail.component';
import { ToChucVuSanXuatNhatKyComponent } from '../to-chuc-vu-san-xuat-nhat-ky/to-chuc-vu-san-xuat-nhat-ky.component';
import { ThanhVienToChucVuSanXuatMobileToChucVuSanXuatNhatKyDetailComponent } from '../thanh-vien-to-chuc-vu-san-xuat-mobile-to-chuc-vu-san-xuat-nhat-ky-detail/thanh-vien-to-chuc-vu-san-xuat-mobile-to-chuc-vu-san-xuat-nhat-ky-detail.component';
import { ThanhVienToChucVuSanXuatMobileToChucVuSanXuatNhatKyDetailIsMuaVoComponent } from '../thanh-vien-to-chuc-vu-san-xuat-mobile-to-chuc-vu-san-xuat-nhat-ky-detail-is-mua-vo/thanh-vien-to-chuc-vu-san-xuat-mobile-to-chuc-vu-san-xuat-nhat-ky-detail-is-mua-vo.component';
import { ToChucVuSanXuatTapTinDinhKemComponent } from '../to-chuc-vu-san-xuat-tap-tin-dinh-kem/to-chuc-vu-san-xuat-tap-tin-dinh-kem.component';

@Component({
  selector: 'app-thanh-vien-to-chuc-vu-san-xuat-mobile-to-chuc-vu-san-xuat-nhat-ky54',
  templateUrl: './thanh-vien-to-chuc-vu-san-xuat-mobile-to-chuc-vu-san-xuat-nhat-ky54.component.html',
  styleUrls: ['./thanh-vien-to-chuc-vu-san-xuat-mobile-to-chuc-vu-san-xuat-nhat-ky54.component.css']
})
export class ThanhVienToChucVuSanXuatMobileToChucVuSanXuatNhatKy54Component implements OnInit {

  @ViewChild('ToChucVuSanXuatNhatKySort54') ToChucVuSanXuatNhatKySort54: MatSort;
  @ViewChild('ToChucVuSanXuatNhatKyPaginator54') ToChucVuSanXuatNhatKyPaginator54: MatPaginator;

  isShowLoading: boolean = false;
  constructor(
    private dialog: MatDialog,

    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public ThanhVienService: ThanhVienService,

    public ToChucService: ToChucService,

    public ToChucVuSanXuatService: ToChucVuSanXuatService,
    public ToChucVuSanXuatVungTrongService: ToChucVuSanXuatVungTrongService,
    public ToChucVuSanXuatNhatKyService: ToChucVuSanXuatNhatKyService,
  ) { }

  ngOnInit(): void {
    this.ThanhVienSearch();
    this.ToChucVuSanXuatSearch();
  }
  DateToChucVuSanXuatNhatKyBatDau(element: ToChucVuSanXuatNhatKy, value) {
    element.BatDau = new Date(value);
  }
  DateToChucVuSanXuatNhatKyKetThuc(element: ToChucVuSanXuatNhatKy, value) {
    element.KetThuc = new Date(value);
  }  
  ThanhVienSearch() {
    this.ThanhVienService.GetLogin();
  }
  ToChucVuSanXuatSearch() {
    this.isShowLoading = true;
    this.ToChucVuSanXuatService.baseParameter.ParentID = this.ThanhVienService.formDataLogin.ToChucID;
    this.ToChucVuSanXuatService.GetByParentID_SearchString_YearToListAsync().subscribe(
      res => {
        this.ToChucVuSanXuatService.list = (res as ToChucVuSanXuat[]).sort((a, b) => (a.BatDau < b.BatDau ? 1 : -1));
        this.ToChucVuSanXuatService.list001 = this.ToChucVuSanXuatService.list;
        if (this.ToChucVuSanXuatService.list001) {
          if (this.ToChucVuSanXuatService.list001.length > 0) {
            this.ToChucVuSanXuatNhatKyService.baseParameter.ParentID = this.ToChucVuSanXuatService.list001[0].ID;
          }
        }
        this.ToChucVuSanXuatVungTrongSearch();
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  ToChucVuSanXuatFilter(searchString: string) {
    this.ToChucVuSanXuatService.Filter(searchString);
  }
  ToChucVuSanXuatVungTrongSearch() {
    this.isShowLoading = true;
    this.ToChucVuSanXuatVungTrongService.baseParameter.ParentID = this.ToChucVuSanXuatNhatKyService.baseParameter.ParentID;
    this.ToChucVuSanXuatVungTrongService.baseParameter.Active = true;
    this.ToChucVuSanXuatVungTrongService.GetByParentIDAndActiveToListAsync().subscribe(
      res => {
        this.ToChucVuSanXuatVungTrongService.list = (res as ToChucVuSanXuatVungTrong[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.ToChucVuSanXuatVungTrongService.list001 = this.ToChucVuSanXuatVungTrongService.list;
        if (this.ToChucVuSanXuatVungTrongService.list001) {
          if (this.ToChucVuSanXuatVungTrongService.list001.length > 0) {
            this.ToChucVuSanXuatNhatKyService.baseParameter.ToChucVuSanXuatVungTrongID = this.ToChucVuSanXuatVungTrongService.list001[0].ID;
            this.ToChucVuSanXuatVungTrongService.formData = this.ToChucVuSanXuatVungTrongService.list001[0];
          }
        }
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  ToChucVuSanXuatVungTrongChange() {
    for (let i = 0; i < this.ToChucVuSanXuatVungTrongService.list001.length; i++) {
      let ToChucVuSanXuatVungTrong = this.ToChucVuSanXuatVungTrongService.list001[i];
      if (ToChucVuSanXuatVungTrong.ID == this.ToChucVuSanXuatNhatKyService.baseParameter.ToChucVuSanXuatVungTrongID) {
        this.ToChucVuSanXuatVungTrongService.formData = ToChucVuSanXuatVungTrong;
      }
    }
  }
  ToChucVuSanXuatNhatKyIsShow(element: ToChucVuSanXuatNhatKy) {
    element.IsShow = !element.IsShow;
  }
  ToChucVuSanXuatNhatKyChangeFileName(files: FileList) {
    if (files) {
      this.ToChucVuSanXuatNhatKyService.fileToUpload = files;
    }
  }
  ToChucVuSanXuatNhatKySearch54() {
    if (this.ToChucVuSanXuatNhatKyService.baseParameter.SearchString.length > 0) {
      this.ToChucVuSanXuatNhatKyService.DataSource54.filter = this.ToChucVuSanXuatNhatKyService.baseParameter.SearchString.toLowerCase();
    }
    else {
      this.isShowLoading = true;
      this.ToChucVuSanXuatNhatKyService.baseParameter.DanhMucNguyenVatLieuPhanLoaiID = environment.DanhMucNguyenVatLieuPhanLoaiID54;
      this.ToChucVuSanXuatNhatKyService.baseParameter.Active = true;
      this.ToChucVuSanXuatNhatKyService.GetSQLByToChucVuSanXuatVungTrongID_DanhMucNguyenVatLieuPhanLoaiID_ActiveToListAsync().subscribe(
        res => {
          this.ToChucVuSanXuatNhatKyService.list54 = (res as ToChucVuSanXuatNhatKy[])
          for (let index = 0; index < this.ToChucVuSanXuatNhatKyService.list54.length; index++) {
            this.ToChucVuSanXuatNhatKyService.list54[index].IsShow = false;
          }
          this.ToChucVuSanXuatNhatKyService.DataSource54 = new MatTableDataSource(this.ToChucVuSanXuatNhatKyService.list54);
          this.ToChucVuSanXuatNhatKyService.DataSource54.sort = this.ToChucVuSanXuatNhatKySort54;
          this.ToChucVuSanXuatNhatKyService.DataSource54.paginator = this.ToChucVuSanXuatNhatKyPaginator54;
          this.isShowLoading = false;
        },
        err => {
          this.isShowLoading = false;
        }
      );
    }
    this.ToChucVuSanXuatVungTrongChange();
  }
  ToChucVuSanXuatNhatKySave54(element: ToChucVuSanXuatNhatKy) {
    element.ParentID = this.ToChucVuSanXuatNhatKyService.baseParameter.ParentID;
    element.ToChucVuSanXuatVungTrongID = this.ToChucVuSanXuatNhatKyService.baseParameter.ToChucVuSanXuatVungTrongID;
    element.DanhMucNguyenVatLieuPhanLoaiID = environment.DanhMucNguyenVatLieuPhanLoaiID54;
    this.ToChucVuSanXuatNhatKyService.formData = element;
    this.ToChucVuSanXuatNhatKyService.SaveAndUploadFilesAsync().subscribe(
      res => {
        this.ToChucVuSanXuatNhatKySearch54();
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }
  ToChucVuSanXuatNhatKyDelete54(element: ToChucVuSanXuatNhatKy) {
    this.isShowLoading = true;
    this.ToChucVuSanXuatNhatKyService.baseParameter.ID = element.ID;
    this.ToChucVuSanXuatNhatKyService.RemoveAsync().subscribe(
      res => {
        this.ToChucVuSanXuatNhatKySearch54();
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }
  ToChucVuSanXuatNhatKyCopy54() {
    if (confirm(environment.Copy54)) {
      this.isShowLoading = true;
      this.ToChucVuSanXuatNhatKyService.baseParameter.DanhMucNguyenVatLieuPhanLoaiID = environment.DanhMucNguyenVatLieuPhanLoaiID54;
      this.ToChucVuSanXuatNhatKyService.CopySQLByToChucVuSanXuatVungTrongID_DanhMucNguyenVatLieuPhanLoaiIDAsync().subscribe(
        res => {
          this.NotificationService.warn(environment.SaveSuccess);
          this.isShowLoading = false;
        },
        err => {
          this.NotificationService.warn(environment.SaveNotSuccess);
          this.isShowLoading = false;
        }
      );
    }
  }
  ToChucVuSanXuatNhatKyCopy54ByID(element: ToChucVuSanXuatNhatKy) {
    if (confirm(environment.CopyByID)) {
      this.isShowLoading = true;
      this.ToChucVuSanXuatNhatKyService.baseParameter.ID = element.ID;
      this.ToChucVuSanXuatNhatKyService.CopySQLByIDAsync().subscribe(
        res => {
          this.ToChucVuSanXuatNhatKySearch54();
          this.NotificationService.warn(environment.SaveSuccess);
          this.isShowLoading = false;
        },
        err => {
          this.NotificationService.warn(environment.SaveNotSuccess);
          this.isShowLoading = false;
        }
      );
    }
  }
  ToChucVuSanXuatNhatKyAddIn(element: ToChucVuSanXuatNhatKy) {
    this.isShowLoading = true;
    this.ToChucVuSanXuatNhatKyService.formData54 = element;
    this.ToChucVuSanXuatNhatKyService.baseParameter.ID = environment.InitializationNumber;
    this.ToChucVuSanXuatNhatKyService.GetByIDAsync().subscribe(
      res => {
        this.ToChucVuSanXuatNhatKyService.formData = res as ToChucVuSanXuatNhatKy;
        this.ToChucVuSanXuatNhatKyService.formData.ToChucVuSanXuatNhatKyID = element.ID;
        this.ToChucVuSanXuatNhatKyService.formData.IsMuaVo = true;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: element.ID };
        const dialog = this.dialog.open(ThanhVienToChucVuSanXuatMobileToChucVuSanXuatNhatKyDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
        });
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  ToChucVuSanXuatNhatKyAddOut(element: ToChucVuSanXuatNhatKy) {
    this.isShowLoading = true;
    this.ToChucVuSanXuatNhatKyService.formData54 = element;
    this.ToChucVuSanXuatNhatKyService.baseParameter.ID = environment.InitializationNumber;
    this.ToChucVuSanXuatNhatKyService.GetByIDAsync().subscribe(
      res => {
        this.ToChucVuSanXuatNhatKyService.formData = res as ToChucVuSanXuatNhatKy;
        this.ToChucVuSanXuatNhatKyService.formData.ToChucVuSanXuatNhatKyID = element.ID;
        this.ToChucVuSanXuatNhatKyService.formData.IsMuaVo = false;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: element.ID };
        const dialog = this.dialog.open(ThanhVienToChucVuSanXuatMobileToChucVuSanXuatNhatKyDetailIsMuaVoComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
        });
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  ToChucVuSanXuatNhatKyAddSaoChep() {
    this.isShowLoading = true;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = environment.DialogConfigWidth;
    dialogConfig.data = { ID: 0 };
    const dialog = this.dialog.open(ToChucVuSanXuatTapTinDinhKemComponent, dialogConfig);
    dialog.afterClosed().subscribe(() => {
    });
    this.isShowLoading = false;
  }
}