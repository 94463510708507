<div class="app-body">
    <div class="row">
        <div class="col-xxl-12">
            <div class="card" style="margin-left: 1px">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-ToChucVuSanXuatNhatKy" data-bs-toggle="tab"
                                    href="#ToChucVuSanXuatNhatKy" role="tab"
                                    aria-controls="ToChucVuSanXuatNhatKy" aria-selected="true">
                                    <span class="badge bg-danger" style="font-size: 16px">4. Gây hại</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="ToChucVuSanXuatNhatKy" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">Vụ mùa</label>
                                        <mat-select class="form-control" name="ParentID" (selectionChange)="ToChucVuSanXuatVungTrongSearch()"
                                            [(ngModel)]="ToChucVuSanXuatNhatKyService.baseParameter.ParentID">
                                            <input placeholder="Tìm..." class="form-control"
                                                (keyup)="ToChucVuSanXuatFilter($event.target.value)">
                                            <mat-option *ngFor="let item of ToChucVuSanXuatService.list001"
                                                [value]="item.ID">
                                                {{item.DanhMucVuMuaName}} - {{item.BatDau | date:'dd/MM/yyyy'}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">Vùng trồng</label>
                                        <mat-select class="form-control" name="ToChucVuSanXuatVungTrongID"
                                            [(ngModel)]="ToChucVuSanXuatNhatKyService.baseParameter.ToChucVuSanXuatVungTrongID">
                                            <mat-option *ngFor="let item of ToChucVuSanXuatVungTrongService.list001"
                                                [value]="item.ID">
                                                {{item.Name}} - {{item.Code}}
                                            </mat-option>
                                        </mat-select>
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                                        <input name="SearchString"
                                            [(ngModel)]="ToChucVuSanXuatNhatKyService.baseParameter.SearchString"
                                            placeholder="Tìm..." class="form-control"
                                            (keyup.enter)="ToChucVuSanXuatNhatKySearch55()">
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                                        <a class="btn btn-success" title="Tìm..." style="width: 100%;"
                                            (click)="ToChucVuSanXuatNhatKySearch55()"><i
                                                class="bi bi-search"></i></a>
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <label *ngIf="!NotificationService.IsMobile" class="form-label">.</label>
                                        <a class="btn btn-info"
                                            title="Sao chép gây hại sang vùng trồng khác cùng vụ mùa"
                                            style="width: 100%;" (click)="ToChucVuSanXuatNhatKyCopy55()">Sao chép</a>
                                    </div>
                                </div>
                                <div class="card mb-4 card-danger">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Gây hại
                                            <span
                                                *ngIf="ToChucVuSanXuatNhatKyService.list55">({{ToChucVuSanXuatNhatKyService.list55.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table *ngIf="!NotificationService.IsMobile" mat-table matSort
                                                    #ToChucVuSanXuatNhatKySort55="matSort"
                                                    [dataSource]="ToChucVuSanXuatNhatKyService.DataSource55"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="BatDau">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Ghi nhận
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Ghi nhận</label>
                                                                    <input
                                                                        [ngModel]="element.BatDau | date:'yyyy-MM-dd'"
                                                                        (ngModelChange)="DateToChucVuSanXuatNhatKyBatDau(element, $event)"
                                                                        [ngModelOptions]="{ updateOn: 'blur' }"
                                                                        type="date" style="text-align: right;"
                                                                        class="form-control" style="text-align: right;"
                                                                        autocomplete="off">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Tác nhân</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Tác nhân"
                                                                        name="SanXuatGayHai{{element.ID}}"
                                                                        [(ngModel)]="element.SanXuatGayHai">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Tên khoa học</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Tên khoa học"
                                                                        name="Code{{element.ID}}"
                                                                        [(ngModel)]="element.Code">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Ghi chú</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Ghi chú" name="Note{{element.ID}}"
                                                                        [(ngModel)]="element.Note">
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SanXuatDieuTra">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Điều tra
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Phương pháp</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Phương pháp"
                                                                        name="SanXuatDieuTra{{element.ID}}"
                                                                        [(ngModel)]="element.SanXuatDieuTra">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Xử lý</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Xử lý"
                                                                        name="SanXuatXuLy{{element.ID}}"
                                                                        [(ngModel)]="element.SanXuatXuLy">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Số lượng</label>
                                                                    <input class="form-control" type="number"
                                                                        style="text-align: right;" placeholder="0"
                                                                        name="SoLuong{{element.ID}}"
                                                                        [(ngModel)]="element.SoLuong">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Mật độ</label>
                                                                    <input class="form-control" type="number"
                                                                        style="text-align: right;" placeholder="0"
                                                                        name="DonGia{{element.ID}}"
                                                                        [(ngModel)]="element.DonGia">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Tỷ lệ thiệt hại</label>
                                                                    <input class="form-control" type="number"
                                                                        style="text-align: right;" placeholder="0"
                                                                        name="ThanhTien{{element.ID}}"
                                                                        [(ngModel)]="element.ThanhTien">
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="NhaCungCap">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Giám sát +
                                                            Giám định
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Người giám sát</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Người giám sát"
                                                                        name="NhaCungCap{{element.ID}}"
                                                                        [(ngModel)]="element.NhaCungCap">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Phương pháp giám
                                                                        sát</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Phương pháp"
                                                                        name="NhaCungCapDienThoai{{element.ID}}"
                                                                        [(ngModel)]="element.NhaCungCapDienThoai">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Vị trí giám sát</label>
                                                                    <input class="form-control" placeholder="Vị trí"
                                                                        name="NhaCungCapDiaChi{{element.ID}}"
                                                                        [(ngModel)]="element.NhaCungCapDiaChi">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Người giám định</label>
                                                                    <input class="form-control"
                                                                        placeholder="Người giám định"
                                                                        name="Name{{element.ID}}"
                                                                        [(ngModel)]="element.Name">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Phương pháp giám
                                                                        định</label>
                                                                    <input class="form-control"
                                                                        placeholder="Phương pháp"
                                                                        name="Description{{element.ID}}"
                                                                        [(ngModel)]="element.Description">
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Active">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tình trạng
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Tải lên
                                                                        [{{element.ID}}]</label>
                                                                    <br />
                                                                    <input type="file" multiple
                                                                        (change)="ToChucVuSanXuatNhatKyChangeFileName($event.target.files)">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <div class="form-check form-check-inline mb-6">
                                                                        <input type="checkbox" class="form-check-input"
                                                                            name="Active{{element.ID}}"
                                                                            [(ngModel)]="element.Active" />
                                                                        <label class="form-check-label" for="Active">Đã
                                                                            hoàn thành công việc</label>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <div class="form-check form-check-inline mb-6">
                                                                        <input type="checkbox" class="form-check-input"
                                                                            name="IsChungThuc{{element.ID}}" disabled
                                                                            [(ngModel)]="element.IsChungThuc" />
                                                                        <label class="form-check-label"
                                                                            for="IsChungThuc">Đã chứng thực và khóa dữ
                                                                            liệu</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12 col-sm-12 col-12">
                                                                <a class="btn btn-info" style="width: 100%;"
                                                                    (click)="ToChucVuSanXuatNhatKySave55(element)"><i
                                                                        class="bi bi-sd-card"></i></a>
                                                            </div>
                                                            <div class="col-lg-12 col-sm-12 col-12">
                                                                <a *ngIf="element.ID>0" class="btn btn-info"
                                                                    style="width: 100%;"
                                                                    (click)="ToChucVuSanXuatNhatKyCopyByID(element)">Copy</a>
                                                            </div>
                                                            <div class="col-lg-12 col-sm-12 col-12">
                                                                <a class="btn btn-danger" style="width: 100%;"
                                                                    (click)="ToChucVuSanXuatNhatKyDelete55(element)"
                                                                    *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                                </a>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucVuSanXuatNhatKyService.displayColumns0055">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucVuSanXuatNhatKyService.displayColumns0055;">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="!NotificationService.IsMobile"
                                                    #ToChucVuSanXuatNhatKyPaginator55="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                                <table *ngIf="NotificationService.IsMobile" mat-table matSort
                                                    #ToChucVuSanXuatNhatKySort55="matSort"
                                                    [dataSource]="ToChucVuSanXuatNhatKyService.DataSource55"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Danh sách
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Ghi nhận</label>
                                                                    <input
                                                                        [ngModel]="element.BatDau | date:'yyyy-MM-dd'"
                                                                        (ngModelChange)="DateToChucVuSanXuatNhatKyBatDau(element, $event)"
                                                                        [ngModelOptions]="{ updateOn: 'blur' }"
                                                                        type="date" style="text-align: right;"
                                                                        class="form-control" style="text-align: right;"
                                                                        autocomplete="off">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Tác nhân</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Tác nhân"
                                                                        name="SanXuatGayHai{{element.ID}}"
                                                                        [(ngModel)]="element.SanXuatGayHai">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Tên khoa học</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Tên khoa học"
                                                                        name="Code{{element.ID}}"
                                                                        [(ngModel)]="element.Code">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Ghi chú</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Ghi chú" name="Note{{element.ID}}"
                                                                        [(ngModel)]="element.Note">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Phương pháp</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Phương pháp"
                                                                        name="SanXuatDieuTra{{element.ID}}"
                                                                        [(ngModel)]="element.SanXuatDieuTra">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Xử lý</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Xử lý"
                                                                        name="SanXuatXuLy{{element.ID}}"
                                                                        [(ngModel)]="element.SanXuatXuLy">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Số lượng</label>
                                                                    <input class="form-control" type="number"
                                                                        style="text-align: right;" placeholder="0"
                                                                        name="SoLuong{{element.ID}}"
                                                                        [(ngModel)]="element.SoLuong">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Mật độ</label>
                                                                    <input class="form-control" type="number"
                                                                        style="text-align: right;" placeholder="0"
                                                                        name="DonGia{{element.ID}}"
                                                                        [(ngModel)]="element.DonGia">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Tỷ lệ thiệt hại</label>
                                                                    <input class="form-control" type="number"
                                                                        style="text-align: right;" placeholder="0"
                                                                        name="ThanhTien{{element.ID}}"
                                                                        [(ngModel)]="element.ThanhTien">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Người giám sát</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Người giám sát"
                                                                        name="NhaCungCap{{element.ID}}"
                                                                        [(ngModel)]="element.NhaCungCap">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Phương pháp giám
                                                                        sát</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Phương pháp"
                                                                        name="NhaCungCapDienThoai{{element.ID}}"
                                                                        [(ngModel)]="element.NhaCungCapDienThoai">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Vị trí giám sát</label>
                                                                    <input class="form-control" placeholder="Vị trí"
                                                                        name="NhaCungCapDiaChi{{element.ID}}"
                                                                        [(ngModel)]="element.NhaCungCapDiaChi">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Người giám định</label>
                                                                    <input class="form-control"
                                                                        placeholder="Người giám định"
                                                                        name="Name{{element.ID}}"
                                                                        [(ngModel)]="element.Name">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Phương pháp giám
                                                                        định</label>
                                                                    <input class="form-control"
                                                                        placeholder="Phương pháp"
                                                                        name="Description{{element.ID}}"
                                                                        [(ngModel)]="element.Description">
                                                                </div>                                                                
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <div class="form-check form-check-inline mb-6">
                                                                        <input type="checkbox" class="form-check-input"
                                                                            name="Active{{element.ID}}"
                                                                            [(ngModel)]="element.Active" />
                                                                        <label class="form-check-label" for="Active">Đã
                                                                            hoàn thành công việc</label>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <div class="form-check form-check-inline mb-6">
                                                                        <input type="checkbox" class="form-check-input"
                                                                            name="IsChungThuc{{element.ID}}" disabled
                                                                            [(ngModel)]="element.IsChungThuc" />
                                                                        <label class="form-check-label"
                                                                            for="IsChungThuc">Đã chứng thực</label>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Tải lên
                                                                        [{{element.ID}}]</label>
                                                                    <br />
                                                                    <input type="file" multiple style="width: 200px;"
                                                                        (change)="ToChucVuSanXuatNhatKyChangeFileName($event.target.files)">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <a class="btn btn-info" style="width: 100%;"
                                                                        (click)="ToChucVuSanXuatNhatKySave55(element)"><i
                                                                            class="bi bi-sd-card"></i></a>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <a *ngIf="element.ID>0" class="btn btn-info"
                                                                        style="width: 100%;"
                                                                        (click)="ToChucVuSanXuatNhatKyCopyByID(element)">Copy</a>
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <a class="btn btn-danger" style="width: 100%;"
                                                                        (click)="ToChucVuSanXuatNhatKyDelete55(element)"
                                                                        *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucVuSanXuatNhatKyService.DisplayColumnsMobile">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucVuSanXuatNhatKyService.DisplayColumnsMobile">
                                                    </tr>
                                                </table>
                                                <mat-paginator *ngIf="NotificationService.IsMobile"
                                                    #ToChucVuSanXuatNhatKyPaginator55="matPaginator"
                                                    [pageSizeOptions]="[10, 20, 50, 100, 500, 999]" [pageSize]="10000"
                                                    [showFirstLastButtons]></mat-paginator>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>