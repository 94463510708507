import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from 'src/environments/environment';
import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class ThanhVienService extends BaseService {

    DataSourceTrucTuyen: MatTableDataSource<any>;

    list: ThanhVien[] | undefined;
    list001: ThanhVien[] | undefined;
    formData!: ThanhVien;
    formDataLogin!: ThanhVien;

    list002: ThanhVien[] | undefined;
    list003: ThanhVien[] | undefined;
    listChuyenGia: ThanhVien[] | undefined;
    listQuanTri: ThanhVien[] | undefined;
    listTrucTuyen: ThanhVien[] | undefined;

    displayColumns001: string[] = ['STT', 'Name', 'TaiKhoan', 'MatKhau', 'Email', 'TypeName', 'Active', 'Save'];
    displayColumns002: string[] = ['STT', 'DanhMucTinhThanhName', 'Description', 'Note', 'Name', 'TaiKhoan', 'Email', 'Active'];
    displayColumns003: string[] = ['STT', 'DanhMucTinhThanhName', 'DanhMucQuanHuyenName', 'Name', 'TaiKhoan', 'Email', 'Active'];
    displayColumns004: string[] = ['STT', 'ID', 'Name', 'TaiKhoan', 'Description', 'Note', 'DanhMucTinhThanhName', 'Active'];
    displayColumns0021: string[] = ['STT', 'Description', 'Note', 'Name', 'TaiKhoan', 'Email', 'Active'];


    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "ThanhVien";
        this.listTrucTuyen = [];
        this.GetLogin();
    }

    GetLogin() {
        this.formDataLogin = {
        }
        this.formDataLogin.Name = localStorage.getItem(environment.ThanhVienHoTen);
        var lastUpdatedMembershipID = localStorage.getItem(environment.ThanhVienID);
        if (lastUpdatedMembershipID) {
            this.formDataLogin.ID = Number(lastUpdatedMembershipID);
        }
        var ThanhVienToChucID = localStorage.getItem(environment.ThanhVienToChucID);
        if (ThanhVienToChucID) {
            this.formDataLogin.ToChucID = Number(ThanhVienToChucID);
        }
    }
    AuthenticationAsync() {
        let url = this.aPIURL + this.controller + '/AuthenticationAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.formData));
        return this.httpClient.post(url, formUpload);
    }
    GetByParentID_SearchString_DanhMucTinhThanhID_DanhMucQuanHuyenID_DanhMucXaPhuongIDToListAsync() {
        let url = this.aPIURL + this.controller + '/GetByParentID_SearchString_DanhMucTinhThanhID_DanhMucQuanHuyenID_DanhMucXaPhuongIDToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
    GetByParentIDOrSearchStringToListAsync() {
        let url = this.aPIURL + this.controller + '/GetByParentIDOrSearchStringToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
    UpdateItemsByActiveAsync() {
        let url = this.aPIURL + this.controller + '/UpdateItemsByActiveAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
    UpdateItemsByActive_ParentIDAsync() {
        let url = this.aPIURL + this.controller + '/UpdateItemsByActive_ParentIDAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
    GetByTrucTuyenAsync() {
        let url = this.aPIURL + this.controller + '/GetByTrucTuyenAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
}

