import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from 'src/app/shared/Notification.service';

import { DanhMucChucNang } from 'src/app/shared/DanhMucChucNang.model';
import { DanhMucChucNangService } from 'src/app/shared/DanhMucChucNang.service';

import { DanhMucThanhVien } from 'src/app/shared/DanhMucThanhVien.model';
import { DanhMucThanhVienService } from 'src/app/shared/DanhMucThanhVien.service';

import { ThanhVienPhanQuyenChucNang } from 'src/app/shared/ThanhVienPhanQuyenChucNang.model';
import { ThanhVienPhanQuyenChucNangService } from 'src/app/shared/ThanhVienPhanQuyenChucNang.service';

@Component({
  selector: 'app-danh-muc-chuc-nang-detail',
  templateUrl: './danh-muc-chuc-nang-detail.component.html',
  styleUrls: ['./danh-muc-chuc-nang-detail.component.css']
})
export class DanhMucChucNangDetailComponent implements OnInit {

  @ViewChild('ThanhVienPhanQuyenChucNangSort001') ThanhVienPhanQuyenChucNangSort001: MatSort;
  @ViewChild('ThanhVienPhanQuyenChucNangPaginator001') ThanhVienPhanQuyenChucNangPaginator001: MatPaginator;
 

  constructor(
    public dialogRef: MatDialogRef<DanhMucChucNangDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    public NotificationService: NotificationService,

    public DanhMucChucNangService: DanhMucChucNangService,

    public DanhMucThanhVienService: DanhMucThanhVienService,   

    public ThanhVienPhanQuyenChucNangService: ThanhVienPhanQuyenChucNangService,
  ) {
  }

  ngOnInit(): void {
    this.DanhMucThanhVienSearch();    
    this.ThanhVienPhanQuyenChucNangSearch001();
    
  }
  DanhMucThanhVienSearch() {
    this.DanhMucThanhVienService.ComponentGetAllToListAsync();
  }
  ThanhVienPhanQuyenChucNangSearch001() {
    if (this.ThanhVienPhanQuyenChucNangService.baseParameter.SearchString.length > 0) {
      this.ThanhVienPhanQuyenChucNangService.DataSource.filter = this.ThanhVienPhanQuyenChucNangService.baseParameter.SearchString.toLowerCase();
    }
    else {
      this.ThanhVienPhanQuyenChucNangGetToList001();
    }
  }
  ThanhVienPhanQuyenChucNangGetToList001() {
    this.DanhMucChucNangService.IsShowLoading = true;
    this.ThanhVienPhanQuyenChucNangService.baseParameter.DanhMucChucNangID = this.DanhMucChucNangService.formData.ID;
    this.ThanhVienPhanQuyenChucNangService.GetByDanhMucChucNangID_EmptyToListAsync().subscribe(
      res => {
        this.ThanhVienPhanQuyenChucNangService.list = (res as ThanhVienPhanQuyenChucNang[]);
        this.ThanhVienPhanQuyenChucNangService.DataSource = new MatTableDataSource(this.ThanhVienPhanQuyenChucNangService.list);
        this.ThanhVienPhanQuyenChucNangService.DataSource.sort = this.ThanhVienPhanQuyenChucNangSort001;
        this.ThanhVienPhanQuyenChucNangService.DataSource.paginator = this.ThanhVienPhanQuyenChucNangPaginator001;
        this.DanhMucChucNangService.IsShowLoading = false;
      },
      err => {
        this.DanhMucChucNangService.IsShowLoading = false;
      }
    );
  }

  

  ThanhVienPhanQuyenChucNangSave(element: ThanhVienPhanQuyenChucNang) {
    this.DanhMucChucNangService.IsShowLoading = true;
    this.ThanhVienPhanQuyenChucNangService.formData = element;
    this.ThanhVienPhanQuyenChucNangService.formData.DanhMucChucNangID = this.DanhMucChucNangService.formData.ID;
    this.ThanhVienPhanQuyenChucNangService.SaveAsync().subscribe(
      res => {
        this.ThanhVienPhanQuyenChucNangSearch001();        
        this.NotificationService.warn(environment.SaveSuccess);
        this.DanhMucChucNangService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.DanhMucChucNangService.IsShowLoading = false;
      }
    );
  }
  ThanhVienPhanQuyenChucNangDelete(element: ThanhVienPhanQuyenChucNang) {
    this.DanhMucChucNangService.IsShowLoading = true;
    this.ThanhVienPhanQuyenChucNangService.baseParameter.ID = element.ID;
    this.ThanhVienPhanQuyenChucNangService.RemoveAsync().subscribe(
      res => {
        this.ThanhVienPhanQuyenChucNangSearch001();        
        this.NotificationService.warn(environment.SaveSuccess);
        this.DanhMucChucNangService.IsShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.DanhMucChucNangService.IsShowLoading = false;
      }
    );
  }
  Close() {
    this.dialogRef.close();
  }

}