import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { UploadService } from 'src/app/shared/Upload.service';
import { DownloadService } from 'src/app/shared/Download.service';

import { DanhMucTinhThanh } from 'src/app/shared/DanhMucTinhThanh.model';
import { DanhMucTinhThanhService } from 'src/app/shared/DanhMucTinhThanh.service';
import { DanhMucThanhVien } from 'src/app/shared/DanhMucThanhVien.model';
import { DanhMucThanhVienService } from 'src/app/shared/DanhMucThanhVien.service';

import { ThoiTiet } from 'src/app/shared/ThoiTiet.model';
import { ThoiTietService } from 'src/app/shared/ThoiTiet.service';

@Component({
  selector: 'app-upload-quy-trinh-san-xuat',
  templateUrl: './upload-quy-trinh-san-xuat.component.html',
  styleUrls: ['./upload-quy-trinh-san-xuat.component.css']
})
export class UploadQuyTrinhSanXuatComponent implements OnInit {

  isShowLoading: boolean = false;
 
  isQuyTrinhSanXuat: boolean = false;
  excelQuyTrinhSanXuatURL: string = environment.APIUploadRootURL + environment.Download + "/QuyTrinhSanXuat.xlsx";
  @ViewChild('uploadQuyTrinhSanXuat') uploadQuyTrinhSanXuat!: ElementRef;

  

  constructor(
    public NotificationService: NotificationService,
    public UploadService: UploadService,
    public DownloadService: DownloadService,    

  ) { }

  ngOnInit(): void {
   
  } 

  changeQuyTrinhSanXuat(files: FileList) {
    if (files) {
      this.isQuyTrinhSanXuat = true;
    }
  }
  onSubmitQuyTrinhSanXuat() {
    let fileToUpload: File;
    fileToUpload = this.uploadQuyTrinhSanXuat.nativeElement.files[0];
    this.isShowLoading = true;    
    this.UploadService.PostQuyTrinhSanXuatByExcelFileAsync(fileToUpload).subscribe(
      res => {
        this.isShowLoading = false;        
        this.NotificationService.warn(environment.UploadSuccess);
      },
      err => {
        this.isShowLoading = false;
        this.NotificationService.warn(environment.UploadNotSuccess);
      }
    );
  }

  
}