import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ToChucVuSanXuatTieuChi } from 'src/app/shared/ToChucVuSanXuatTieuChi.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class ToChucVuSanXuatTieuChiService extends BaseService{

    list: ToChucVuSanXuatTieuChi[] | undefined;
    list001: ToChucVuSanXuatTieuChi[] | undefined;
    formData!: ToChucVuSanXuatTieuChi;    

    displayColumns001: string[] = ['STT', 'Name', 'Year', 'Month', 'KetQuaThucTeThang', 'KetQuaThucTeNam', 'KetQuaNhoNhat2025', 'TyLeHoanThanh2025', 'KetQuaNhoNhat2030', 'TyLeHoanThanh2030', 'IsChungThuc', 'Save'];

    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "ToChucVuSanXuatTieuChi";
    }

    GetByParentID_Year_MonthToListAsync() {
        let url = this.aPIURL + this.controller + '/GetByParentID_Year_MonthToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
    GetSQLByParentID_Year_MonthToListAsync() {
        let url = this.aPIURL + this.controller + '/GetSQLByParentID_Year_MonthToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(this.baseParameter));
        return this.httpClient.post(url, formUpload);
    }
}

