import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';


import { DanhMucVuSanXuatPhanDoan } from 'src/app/shared/DanhMucVuSanXuatPhanDoan.model';
import { DanhMucVuSanXuatPhanDoanService } from 'src/app/shared/DanhMucVuSanXuatPhanDoan.service';
import { DanhMucVuMua } from 'src/app/shared/DanhMucVuMua.model';
import { DanhMucVuMuaService } from 'src/app/shared/DanhMucVuMua.service';
import { DanhMucVuSanXuatTieuChi } from 'src/app/shared/DanhMucVuSanXuatTieuChi.model';
import { DanhMucVuSanXuatTieuChiService } from 'src/app/shared/DanhMucVuSanXuatTieuChi.service';
import { QuyTrinhSanXuat } from 'src/app/shared/QuyTrinhSanXuat.model';
import { QuyTrinhSanXuatService } from 'src/app/shared/QuyTrinhSanXuat.service';
import { DanhMucVuSanXuatThietBi } from 'src/app/shared/DanhMucVuSanXuatThietBi.model';
import { DanhMucVuSanXuatThietBiService } from 'src/app/shared/DanhMucVuSanXuatThietBi.service';

@Component({
  selector: 'app-danh-muc-vu-san-xuat-phan-doan',
  templateUrl: './danh-muc-vu-san-xuat-phan-doan.component.html',
  styleUrls: ['./danh-muc-vu-san-xuat-phan-doan.component.css']
})
export class DanhMucVuSanXuatPhanDoanComponent implements OnInit {

  @ViewChild('DanhMucVuSanXuatPhanDoanSort') DanhMucVuSanXuatPhanDoanSort: MatSort;
  @ViewChild('DanhMucVuSanXuatPhanDoanPaginator') DanhMucVuSanXuatPhanDoanPaginator: MatPaginator;

  @ViewChild('DanhMucVuMuaSort') DanhMucVuMuaSort: MatSort;
  @ViewChild('DanhMucVuMuaPaginator') DanhMucVuMuaPaginator: MatPaginator;

  @ViewChild('DanhMucVuSanXuatTieuChiSort') DanhMucVuSanXuatTieuChiSort: MatSort;
  @ViewChild('DanhMucVuSanXuatTieuChiPaginator') DanhMucVuSanXuatTieuChiPaginator: MatPaginator;

  @ViewChild('QuyTrinhSanXuatSort') QuyTrinhSanXuatSort: MatSort;
  @ViewChild('QuyTrinhSanXuatPaginator') QuyTrinhSanXuatPaginator: MatPaginator;

  @ViewChild('DanhMucVuSanXuatThietBiSort') DanhMucVuSanXuatThietBiSort: MatSort;
  @ViewChild('DanhMucVuSanXuatThietBiPaginator') DanhMucVuSanXuatThietBiPaginator: MatPaginator;

  isShowLoading: boolean = false;

  constructor(
    private dialog: MatDialog,
    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public DanhMucVuSanXuatPhanDoanService: DanhMucVuSanXuatPhanDoanService,
    public DanhMucVuMuaService: DanhMucVuMuaService,
    public DanhMucVuSanXuatTieuChiService: DanhMucVuSanXuatTieuChiService,
    public QuyTrinhSanXuatService: QuyTrinhSanXuatService,
    public DanhMucVuSanXuatThietBiService: DanhMucVuSanXuatThietBiService,

  ) { }

  ngOnInit(): void {
  }
  DanhMucVuSanXuatPhanDoanSearch() {
    this.DanhMucVuSanXuatPhanDoanService.SearchAll(this.DanhMucVuSanXuatPhanDoanSort, this.DanhMucVuSanXuatPhanDoanPaginator);
  }
  DanhMucVuSanXuatPhanDoanSave(element: DanhMucVuSanXuatPhanDoan) {
    this.DanhMucVuSanXuatPhanDoanService.formData = element;
    this.NotificationService.warn(this.DanhMucVuSanXuatPhanDoanService.ComponentSaveAll(this.DanhMucVuSanXuatPhanDoanSort, this.DanhMucVuSanXuatPhanDoanPaginator));
  }
  DanhMucVuSanXuatPhanDoanDelete(element: DanhMucVuSanXuatPhanDoan) {
    this.DanhMucVuSanXuatPhanDoanService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.DanhMucVuSanXuatPhanDoanService.ComponentDeleteAll(this.DanhMucVuSanXuatPhanDoanSort, this.DanhMucVuSanXuatPhanDoanPaginator));
  }

  DanhMucVuMuaSearch() {
    this.DanhMucVuMuaService.SearchAll(this.DanhMucVuMuaSort, this.DanhMucVuMuaPaginator);
  }
  DanhMucVuMuaSave(element: DanhMucVuMua) {
    this.DanhMucVuMuaService.formData = element;
    this.NotificationService.warn(this.DanhMucVuMuaService.ComponentSaveAll(this.DanhMucVuMuaSort, this.DanhMucVuMuaPaginator));
  }
  DanhMucVuMuaDelete(element: DanhMucVuMua) {
    this.DanhMucVuMuaService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.DanhMucVuMuaService.ComponentDeleteAll(this.DanhMucVuMuaSort, this.DanhMucVuMuaPaginator));
  }

  DanhMucVuSanXuatTieuChiSearch() {
    this.DanhMucVuSanXuatTieuChiService.SearchAll(this.DanhMucVuSanXuatTieuChiSort, this.DanhMucVuSanXuatTieuChiPaginator);
  }
  DanhMucVuSanXuatTieuChiSave(element: DanhMucVuSanXuatTieuChi) {
    this.DanhMucVuSanXuatTieuChiService.formData = element;
    this.NotificationService.warn(this.DanhMucVuSanXuatTieuChiService.ComponentSaveAll(this.DanhMucVuSanXuatTieuChiSort, this.DanhMucVuSanXuatTieuChiPaginator));
  }
  DanhMucVuSanXuatTieuChiDelete(element: DanhMucVuSanXuatTieuChi) {
    this.DanhMucVuSanXuatTieuChiService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.DanhMucVuSanXuatTieuChiService.ComponentDeleteAll(this.DanhMucVuSanXuatTieuChiSort, this.DanhMucVuSanXuatTieuChiPaginator));
  }

  QuyTrinhSanXuatSearch() {
    this.QuyTrinhSanXuatService.SearchAll(this.QuyTrinhSanXuatSort, this.QuyTrinhSanXuatPaginator);
  }
  QuyTrinhSanXuatSave(element: QuyTrinhSanXuat) {
    this.QuyTrinhSanXuatService.formData = element;
    this.NotificationService.warn(this.QuyTrinhSanXuatService.ComponentSaveAll(this.QuyTrinhSanXuatSort, this.QuyTrinhSanXuatPaginator));
  }
  QuyTrinhSanXuatDelete(element: QuyTrinhSanXuat) {
    this.QuyTrinhSanXuatService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.QuyTrinhSanXuatService.ComponentDeleteAll(this.QuyTrinhSanXuatSort, this.QuyTrinhSanXuatPaginator));
  }

  DanhMucVuSanXuatThietBiSearch() {
    this.DanhMucVuSanXuatThietBiService.SearchAll(this.DanhMucVuSanXuatThietBiSort, this.DanhMucVuSanXuatThietBiPaginator);
  }
  DanhMucVuSanXuatThietBiSave(element: DanhMucVuSanXuatThietBi) {
    this.DanhMucVuSanXuatThietBiService.formData = element;
    this.NotificationService.warn(this.DanhMucVuSanXuatThietBiService.ComponentSaveAll(this.DanhMucVuSanXuatThietBiSort, this.DanhMucVuSanXuatThietBiPaginator));
  }
  DanhMucVuSanXuatThietBiDelete(element: DanhMucVuSanXuatThietBi) {
    this.DanhMucVuSanXuatThietBiService.baseParameter.ID = element.ID;
    this.NotificationService.warn(this.DanhMucVuSanXuatThietBiService.ComponentDeleteAll(this.DanhMucVuSanXuatThietBiSort, this.DanhMucVuSanXuatThietBiPaginator));
  }
}
