<div class="custom-tabs-container">
    <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
        <li class="nav-item" role="presentation">
            <a class="nav-link active" id="tab-ToChucVuSanXuatNhatKyDetail" data-bs-toggle="tab"
                href="#ToChucVuSanXuatNhatKyDetail" role="tab" aria-controls="ToChucVuSanXuatNhatKyDetail"
                aria-selected="true">
                <span class="badge bg-success" style="font-size: 16px;">6. Bán ra</span>
            </a>
        </li>
        <li class="nav-item" role="presentation">
            <a style="cursor: pointer;" class="nav-link" id="tab-Close" data-bs-toggle="tab" (click)="Close()"
                role="tab" aria-controls="Close" aria-selected="false">
                <span class="badge bg-danger" style="font-size: 16px;"><i class="bi bi-x-lg"></i></span>
            </a>
        </li>
    </ul>
    <div class="tab-content" id="customTabContent">
        <div class="tab-pane fade show active" id="ToChucVuSanXuatNhatKyDetail" role="tabpanel">
            <div class="row gx-4">
                <div class="col-lg-4 col-sm-12 col-12">
                    <a style="width: 100%;" title="Lưu thay đổi"
                        (click)="ToChucVuSanXuatNhatKySave()" class="btn btn-info"><i
                            class="bi bi-sd-card"></i> Lưu thay đổi</a>
                </div>
                <div class="col-lg-2 col-sm-12 col-12">
                    <div class="form-check form-check-inline mb-6">
                        <input type="checkbox" class="form-check-input" name="Active"
                            [(ngModel)]="ToChucVuSanXuatNhatKyService.formData.Active" />
                        <label class="form-check-label" for="Active">Đã hoàn thành</label>
                    </div>
                </div>                                              
            </div>
            <div class="row gx-4">                
                <div class="col-lg-4 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Bán ra</label>
                        <input class="form-control" type="text" placeholder="Bán ra"
                            name="DanhMucNguyenVatLieuName"
                            [(ngModel)]="ToChucVuSanXuatNhatKyService.formData.DanhMucNguyenVatLieuName">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Số lượng</label>
                        <input class="form-control" type="number" style="text-align: right;"
                            placeholder="0" name="SoLuong"
                            [(ngModel)]="ToChucVuSanXuatNhatKyService.formData.SoLuong">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Đơn vị tính</label>
                        <input class="form-control" type="text" placeholder="Đơn vị tính"
                            name="DanhMucDonViTinhName"
                            [(ngModel)]="ToChucVuSanXuatNhatKyService.formData.DanhMucDonViTinhName">
                    </div>                          
                </div>
                <div class="col-lg-4 col-sm-12 col-12">                                        
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Người mua (nếu
                            có)</label>
                        <input class="form-control" type="text" placeholder="Người mua (nếu có)"
                            name="NhaCungCap"
                            [(ngModel)]="ToChucVuSanXuatNhatKyService.formData.NhaCungCap">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Điện thoại</label>
                        <input class="form-control" type="text" placeholder="Điện thoại"
                            name="NhaCungCapDienThoai"
                            [(ngModel)]="ToChucVuSanXuatNhatKyService.formData.NhaCungCapDienThoai">
                    </div>
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Địa chỉ</label>
                        <input class="form-control" type="text" placeholder="Địa chỉ"
                            name="NhaCungCapDiaChi"
                            [(ngModel)]="ToChucVuSanXuatNhatKyService.formData.NhaCungCapDiaChi">
                    </div>                                        
                </div>
                <div class="col-lg-4 col-sm-12 col-12">
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ghi nhận</label>
                        <input name="BatDau"
                            [ngModel]="ToChucVuSanXuatNhatKyService.formData.BatDau | date:'yyyy-MM-dd'"
                            (ngModelChange)="DateBatDau($event)"
                            [ngModelOptions]="{ updateOn: 'blur' }" type="date"
                            style="text-align: right;" class="form-control" autocomplete="off">
                    </div>                           
                    <div class="col-lg-12 col-sm-12 col-12">
                        <label class="form-label">Ghi chú</label>
                        <input class="form-control" type="text" placeholder="Ghi chú" name="Note"
                            [(ngModel)]="ToChucVuSanXuatNhatKyService.formData.Note">
                    </div>
                    <div class="col-lg-4 col-sm-12 col-12">
                        <label class="form-label">Tải lên</label>
                        <br/>
                        <input type="file" multiple
                            (change)="ToChucVuSanXuatNhatKyChangeFileName($event.target.files)">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>
<br />
<br />
<br />