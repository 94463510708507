import { Component, OnInit, Inject, ElementRef, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';
import { DanhMucNguyenVatLieuPhanLoai } from 'src/app/shared/DanhMucNguyenVatLieuPhanLoai.model';
import { DanhMucNguyenVatLieuPhanLoaiService } from 'src/app/shared/DanhMucNguyenVatLieuPhanLoai.service';
import { DanhMucVuSanXuatThietBi } from 'src/app/shared/DanhMucVuSanXuatThietBi.model';
import { DanhMucVuSanXuatThietBiService } from 'src/app/shared/DanhMucVuSanXuatThietBi.service';

import { ToChuc } from 'src/app/shared/ToChuc.model';
import { ToChucService } from 'src/app/shared/ToChuc.service';

import { ToChucVuSanXuatNhatKy } from 'src/app/shared/ToChucVuSanXuatNhatKy.model';
import { ToChucVuSanXuatNhatKyService } from 'src/app/shared/ToChucVuSanXuatNhatKy.service';

@Component({
  selector: 'app-thanh-vien-to-chuc-vu-san-xuat-mobile-to-chuc-vu-san-xuat-nhat-ky-detail-is-mua-vo',
  templateUrl: './thanh-vien-to-chuc-vu-san-xuat-mobile-to-chuc-vu-san-xuat-nhat-ky-detail-is-mua-vo.component.html',
  styleUrls: ['./thanh-vien-to-chuc-vu-san-xuat-mobile-to-chuc-vu-san-xuat-nhat-ky-detail-is-mua-vo.component.css']
})
export class ThanhVienToChucVuSanXuatMobileToChucVuSanXuatNhatKyDetailIsMuaVoComponent implements OnInit {

  isShowLoading: boolean = false;

  constructor(
    private dialog: MatDialog,
    public router: Router,
    public dialogRef: MatDialogRef<ThanhVienToChucVuSanXuatMobileToChucVuSanXuatNhatKyDetailIsMuaVoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    public NotificationService: NotificationService,
    public DownloadService: DownloadService,


    public DanhMucNguyenVatLieuPhanLoaiService: DanhMucNguyenVatLieuPhanLoaiService,
    public DanhMucVuSanXuatThietBiService: DanhMucVuSanXuatThietBiService,

    public ToChucService: ToChucService,

    public ToChucVuSanXuatNhatKyService: ToChucVuSanXuatNhatKyService,

  ) { }

  ngOnInit(): void {
    this.DanhMucVuSanXuatThietBiSearch();
    this.DanhMucNguyenVatLieuPhanLoaiSearch();
    this.ToChucSearch();
  }
  DanhMucVuSanXuatThietBiSearch() {
    this.isShowLoading = true;
    this.DanhMucVuSanXuatThietBiService.baseParameter.Active = true;
    this.DanhMucVuSanXuatThietBiService.GetByActiveToListAsync().subscribe(
      res => {
        this.DanhMucVuSanXuatThietBiService.list = (res as DanhMucVuSanXuatThietBi[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucVuSanXuatThietBiService.list001 = this.DanhMucVuSanXuatThietBiService.list;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucNguyenVatLieuPhanLoaiSearch() {
    this.isShowLoading = true;
    this.DanhMucNguyenVatLieuPhanLoaiService.baseParameter.Active = true;
    this.DanhMucNguyenVatLieuPhanLoaiService.GetByActiveToListAsync().subscribe(
      res => {
        this.DanhMucNguyenVatLieuPhanLoaiService.list = (res as DanhMucNguyenVatLieuPhanLoai[]).sort((a, b) => (a.Name > b.Name ? 1 : -1));
        this.DanhMucNguyenVatLieuPhanLoaiService.list001 = this.DanhMucNguyenVatLieuPhanLoaiService.list;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  ToChucSearch() {
    this.isShowLoading = true;
    this.ToChucService.baseParameter.ParentID = environment.DanhMucToChucIDHaTang;
    this.ToChucService.baseParameter.Active = true;
    this.ToChucService.GetByParentIDAndActiveToListAsync().subscribe(
      res => {
        this.ToChucService.listHaTang = (res as ToChuc[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.ToChucService.listHaTang001 = this.ToChucService.listHaTang;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucVuSanXuatThietBiFilter(searchString: string) {
    searchString = searchString.trim();
    this.DanhMucVuSanXuatThietBiService.Filter(searchString);
  }
  ToChucVuSanXuatNhatKyFilter(searchString: string) {
    searchString = searchString.trim();
    this.ToChucVuSanXuatNhatKyService.Filter54(searchString);
  }
  ToChucFilter(searchString: string) {
    searchString = searchString.trim();
    this.ToChucService.FilterHaTang(searchString);
  }
  ToChucVuSanXuatNhatKySave() {
    this.isShowLoading = true;
    this.ToChucVuSanXuatNhatKyService.formData.IsMuaVo = false;
    this.ToChucVuSanXuatNhatKyService.SaveAndUploadFilesAsync().subscribe(
      res => {
        this.ToChucVuSanXuatNhatKyService.formData = res as ToChucVuSanXuatNhatKy;
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }
  ToChucVuSanXuatNhatKyChangeFileName(files: FileList) {
    if (files) {
      this.ToChucVuSanXuatNhatKyService.fileToUpload = files;
    }
  }
  DateBatDau(value) {
    this.ToChucVuSanXuatNhatKyService.formData.BatDau = new Date(value);
  }
  DateKetThuc(value) {
    this.ToChucVuSanXuatNhatKyService.formData.KetThuc = new Date(value);
  }
  Close() {
    this.dialogRef.close();
  }
}