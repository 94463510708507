<div class="app-hero-header d-flex align-items-center">
    <div class="d-flex align-items-center">
        <div class="me-3 icon-box md bg-white rounded-4">
            <i class="bi bi-gear fs-3 text-primary"></i>
        </div>
        <div>
            <h2 class="mb-1">Dữ liệu cơ sở</h2>
            <h6 class="m-0 text-dark fw-light">
                Mô hình thí điểm
            </h6>
        </div>
    </div>
    <div class="ms-auto d-lg-flex d-none flex-row">
        <div class="d-flex flex-row gap-2">
        </div>
    </div>
</div>
<div class="app-body">
    <div class="row gx-4">
        <div class="col-xxl-12">
            <div class="card mb-4">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-DanhMucVuSanXuatPhanDoan" data-bs-toggle="tab"
                                    href="#DanhMucVuSanXuatPhanDoan" role="tab" aria-controls="DanhMucVuSanXuatPhanDoan"
                                    aria-selected="true">
                                    <span class="badge bg-info" style="font-size: 16px;">Phân đoạn</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-DanhMucVuSanXuatTieuChi" data-bs-toggle="tab"
                                    href="#DanhMucVuSanXuatTieuChi" role="tab" aria-controls="DanhMucVuSanXuatTieuChi"
                                    aria-selected="false">
                                    <span class="badge bg-success" style="font-size: 16px;">Tiêu chí</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-DanhMucVuMua" data-bs-toggle="tab" href="#DanhMucVuMua"
                                    role="tab" aria-controls="DanhMucVuMua" aria-selected="false">
                                    <span class="badge bg-danger" style="font-size: 16px;">Vụ mùa</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-QuyTrinhSanXuat" data-bs-toggle="tab" href="#QuyTrinhSanXuat"
                                    role="tab" aria-controls="QuyTrinhSanXuat" aria-selected="false">
                                    <span class="badge bg-warning" style="font-size: 16px;">Quy trình sản xuất</span>
                                </a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" id="tab-DanhMucVuSanXuatThietBi" data-bs-toggle="tab"
                                    href="#DanhMucVuSanXuatThietBi" role="tab"
                                    aria-controls="DanhMucVuSanXuatThietBi" aria-selected="false">
                                    <span class="badge bg-primary" style="font-size: 16px;">Thiết bị</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div class="tab-pane fade show active" id="DanhMucVuSanXuatPhanDoan" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-10 col-sm-12 col-12">
                                        <input [(ngModel)]="DanhMucVuSanXuatPhanDoanService.SearchString"
                                            placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="DanhMucVuSanXuatPhanDoanSearch()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <a class="btn btn-success" title="Tìm"
                                            (click)="DanhMucVuSanXuatPhanDoanSearch()"><i
                                                class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                    </div>
                                </div>
                                <div class="card mb-4 card-info">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Phân đoạn
                                            <span
                                                *ngIf="DanhMucVuSanXuatPhanDoanService.list">({{DanhMucVuSanXuatPhanDoanService.list.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #DanhMucVuSanXuatPhanDoanSort="matSort"
                                                    [dataSource]="DanhMucVuSanXuatPhanDoanService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{DanhMucVuSanXuatPhanDoanPaginator.pageSize *
                                                            DanhMucVuSanXuatPhanDoanPaginator.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>ID
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.ID}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ParentID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Parent
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="number" placeholder="0"
                                                                name="ParentID{{element.ID}}"
                                                                [(ngModel)]="element.ParentID"
                                                                style="text-align: right;">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Code">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Code</th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text" placeholder="Code"
                                                                name="Code{{element.ID}}" [(ngModel)]="element.Code">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu đề
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Tiêu đề" name="Name{{element.ID}}"
                                                                [(ngModel)]="element.Name">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Note">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi chú
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Ghi chú" name="Note{{element.ID}}"
                                                                [(ngModel)]="element.Note">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SortOrder">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sắp xếp
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="number" placeholder="0"
                                                                name="SortOrder{{element.ID}}"
                                                                [(ngModel)]="element.SortOrder"
                                                                style="text-align: right;">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Active">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Kích hoạt
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="Active{{element.ID}}"
                                                                    [(ngModel)]="element.Active">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info"
                                                                (click)="DanhMucVuSanXuatPhanDoanSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger"
                                                                (click)="DanhMucVuSanXuatPhanDoanDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="DanhMucVuSanXuatPhanDoanService.displayColumns">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: DanhMucVuSanXuatPhanDoanService.displayColumns;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #DanhMucVuSanXuatPhanDoanPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="DanhMucVuSanXuatTieuChi" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-10 col-sm-12 col-12">
                                        <input [(ngModel)]="DanhMucVuSanXuatTieuChiService.SearchString"
                                            placeholder="Tìm ..." class="form-control"
                                            (keyup.enter)="DanhMucVuSanXuatTieuChiSearch()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <a class="btn btn-success" title="Tìm"
                                            (click)="DanhMucVuSanXuatTieuChiSearch()"><i
                                                class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                    </div>
                                </div>
                                <div class="card mb-4 card-success">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Tiêu chí
                                            <span
                                                *ngIf="DanhMucVuSanXuatTieuChiService.list">({{DanhMucVuSanXuatTieuChiService.list.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #DanhMucVuSanXuatTieuChiSort="matSort"
                                                    [dataSource]="DanhMucVuSanXuatTieuChiService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{DanhMucVuSanXuatTieuChiPaginator.pageSize *
                                                            DanhMucVuSanXuatTieuChiPaginator.pageIndex + i + 1}}                                                           
                                                        </td>
                                                    </ng-container>                                                   
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu chí
                                                        </th>
                                                        <td mat-cell *matCellDef="let element" style="width: 600px;">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Tiêu chí</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Tiêu đề" name="Name{{element.ID}}"
                                                                        [(ngModel)]="element.Name">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Sắp xếp</label>
                                                                    <input class="form-control" type="number"
                                                                        placeholder="0" name="SortOrder{{element.ID}}"
                                                                        [(ngModel)]="element.SortOrder"
                                                                        style="text-align: right;">
                                                                </div>                                                                
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">ID</label>
                                                                    <input class="form-control" type="number" disabled
                                                                        placeholder="0" name="ID{{element.ID}}"
                                                                        [(ngModel)]="element.ID"
                                                                        style="text-align: right;">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <div class="form-check form-check-inline mb-6">
                                                                        <input type="checkbox" class="form-check-input"
                                                                            name="Active{{element.ID}}"
                                                                            [(ngModel)]="element.Active" />
                                                                        <label class="form-check-label"
                                                                            for="Active">Kích hoạt</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="KetQuaNhoNhat2025">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>2025
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Nhỏ nhất</label>
                                                                    <input class="form-control" type="number"
                                                                        placeholder="0"
                                                                        name="KetQuaNhoNhat2025{{element.ID}}"
                                                                        [(ngModel)]="element.KetQuaNhoNhat2025"
                                                                        style="text-align: right;">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Lớn nhất</label>
                                                                    <input class="form-control" type="number"
                                                                        placeholder="0"
                                                                        name="KetQuaLonNhat2025{{element.ID}}"
                                                                        [(ngModel)]="element.KetQuaLonNhat2025"
                                                                        style="text-align: right;">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Đơn vị tính</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Đơn vị tính"
                                                                        name="DonViTinh2025{{element.ID}}"
                                                                        [(ngModel)]="element.DonViTinh2025">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Tỷ
                                                                        lệ hoàn thành (%)</label>
                                                                    <input class="form-control" type="number"
                                                                        placeholder="0"
                                                                        name="TyLeHoanThanh2025{{element.ID}}"
                                                                        [(ngModel)]="element.TyLeHoanThanh2025"
                                                                        style="text-align: right;">
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="KetQuaNhoNhat2030">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>2030
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Nhỏ nhất</label>
                                                                    <input class="form-control" type="number"
                                                                        placeholder="0"
                                                                        name="KetQuaNhoNhat2030{{element.ID}}"
                                                                        [(ngModel)]="element.KetQuaNhoNhat2030"
                                                                        style="text-align: right;">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Lớn nhất</label>
                                                                    <input class="form-control" type="number"
                                                                        placeholder="0"
                                                                        name="KetQuaLonNhat2030{{element.ID}}"
                                                                        [(ngModel)]="element.KetQuaLonNhat2030"
                                                                        style="text-align: right;">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Đơn vị tính</label>
                                                                    <input class="form-control" type="text"
                                                                        placeholder="Đơn vị tính"
                                                                        name="DonViTinh2030{{element.ID}}"
                                                                        [(ngModel)]="element.DonViTinh2030">
                                                                </div>
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <label class="form-label">Tỷ
                                                                        lệ hoàn thành (%)</label>
                                                                    <input class="form-control" type="number"
                                                                        placeholder="0"
                                                                        name="TyLeHoanThanh2030{{element.ID}}"
                                                                        [(ngModel)]="element.TyLeHoanThanh2030"
                                                                        style="text-align: right;">
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info"
                                                                (click)="DanhMucVuSanXuatTieuChiSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger"
                                                                (click)="DanhMucVuSanXuatTieuChiDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="DanhMucVuSanXuatTieuChiService.displayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: DanhMucVuSanXuatTieuChiService.displayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #DanhMucVuSanXuatTieuChiPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="DanhMucVuMua" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-10 col-sm-12 col-12">
                                        <input [(ngModel)]="DanhMucVuMuaService.SearchString" placeholder="Tìm ..."
                                            class="form-control" (keyup.enter)="DanhMucVuMuaSearch()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <a class="btn btn-success" title="Tìm" (click)="DanhMucVuMuaSearch()"><i
                                                class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                    </div>
                                </div>
                                <div class="card mb-4 card-danger">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Vụ mùa
                                            <span *ngIf="DanhMucVuMuaService.list">({{DanhMucVuMuaService.list.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #DanhMucVuMuaSort="matSort"
                                                    [dataSource]="DanhMucVuMuaService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{DanhMucVuMuaPaginator.pageSize *
                                                            DanhMucVuMuaPaginator.pageIndex + i + 1}} [{{element.ID}}]
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Code">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Code</th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text" placeholder="Code"
                                                                name="Code{{element.ID}}" [(ngModel)]="element.Code">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu đề
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Tiêu đề" name="Name{{element.ID}}"
                                                                [(ngModel)]="element.Name">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ThangBatDau">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tháng bắt
                                                            đầu
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="number" placeholder="0"
                                                                name="ThangBatDau{{element.ID}}"
                                                                [(ngModel)]="element.ThangBatDau"
                                                                style="text-align: right;">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ThangKetThuc">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tháng kết
                                                            thúc
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="number" placeholder="0"
                                                                name="ThangKetThuc{{element.ID}}"
                                                                [(ngModel)]="element.ThangKetThuc"
                                                                style="text-align: right;">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SortOrder">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sắp xếp
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="number" placeholder="0"
                                                                name="SortOrder{{element.ID}}"
                                                                [(ngModel)]="element.SortOrder"
                                                                style="text-align: right;">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Active">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Kích hoạt
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="Active{{element.ID}}"
                                                                    [(ngModel)]="element.Active">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info"
                                                                (click)="DanhMucVuMuaSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger"
                                                                (click)="DanhMucVuMuaDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="DanhMucVuMuaService.displayColumns001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: DanhMucVuMuaService.displayColumns001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #DanhMucVuMuaPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="QuyTrinhSanXuat" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-10 col-sm-12 col-12">
                                        <input [(ngModel)]="QuyTrinhSanXuatService.baseParameter.SearchString" placeholder="Tìm ..."
                                            class="form-control" (keyup.enter)="QuyTrinhSanXuatSearch()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <a class="btn btn-success" title="Tìm" (click)="QuyTrinhSanXuatSearch()"><i
                                                class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                    </div>
                                </div>
                                <div class="card mb-4 card-warning">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Quy trình kỹ thuật sản xuất lúa chất lượng cao và phát thải thấp vùng đồng bằng sông Cửu Long
                                            <span *ngIf="QuyTrinhSanXuatService.list">({{QuyTrinhSanXuatService.list.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #QuyTrinhSanXuatSort="matSort"
                                                    [dataSource]="QuyTrinhSanXuatService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{QuyTrinhSanXuatPaginator.pageSize *
                                                                QuyTrinhSanXuatPaginator.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>ID
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.ID}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ParentID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Parent
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="number" placeholder="0"
                                                                name="ParentID{{element.ID}}"
                                                                [(ngModel)]="element.ParentID"
                                                                style="text-align: right;">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Code">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Code</th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text" placeholder="Code"
                                                                name="Code{{element.ID}}" [(ngModel)]="element.Code">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu đề
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Tiêu đề" name="Name{{element.ID}}"
                                                                [(ngModel)]="element.Name">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Note">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi chú
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Ghi chú" name="Note{{element.ID}}"
                                                                [(ngModel)]="element.Note">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SortOrder">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sắp xếp
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="number" placeholder="0"
                                                                name="SortOrder{{element.ID}}"
                                                                [(ngModel)]="element.SortOrder"
                                                                style="text-align: right;">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Active">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Kích hoạt
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="Active{{element.ID}}"
                                                                    [(ngModel)]="element.Active">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info"
                                                                (click)="QuyTrinhSanXuatSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger"
                                                                (click)="QuyTrinhSanXuatDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="QuyTrinhSanXuatService.displayColumns">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: QuyTrinhSanXuatService.displayColumns;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #QuyTrinhSanXuatPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="DanhMucVuSanXuatThietBi" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-10 col-sm-12 col-12">
                                        <input [(ngModel)]="DanhMucVuSanXuatThietBiService.baseParameter.SearchString" placeholder="Tìm ..."
                                            class="form-control" (keyup.enter)="DanhMucVuSanXuatThietBiSearch()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <a class="btn btn-success" title="Tìm" (click)="DanhMucVuSanXuatThietBiSearch()"><i
                                                class="bi bi-search"></i></a>&nbsp;&nbsp;&nbsp;
                                    </div>
                                </div>
                                <div class="card mb-4 card-primary">
                                    <div class="card-header">
                                        <h5 class="card-title text-white bg-primary">Thiết bị
                                            <span *ngIf="DanhMucVuSanXuatThietBiService.list">({{DanhMucVuSanXuatThietBiService.list.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body bg-primary">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #DanhMucVuSanXuatThietBiSort="matSort"
                                                    [dataSource]="DanhMucVuSanXuatThietBiService.DataSource"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="STT">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            STT
                                                        </th>
                                                        <td mat-cell *matCellDef="let element; index as i;"
                                                            style="text-align: center;">
                                                            {{DanhMucVuSanXuatThietBiPaginator.pageSize *
                                                                DanhMucVuSanXuatThietBiPaginator.pageIndex + i + 1}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>ID
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            {{element.ID}}
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="ParentID">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Parent
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="number" placeholder="0"
                                                                name="ParentID{{element.ID}}"
                                                                [(ngModel)]="element.ParentID"
                                                                style="text-align: right;">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Code">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Code</th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text" placeholder="Code"
                                                                name="Code{{element.ID}}" [(ngModel)]="element.Code">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Name">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu đề
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Tiêu đề" name="Name{{element.ID}}"
                                                                [(ngModel)]="element.Name">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Note">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ghi chú
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="text"
                                                                placeholder="Ghi chú" name="Note{{element.ID}}"
                                                                [(ngModel)]="element.Note">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="SortOrder">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sắp xếp
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <input class="form-control" type="number" placeholder="0"
                                                                name="SortOrder{{element.ID}}"
                                                                [(ngModel)]="element.SortOrder"
                                                                style="text-align: right;">
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Active">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Kích hoạt
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="Active{{element.ID}}"
                                                                    [(ngModel)]="element.Active">
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="Save">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <a class="btn btn-info"
                                                                (click)="DanhMucVuSanXuatThietBiSave(element)"><i
                                                                    class="bi bi-sd-card"></i>
                                                            </a>&nbsp;&nbsp;&nbsp;
                                                            <a class="btn btn-danger"
                                                                (click)="DanhMucVuSanXuatThietBiDelete(element)"
                                                                *ngIf="element.ID>0"><i class="bi bi-trash"></i>
                                                            </a>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="DanhMucVuSanXuatThietBiService.displayColumns">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: DanhMucVuSanXuatThietBiService.displayColumns;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #DanhMucVuSanXuatThietBiPaginator="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>