import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
    providedIn: 'root'
})
export class UploadService {
    aPIURL: string = environment.APIUploadURL;
    controller: string = "Upload";
    constructor(private httpClient: HttpClient) {
        this.initializationFormData();
    }
    initializationFormData() {
    }
    PostToChucByExcelFileAsync(fileToUpload: File) {
        let url = this.aPIURL + this.controller + '/PostToChucByExcelFileAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('file', fileToUpload, fileToUpload.name);
        return this.httpClient.post(url, formUpload);
    }
    PostToChucFixErrorByExcelFileAsync(fileToUpload: File) {
        let url = this.aPIURL + this.controller + '/PostToChucFixErrorByExcelFileAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('file', fileToUpload, fileToUpload.name);
        return this.httpClient.post(url, formUpload);
    }
    PostDanhMucNguyenVatLieuByExcelFileAsync(fileToUpload: File) {
        let url = this.aPIURL + this.controller + '/PostDanhMucNguyenVatLieuByExcelFileAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('file', fileToUpload, fileToUpload.name);
        return this.httpClient.post(url, formUpload);
    }
    PostToChucQuyTrinhSanXuatByExcelFileAsync(fileToUpload: File) {
        let url = this.aPIURL + this.controller + '/PostToChucQuyTrinhSanXuatByExcelFileAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('file', fileToUpload, fileToUpload.name);
        return this.httpClient.post(url, formUpload);
    }
    PostThanhVien_CoQuanQuanLyByExcelFileAsync(fileToUpload: File) {
        let url = this.aPIURL + this.controller + '/PostThanhVien_CoQuanQuanLyByExcelFileAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('file', fileToUpload, fileToUpload.name);
        return this.httpClient.post(url, formUpload);
    }
    PostThanhVienByExcelFileAsync(fileToUpload: File,  parentID: number) {
        let url = this.aPIURL + this.controller + '/PostThanhVienByExcelFileAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('file', fileToUpload, fileToUpload.name);
        formUpload.append('parentID', JSON.stringify(parentID));
        return this.httpClient.post(url, formUpload);
    }
    PostDanhMucSanPhamGiongByExcelFileAsync(fileToUpload: File) {        
        let url = this.aPIURL + this.controller + '/PostDanhMucSanPhamGiongByExcelFileAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('file', fileToUpload, fileToUpload.name);        
        return this.httpClient.post(url, formUpload);
    }
    PostDanhMucSanPhamGiongBaoHoByExcelFileAsync(fileToUpload: File) {
        let url = this.aPIURL + this.controller + '/PostDanhMucSanPhamGiongBaoHoByExcelFileAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('file', fileToUpload, fileToUpload.name);        
        return this.httpClient.post(url, formUpload);
    }
    PostDanhMucTinhThanhToaDoByJSON1FileAsync(fileToUpload: File) {
        let url = this.aPIURL + this.controller + '/PostDanhMucTinhThanhToaDoByJSON1FileAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('file', fileToUpload, fileToUpload.name);        
        return this.httpClient.post(url, formUpload);
    }
    PostDanhMucTinhThanhToaDoByJSON2FileAsync(fileToUpload: File) {
        let url = this.aPIURL + this.controller + '/PostDanhMucTinhThanhToaDoByJSON2FileAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('file', fileToUpload, fileToUpload.name);        
        return this.httpClient.post(url, formUpload);
    }
    PostDanhMucTinhThanhToaDoByJSON3FileAsync(fileToUpload: File) {
        let url = this.aPIURL + this.controller + '/PostDanhMucTinhThanhToaDoByJSON3FileAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('file', fileToUpload, fileToUpload.name);        
        return this.httpClient.post(url, formUpload);
    }
    PostQuyTrinhSanXuatByExcelFileAsync(fileToUpload: File) {
        let url = this.aPIURL + this.controller + '/PostQuyTrinhSanXuatByExcelFileAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('file', fileToUpload, fileToUpload.name);        
        return this.httpClient.post(url, formUpload);
    }
}

