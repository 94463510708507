import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';
import { ToChuc } from 'src/app/shared/ToChuc.model';
import { ToChucService } from 'src/app/shared/ToChuc.service';

import { ToChucVuSanXuat } from 'src/app/shared/ToChucVuSanXuat.model';
import { ToChucVuSanXuatService } from 'src/app/shared/ToChucVuSanXuat.service';
import { ToChucVuSanXuatVungTrong } from 'src/app/shared/ToChucVuSanXuatVungTrong.model';
import { ToChucVuSanXuatVungTrongService } from 'src/app/shared/ToChucVuSanXuatVungTrong.service';
import { ToChucVuSanXuatNhatKy } from 'src/app/shared/ToChucVuSanXuatNhatKy.model';
import { ToChucVuSanXuatNhatKyService } from 'src/app/shared/ToChucVuSanXuatNhatKy.service';

@Component({
  selector: 'app-thanh-vien-to-chuc-vu-san-xuat-mobile-to-chuc-vu-san-xuat-vung-trong',
  templateUrl: './thanh-vien-to-chuc-vu-san-xuat-mobile-to-chuc-vu-san-xuat-vung-trong.component.html',
  styleUrls: ['./thanh-vien-to-chuc-vu-san-xuat-mobile-to-chuc-vu-san-xuat-vung-trong.component.css']
})
export class ThanhVienToChucVuSanXuatMobileToChucVuSanXuatVungTrongComponent implements OnInit {

  @ViewChild('ToChucVuSanXuatVungTrongSort') ToChucVuSanXuatVungTrongSort: MatSort;
  @ViewChild('ToChucVuSanXuatVungTrongPaginator') ToChucVuSanXuatVungTrongPaginator: MatPaginator;

  isShowLoading: boolean = false;
  constructor(
    private dialog: MatDialog,

    public NotificationService: NotificationService,
    public DownloadService: DownloadService,

    public ThanhVienService: ThanhVienService,

    public ToChucService: ToChucService,

    public ToChucVuSanXuatService: ToChucVuSanXuatService,
    public ToChucVuSanXuatVungTrongService: ToChucVuSanXuatVungTrongService,
    public ToChucVuSanXuatNhatKyService: ToChucVuSanXuatNhatKyService,
  ) { }

  ngOnInit(): void {
    this.ThanhVienSearch();
    this.ToChucVuSanXuatSearch();
  }
  ThanhVienSearch() {
    this.ThanhVienService.GetLogin();
  }
  ToChucVuSanXuatSearch() {
    this.isShowLoading = true;
    this.ToChucVuSanXuatService.baseParameter.ParentID = this.ThanhVienService.formDataLogin.ToChucID;
    this.ToChucVuSanXuatService.GetByParentID_SearchString_YearToListAsync().subscribe(
      res => {
        this.ToChucVuSanXuatService.list = (res as ToChucVuSanXuat[]).sort((a, b) => (a.BatDau < b.BatDau ? 1 : -1));
        this.ToChucVuSanXuatService.list001=this.ToChucVuSanXuatService.list;
        if (this.ToChucVuSanXuatService.list001) {
          if (this.ToChucVuSanXuatService.list001.length > 0) {
            this.ToChucVuSanXuatVungTrongService.baseParameter.ParentID = this.ToChucVuSanXuatService.list001[0].ID;
          }
        }
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  ToChucVuSanXuatFilter(searchString: string) {
    this.ToChucVuSanXuatService.Filter(searchString);
  }
  ToChucVuSanXuatVungTrongSearch() {
    if (this.ToChucVuSanXuatVungTrongService.baseParameter.SearchString.length > 0) {
      this.ToChucVuSanXuatVungTrongService.DataSource.filter = this.ToChucVuSanXuatVungTrongService.baseParameter.SearchString.toLowerCase();
    }
    else {
      this.isShowLoading = true;      
      this.ToChucVuSanXuatVungTrongService.GetByParentIDToListAsync().subscribe(
        res => {
          this.ToChucVuSanXuatVungTrongService.list = (res as ToChucVuSanXuatVungTrong[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
          this.ToChucVuSanXuatVungTrongService.DataSource = new MatTableDataSource(this.ToChucVuSanXuatVungTrongService.list);
          this.ToChucVuSanXuatVungTrongService.DataSource.sort = this.ToChucVuSanXuatVungTrongSort;
          this.ToChucVuSanXuatVungTrongService.DataSource.paginator = this.ToChucVuSanXuatVungTrongPaginator;
          this.isShowLoading = false;
        },
        err => {
          this.isShowLoading = false;
        }
      );
    }    
  }
  ToChucVuSanXuatVungTrongSave(element: ToChucVuSanXuatVungTrong) {
    element.ParentID = this.ToChucVuSanXuatVungTrongService.baseParameter.ParentID;
    this.ToChucVuSanXuatVungTrongService.formData = element;
    this.ToChucVuSanXuatVungTrongService.SaveAsync().subscribe(
      res => {
        this.ToChucVuSanXuatVungTrongSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }
  ToChucVuSanXuatNhatKyCopy(element: ToChucVuSanXuatVungTrong) {
    if (confirm(environment.CopyNhatKy)) {
      this.isShowLoading = true;
      this.ToChucVuSanXuatNhatKyService.baseParameter.ToChucVuSanXuatVungTrongID = element.ID;
      this.ToChucVuSanXuatNhatKyService.CopyFullSQLByToChucVuSanXuatVungTrongIDAsync().subscribe(
        res => {
          this.NotificationService.warn(environment.SaveSuccess);
          this.isShowLoading = false;
        },
        err => {
          this.NotificationService.warn(environment.SaveNotSuccess);
          this.isShowLoading = false;
        }
      );
    }
  }
 
}
