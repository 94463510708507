import { Component, OnInit, Inject, ElementRef, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';
import { DanhMucNguyenVatLieuPhanLoai } from 'src/app/shared/DanhMucNguyenVatLieuPhanLoai.model';
import { DanhMucNguyenVatLieuPhanLoaiService } from 'src/app/shared/DanhMucNguyenVatLieuPhanLoai.service';
import { DanhMucVuMua } from 'src/app/shared/DanhMucVuMua.model';
import { DanhMucVuMuaService } from 'src/app/shared/DanhMucVuMua.service';
import { DanhMucVuSanXuatPhanDoan } from 'src/app/shared/DanhMucVuSanXuatPhanDoan.model';
import { DanhMucVuSanXuatPhanDoanService } from 'src/app/shared/DanhMucVuSanXuatPhanDoan.service';
import { DanhMucVuSanXuatThietBi } from 'src/app/shared/DanhMucVuSanXuatThietBi.model';
import { DanhMucVuSanXuatThietBiService } from 'src/app/shared/DanhMucVuSanXuatThietBi.service';

import { ToChuc } from 'src/app/shared/ToChuc.model';
import { ToChucService } from 'src/app/shared/ToChuc.service';

import { ToChucVuSanXuat } from 'src/app/shared/ToChucVuSanXuat.model';
import { ToChucVuSanXuatService } from 'src/app/shared/ToChucVuSanXuat.service';

import { ToChucVuSanXuatVungTrong } from 'src/app/shared/ToChucVuSanXuatVungTrong.model';
import { ToChucVuSanXuatVungTrongService } from 'src/app/shared/ToChucVuSanXuatVungTrong.service';

import { ToChucVuSanXuatNhatKy } from 'src/app/shared/ToChucVuSanXuatNhatKy.model';
import { ToChucVuSanXuatNhatKyService } from 'src/app/shared/ToChucVuSanXuatNhatKy.service';

import { ToChucVuSanXuatTieuChi } from 'src/app/shared/ToChucVuSanXuatTieuChi.model';
import { ToChucVuSanXuatTieuChiService } from 'src/app/shared/ToChucVuSanXuatTieuChi.service';

import { ToChucVuSanXuatTapTinDinhKem } from 'src/app/shared/ToChucVuSanXuatTapTinDinhKem.model';
import { ToChucVuSanXuatTapTinDinhKemService } from 'src/app/shared/ToChucVuSanXuatTapTinDinhKem.service';
import { ToChucVuSanXuatNhatKyDetailComponent } from '../to-chuc-vu-san-xuat-nhat-ky-detail/to-chuc-vu-san-xuat-nhat-ky-detail.component';
import { ToChucVuSanXuatNhatKyComponent } from '../to-chuc-vu-san-xuat-nhat-ky/to-chuc-vu-san-xuat-nhat-ky.component';

@Component({
  selector: 'app-thanh-vien-to-chuc-vu-san-xuat-detail',
  templateUrl: './thanh-vien-to-chuc-vu-san-xuat-detail.component.html',
  styleUrls: ['./thanh-vien-to-chuc-vu-san-xuat-detail.component.css']
})
export class ThanhVienToChucVuSanXuatDetailComponent implements OnInit {

  @ViewChild('ToChucVuSanXuatTapTinDinhKemSort') ToChucVuSanXuatTapTinDinhKemSort: MatSort;
  @ViewChild('ToChucVuSanXuatTapTinDinhKemPaginator') ToChucVuSanXuatTapTinDinhKemPaginator: MatPaginator;

  @ViewChild('ToChucVuSanXuatNhatKySort54') ToChucVuSanXuatNhatKySort54: MatSort;
  @ViewChild('ToChucVuSanXuatNhatKyPaginator54') ToChucVuSanXuatNhatKyPaginator54: MatPaginator;

  @ViewChild('ToChucVuSanXuatNhatKySort55') ToChucVuSanXuatNhatKySort55: MatSort;
  @ViewChild('ToChucVuSanXuatNhatKyPaginator55') ToChucVuSanXuatNhatKyPaginator55: MatPaginator;

  @ViewChild('ToChucVuSanXuatNhatKySort') ToChucVuSanXuatNhatKySort: MatSort;
  @ViewChild('ToChucVuSanXuatNhatKyPaginator') ToChucVuSanXuatNhatKyPaginator: MatPaginator;

  @ViewChild('ToChucVuSanXuatNhatKyIsMuaVoSort') ToChucVuSanXuatNhatKyIsMuaVoSort: MatSort;
  @ViewChild('ToChucVuSanXuatNhatKyIsMuaVoPaginator') ToChucVuSanXuatNhatKyIsMuaVoPaginator: MatPaginator;

  @ViewChild('ToChucVuSanXuatVungTrongSort') ToChucVuSanXuatVungTrongSort: MatSort;
  @ViewChild('ToChucVuSanXuatVungTrongPaginator') ToChucVuSanXuatVungTrongPaginator: MatPaginator;

  @ViewChild('ToChucVuSanXuatTieuChiSort') ToChucVuSanXuatTieuChiSort: MatSort;
  @ViewChild('ToChucVuSanXuatTieuChiPaginator') ToChucVuSanXuatTieuChiPaginator: MatPaginator;

  isShowLoading: boolean = false;

  IsChungThuc54: boolean = false;
  IsChungThuc55: boolean = false;
  IsChungThuc00: boolean = false;
  IsChungThucIsMuaVo: boolean = false;

  constructor(
    private dialog: MatDialog,
    public router: Router,
    public dialogRef: MatDialogRef<ThanhVienToChucVuSanXuatDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    public NotificationService: NotificationService,
    public DownloadService: DownloadService,


    public DanhMucNguyenVatLieuPhanLoaiService: DanhMucNguyenVatLieuPhanLoaiService,
    public DanhMucVuMuaService: DanhMucVuMuaService,
    public DanhMucVuSanXuatPhanDoanService: DanhMucVuSanXuatPhanDoanService,
    public DanhMucVuSanXuatThietBiService: DanhMucVuSanXuatThietBiService,

    public ToChucService: ToChucService,


    public ToChucVuSanXuatService: ToChucVuSanXuatService,
    public ToChucVuSanXuatVungTrongService: ToChucVuSanXuatVungTrongService,
    public ToChucVuSanXuatNhatKyService: ToChucVuSanXuatNhatKyService,
    public ToChucVuSanXuatTieuChiService: ToChucVuSanXuatTieuChiService,
    public ToChucVuSanXuatTapTinDinhKemService: ToChucVuSanXuatTapTinDinhKemService,

  ) {

  }

  ngOnInit(): void {
    this.DanhMucVuSanXuatThietBiSearch();
    this.DanhMucVuSanXuatPhanDoanSearch();
    this.DanhMucNguyenVatLieuPhanLoaiSearch();
    this.DanhMucVuMuaSearch();
    this.ToChucSearch();
    this.ToChucVuSanXuatVungTrongSearch();
  }

  ToChucVuSanXuatNhatKyChungThuc54Change() {
    if (confirm(environment.ChungThucConfirm)) {
      this.isShowLoading = true;
      for (let index = 0; index < this.ToChucVuSanXuatNhatKyService.list54.length; index++) {
        if (this.ToChucVuSanXuatNhatKyService.list54[index].Active == true) {
          this.ToChucVuSanXuatNhatKyService.list54[index].IsChungThuc = this.IsChungThuc54;
        }
      }
      this.isShowLoading = true;
      this.ToChucVuSanXuatNhatKyService.SaveListAsync(this.ToChucVuSanXuatNhatKyService.list54).subscribe(
        res => {
          this.NotificationService.warn(environment.SaveSuccess);
          this.isShowLoading = false;
        },
        err => {
          this.NotificationService.warn(environment.SaveNotSuccess);
          this.isShowLoading = false;
        }
      );
    }
  }
  ToChucVuSanXuatNhatKyChungThuc55Change() {
    if (confirm(environment.ChungThucConfirm)) {
      this.isShowLoading = true;
      for (let index = 0; index < this.ToChucVuSanXuatNhatKyService.list55.length; index++) {
        if (this.ToChucVuSanXuatNhatKyService.list55[index].Active == true) {
          this.ToChucVuSanXuatNhatKyService.list55[index].IsChungThuc = this.IsChungThuc55;
        }
      }
      this.isShowLoading = true;
      this.ToChucVuSanXuatNhatKyService.SaveListAsync(this.ToChucVuSanXuatNhatKyService.list55).subscribe(
        res => {
          this.NotificationService.warn(environment.SaveSuccess);
          this.isShowLoading = false;
        },
        err => {
          this.NotificationService.warn(environment.SaveNotSuccess);
          this.isShowLoading = false;
        }
      );
    }
  }
  ToChucVuSanXuatNhatKyChungThuc00Change() {
    if (confirm(environment.ChungThucConfirm)) {
      this.isShowLoading = true;
      for (let index = 0; index < this.ToChucVuSanXuatNhatKyService.list.length; index++) {
        if (this.ToChucVuSanXuatNhatKyService.list[index].Active == true) {
          this.ToChucVuSanXuatNhatKyService.list[index].IsChungThuc = this.IsChungThuc00;
        }
      }
      this.isShowLoading = true;
      this.ToChucVuSanXuatNhatKyService.SaveListAsync(this.ToChucVuSanXuatNhatKyService.list).subscribe(
        res => {
          this.NotificationService.warn(environment.SaveSuccess);
          this.isShowLoading = false;
        },
        err => {
          this.NotificationService.warn(environment.SaveNotSuccess);
          this.isShowLoading = false;
        }
      );
    }
  }
  ToChucVuSanXuatNhatKyChungThucIsMuaVoChange() {
    if (confirm(environment.ChungThucConfirm)) {
      this.isShowLoading = true;
      for (let index = 0; index < this.ToChucVuSanXuatNhatKyService.listIsMuaVo.length; index++) {
        if (this.ToChucVuSanXuatNhatKyService.listIsMuaVo[index].Active == true) {
          this.ToChucVuSanXuatNhatKyService.listIsMuaVo[index].IsChungThuc = this.IsChungThucIsMuaVo;
        }
      }
      this.isShowLoading = true;
      this.ToChucVuSanXuatNhatKyService.SaveListAsync(this.ToChucVuSanXuatNhatKyService.listIsMuaVo).subscribe(
        res => {
          this.NotificationService.warn(environment.SaveSuccess);
          this.isShowLoading = false;
        },
        err => {
          this.NotificationService.warn(environment.SaveNotSuccess);
          this.isShowLoading = false;
        }
      );
    }
  }

  ToChucVuSanXuatTapTinDinhKemSearch() {
    if (this.ToChucVuSanXuatTapTinDinhKemService.baseParameter.SearchString.length > 0) {
      this.ToChucVuSanXuatTapTinDinhKemService.DataSource.filter = this.ToChucVuSanXuatTapTinDinhKemService.baseParameter.SearchString.toLowerCase();
    }
    else {
      this.isShowLoading = true;
      this.ToChucVuSanXuatTapTinDinhKemService.GetByToChucVuSanXuatVungTrongID_EmptyToListAsync().subscribe(
        res => {
          this.ToChucVuSanXuatTapTinDinhKemService.list = (res as ToChucVuSanXuatTapTinDinhKem[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
          this.ToChucVuSanXuatTapTinDinhKemService.DataSource = new MatTableDataSource(this.ToChucVuSanXuatTapTinDinhKemService.list);
          this.ToChucVuSanXuatTapTinDinhKemService.DataSource.sort = this.ToChucVuSanXuatTapTinDinhKemSort;
          this.ToChucVuSanXuatTapTinDinhKemService.DataSource.paginator = this.ToChucVuSanXuatTapTinDinhKemPaginator;
          this.isShowLoading = false;
        },
        err => {
          this.isShowLoading = false;
        }
      );
    }
  }
  ToChucVuSanXuatTapTinDinhKemSave(element: ToChucVuSanXuatTapTinDinhKem) {
    this.isShowLoading = true;
    element.ParentID = this.ToChucVuSanXuatService.formData.ID;
    element.ToChucVuSanXuatVungTrongID = this.ToChucVuSanXuatTapTinDinhKemService.baseParameter.ToChucVuSanXuatVungTrongID;
    this.ToChucVuSanXuatTapTinDinhKemService.formData = element;
    this.ToChucVuSanXuatTapTinDinhKemService.SaveAndUploadFilesAsync().subscribe(
      res => {
        this.ToChucVuSanXuatTapTinDinhKemSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }
  ToChucVuSanXuatTapTinDinhKemDelete(element: ToChucVuSanXuatTapTinDinhKem) {
    this.isShowLoading = true;
    this.ToChucVuSanXuatTapTinDinhKemService.baseParameter.ID = element.ID;
    this.ToChucVuSanXuatTapTinDinhKemService.RemoveAsync().subscribe(
      res => {
        this.ToChucVuSanXuatTapTinDinhKemSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }

  ToChucVuSanXuatNhatKySearch54() {
    if (this.ToChucVuSanXuatNhatKyService.baseParameter.SearchString.length > 0) {
      this.ToChucVuSanXuatNhatKyService.DataSource54.filter = this.ToChucVuSanXuatNhatKyService.baseParameter.SearchString.toLowerCase();
    }
    else {
      this.isShowLoading = true;
      this.ToChucVuSanXuatNhatKyService.baseParameter.DanhMucNguyenVatLieuPhanLoaiID = environment.DanhMucNguyenVatLieuPhanLoaiID54;
      this.ToChucVuSanXuatNhatKyService.baseParameter.Active = true;
      this.ToChucVuSanXuatNhatKyService.GetSQLByToChucVuSanXuatVungTrongID_DanhMucNguyenVatLieuPhanLoaiID_ActiveToListAsync().subscribe(
        res => {
          this.ToChucVuSanXuatNhatKyService.list54 = (res as ToChucVuSanXuatNhatKy[])
          for (let index = 0; index < this.ToChucVuSanXuatNhatKyService.list54.length; index++) {
            this.ToChucVuSanXuatNhatKyService.list54[index].IsShow = false;
          }
          this.ToChucVuSanXuatNhatKyService.DataSource54 = new MatTableDataSource(this.ToChucVuSanXuatNhatKyService.list54);
          this.ToChucVuSanXuatNhatKyService.DataSource54.sort = this.ToChucVuSanXuatNhatKySort54;
          this.ToChucVuSanXuatNhatKyService.DataSource54.paginator = this.ToChucVuSanXuatNhatKyPaginator54;
          this.isShowLoading = false;
        },
        err => {
          this.isShowLoading = false;
        }
      );
    }
    this.ToChucVuSanXuatVungTrongChange();
  }
  ToChucVuSanXuatNhatKySave54(element: ToChucVuSanXuatNhatKy) {
    element.ParentID = this.ToChucVuSanXuatService.formData.ID;
    element.ToChucVuSanXuatVungTrongID = this.ToChucVuSanXuatNhatKyService.baseParameter.ToChucVuSanXuatVungTrongID;
    element.DanhMucNguyenVatLieuPhanLoaiID = environment.DanhMucNguyenVatLieuPhanLoaiID54;
    this.ToChucVuSanXuatNhatKyService.formData = element;
    this.ToChucVuSanXuatNhatKyService.SaveAndUploadFilesAsync().subscribe(
      res => {
        this.ToChucVuSanXuatNhatKySearch54();
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }
  ToChucVuSanXuatNhatKyDelete54(element: ToChucVuSanXuatNhatKy) {
    this.isShowLoading = true;
    this.ToChucVuSanXuatNhatKyService.baseParameter.ID = element.ID;
    this.ToChucVuSanXuatNhatKyService.RemoveAsync().subscribe(
      res => {
        this.ToChucVuSanXuatNhatKySearch54();
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }
  ToChucVuSanXuatNhatKyCopy54() {
    if (confirm(environment.Copy54)) {
      this.isShowLoading = true;
      this.ToChucVuSanXuatNhatKyService.baseParameter.DanhMucNguyenVatLieuPhanLoaiID = environment.DanhMucNguyenVatLieuPhanLoaiID54;
      this.ToChucVuSanXuatNhatKyService.CopySQLByToChucVuSanXuatVungTrongID_DanhMucNguyenVatLieuPhanLoaiIDAsync().subscribe(
        res => {
          this.NotificationService.warn(environment.SaveSuccess);
          this.isShowLoading = false;
        },
        err => {
          this.NotificationService.warn(environment.SaveNotSuccess);
          this.isShowLoading = false;
        }
      );
    }
  }
  ToChucVuSanXuatNhatKyCopy54ByID(element: ToChucVuSanXuatNhatKy) {
    if (confirm(environment.CopyByID)) {
      this.isShowLoading = true;
      this.ToChucVuSanXuatNhatKyService.baseParameter.ID = element.ID;
      this.ToChucVuSanXuatNhatKyService.CopySQLByIDAsync().subscribe(
        res => {
          this.ToChucVuSanXuatNhatKySearch54();
          this.NotificationService.warn(environment.SaveSuccess);
          this.isShowLoading = false;
        },
        err => {
          this.NotificationService.warn(environment.SaveNotSuccess);
          this.isShowLoading = false;
        }
      );
    }
  }
  ToChucVuSanXuatNhatKyCopyByID(element: ToChucVuSanXuatNhatKy) {
    if (confirm(environment.CopyByID)) {
      this.isShowLoading = true;
      this.ToChucVuSanXuatNhatKyService.formData = element;
      this.ToChucVuSanXuatNhatKyService.formData.ID = environment.InitializationNumber;
      this.ToChucVuSanXuatNhatKyService.SaveAsync().subscribe(
        res => {
          this.ToChucVuSanXuatNhatKySearch55();
          this.ToChucVuSanXuatNhatKySearch();
          this.ToChucVuSanXuatNhatKySearchIsMuaVo();
          this.NotificationService.warn(environment.SaveSuccess);
          this.isShowLoading = false;
        },
        err => {
          this.NotificationService.warn(environment.SaveNotSuccess);
          this.isShowLoading = false;
        }
      );
    }
  }

  ToChucVuSanXuatNhatKySearch55() {
    if (this.ToChucVuSanXuatNhatKyService.baseParameter.SearchString.length > 0) {
      this.ToChucVuSanXuatNhatKyService.DataSource55.filter = this.ToChucVuSanXuatNhatKyService.baseParameter.SearchString.toLowerCase();
    }
    else {
      this.isShowLoading = true;
      this.ToChucVuSanXuatNhatKyService.baseParameter.DanhMucNguyenVatLieuPhanLoaiID = environment.DanhMucNguyenVatLieuPhanLoaiID55;
      this.ToChucVuSanXuatNhatKyService.baseParameter.Active = true;
      this.ToChucVuSanXuatNhatKyService.GetByToChucVuSanXuatVungTrongID_DanhMucNguyenVatLieuPhanLoaiID_Active_EmptyToListAsync().subscribe(
        res => {
          this.ToChucVuSanXuatNhatKyService.list55 = (res as ToChucVuSanXuatNhatKy[])
          this.ToChucVuSanXuatNhatKyService.DataSource55 = new MatTableDataSource(this.ToChucVuSanXuatNhatKyService.list55);
          this.ToChucVuSanXuatNhatKyService.DataSource55.sort = this.ToChucVuSanXuatNhatKySort55;
          this.ToChucVuSanXuatNhatKyService.DataSource55.paginator = this.ToChucVuSanXuatNhatKyPaginator55;
          this.isShowLoading = false;
        },
        err => {
          this.isShowLoading = false;
        }
      );
    }
  }
  ToChucVuSanXuatNhatKySave55(element: ToChucVuSanXuatNhatKy) {
    element.ParentID = this.ToChucVuSanXuatService.formData.ID;
    element.ToChucVuSanXuatVungTrongID = this.ToChucVuSanXuatNhatKyService.baseParameter.ToChucVuSanXuatVungTrongID;
    element.DanhMucNguyenVatLieuPhanLoaiID = environment.DanhMucNguyenVatLieuPhanLoaiID55;
    this.ToChucVuSanXuatNhatKyService.formData = element;
    this.ToChucVuSanXuatNhatKyService.SaveAndUploadFilesAsync().subscribe(
      res => {
        this.ToChucVuSanXuatNhatKySearch55();
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }
  ToChucVuSanXuatNhatKyDelete55(element: ToChucVuSanXuatNhatKy) {
    this.isShowLoading = true;
    this.ToChucVuSanXuatNhatKyService.baseParameter.ID = element.ID;
    this.ToChucVuSanXuatNhatKyService.RemoveAsync().subscribe(
      res => {
        this.ToChucVuSanXuatNhatKySearch55();
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }
  ToChucVuSanXuatNhatKyCopy55() {
    if (confirm(environment.Copy55)) {
      this.isShowLoading = true;
      this.ToChucVuSanXuatNhatKyService.baseParameter.DanhMucNguyenVatLieuPhanLoaiID = environment.DanhMucNguyenVatLieuPhanLoaiID55;
      this.ToChucVuSanXuatNhatKyService.CopySQLByToChucVuSanXuatVungTrongID_DanhMucNguyenVatLieuPhanLoaiIDAsync().subscribe(
        res => {
          this.ToChucVuSanXuatNhatKySearch55();
          this.NotificationService.warn(environment.SaveSuccess);
          this.isShowLoading = false;
        },
        err => {
          this.NotificationService.warn(environment.SaveNotSuccess);
          this.isShowLoading = false;
        }
      );
    }
  }

  ToChucVuSanXuatNhatKySearchIsMuaVo() {
    if (this.ToChucVuSanXuatNhatKyService.baseParameter.SearchString.length > 0) {
      this.ToChucVuSanXuatNhatKyService.DataSource.filter = this.ToChucVuSanXuatNhatKyService.baseParameter.SearchString.toLowerCase();
    }
    else {
      this.isShowLoading = true;     
      this.ToChucVuSanXuatNhatKyService.baseParameter.IsMuaVo = false;
      this.ToChucVuSanXuatNhatKyService.GetByToChucVuSanXuatVungTrongID_IsMuaVo_Empty001ToListAsync().subscribe(
        res => {
          this.ToChucVuSanXuatNhatKyService.listIsMuaVo = (res as ToChucVuSanXuatNhatKy[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
          this.ToChucVuSanXuatNhatKyService.DataSourceIsMuaVo = new MatTableDataSource(this.ToChucVuSanXuatNhatKyService.listIsMuaVo);
          this.ToChucVuSanXuatNhatKyService.DataSourceIsMuaVo.sort = this.ToChucVuSanXuatNhatKyIsMuaVoSort;
          this.ToChucVuSanXuatNhatKyService.DataSourceIsMuaVo.paginator = this.ToChucVuSanXuatNhatKyIsMuaVoPaginator;
          this.isShowLoading = false;
        },
        err => {
          this.isShowLoading = false;
        }
      );
    }
    this.ToChucVuSanXuatNhatKySearch54();
  }
  ToChucVuSanXuatNhatKySaveIsMuaVo(element: ToChucVuSanXuatNhatKy) {
    element.IsMuaVo = false;
    element.ParentID = this.ToChucVuSanXuatService.formData.ID;
    element.ToChucVuSanXuatVungTrongID = this.ToChucVuSanXuatNhatKyService.baseParameter.ToChucVuSanXuatVungTrongID;
    this.ToChucVuSanXuatNhatKyService.formData = element;
    this.ToChucVuSanXuatNhatKyService.SaveAndUploadFilesAsync().subscribe(
      res => {
        this.ToChucVuSanXuatNhatKySearchIsMuaVo();
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }
  ToChucVuSanXuatNhatKyDeleteIsMuaVo(element: ToChucVuSanXuatNhatKy) {
    this.isShowLoading = true;
    this.ToChucVuSanXuatNhatKyService.baseParameter.ID = element.ID;
    this.ToChucVuSanXuatNhatKyService.RemoveAsync().subscribe(
      res => {
        this.ToChucVuSanXuatNhatKySearchIsMuaVo();
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }

  ToChucVuSanXuatNhatKySearch() {
    if (this.ToChucVuSanXuatNhatKyService.baseParameter.SearchString.length > 0) {
      this.ToChucVuSanXuatNhatKyService.DataSource.filter = this.ToChucVuSanXuatNhatKyService.baseParameter.SearchString.toLowerCase();
    }
    else {
      this.isShowLoading = true;      
      this.ToChucVuSanXuatNhatKyService.baseParameter.IsMuaVo = true;
      this.ToChucVuSanXuatNhatKyService.GetByToChucVuSanXuatVungTrongID_IsMuaVo_Empty001ToListAsync().subscribe(
        res => {
          this.ToChucVuSanXuatNhatKyService.list = (res as ToChucVuSanXuatNhatKy[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
          this.ToChucVuSanXuatNhatKyService.DataSource = new MatTableDataSource(this.ToChucVuSanXuatNhatKyService.list);
          this.ToChucVuSanXuatNhatKyService.DataSource.sort = this.ToChucVuSanXuatNhatKySort;
          this.ToChucVuSanXuatNhatKyService.DataSource.paginator = this.ToChucVuSanXuatNhatKyPaginator;
          this.isShowLoading = false;
        },
        err => {
          this.isShowLoading = false;
        }
      );
    }
    this.ToChucVuSanXuatNhatKySearch54();
  }
  ToChucVuSanXuatNhatKySave(element: ToChucVuSanXuatNhatKy) {
    element.IsMuaVo = true;
    element.ParentID = this.ToChucVuSanXuatService.formData.ID;
    element.ToChucVuSanXuatVungTrongID = this.ToChucVuSanXuatNhatKyService.baseParameter.ToChucVuSanXuatVungTrongID;
    this.ToChucVuSanXuatNhatKyService.formData = element;
    this.ToChucVuSanXuatNhatKyService.SaveAndUploadFilesAsync().subscribe(
      res => {
        this.ToChucVuSanXuatNhatKySearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }
  ToChucVuSanXuatNhatKyDelete(element: ToChucVuSanXuatNhatKy) {
    this.isShowLoading = true;
    this.ToChucVuSanXuatNhatKyService.baseParameter.ID = element.ID;
    this.ToChucVuSanXuatNhatKyService.RemoveAsync().subscribe(
      res => {
        this.ToChucVuSanXuatNhatKySearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }
  ToChucVuSanXuatNhatKyCopy00() {
    if (confirm(environment.Copy00)) {
      this.isShowLoading = true;
      this.ToChucVuSanXuatNhatKyService.CopySQLByToChucVuSanXuatVungTrongIDAsync().subscribe(
        res => {
          this.NotificationService.warn(environment.SaveSuccess);
          this.isShowLoading = false;
        },
        err => {
          this.NotificationService.warn(environment.SaveNotSuccess);
          this.isShowLoading = false;
        }
      );
    }
  }
  ToChucVuSanXuatNhatKyCopy(element: ToChucVuSanXuatVungTrong) {
    if (confirm(environment.CopyNhatKy)) {
      this.isShowLoading = true;
      this.ToChucVuSanXuatNhatKyService.baseParameter.ToChucVuSanXuatVungTrongID = element.ID;
      this.ToChucVuSanXuatNhatKyService.CopyFullSQLByToChucVuSanXuatVungTrongIDAsync().subscribe(
        res => {
          this.NotificationService.warn(environment.SaveSuccess);
          this.isShowLoading = false;
        },
        err => {
          this.NotificationService.warn(environment.SaveNotSuccess);
          this.isShowLoading = false;
        }
      );
    }
  }

  ToChucVuSanXuatVungTrongSearch() {
    if (this.ToChucVuSanXuatVungTrongService.baseParameter.SearchString.length > 0) {
      this.ToChucVuSanXuatVungTrongService.DataSource.filter = this.ToChucVuSanXuatVungTrongService.baseParameter.SearchString.toLowerCase();
    }
    else {
      this.isShowLoading = true;
      this.ToChucVuSanXuatVungTrongService.baseParameter.ParentID = this.ToChucVuSanXuatService.formData.ID;
      this.ToChucVuSanXuatVungTrongService.GetByParentIDToListAsync().subscribe(
        res => {
          this.ToChucVuSanXuatVungTrongService.list = (res as ToChucVuSanXuatVungTrong[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
          this.ToChucVuSanXuatVungTrongService.DataSource = new MatTableDataSource(this.ToChucVuSanXuatVungTrongService.list);
          this.ToChucVuSanXuatVungTrongService.DataSource.sort = this.ToChucVuSanXuatVungTrongSort;
          this.ToChucVuSanXuatVungTrongService.DataSource.paginator = this.ToChucVuSanXuatVungTrongPaginator;
          this.isShowLoading = false;
        },
        err => {
          this.isShowLoading = false;
        }
      );
    }
    this.isShowLoading = true;
    this.ToChucVuSanXuatVungTrongService.baseParameter.ParentID = this.ToChucVuSanXuatService.formData.ID;
    this.ToChucVuSanXuatVungTrongService.baseParameter.Active = true;
    this.ToChucVuSanXuatVungTrongService.GetByParentIDAndActiveToListAsync().subscribe(
      res => {
        this.ToChucVuSanXuatVungTrongService.list001 = (res as ToChucVuSanXuatVungTrong[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        if (this.ToChucVuSanXuatVungTrongService.list001) {
          if (this.ToChucVuSanXuatVungTrongService.list001.length > 0) {
            this.ToChucVuSanXuatNhatKyService.baseParameter.ToChucVuSanXuatVungTrongID = this.ToChucVuSanXuatVungTrongService.list001[0].ID;
            this.ToChucVuSanXuatVungTrongService.formData = this.ToChucVuSanXuatVungTrongService.list001[0];
          }
        }
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  ToChucVuSanXuatVungTrongSave(element: ToChucVuSanXuatVungTrong) {
    element.ParentID = this.ToChucVuSanXuatService.formData.ID;
    this.ToChucVuSanXuatVungTrongService.formData = element;
    this.ToChucVuSanXuatVungTrongService.SaveAsync().subscribe(
      res => {
        this.ToChucVuSanXuatVungTrongSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }
  ToChucVuSanXuatVungTrongChange() {
    for (let i = 0; i < this.ToChucVuSanXuatVungTrongService.list001.length; i++) {
      let ToChucVuSanXuatVungTrong = this.ToChucVuSanXuatVungTrongService.list001[i];
      if (ToChucVuSanXuatVungTrong.ID == this.ToChucVuSanXuatNhatKyService.baseParameter.ToChucVuSanXuatVungTrongID) {
        this.ToChucVuSanXuatVungTrongService.formData = ToChucVuSanXuatVungTrong;
      }
    }
  }
  ToChucVuSanXuatNhatKyIsShow(element: ToChucVuSanXuatNhatKy) {
    element.IsShow = !element.IsShow;
  }
  ToChucVuSanXuatTieuChiSearch() {
    if (this.ToChucVuSanXuatTieuChiService.baseParameter.SearchString.length > 0) {
      this.ToChucVuSanXuatTieuChiService.DataSource.filter = this.ToChucVuSanXuatTieuChiService.baseParameter.SearchString.toLowerCase();
    }
    else {
      this.isShowLoading = true;
      this.ToChucVuSanXuatTieuChiService.baseParameter.ParentID = this.ToChucVuSanXuatService.formData.ID;
      this.ToChucVuSanXuatTieuChiService.GetSQLByParentID_Year_MonthToListAsync().subscribe(
        res => {
          this.ToChucVuSanXuatTieuChiService.list = (res as ToChucVuSanXuatTieuChi[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
          this.ToChucVuSanXuatTieuChiService.DataSource = new MatTableDataSource(this.ToChucVuSanXuatTieuChiService.list);
          this.ToChucVuSanXuatTieuChiService.DataSource.sort = this.ToChucVuSanXuatTieuChiSort;
          this.ToChucVuSanXuatTieuChiService.DataSource.paginator = this.ToChucVuSanXuatTieuChiPaginator;
          this.isShowLoading = false;
        },
        err => {
          this.isShowLoading = false;
        }
      );
    }
  }
  ToChucVuSanXuatTieuChiSave(element: ToChucVuSanXuatTieuChi) {
    element.ParentID = this.ToChucVuSanXuatService.formData.ID;
    this.ToChucVuSanXuatTieuChiService.formData = element;
    this.ToChucVuSanXuatTieuChiService.SaveAsync().subscribe(
      res => {
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }

  ToChucVuSanXuatSave() {
    this.isShowLoading = true;
    this.ToChucVuSanXuatService.SaveAsync().subscribe(
      res => {
        this.ToChucVuSanXuatService.formData = res as ToChucVuSanXuat;
        this.ToChucVuSanXuatVungTrongSearch();
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }

  DateBatDau(value) {
    this.ToChucVuSanXuatService.formData.BatDau = new Date(value);
  }
  DateNgaySanXuat(value) {
    this.ToChucVuSanXuatService.formData.NgaySanXuat = new Date(value);
  }
  DateNgayThuHoach(value) {
    this.ToChucVuSanXuatService.formData.NgayThuHoach = new Date(value);
  }
  DateKetThuc(value) {
    this.ToChucVuSanXuatService.formData.KetThuc = new Date(value);
  }
  DateToChucVuSanXuatNhatKyBatDau(element: ToChucVuSanXuatNhatKy, value) {
    element.BatDau = new Date(value);
  }
  DateToChucVuSanXuatNhatKyKetThuc(element: ToChucVuSanXuatNhatKy, value) {
    element.KetThuc = new Date(value);
  }
  ChangeFileName(files: FileList) {
    if (files) {
      this.ToChucVuSanXuatTapTinDinhKemService.fileToUpload = files;
    }
  }
  ToChucVuSanXuatNhatKyChangeFileName(files: FileList) {
    if (files) {
      this.ToChucVuSanXuatNhatKyService.fileToUpload = files;
    }
  }
  DanhMucVuSanXuatThietBiSearch() {
    this.isShowLoading = true;
    this.DanhMucVuSanXuatThietBiService.baseParameter.Active = true;
    this.DanhMucVuSanXuatThietBiService.GetByActiveToListAsync().subscribe(
      res => {
        this.DanhMucVuSanXuatThietBiService.list = (res as DanhMucVuSanXuatThietBi[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucVuSanXuatThietBiService.list001 = this.DanhMucVuSanXuatThietBiService.list;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucVuSanXuatThietBiFilter(searchString: string) {
    searchString = searchString.trim();
    this.DanhMucVuSanXuatThietBiService.Filter(searchString);
  }
  ToChucVuSanXuatNhatKyFilter(searchString: string) {
    searchString = searchString.trim();
    this.ToChucVuSanXuatNhatKyService.Filter54(searchString);
  }
  ToChucFilter(searchString: string) {
    searchString = searchString.trim();
    this.ToChucService.FilterHaTang(searchString);
  }
  DanhMucVuSanXuatPhanDoanSearch() {
    this.isShowLoading = true;
    this.DanhMucVuSanXuatPhanDoanService.baseParameter.Active = true;
    this.DanhMucVuSanXuatPhanDoanService.GetByActiveToListAsync().subscribe(
      res => {
        this.DanhMucVuSanXuatPhanDoanService.list = (res as DanhMucVuSanXuatPhanDoan[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucVuSanXuatPhanDoanService.list001 = this.DanhMucVuSanXuatPhanDoanService.list;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucNguyenVatLieuPhanLoaiSearch() {
    this.isShowLoading = true;
    this.DanhMucNguyenVatLieuPhanLoaiService.baseParameter.Active = true;
    this.DanhMucNguyenVatLieuPhanLoaiService.GetByActiveToListAsync().subscribe(
      res => {
        this.DanhMucNguyenVatLieuPhanLoaiService.list = (res as DanhMucNguyenVatLieuPhanLoai[]).sort((a, b) => (a.Name > b.Name ? 1 : -1));
        this.DanhMucNguyenVatLieuPhanLoaiService.list001 = this.DanhMucNguyenVatLieuPhanLoaiService.list;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucVuMuaSearch() {
    this.isShowLoading = true;
    this.DanhMucVuMuaService.baseParameter.Active = true;
    this.DanhMucVuMuaService.GetByActiveToListAsync().subscribe(
      res => {
        this.DanhMucVuMuaService.list = (res as DanhMucVuMua[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucVuMuaService.list001 = this.DanhMucVuMuaService.list;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  ToChucSearch() {
    this.isShowLoading = true;
    this.ToChucService.baseParameter.IsZeroCarbonRice = true;
    this.ToChucService.GetByIsZeroCarbonRiceToListAsync().subscribe(
      res => {
        this.ToChucService.list = (res as ToChuc[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
    this.ToChucService.baseParameter.ParentID = environment.DanhMucToChucIDHaTang;
    this.ToChucService.baseParameter.Active = true;
    this.ToChucService.GetByParentIDAndActiveToListAsync().subscribe(
      res => {
        this.ToChucService.listHaTang = (res as ToChuc[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.ToChucService.listHaTang001 = this.ToChucService.listHaTang;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  ToChucVuSanXuatNhatKyAddIn(element: ToChucVuSanXuatNhatKy) {
    this.isShowLoading = true;
    this.ToChucVuSanXuatNhatKyService.formData54 = element;
    this.ToChucVuSanXuatNhatKyService.baseParameter.ID = environment.InitializationNumber;
    this.ToChucVuSanXuatNhatKyService.GetByIDAsync().subscribe(
      res => {
        this.ToChucVuSanXuatNhatKyService.formData = res as ToChucVuSanXuatNhatKy;
        this.ToChucVuSanXuatNhatKyService.formData.ToChucVuSanXuatNhatKyID = element.ID;
        this.ToChucVuSanXuatNhatKyService.formData.IsMuaVo = true;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: element.ID };
        const dialog = this.dialog.open(ToChucVuSanXuatNhatKyDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
        });
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  ToChucVuSanXuatNhatKyAddOut(element: ToChucVuSanXuatNhatKy) {
    this.isShowLoading = true;
    this.ToChucVuSanXuatNhatKyService.formData54 = element;
    this.ToChucVuSanXuatNhatKyService.baseParameter.ID = environment.InitializationNumber;
    this.ToChucVuSanXuatNhatKyService.GetByIDAsync().subscribe(
      res => {
        this.ToChucVuSanXuatNhatKyService.formData = res as ToChucVuSanXuatNhatKy;
        this.ToChucVuSanXuatNhatKyService.formData.ToChucVuSanXuatNhatKyID = element.ID;
        this.ToChucVuSanXuatNhatKyService.formData.IsMuaVo = false;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: element.ID };
        const dialog = this.dialog.open(ToChucVuSanXuatNhatKyComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
        });
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  Close() {
    this.dialogRef.close();
  }
}