import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DownloadService } from 'src/app/shared/Download.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { DanhMucTinhThanh } from 'src/app/shared/DanhMucTinhThanh.model';
import { DanhMucTinhThanhService } from 'src/app/shared/DanhMucTinhThanh.service';
import { DanhMucQuanHuyen } from 'src/app/shared/DanhMucQuanHuyen.model';
import { DanhMucQuanHuyenService } from 'src/app/shared/DanhMucQuanHuyen.service';
import { DanhMucXaPhuong } from 'src/app/shared/DanhMucXaPhuong.model';
import { DanhMucXaPhuongService } from 'src/app/shared/DanhMucXaPhuong.service';

import { ToChuc } from 'src/app/shared/ToChuc.model';
import { ToChucService } from 'src/app/shared/ToChuc.service';


import { ToChucThuongLaiDetailComponent } from '../to-chuc-thuong-lai-detail/to-chuc-thuong-lai-detail.component';

@Component({
  selector: 'app-to-chuc-thuong-lai',
  templateUrl: './to-chuc-thuong-lai.component.html',
  styleUrls: ['./to-chuc-thuong-lai.component.css']
})
export class ToChucThuongLaiComponent implements OnInit {

  ToChucDataSource: MatTableDataSource<any>;
  @ViewChild('ToChucSort') ToChucSort: MatSort;
  @ViewChild('ToChucPaginator') ToChucPaginator: MatPaginator;

  
  isShowLoading: boolean = false;
  searchString: string = environment.InitializationString;
  domainName = environment.DomainDestination;
  DanhMucTinhThanhID: number = environment.InitializationNumber;
  DanhMucQuanHuyenID: number = environment.InitializationNumber;
  DanhMucXaPhuongID: number = environment.InitializationNumber;
  ToChucID: number = environment.InitializationNumber;



  constructor(
    private dialog: MatDialog,

    public NotificationService: NotificationService,
    public DownloadService: DownloadService,


    public DanhMucTinhThanhService: DanhMucTinhThanhService,
    public DanhMucQuanHuyenService: DanhMucQuanHuyenService,
    public DanhMucXaPhuongService: DanhMucXaPhuongService,
  
    public ToChucService: ToChucService,
  ) { }

  ngOnInit(): void {
    this.DanhMucTinhThanhGetToList();
  }  

  
  DanhMucTinhThanhGetToList() {
    this.isShowLoading = true;
    this.DanhMucTinhThanhService.baseParameter.Active = true;
    this.DanhMucTinhThanhService.GetAllToListAsync().subscribe(
      res => {
        this.DanhMucTinhThanhService.list = (res as DanhMucTinhThanh[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucTinhThanhService.list001 = this.DanhMucTinhThanhService.list;
        if (this.DanhMucTinhThanhService.list) {
          if (this.DanhMucTinhThanhService.list.length) {
            this.DanhMucTinhThanhID = this.DanhMucTinhThanhService.list[0].ID;
          }
        }
        this.DanhMucQuanHuyenGetToList();
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucQuanHuyenGetToList() {
    this.isShowLoading = true;
    this.DanhMucQuanHuyenService.baseParameter.ParentID = this.DanhMucTinhThanhID;
    this.DanhMucQuanHuyenService.GetByParentIDToListAsync().subscribe(
      res => {
        this.DanhMucQuanHuyenService.list = (res as DanhMucQuanHuyen[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucQuanHuyenService.list001 = this.DanhMucQuanHuyenService.list;
        this.DanhMucXaPhuongGetToList();
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucXaPhuongGetToList() {
    this.isShowLoading = true;
    this.DanhMucXaPhuongService.baseParameter.ParentID = this.DanhMucQuanHuyenID;
    this.DanhMucXaPhuongService.GetByParentIDToListAsync().subscribe(
      res => {
        this.DanhMucXaPhuongService.list = (res as DanhMucXaPhuong[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucXaPhuongService.list001 = this.DanhMucXaPhuongService.list;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
    this.ToChucSearch();
  }

  DanhMucTinhThanhFilter(searchString: string) {
    this.DanhMucTinhThanhService.Filter(searchString);
  }
  DanhMucQuanHuyenFilter(searchString: string) {
    this.DanhMucQuanHuyenService.Filter(searchString);
  }
  DanhMucXaPhuongFilter(searchString: string) {
    this.DanhMucXaPhuongService.Filter(searchString);
  }

  ToChucGetToList() {
    this.isShowLoading = true;
    this.ToChucService.baseParameter.ParentID = environment.DanhMucToChucIDThuongLai;
    this.ToChucService.baseParameter.DanhMucTinhThanhID = this.DanhMucTinhThanhID;
    this.ToChucService.baseParameter.DanhMucQuanHuyenID = this.DanhMucQuanHuyenID;
    this.ToChucService.baseParameter.DanhMucXaPhuongID = this.DanhMucXaPhuongID;
    this.ToChucService.baseParameter.SearchString = this.ToChucService.SearchString;
    this.ToChucService.GetByParentID_SearchString_DanhMucTinhThanhID_DanhMucQuanHuyenID_DanhMucXaPhuongIDToListAsync().subscribe(
      res => {
        this.ToChucService.list000 = (res as ToChuc[]).sort((a, b) => (a.Name > b.Name ? 1 : -1));
        if (this.ToChucService.list000) {
          if (this.ToChucService.list000.length > 0) {
            this.ToChucID = this.ToChucService.list000[0].ID;
          }
        }
        this.ToChucDataSource = new MatTableDataSource(this.ToChucService.list000);
        this.ToChucDataSource.sort = this.ToChucSort;
        this.ToChucDataSource.paginator = this.ToChucPaginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  
  ToChucSearch() {
    this.ToChucGetToList();
  }

  

  ToChucAdd(ID: any) {
    this.ToChucService.baseParameter.ID = ID;
    this.ToChucService.GetByIDAsync().subscribe(
      res => {
        this.ToChucService.formData = res as ToChuc;
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(ToChucThuongLaiDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
        });
      },
      err => {
      }
    );
  }

 
}