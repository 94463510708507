<div class="app-hero-header d-flex align-items-center">
    <div class="d-flex align-items-center">
        <div class="me-3 icon-box md bg-white rounded-4">
            <i class="bi bi-hypnotize fs-3 text-primary"></i>
        </div>
        <div>
            <h2 class="mb-1">{{ToChucVuSanXuatService.formData.DanhMucVuMuaName}} -
                {{ToChucVuSanXuatService.formData.Code}} [{{ToChucVuSanXuatService.formData.ID}}]</h2>
            <h6 class="m-0 text-dark fw-light">
                Nhật ký sản xuất
            </h6>
        </div>
    </div>
    <div class="ms-auto d-lg-flex d-none flex-row">
        <div class="d-flex flex-row gap-2">
            <a class="btn btn-danger" title="Đóng" (click)="Close()">
                <i class="bi bi-x-lg"></i>
            </a>
        </div>
    </div>
</div>
<div class="app-body">
    <div class="row gx-4">
        <div class="col-xxl-12">
            <div class="card mb-4">
                <div class="card-body">
                    <div class="custom-tabs-container">
                        <ul class="nav nav-tabs justify-content-end" id="customTab5" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" id="tab-ToChucVuSanXuatNhatKyDetail54" data-bs-toggle="tab"
                                    href="#ToChucVuSanXuatNhatKyDetail54" role="tab"
                                    aria-controls="ToChucVuSanXuatNhatKyDetail54" aria-selected="true">
                                    <span class="badge bg-success" style="font-size: 16px;">03: Nhật ký</span>
                                </a>
                            </li>
                        </ul>
                        <div class="tab-content" id="customTabContent">
                            <div *ngIf="ToChucVuSanXuatService.formData.ID>0" class="tab-pane fade show active"
                                id="ToChucVuSanXuatNhatKyDetail54" role="tabpanel">
                                <div class="row gx-4">
                                    <div class="col-lg-4 col-sm-12 col-12">
                                        <select class="form-select"
                                            [(ngModel)]="ToChucVuSanXuatNhatKyService.baseParameter.ToChucVuSanXuatVungTrongID">
                                            <option *ngFor="let item of ToChucVuSanXuatVungTrongService.list001;"
                                                [value]="item.ID">
                                                {{item.Name}} - {{item.Code}}</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-3 col-sm-12 col-12">
                                        <input [(ngModel)]="ToChucVuSanXuatNhatKyService.baseParameter.SearchString"
                                            placeholder="Tìm..." class="form-control"
                                            (keyup.enter)="ToChucVuSanXuatNhatKySearch54()">
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <div class="form-check form-check-inline mb-6">
                                            <input type="checkbox" class="form-check-input" name="IsChungThuc54"
                                                [(ngModel)]="IsChungThuc54"
                                                (change)="ToChucVuSanXuatNhatKyChungThuc54Change()" />
                                            <label class="form-check-label" for="IsChungThuc54">Chứng thực</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-1 col-sm-12 col-12">
                                        <a class="btn btn-success" title="Tìm" style="width: 100%;"
                                            (click)="ToChucVuSanXuatNhatKySearch54()"><i class="bi bi-search"></i></a>
                                    </div>
                                    <div class="col-lg-2 col-sm-12 col-12">
                                        <a class="btn btn-info"
                                            title="Sao chép nhật ký sang vùng trồng khác cùng vụ mùa"
                                            style="width: 100%;" (click)="ToChucVuSanXuatNhatKyCopy54()">Sao chép</a>
                                    </div>
                                </div>
                                <div class="card mb-4 card-success">
                                    <div class="card-header">
                                        <h5 class="card-title text-white">Sổ nhật ký canh tác lúa phát thải thấp của
                                            {{ToChucVuSanXuatVungTrongService.formData.Name}}
                                            <span
                                                *ngIf="ToChucVuSanXuatNhatKyService.list54">({{ToChucVuSanXuatNhatKyService.list54.length}}
                                                items)</span>
                                        </h5>
                                    </div>
                                    <div class="card-body">
                                        <div class="table-outer">
                                            <div class="table-responsive">
                                                <table mat-table matSort #ToChucVuSanXuatNhatKySort54="matSort"
                                                    [dataSource]="ToChucVuSanXuatNhatKyService.DataSource54"
                                                    class="table table-striped m-0">
                                                    <ng-container matColumnDef="IsSaoChep">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sao chép
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="form-check form-check-inline">
                                                                <input class="form-check-input" type="checkbox"
                                                                    name="IsSaoChep{{element.ID}}"
                                                                    [(ngModel)]="element.IsSaoChep">
                                                                <label class="form-check-label" for="IsSaoChep">{{element.ID}}</label>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <ng-container matColumnDef="BatDau">
                                                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                                                            Sổ nhật ký canh tác lúa phát thải thấp
                                                        </th>
                                                        <td mat-cell *matCellDef="let element">
                                                            <div class="row gx-4">
                                                                <div class="col-lg-12 col-sm-12 col-12">
                                                                    <a *ngIf="element.Active && !element.IsChungThuc"
                                                                        style="cursor: pointer; color: green;"
                                                                        title="{{element.Name}}"
                                                                        (click)="ToChucVuSanXuatNhatKyIsShow(element)"><b>{{element.Code}}
                                                                            - {{element.Name}} (Đã hoàn thành)</b></a>
                                                                    <a *ngIf="element.Active && element.IsChungThuc"
                                                                        style="cursor: pointer; color: blue;"
                                                                        title="{{element.Name}}"
                                                                        (click)="ToChucVuSanXuatNhatKyIsShow(element)"><b>{{element.Code}}
                                                                            - {{element.Name}} (Đã chứng thực)</b></a>
                                                                    <a *ngIf="!element.Active"
                                                                        style="cursor: pointer; color: red;"
                                                                        title="{{element.Name}}"
                                                                        (click)="ToChucVuSanXuatNhatKyIsShow(element)"><b>{{element.Code}}
                                                                            - {{element.Name}}</b></a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ng-container>
                                                    <tr mat-header-row
                                                        *matHeaderRowDef="ToChucVuSanXuatNhatKyService.displayColumns0054001">
                                                    </tr>
                                                    <tr mat-row
                                                        *matRowDef="let row; columns: ToChucVuSanXuatNhatKyService.displayColumns0054001;">
                                                    </tr>
                                                </table>
                                                <mat-paginator #ToChucVuSanXuatNhatKyPaginator54="matPaginator"
                                                    [pageSizeOptions]="[10,20,50,100,200,500]" [pageSize]="5"
                                                    [showFirstLastButtons]></mat-paginator>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>