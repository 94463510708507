import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ToChucHopTac } from 'src/app/shared/ToChucHopTac.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class ToChucHopTacService extends BaseService{
    list: ToChucHopTac[] | undefined;
    list001: ToChucHopTac[] | undefined;
    formData!: ToChucHopTac;

    displayColumns001: string[] = ['STT', 'DanhMucLienKetID','ToChucID','DienTich','SanLuong','NamBatDau','Note', 'Save'];
    displayColumns002: string[] = ['STT', 'Display', 'Code', 'Name', 'NgayGhiNhan', 'Save'];
    displayColumns003: string[] = ['STT', 'Display', 'Name', 'Code', 'NgayGhiNhan', 'Save'];
    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "ToChucHopTac";
    }
}

